import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import api from "components/API/api";
import moment from "moment";
import Select from "react-select";
import CustomCalendar from "components/Calendar/CustomerCalenderForMonth";

const filterOptions = [
  { value: "all", label: "All Assigned Data" },
  { value: "pickup/dropoff", label: "Two Pickups" },
  { value: "pickup", label: "One Pickup Only" },
  { value: "nothing", label: "No Pickup" },
];

function UserRideHistory({ userId, agency }) {
  const [rideData, setRideData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filter, setFilter] = useState({ value: "all", label: "All Assigned Workers" });
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [date, setDate] = useState({
    from: new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1),
    till: new Date(selectedDate.getFullYear(), (selectedDate.getMonth() + 1), 1),
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getWorkerRideHistory();
  }, [date.from, date.till]);

  useEffect(() => {
    if (filter) {
      filterData();
    }
  }, [filter]);

  //function to get history
  const getWorkerRideHistory = async () => {
    try {
      const { data } = await api().get("/driver-pickup/employee/assigned", {
        params: {
          ...date,
          employeeId: userId,
        },
      });
      // console.log("data", data);
      let tempHistory = [];
      data.map((d) => {
        tempHistory.push({
          date: moment(d.date).format("MMM Do YYYY"),
          note: d.assignedEmployees[0]?.note,
          shiftTiming: d.assignedEmployees[0]?.shiftTiming,
          firstPickup:
            d?.assignedEmployees[0]?.firstPickup?.time &&
            moment(d.assignedEmployees[0].firstPickup.time).format("h:mm a"),
          secondPickup:
            d?.assignedEmployees[0]?.secondPickup?.time &&
            moment(d.assignedEmployees[0].secondPickup.time).format("h:mm a"),
          driver: d?.driverName,
          assignedBy: d.assignedEmployees[0]?.autoAssigned
            ? "Driver (Auto-assigned)"
            : d.assignedEmployees[0]?.assignedBy,
        });
      });
      setRideData(tempHistory);
      setFilteredData(tempHistory);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  //filter data based on selected filter option
  const filterData = () => {
    console.log("filter.value", filter.value);
    let tempData = [];
    if (filter.value === "all") {
      tempData = rideData;
    } else if (filter.value === "pickup/dropoff") {
      rideData.map((u) => {
        if (u.firstPickup && u.secondPickup) tempData.push(u);
      });
    } else if (filter.value === "pickup") {
      rideData.map((u) => {
        if (u.firstPickup && !u.secondPickup) tempData.push(u);
      });
    } else {
      rideData.map((u) => {
        if (!u.firstPickup && !u.secondPickup) tempData.push(u);
      });
    }
    setFilteredData(tempData);
  };

  const onChangeDate = (date) => {
    // console.log(new Date(date).getFullYear());
    let tempDate = new Date(date);
    setSelectedDate(tempDate);
    setDate({
      from: new Date(tempDate.getFullYear(), tempDate.getMonth(), 1),
      till: new Date(tempDate.getFullYear(), (tempDate.getMonth() + 1), 1),
    });
  };

  return (
    <div className="content">
      <CardHeader>
        <CardTitle tag="h4">Driver Ride History</CardTitle>
      </CardHeader>
      <CardBody>
        <Row className="justify-content-between">
          <Col lg="8" md="8"></Col>
          <Col lg="4" md="4">
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              options={filterOptions}
              defaultValue={filter}
              onChange={(value) => {
                // console.log(value);
                setFilter(value);
              }}
              placeholder="Select"
            />
          </Col>
        </Row>
        <CustomCalendar
          myDate={selectedDate}
          onChangeDate={onChangeDate}
          disableFutureDate={true}
        />

        <Row style={{ marginTop: 20 }}>
          <Col>
            <ReactTable
              filterable
              columns={[
                {
                  Header: "Date",
                  accessor: "date",
                },
                {
                  Header: "Note",
                  accessor: "note",
                },
                {
                  Header: "Shift Timing",
                  accessor: "shiftTiming",
                },
                {
                  Header: "First Pickup",
                  accessor: "firstPickup",
                  filterable: false,
                },
                {
                  Header: "Second Pickup",
                  accessor: "secondPickup",
                  filterable: false,
                },
                {
                  Header: "Driver",
                  accessor: "driver",
                },
                {
                  Header: "Assigned By",
                  accessor: "assignedBy",
                },
              ]}
              // manual
              data={filteredData}
              loading={isLoading}
              defaultPageSize={10}
              pageSizeOptions={[10, 15, 30, 60]}
              showPaginationTop={true}
              showPaginationBottom={false}
              // onFetchData={(state, instance) => {
              //   let searchParams = {
              //     page: state.page + 1,
              //     size: state.pageSize,
              //     field: state.sorted.length ? state.sorted[0].id : "hdId",
              //     sort: state.sorted.length ? (state.sorted[0].desc ? "desc" : "asc") : "desc",
              //     filter: state.filtered,
              //   };
              //   setReactTableState(searchParams);
              //   console.log("fetch");
              //   // setTimer(
              //   //   setTimeout(() => {
              //   //     setLoadingTable(true);
              //   getWorkerShiftHistory(searchParams);
              //   //   }, 1000),
              //   // );
              //   // clearTimeout(timer);
              // }}
              className="-striped -highlight primary-pagination"
            />
          </Col>
        </Row>
      </CardBody>
    </div>
  );
}

export default UserRideHistory;
