import React from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Media,
  Table,
  InputGroup,
  Input,
  InputGroupAddon,
  Button,
} from "reactstrap";
//import Breadcrumbs from "../../../components/@vuexy/breadCrumbs/BreadCrumb"
// import logo from "../../../assets/img/logo/logo.png"
// import { Mail, Phone, FileText, Download } from "react-feather"

// import "../../../assets/scss/pages/invoice.scss"

export default function RegisterSuccess(props) {
  return (
    <>
      {/* <Breadcrumbs
          breadCrumbTitle="Invoice"
          breadCrumbParent="Pages"
          breadCrumbActive="Invoice"
        /> */}
      <Row>
        <Col
          className="d-flex flex-column flex-md-row justify-content-end invoice-header mb-1"
          md="7"
          sm="12"
        >
          <Button color="primary" outline onClick={() => window.print()}>
            <span className="align-middle ml-50">Print</span>
          </Button>
        </Col>
        <Col className="invoice-wrapper" sm="12">
          <Card className="invoice-page">
            <CardBody>
              <Row>
                <Col md="6" sm="12" className="pt-1"></Col>
                <Col md="6" sm="12" className="text-right">
                  <h1>Invoice</h1>
                  <div className="invoice-details mt-2">
                    <h6>INVOICE NO.</h6>
                    <p>
                      {props.registration &&
                        props.registration.paymentData &&
                        props.registration.paymentData.invoiceNo}
                    </p>
                    <h6 className="mt-2">INVOICE DATE</h6>

                    <p>
                      {props.registration &&
                        props.registration.paymentData &&
                        props.registration.paymentData.invoiceDate}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="pt-2">
                <Col md="6" sm="12">
                  <h5>Recipient</h5>
                  <div className="recipient-info my-2">
                    <p>
                      {props.registration &&
                        props.registration.user &&
                        props.registration.user.name}
                    </p>
                    <p>
                      {props.registration &&
                        props.registration.user &&
                        props.registration.user.address}
                    </p>
                  </div>
                  <div className="recipient-contact pb-2">
                    <p>
                      {props.registration &&
                        props.registration.user &&
                        props.registration.user.email}
                    </p>
                    <p>
                      {props.registration &&
                        props.registration.user &&
                        props.registration.user.phone}
                    </p>
                  </div>
                </Col>
                <Col md="6" sm="12" className="text-right">
                  <h5>HD group of Companies</h5>
                  <div className="company-info my-2">
                    <p>Etobicoke, ON</p>
                    <p>94203</p>
                  </div>
                  <div className="company-contact">
                    <p>contact@hd-group.com</p>
                    <p>+91 999 999 9999</p>
                  </div>
                </Col>
              </Row>
              <div className="invoice-items-table pt-1">
                <Row>
                  <Col sm="12">
                    <Table responsive borderless>
                      <thead>
                        <tr>
                          <th>PLAN</th>
                          <th>PACKAGE</th>
                          <th>AMOUNT</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {props.registration &&
                              props.registration.plan &&
                              props.registration.plan.name}
                          </td>
                          <td>
                            {props.registration &&
                              props.registration.plan &&
                              props.registration.plan.package}
                          </td>
                          <td>
                            $
                            {props.registration &&
                              props.registration.paymentData &&
                              props.registration.paymentData.subTotal}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
              <div className="invoice-total-table">
                <Row>
                  <Col sm={{ size: 7, offset: 5 }} xs={{ size: 7, offset: 5 }}>
                    <Table responsive borderless>
                      <tbody>
                        <tr>
                          <th>SUBTOTAL</th>
                          <td>
                            ${" "}
                            {props.registration &&
                              props.registration.paymentData &&
                              props.registration.paymentData.subTotal}
                          </td>
                        </tr>
                        <tr>
                          <th>TAX</th>
                          <td>
                            ${" "}
                            {props.registration &&
                              props.registration.paymentData &&
                              props.registration.paymentData.tax}
                          </td>
                        </tr>
                        <tr>
                          <th>TOTAL</th>
                          <td>
                            ${" "}
                            {props.registration &&
                              props.registration.paymentData &&
                              props.registration.paymentData.subTotal}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}
