import { Modal } from "@mui/material";
import { statusEnum } from "utils/statusVariables";
import { Button , FormGroup, } from "reactstrap";

const RemoveUserModal = ({
  openModal,
  confirmUser,
  handleCloseRemoveUserModal
}) => {
  return (
    <Modal
      open={openModal.removeUser}
      onClose={handleCloseRemoveUserModal}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <FormGroup style={{ width: "500px", backgroundColor: "#fff", borderRadius: 10 }}>
        <div style={{ padding: 25 }}>
          <div style={{ justifyContent: "center", display: "flex" }}>
            <h5>Do you want to reject this user to work in this shift?</h5>
          </div>
          <div style={{ textAlign: "right", justifyContent: "space-between", display: "flex" }}>
            <Button color="secondary" onClick={() => handleCloseRemoveUserModal()}>
              Cancel
            </Button>
            <Button
              color="danger"
              onClick={() => confirmUser(statusEnum.selectedForWork.Rejected)}
            >
              Reject
            </Button>
          </div>
        </div>
      </FormGroup>
    </Modal>
   );
  };

  export default RemoveUserModal;