/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
// react plugin that creates an input with badges
import TagsInput from "react-tagsinput";
import Select from "react-select";
import { useParams } from "react-router-dom";
// reactstrap components
import { Modal } from "@mui/material";
import Resizer from "react-image-file-resizer";
import {
  Button,
  Label,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  FormText,
  Row,
  Col,
  Spinner,
} from "reactstrap";

// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";

// core components
import DashboardNavbar from "../components/DashboardNavbar.jsx";

import FooterBlack from "components/Footers/FooterBlack.js";

import ImageUpload from "components/CustomUpload/ImageUpload.js";

import api, { baseURL } from "components/API/api";

import LoadingOverlay from "components/OverLay/LoadingOverlay";

import SweetAlert from "react-bootstrap-sweetalert";

import AllPlans from "../plans/AllPlans";
import moment from "moment";
import { Card } from "reactstrap";
import Checkout from "../payment/Checkout.jsx";
import useWindowDimensions from "./../../../components/Hooks/useWindowDimensions";

const EMPTY_NEW_PLAN = { plan: null, paymentMethod: null };

export default function BusinessDetail(props) {
  document.documentElement.classList.remove("nav-open");

  const [image, setImage] = useState("");
  const { businessId } = useParams();
  const [business, setBusiness] = useState(null);
  const [payment, setPayment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState(null);
  const [slugLoading, setSlugLoading] = useState(false);
  const [logoUploadLoading, setLogoUploadLoading] = useState(false);
  const [openChangePlanModal, setOpenChangePlanModal] = useState(false);
  const [newPlan, setNewPlan] = useState(EMPTY_NEW_PLAN);
  const dimensions = useWindowDimensions();

  useEffect(() => {
    let logo = businessId && `${baseURL}/agency/${businessId}/logo`;
    setImage(logo);
    loadBusinessDetail();
    loadPaymentDetails();
  }, []);

  const loadBusinessDetail = () => {
    api()
      .get(`/agency/${businessId}`)
      .then((res) => {
        setBusiness(res.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const loadPaymentDetails = () => {
    api()
      .get(`/agency/payment/${businessId}`)
      .then((res) => {
        setPayment(res.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const resizeFile = async (file) =>
    await new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        150,
        150,
        "png",
        50,
        0,
        (uri) => {
          resolve(uri);
        },
        "blob",
      );
    });

  const uploadLogo = async (image64, imageFile, imageName) => {
    //console.log(image);
    //console.log(imageState);
    setLogoUploadLoading(true);
    console.log(imageFile);
    const axiosConfig = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    let formData = new FormData();
    formData.append("id", businessId);
    formData.append("fileName", "logo");

    const file = await resizeFile(imageFile);
    formData.append("file", file);
    api(axiosConfig)
      .post("/agency/upload-image", formData)
      .then(() => {
        console.log("successfully uploaded image");
        setLogoUploadLoading(false);
        let logo = businessId && `${baseURL}/agency/${businessId}/logo`;
        console.log(logo);
        setImage(image64);
      })
      .catch(() => {
        setLogoUploadLoading(false);
      });
  };
  const removeLogo = async () => {
    setLogoUploadLoading(true);
    api()
      .post("/agency/remove-image", { id: businessId, imageName: "logo" })
      .then(() => {
        console.log("successfully removed image");

        setImage("");
        setLogoUploadLoading(false);
      })
      .catch(() => {
        setLogoUploadLoading(false);
      });
  };

  const setBusinessSlug = (slug) => {
    //check if slug is avaliable
    let slugError = "";
    if (slug.match(/[\s]/gm)) {
      slugError = "Business url mustn't contain whitespace.";
    } else if (slug.match(/[A-Z]/gm)) {
      slugError = "Business url cannot be uppercase.";
    } else if (slug.match(/[^\w-]/gm)) {
      slugError = "Business url contains invalid characters";
    }
    if (slugError) {
      setErrors({ ...errors, slugError });
      return;
    } else {
      setSlugLoading(true);
      setErrors({ ...errors, slugError: "" });
      setBusiness({ ...business, slug: slug });
    }
  };

  const updateAgency = async () => {
    try {
      setLoading(true);
      await api().patch("/agency", business);
      await loadBusinessDetail();
      setAlert(
        <SweetAlert
          success
          title="Successfully updated business data."
          onConfirm={hideAlert}
          showConfirm={true}
        />,
      );
    } catch (e) {
      setLoading(false);
      setErrors({ ...errors, slugError: "The slug is invalid" });
      setAlert(
        <SweetAlert
          error
          title="Sorry! couldn't update the business."
          onConfirm={hideAlert}
          showConfirm={true}
        >
          {e.response && e.response.data && <div>{e.response.data.message}</div>}
        </SweetAlert>,
      );
    }
  };

  const confirmPayment = async (paymentMethod) => {
    //TODO: send data to backend
    setLoading(true);
    try {
      const resp = await api().patch(`/agency/subscription`, {
        agencyId: businessId,
        paymentMethod,
        plan: newPlan.plan,
      });

      setOpenChangePlanModal(false);
      await loadPaymentDetails();
      setLoading(false);
    } catch (error) {
      console.log(error);
      console.log(error.response);
      setLoading(false);

      setAlert(
        <SweetAlert error title={"There was a problem signing up"} onConfirm={hideAlert}>
          {" "}
          {}
        </SweetAlert>,
      );
    }
  };

  const cancelSubscription = async () => {
    setAlert(
      <SweetAlert
        title={"Are you sure you would like to cancel your subscription?"}
        onCancel={hideAlert}
        onConfirm={confirmCancelSubscription}
        showCancel={true}
        confirmBtnText={"Yes"}
        cancelBtnText={"No"}
        cancelBtnBsStyle={"primary"}
        confirmBtnBsStyle={"danger"}
      >
        {" "}
        You will no longer be able to access your business data.
      </SweetAlert>,
    );
  };

  const confirmCancelSubscription = async () => {
    hideAlert();
    setLoading(true);
    try {
      const resp = await api().delete(`/agency/subscription/${businessId}`);
      await loadPaymentDetails();
      setAlert(
        <SweetAlert title={"Subscription canceled successfully"} onConfirm={hideAlert}>
          {" "}
          {}
        </SweetAlert>,
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setAlert(
        <SweetAlert error title={"There was a problem signing up"} onConfirm={hideAlert}>
          {" "}
          {}
        </SweetAlert>,
      );
    }
  };

  const hideAlert = () => {
    setAlert(null);
  };

  return (
    <>
      <DashboardNavbar />
      <div className="main add-product" style={{ marginTop: "20" }}>
        <div className="section">
          <Container style={{ marginTop: "50px" }}>
            {loading && <LoadingOverlay />}
            <Row>
              <Col md="2"></Col>
              <Col md="3" sm="5" style={{ textAlign: "center" }}>
                <h6>Business Logo</h6>
                {logoUploadLoading ? (
                  <Spinner />
                ) : (
                  <ImageUpload
                    preview={image}
                    onChange={uploadLogo}
                    onRemove={() => {
                      removeLogo();
                    }}
                    previewText="Add New Logo"
                  />
                )}
              </Col>
              <Col md="5" sm="7">
                <FormGroup>
                  <h6>
                    Business Name <span className="icon-danger">*</span>
                  </h6>
                  <Input
                    className="border-input"
                    placeholder=""
                    type="text"
                    value={business && business.name}
                    onChange={(e) => {
                      setBusiness({ ...business, name: e.target.value });
                    }}
                    name="name"
                  />
                </FormGroup>
                <FormGroup>
                  <h6>
                    Address <span className="icon-danger">*</span>
                  </h6>
                  <Input
                    className="border-input"
                    placeholder=""
                    type="text"
                    value={business && business.address}
                    onChange={(e) => {
                      setBusiness({ ...business, address: e.target.value });
                    }}
                    name="address"
                  />
                </FormGroup>
                <FormGroup>
                  <h6>
                    City <span className="icon-danger">*</span>
                  </h6>
                  <Input
                    className="border-input"
                    placeholder=""
                    type="text"
                    value={business && business.city}
                    onChange={(e) => {
                      setBusiness({ ...business, city: e.target.value });
                    }}
                    name="city"
                  />
                </FormGroup>
                <FormGroup>
                  <h6>
                    Business Slug <span className="icon-danger">*</span>
                  </h6>
                  <Input
                    className="border-input"
                    defaultValue=""
                    placeholder=""
                    type="text"
                    value={business && business.slug}
                    onChange={(e) => {
                      setBusinessSlug(e.target.value);
                    }}
                    name="slug"
                    autoComplete="false"
                  />
                  <FormText color="danger" tag="span">
                    {errors.slugError}
                  </FormText>
                </FormGroup>
                <h6>Subscription Plan</h6>
                <FormGroup>
                  {payment && (
                    <Card className="no-transition" style={{ padding: "8px" }}>
                      <h4>{payment.subscription.name}</h4>
                      <p>{payment.subscription.details}</p>
                      <p>Status: {payment.status}</p>
                      <p>Start date: {moment(payment.startDate).format("L")}</p>
                      {payment.endDate && <p>End date: {moment(payment.endDate).format("L")}</p>}
                      {!payment.endDate && payment.nextCharge && (
                        <p>Next charge: {moment(payment.nextCharge).format("L")}</p>
                      )}
                      {!payment && <Spinner />}
                    </Card>
                  )}
                  <FormText color="success" tag="span"></FormText>
                </FormGroup>
                {payment && !payment.endDate && (
                  <Button onClick={cancelSubscription}>Cancel Plan</Button>
                )}
                <Button
                  onClick={() => {
                    setOpenChangePlanModal(true);
                    setNewPlan(EMPTY_NEW_PLAN);
                  }}
                >
                  Change plan
                </Button>
                <FormGroup>
                  <h6>Business Status</h6>
                  <Switch
                    offColor="success"
                    offText={<i className="nc-icon nc-simple-remove" />}
                    onColor="success"
                    onText={<i className="nc-icon nc-check-2" />}
                    value={business && business.active}
                  />
                  <label></label>
                </FormGroup>
                <Row>
                  <Col md="6">
                    <Button
                      block
                      className="btn-round"
                      color="danger"
                      outline
                      type="reset"
                      size="md"
                      onClick={() => {
                        props.history.push("/staffing/dashboard");
                      }}
                    >
                      Back
                    </Button>
                  </Col>
                  <Col md="6">
                    <Button
                      block
                      className="btn-round"
                      color="primary"
                      outline
                      type="submit"
                      size="md"
                      onClick={updateAgency}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col md="2"></Col>
            </Row>

            <Modal
              open={openChangePlanModal}
              onClose={() => {
                setOpenChangePlanModal(false);
              }}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ minWidth: dimensions.width * 0.7 }}>
                {!newPlan.plan && (
                  <AllPlans
                    onSelect={(plan) => {
                      setNewPlan({ ...newPlan, plan });
                    }}
                  />
                )}
                {newPlan.plan && (
                  <div style={{ background: "white", padding: "16px" }}>
                    <h4>Payment</h4>
                    <Checkout plan={newPlan.plan} onConfirm={confirmPayment} />
                  </div>
                )}
              </div>
            </Modal>
          </Container>
        </div>
      </div>
      {alert}
      <FooterBlack />
    </>
  );
}
