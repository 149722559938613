import React from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import PropTypes from "prop-types";

let availability = {
  // 0: Monday
  active: false,
  m: false,
  a: false,
  n: false,
};

export const initialAvailability = [
  { ...availability },
  { ...availability },
  { ...availability },
  { ...availability },
  { ...availability },
  { ...availability },
  { ...availability },
];

export default function RenderAvailability({
  onChange,
  availability = initialAvailability.map((a) => ({ ...a })),
  disabled
}) {
  const toggleShifts = (MAN, day) => {
    onChange(
      availability.map((temp, i) => {
        if (i === day) {
          MAN === "m"
            ? (temp.m = !temp.m) // m
            : MAN === "a"
            ? (temp.a = !temp.a) // a
            : (temp.n = !temp.n); // n
        }
        return temp;
      }),
    );
  };

  const toggleCheckMark = (day) => {
    onChange(
      availability.map((temp, i) => {
        if (i === day) {
          temp.active = !temp.active;
        }
        return temp;
      }),
    );
  };

  const renderAvailability = (dayOfWeekText, day) => {
    let active = availability[day] ? availability[day].active : false;
    return (
      <Row key={day}>
        <Col sm="3">
          <FormGroup check inline>
            <Label check>
              <Input type="checkbox" 
              checked={active} 
              onChange={() => toggleCheckMark(day)} 
              disabled={disabled || false}
              />
              <span className="form-check-sign" />
              {dayOfWeekText}
            </Label>
          </FormGroup>
        </Col>
        {active ? (
          <>
            <Col sm="9" align="right">
              <FormGroup check inline>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={availability[day].m}
                    onChange={() => toggleShifts("m", day)}
                    disabled={disabled || false}
                  />
                  <span className="form-check-sign" />
                  Morning
                </Label>
              </FormGroup>

              <FormGroup check inline>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={availability[day].a}
                    onChange={() => toggleShifts("a", day)}
                    disabled={disabled || false}
                  />
                  <span className="form-check-sign" />
                  Afternoon
                </Label>
              </FormGroup>

              <FormGroup check inline>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={availability[day].n}
                    onChange={() => toggleShifts("n", day)}
                    disabled={disabled || false}
                  />
                  <span className="form-check-sign" />
                  Night
                </Label>
              </FormGroup>
            </Col>
          </>
        ) : null}
      </Row>
    );
  };
  return availability ? (
    <div>
      {renderAvailability("Monday", 0)}
      {renderAvailability("Tuesday", 1)}
      {renderAvailability("Wednesday", 2)}
      {renderAvailability("Thursday", 3)}
      {renderAvailability("Friday", 4)}
      {renderAvailability("Saturday", 5)}
      {renderAvailability("Sunday", 6)}
    </div>
  ) : null;
}

RenderAvailability.propTypes = {
  onChange: PropTypes.func,
  availability: PropTypes.object,
};
