import React, { useState, useEffect } from "react";
// reactstrap components
import {
  CardHeader,
  CardBody,
  Label,
  Row,
  Col,
  Input,
  FormGroup,
} from "reactstrap";
import ReactTable from "react-table";
import api from "components/API/api";
import moment from "moment";
import CustomCalendar from "components/Calendar/CustomerCalenderForMonth";
import { statusEnum } from "utils/statusVariables";
import CheckInTimeCell from "views/Agency/LiveSelectedUsers/CheckInTimeCell";

const statusEnumText = [
  "Applied",
  "Selected",
  "Backup",
  "Rejected",
  "UnScheduled",
  "Dropped",
  "ComputerDropped",
];
const UnScheduleReasonList = [
  "User Bailed",
  "User Didn't Pickup Call",
  "User DidNot Go For Other Reasons",
  "Scheduled By Mistake",
  "Cancelled Due To Less Work",
  "Cancelled By Plant",
  "User Was Sent Back",
];

function UserShiftHistory({ userId, agency }) {
  const [shiftHistory, setShiftHistory] = useState([]);
  //react table states
  const [reactTableState, setReactTableState] = useState({
    page: 1,
    size: 10,
    field: "start",
    sort: "desc",
    filter: [],
  });
  const [loadingTable, setLoadingTable] = useState(false);
  const [date, setDate] = useState(new Date());
  const [pages, setPages] = useState(-1);
  const [filter, setFilter] = useState({
    status: null, /// used to get confirmed status
    from: new Date(date.getFullYear(), date.getMonth(), 1),
    till: new Date(date.getFullYear(), (date.getMonth() + 1), 1),
  });

  useEffect(() => {
    getWorkerShiftHistory();
    setLoadingTable(true);
  }, [filter.status, filter.from, filter.till]);

  //function to get history
  const getWorkerShiftHistory = async (filterOptions = reactTableState) => {

    try {
      let d = new Date();
      let params;
      if (Number.isInteger(filter.status)) {
        params = {
          ...filterOptions,
          ...filter,
          userId,
          agency,
        };
      } else {
        params = {
          ...filterOptions,
          ...filter,
          all: filter.status === "all" ? true : false,
          userId,
          agency,
        };
      }

      const { data } = await api().get("/selected-for-work/user", { params });

      let tempHistory = [];
      data.history.map((d) => {
        const clockIn = d.check?.in;
        const clockOut = d.check?.out;
        let history = {
          _id: d._id,
          business: d.shift.businessName,
          address: d.shift.businessIntersection,
          end: moment(d.end).format("MMM Do YYYY, h:mm a"),
          start: moment(d.start).format("MMM Do YYYY, h:mm a"),
          pay: d.shift.payRate ? `$ ${d.shift.payRate}` : d.shift.payRate,
          description: d.shift.description,
          shiftName: d.shift.shiftName,
          status: statusEnumText[d.status] || "Confirmed",
          unscheduleReason: UnScheduleReasonList[d.unscheduleReason],
          clockIn,
          clockOut,
          startTime: d.start,
          endTime: d.end,
        };
        tempHistory.push(history);
      });
      setPages(data.noPages);
      setShiftHistory(tempHistory);
      setLoadingTable(false);
    } catch (e) {
      console.log(e);
      setLoadingTable(false);
    }
  };

  const onChangeDate = (date) => {
    let tempDate = new Date(date);
    setDate(tempDate);
    setFilter({
      ...filter,
      from: new Date(tempDate.getFullYear(), tempDate.getMonth(), 1),
      till: new Date(tempDate.getFullYear(), (tempDate.getMonth() + 1), 1),
    });
  };

  return (
    <div className="content">
      <CardHeader>
        <h4>Shift History</h4>
      </CardHeader>
      <CardBody>
        <Row className="justify-content-between">
          <Col lg="6" md="6"></Col>
          <Col lg="6" md="6">
            <div style={{ textAlign: "right" }}>
              <FormGroup row className="align-items-right">
                <Label for="Search" sm={3} style={{ color: "black", fontSize: "14px" }}>
                  Worker Status
                </Label>
                <Col sm={9}>
                  <Input
                    type="select"
                    name="status"
                    onChange={(e) => {
                      setFilter({ ...filter, status: e.target.value === "null" ? null : e.target.value });
                    }}
                    value={filter.status || ""}
                  >
                    <option key={0} value={"null"}>
                      Confirmed (Selected and Backup)
                    </option>

                    {Object.values(statusEnum.selectedForWork)
                      .map((b, i) => {
                        return (
                          <option key={i} value={b}>
                            {statusEnumText[i]}
                          </option>
                        );
                      })}
                    <option key={null} value={"all"}>
                      All
                    </option>
                  </Input>
                </Col>
              </FormGroup>
            </div>
          </Col>
        </Row>
        <CustomCalendar myDate={date} onChangeDate={onChangeDate} disableFutureDate={true} />

        <ReactTable
          filterable
          columns={[
            {
              Header: "BUSINESS",
              accessor: "business",
            },
            {
              Header: "ADDRESS",
              accessor: "address",
            },
            {
              Header: "START",
              accessor: "start",
            },
            {
              Header: "END",
              accessor: "end",
            },
            {
              Header: "Clocked In",
              accessor: "clockIn",
              filterable: false,
              Cell: (row) => {
                return (
                  <CheckInTimeCell
                    checkIn={row.original.clockIn}
                    selectedForWorkId={row.original._id}
                    onChange={() => {
                      getWorkerShiftHistory();
                    }}
                    defaultTime={row.original.startTime}
                    type="in"
                  />
                );
              },
            },
            {
              Header: "Clocked Out",
              accessor: "clockOut",
              filterable: false,
              Cell: (row) => {
                return (
                  <CheckInTimeCell
                    checkIn={row.original.clockOut}
                    selectedForWorkId={row.original._id}
                    defaultTime={row.original.endTime}
                    onChange={() => {
                      getWorkerShiftHistory();
                    }}
                    type="out"
                  />
                );
              },
            },
            {
              Header: "Shift",
              accessor: "shiftName",
            },
            filter.status === 4
              ? {
                  Header: "UnSchedule Reason",
                  accessor: "unscheduleReason",
                  maxWidth: 150,
                }
              : {
                  Header: "Status",
                  accessor: "status",
                  maxWidth: 150,
                },
            {
              Header: "PAY/HOUR",
              accessor: "pay",
              maxWidth: 80,
            },
          ]}
          manual
          data={shiftHistory}
          pages={pages}
          loading={loadingTable}
          defaultPageSize={10}
          pageSizeOptions={[10, 15, 30, 60]}
          showPaginationTop={true}
          showPaginationBottom={false}
          onFetchData={(state, instance) => {
            let searchParams = {
              page: state.page + 1,
              size: state.pageSize,
              field: "start",
              sort: state.sorted.length ? (state.sorted[0].desc ? "desc" : "asc") : "desc",
              filter: state.filtered,
            };
            setReactTableState(searchParams);
            getWorkerShiftHistory(searchParams);
          }}
          className="-striped -highlight primary-pagination"
        />
      </CardBody>
    </div>
  );
}

export default UserShiftHistory;
