export const csvDataHeaders = [
  {
    label: "PRODUCTION NO",
    key: "productionNo",
  },
  {
    label: "FIRST NAME",
    key: "firstName",
  },
  {
    label: "LAST NAME",
    key: "lastName",
  },
  {
    label: "BADGE ID",
    key: "workerPin",
  },
  {
    label: "HDID",
    key: "hdId",
  },
  {
    label: "TIME",
    key: "time",
  },
  {
    label: "GENDER",
    key: "gender",
  },
  {
    label: "VACCINE STATUS",
    key: "vaccineStatus",
  },
  {
    label: "AGENCY",
    key: "agency",
  },
  {
    label: "DEPT",
    key: "dept",
  },
];

export const listneningSockets = [
  "selectedForWork",
  "deSelectedForWork",
  "shiftUpdated",
  "apply",
  "accepted",
  "rejected",
  "signIn",
];
