/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useContext, useEffect } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import { Redirect } from "react-router-dom";
import { CSVLink } from "react-csv";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Spinner,
  Label,
} from "reactstrap";

import { Visibility, PersonAdd, AddCircleOutline, Clear } from "@mui/icons-material";
import { Modal } from "@mui/material";
import AgencyContext from "../../../../context/AgencyContext";
import * as utils from "components/Utilities/utilities.js";
import api from "components/API/api.js";
import { Input } from "reactstrap";
import BusinessTab from "components/BusinessTab";
import { useHasPermissions } from "hooks/usePermissions";

function AllStaff(props) {
  const context = useContext(AgencyContext);
  const [staffData, setStaffData] = useState([]);
  const [filteredStaff, setFilteredStaff] = useState([]);
  const [staffDataHeaders, setStaffDataHeaders] = useState([
    {
      label: "Staff HDID",
      key: "hdId",
    },
    {
      label: "Staff firstName",
      key: "firstName",
    },
    {
      label: "Staff lastName",
      key: "lastName",
    },
    {
      label: "Contact Email",
      key: "email",
    },
    {
      label: "Contact Phone Number",
      key: "phone",
    },
  ]);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [selectedStaffId, setSelectedStaffId] = useState(null);
  const [selectedDocId, setSelectedDocId] = useState(null);
  const [selectedProfileId, setSelectedProfileId] = useState(null);
  const [selectedFirstName, setSelectedFirstName] = useState(null);
  const [selectedLastName, setSelectedLastName] = useState(null);
  const [businessList, setBusinessList] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState("all");
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [tableQuery, setTableQuery] = useState("");
  const [alert, setAlert] = useState(null);
  const [loadingTable, setLoadingTable] = useState(false);
  const canDeleteStaff = useHasPermissions({ module: "BusinessStaff", action: "Delete" });
  // console.log(canDeleteStaff);

  // pagination parameters
  const [loading, setLoading] = useState("false");
  const [pages, setPages] = useState(1);
  const [reactTableState, setReactTableState] = useState({
    page: 1,
    size: 10,
    field: "hdId",
    sort: "desc",
    filter: [],
  });

  useEffect(() => {
    fetchBusinesses();
  }, []);

  useEffect(() => {
    if (selectedBusiness) {
      // console.log("selectedBusines", selectedBusiness)
      fetchData();
    }
  }, [selectedBusiness]);

  const RenderActionButtons = (hdId, docId, firstName, lastName) => {
    return (
      <div className="actions-center">
        <Button
          onClick={() => {
            handleOpenProfile(docId);
          }}
          color="info"
          size="md"
          className="btn-link btn-icon"
        >
          <Visibility style={{ marginLeft: -1 }} />
        </Button>{" "}
        {canDeleteStaff ? (
          <Button
            onClick={() => {
              setSelectedStaffId(hdId);
              setSelectedDocId(docId);
              setSelectedFirstName(firstName);
              setSelectedLastName(lastName);
              handleOpenDeleteModal();
            }}
            color="danger"
            size="md"
            className="btn-link remove btn-icon"
          >
            <Clear style={{ marginLeft: -1 }} />
          </Button>
        ) : null}
      </div>
    );
  };

  //Loading Overlay
  const hideAlert = () => {
    setAlert(null);
  };

  const loadingOverlay = () => {
    return (
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
          background: "transparent",
        }}
        title={<Spinner color="light" style={{ width: "3rem", height: "3rem" }} />}
        onConfirm={hideAlert}
        showConfirm={false}
      />
    );
  };

  const handleOpenProfile = (docId) => {
    let path = `/${context.agency.agency.slug}/admin/business-staff-profile/${docId}`;
    props.history.push(path);
  };

  const handleOpenDeleteModal = () => {
    setConfirmDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setConfirmDeleteModalOpen(false);
  };

  const deleteStaff = async (hdId, userId, profileId) => {
    try {
      const resp = await api().delete("/users/delete-manager", {
        params: {
          userId: userId,
        },
      });
      fetchData();
      setIsLoading(false);
      setAlert(
        <SweetAlert
          success
          title="Shift successfully deleted"
          onConfirm={hideAlert}
          showConfirm={true}
        />,
      );
      handleCloseDeleteModal();
    } catch (error) {
      console.log("error", error);
      handleCloseDeleteModal();
    }
  };

  const confirmDeleteBody = () => {
    return (
      <Modal
        open={confirmDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        // aria-labelledby="simple-modal-title"
        // aria-describedby="simple-modal-description"
      >
        <FormGroup style={{ width: 300, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>
                Delete {selectedFirstName} {selectedLastName}?
              </h5>
            </div>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button onClick={() => handleCloseDeleteModal()}>Cancel</Button>
              <Button
                color="danger"
                onClick={() => deleteStaff(selectedStaffId, selectedDocId, selectedProfileId)}
              >
                Delete
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  // fetch the business list
  const fetchBusinesses = async () => {
    try {
      setIsLoading(true);
      const { data } = await api().get(`/business/list`, {
        params: { slug: context.agency.agency.slug },
      });
      let tempBusinessList = [];
      data.map((business) => {
        tempBusinessList.push({
          value: business._id,
          label: business.name,
        });
      });
      setBusinessList(tempBusinessList);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      console.log("Error while fetching business list");
    }
  };

  const getBusinessName = (businessId) =>
    businessList.length > 0 && businessList.find((b) => b.value === businessId)?.label;

  const fetchData = async (filterOptions = reactTableState) => {
    try {
      if (selectedBusiness.value) {
        const { data } = await api().get("/users/business-managers", {
          params: {
            ...filterOptions,
            businessId: selectedBusiness?.value,
          },
        });
        // console.log("fetched data", data);
        let users = data.users || [];
        users = users.map((u, index) => ({
          ...u,
          actions: RenderActionButtons(u.hdId, u._id, u.firstName, u.lastName),
          role: u.profile[0]?.role?.match(/[A-Z][a-z]+|[0-9]+/g).join(" "),
          // businessName: getBusinessName(u.profile[0].business)
        }));

        // console.log("data", users);
        setStaffData(users);
        setPages(data.noPages);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="content">
        {isLoading ? (
          loadingOverlay()
        ) : (
          <Row>
            <Col md="12">
              <FormGroup>
                <CSVLink data={staffData} headers={staffDataHeaders} filename={"Staff_Data.csv"}>
                  <Button color="default" outline>
                    Export All
                  </Button>
                </CSVLink>
              </FormGroup>
              <Card className="no-transition">
                <CardHeader>
                  <CardTitle tag="h4">
                    {" "}
                    All Staff of {selectedBusiness && selectedBusiness.label}{" "}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Row style={{ marginBottom: 40 }}>
                    <BusinessTab
                      onSelect={(business) => {
                        // console.log(business)
                        business?._id
                          ? setSelectedBusiness(business)
                          : setSelectedBusiness({ value: "all" });
                      }}
                    />
                  </Row>

                  <ReactTable
                    columns={[
                      {
                        Header: "ID",
                        accessor: "hdId",
                        maxWidth: 70,
                      },
                      {
                        Header: "FirstName",
                        accessor: "firstName",
                      },
                      {
                        Header: "LastName",
                        accessor: "lastName",
                      },
                      // {
                      //   Header: "Business",
                      //   accessor: "businessName",
                      // },
                      {
                        Header: "Role",
                        accessor: "role",
                      },
                      {
                        Header: "Contact Email",
                        accessor: "email",
                      },
                      {
                        Header: "Contact Phone",
                        accessor: "phone",
                      },

                      {
                        Header: "Actions",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                        maxWidth: 110,
                      },
                    ]}
                    manual
                    loading={loadingTable}
                    pages={pages}
                    data={staffData}
                    onFetchData={(state, instance) => {
                      let searchParams = {
                        page: state.page + 1,
                        size: state.pageSize,
                        field: state.sorted.length ? state.sorted[0].id : "hdId",
                        sort: state.sorted.length
                          ? state.sorted[0].desc
                            ? "desc"
                            : "asc"
                          : "desc",
                        filter: state.filtered,
                      };
                      setReactTableState(searchParams);
                      fetchData(searchParams);
                    }}
                    defaultPageSize={10}
                    showPaginationTop
                    showPaginationBottom
                    /*
                      You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                    */
                    className="-striped -highlight primary-pagination"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
        {alert}
        {confirmDeleteBody()}
      </div>
    </>
  );
}

export default AllStaff;
