import React from "react";

function RegisterText() {
  return (
    <>
        <div className="info info-horizontal">
          <div className="icon">
            <i className="fa fa-umbrella" />
          </div>
          <div className="description">
            <h3>We've got you covered</h3>
            <p>
              Streamline your business with real time updates, shift requests and more!
                    </p>
          </div>
        </div>
        <div className="info info-horizontal">
          <div className="icon">
            <i className="fa fa-map-signs" />
          </div>
          <div className="description">
            <h3>Expert technical support</h3>
            <p>
              Confused about anything? Our tech support team is standing by to help you with all your problems.
                    </p>
          </div>
        </div>
        <div className="info info-horizontal">
          <div className="icon">
            <i className="fa fa-user-secret" />
          </div>
          <div className="description">
            <h3>We value your privacy</h3>
            <p>
              Your data will be securely stored with industry standard encryption and security practices.
                    </p>
          </div>
        </div>
    </>
  );
}

export default RegisterText;
