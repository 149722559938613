/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import { Redirect, Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import SweetAlert from "react-bootstrap-sweetalert";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Spinner,
  Label,
} from "reactstrap";

import { Visibility, PersonAdd, AddCircleOutline, Clear } from "@mui/icons-material";
import { Modal } from "@mui/material";

import { Input } from "reactstrap";
import StaffingContext from "../../../context/StaffingContext";
import api from "../../../components/API/api";


class AllStaff extends React.Component {
  static contextType = StaffingContext;

  constructor(props) {
    super(props);
    this.state = {
      staffData: [],
      staffDataHeaders: [
        {
          label: "Staff HDID",
          key: "hdId",
        },
        {
          label: "Staff firstName",
          key: "firstName",
        },
        {
          label: "Staff lastName",
          key: "lastName",
        },
        {
          label: "Contact Email",
          key: "email",
        },
        {
          label: "Contact Phone Number",
          key: "phone",
        },
      ],
      confirmDeleteModalOpen: false,

      selectedStaffId: null,
      selectedDocId: null,
      selectedProfileId: null,
      selectedFirstName: null,
      selectedLastName: null,

      isLoading: false,
      openModal: false,

      tableQuery: "",

      //pagination parameters
      loading: "false",
      pages: 0,
    };
  }

  componentDidMount() {}

  RenderActionButtons = (hdId, docId, firstName, lastName) => {
    return (
      <div className="actions-right">
        <Button
        tag={Link}
        to={`/super/admin/admin-profile/${docId}`}
          color="info"
          size="md"
          className="btn-link btn-icon"
        >
          <Visibility style={{ marginLeft: -1 }} />
        </Button>{" "}
        <Button
          onClick={() => {
            this.setState(
              {
                selectedStaffId: hdId,
                selectedDocId: docId,
                selectedFirstName: firstName,
                selectedLastName: lastName,
              },
              this.handleOpenDeleteModal(),
            );
          }}
          color="danger"
          size="md"
          className="btn-link remove btn-icon"
        >
          <Clear style={{ marginLeft: -1 }} />
        </Button>{" "}
      </div>
    );
  };

  //Loading Overlay
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  loadingOverlay = () => {
    return (
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
          background: "transparent",
        }}
        title={<Spinner color="light" style={{ width: "3rem", height: "3rem" }} />}
        onConfirm={this.hideAlert}
        showConfirm={false}
      />
    );
  };

  handleOpenProfile = (docId) => {
    let path = `/super/admin/admin-profile/${docId}`;
    this.props.history.push(path);
  };

  handleOpenDeleteModal = () => {
    this.setState({ confirmDeleteModalOpen: true });
  };

  handleCloseDeleteModal = () => {
    this.setState({ confirmDeleteModalOpen: false });
  };

  deleteStaff = async (hdId, userId, profileId) => {
    this.setState({ isLoading: true })
    try {
      const resp = await api().delete("/users/delete-manager", {
        params: {
          userId: userId,
          profileId: profileId,
        },
      });
      this.fetchData();
      this.setState(
        {
          isLoading: false,
          alert: (
            <SweetAlert
              success
              title="Staff successfully deleted"
              onConfirm={this.hideAlert}
              showConfirm={true}
            />
          ),
        },
        this.handleCloseDeleteModal(),
      );
    } catch (error) {
      this.setState({ isLoading: false })
      console.log("error", error);
    }
  };

  confirmDeleteBody = () => {
    // console.log(this.state.selectedLastName)
    return (
      <Modal
        open={this.state.confirmDeleteModalOpen}
        onClose={this.handleCloseDeleteModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        // aria-labelledby="simple-modal-title"
        // aria-describedby="simple-modal-description"
      >
        <FormGroup style={{ width: 300, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>
                Delete {this.state.selectedFirstName} {this.state.selectedLastName}?
              </h5>
            </div>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button onClick={() => this.handleCloseDeleteModal()}>Cancel</Button>
              <Button
                color="danger"
                onClick={() =>
                  this.deleteStaff(
                    this.state.selectedStaffId,
                    this.state.selectedDocId,
                    this.state.selectedProfileId,
                  )
                }
              >
                Delete
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  fetchData = async (state) => {
    try {
      this.setState({ loadingTable: true });
      if (!state) {
        state = this.state.lastTableState;
      }
      this.setState({ lastTableState: state });
      console.log("state", state);
  
      let field = null;
      let sort = null;
      if (state.sorted.length > 0) {
        field = state.sorted[0].id;
        sort = state.sorted[0].desc ? "desc" : "asc";
      }
      const { data } = await api().get("/users/get-admins", {
        params: {
          page: state.page + 1,
          size: state.pageSize,
          field,
          sort,
          query: this.state.tableQuery,
        },
      });
      console.log(data);
      let users = data.users || [];
      users = users.map((u, index) => ({
        ...u,
        actions: this.RenderActionButtons(u.hdId, u._id, u.firstName, u.lastName),
      }));
  
      console.log("data", data);
      this.setState({
        staffData: users,
        pages: data.noPages,
        loadingTable: false,
      });
    } catch (error) {
      console.log(error)
    }
  };

  render() {
    //<Button color="default" outline>Export All</Button>
    return (
      <>
        <div className="content">
          {
            this.state.isLoading ? (
              this.loadingOverlay()
            ) : (
              <Row>
                <Col md="12">
                  <FormGroup>
                    <CSVLink
                      data={this.state.staffData}
                      headers={this.state.staffDataHeaders}
                      filename={"Staff_Data.csv"}
                    >
                      <Button color="default" outline>
                        Export All
                      </Button>
                    </CSVLink>
                  </FormGroup>
                  <Card className="no-transition">
                    <CardHeader>
                      <CardTitle tag="h4">All Staff</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div style={{ textAlign: "right" }}>
                        <FormGroup row className="align-items-right">
                          <Col sm="7"></Col>
                          <Label for="Search" sm={2} style={{ color: "black", fontSize: "14px" }}>
                            Search
                          </Label>
                          <Col sm={3}>
                            <Input
                              type="text"
                              id="Search"
                              value={this.state.tableQuery}
                              onChange={(e) =>
                                this.setState({ tableQuery: e.target.value }, () =>
                                  this.fetchData(),
                                )
                              }
                            />
                          </Col>
                        </FormGroup>
                      </div>
                      <ReactTable
                        columns={[
                          {
                            Header: "ID",
                            accessor: "hdId",
                            maxWidth: 70,
                          },
                          {
                            Header: "FirstName",
                            accessor: "firstName",
                          },
                          {
                            Header: "LastName",
                            accessor: "lastName",
                          },
                          {
                            Header: "Contact Email",
                            accessor: "email",
                          },
                          {
                            Header: "Contact Phone",
                            accessor: "phone",
                          },
                          {
                            Header: "Actions",
                            accessor: "actions",
                            sortable: false,
                            filterable: false,
                            maxWidth: 110,
                          },
                        ]}
                        manual
                        loading={this.state.loadingTable}
                        pages={this.state.pages}
                        data={this.state.staffData}
                        onFetchData={this.fetchData}
                        sorted={this.state.sorted}
                        defaultPageSize={10}
                        showPaginationTop
                        showPaginationBottom
                        /*
                        You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                      */
                        className="-striped -highlight primary-pagination"
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )
            
          }
          {this.state.alert}
          {this.confirmDeleteBody()}
        </div>
      </>
    );
  }
}

export default AllStaff;
