/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useContext, useEffect } from "react";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import MaskedInput from "react-text-mask";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Spinner,
} from "reactstrap";
import moment from "moment";
import { validateEmail } from "components/Utilities/utilities.js";
import api from "../../../components/API/api";
import AgencyContext from "context/AgencyContext";
import CommentsTab from "../AllUsers/UserProfile/Comments.jsx";

function CreateNewSalesLead(props) {
  const agencyContext = useContext(AgencyContext);
  // states
  const [isLoading, setIsLoading] = useState(false);
  const [pageTabs, setPageTabs] = useState("editProfile");
  const [businessName, setBusinessName] = useState(null);
  const [businessAddress, setBusinessAddress] = useState(null);
  const [contactName, setContactName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [lastContactedStaff, setLastContactedStaff] = useState(null);
  const [alert, setAlert] = useState(null);
  const STATUSOPTIONS = [
    { value: "interested", label: "Interested" },
    { value: "notInterested", label: "Not Interested" },
    { value: "followUp", label: "Follow Up" },
  ];
  const [commentsHistory, setCommentsHistory] = useState([]);
  const [addComment, setAddComment] = useState("");

  //error states
  const [errorBusinessName, setErrorBusinessName] = useState(null);
  const [errorBusinessAddress, setErrorBusinessAddress] = useState(null);
  const [errorContactName, setErrorContactName] = useState(null);
  const [errorEmail, setErrorEmail] = useState(null);
  const [errorPhone, setErrorPhone] = useState(null);
  const [errorAddComment, setErrorAddComment] = useState("");

  useEffect(() => {
    fetchProfile();
    loadCommentHistory(props.match.params.id);
  }, []);

  const fetchProfile = async () => {
    try {
      // get the sales lead Id from props
      setIsLoading(true);
      let salesId = props.match.params.id;
      const { data } = await api().get(`/sales-lead/${salesId}`);
      console.log(data);
      setBusinessAddress(data.address);
      setBusinessName(data.name);
      setEmail(data.email);
      setPhone(data.phone);
      setContactName(data.contactName);
      setLastContactedStaff(data.lastContactedStaff);
      if (data.status) {
        STATUSOPTIONS.map((option) => {
          if (option.value === data.status) {
            setSelectedStatus(option);
          }
        });
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("Error while fetching profile", err);
    }
  };

  const loadCommentHistory = (_id) => {
    setIsLoading(true);
    api()
    .get(`/user-note?commentFieldId=${_id}&fieldType=Sale`)
      .then((res) => {
        const data = res.data.notes;
        let tempArray = [];
        data.map((q) => {
          let tempDate = q.createdAt;
          tempArray.push({
            noteBy: q.noteBy,
            note: q.note,
            time: moment(tempDate).format("MMM Do YYYY, h:mm a"),
          });
        });
        setCommentsHistory(tempArray);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("error getting comment history for sale: ", _id, err);
        setIsLoading(false);
      });
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const loadingOverlay = () => {
    return (
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
          background: "transparent",
        }}
        title={<Spinner color="light" style={{ width: "3rem", height: "3rem" }} />}
        onConfirm={hideAlert}
        showConfirm={false}
      />
    );
  };

  const handleSubmit = () => {
    setIsLoading(false);
    let hasError = false;

    // Start of Error Handling
    if (businessName == null || businessName.trim() == "") {
      setErrorBusinessName("* Must have a business name");
      hasError = true;
    }
    if (businessAddress == null || businessAddress.trim() == "") {
      setErrorBusinessAddress("* Must have a business address");
      hasError = true;
    }
    if (contactName == null || contactName.trim() == "") {
      setErrorContactName("* Must have contact name");
      hasError = true;
    }

    if (!(phone == null || phone.trim() == "")) {
      if (phone.length != 10 || phone < 0) {
        setErrorPhone("* Please input a valid phone number in the format ###-###-####.");
        hasError = true;
      }
    }

    if (!(email == null || email.trim() == "")) {
      if (email != null && validateEmail(email) === false) {
        setErrorEmail("* Please input a valid email");
        hasError = true;
      }
    }

    if (hasError) {
      setIsLoading(false);
      return;
    } else {
      //Insert new business in the database
      let salesId = props.match.params.id;
      let dataToSend = {
        name: businessName,
        address: businessAddress,
        contactName: contactName,
        phone: phone ? phone : "",
        email: email ? email : "",
        status: selectedStatus ? selectedStatus.value : "",
        lastContactedStaff: lastContactedStaff ? lastContactedStaff : "",
      };
      console.log(dataToSend);
      api()
        .patch(`/sales-lead/${salesId}`, dataToSend)
        .then(() => {
          console.log("successfully saved Data");
          setIsLoading(false);
          setAlert(
            <SweetAlert success title="sales lead successfully updated" onConfirm={hideAlert} />,
          );
        })
        .catch((err) => {
          console.log("Error setting documents", err);
          setIsLoading(false);
          setAlert(
            <SweetAlert warning title={`Error updating sales lead`} onConfirm={hideAlert} />,
          );
        });
    }
  };

  const handleAddComment = (id, comment) => {
    let dataToSend = {
      note: comment,
      commentField: "Sale",
      commentFieldId: id,
    };
    api()
    .post("/user-note", dataToSend)
      .then((res) => {
        console.log("comment saved");
        let tempCommentHistory = commentsHistory;
        let tempDate = res.data.createdAt;
        let newComment = {
          note: comment,
          noteBy: agencyContext.agency.user.firstName + " " + agencyContext.agency.user.lastName,
          time: moment(tempDate).format("MMM Do YYYY, h:mm a"),
        };
        tempCommentHistory.push(newComment);

        setAddComment("");
        setCommentsHistory(tempCommentHistory);
      })
      .catch((err) => {
        console.log(err);
        console.log("Error while saving comment");
      });
  };

  const renderNavTabs = () => {
    let tabList = [
      { name: "Edit Profile", id: "editProfile" },
      { name: "Comments", id: "comments" },
    ];

    return (
      <>
        {tabList.map((tab) => {
          return (
            <NavItem>
              <NavLink
                data-toggle="tab"
                role="tablist"
                className={pageTabs === tab.id ? "active" : ""}
                onClick={() => setPageTabs(tab.id)}
              >
                <i className="now-ui-icons ui-2_settings-90" />
                {tab.name}
              </NavLink>
            </NavItem>
          );
        })}
      </>
    );
  };

  return (
    <div className="content">
      {isLoading ? (
        loadingOverlay()
      ) : (
        <Row>
          <Col md="12">
            <Card className="no-transition">
              <CardHeader>
                <CardTitle tag="h4">Sales Lead Profile of {businessName}</CardTitle>
              </CardHeader>
              <CardBody>
                <Nav
                  className="nav-pills-primary nav-pills-icons justify-content-center"
                  pills
                  role="tablist"
                >
                  {renderNavTabs()}
                </Nav>
                <TabContent className="tab-space tab-subcategories" activeTab={pageTabs}>
                  <TabPane tabId="editProfile">
                    <CardBody>
                      <Form>
                        <Row md="12">
                          <Col md="12">
                            <Row>
                              <Label sm="2">* Name</Label>
                              <Col sm="5">
                                <FormGroup>
                                  <Input
                                    type="text"
                                    autoComplete="off"
                                    defaultValue={businessName}
                                    onChange={(e) => {
                                      setBusinessName(e.target.value);
                                      setErrorBusinessName("");
                                    }}
                                  />
                                  <FormText color="danger" tag="span">
                                    {errorBusinessName}
                                  </FormText>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Label sm="2">* Contact Name</Label>
                              <Col sm="5">
                                <FormGroup>
                                  <Input
                                    type="text"
                                    autoComplete="off"
                                    defaultValue={contactName}
                                    onChange={(e) => {
                                      setContactName(e.target.value);
                                      setErrorContactName("");
                                    }}
                                  />
                                  <FormText color="danger" tag="span">
                                    {errorContactName}
                                  </FormText>
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row>
                              <Label sm="2">* Address</Label>
                              <Col sm="5">
                                <FormGroup>
                                  <Input
                                    type="text"
                                    autoComplete="off"
                                    defaultValue={businessAddress}
                                    onChange={(e) => {
                                      setBusinessAddress(e.target.value);
                                      setErrorBusinessAddress("");
                                    }}
                                  />
                                  <FormText color="danger" tag="span">
                                    {errorBusinessAddress}
                                  </FormText>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Label sm="2">Phone</Label>
                              <Col sm="5">
                                <FormGroup>
                                  <MaskedInput
                                    mask={[
                                      "(",
                                      /[1-9]/,
                                      /\d/,
                                      /\d/,
                                      ")",
                                      "-",
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                      "-",
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                    ]}
                                    className="form-control"
                                    autoComplete="off"
                                    maxLength="15"
                                    placeholder="(xxx)-xxx-xxxx"
                                    defaultValue={phone}
                                    onChange={(e) => {
                                      //Allows the user to type in any format. Only numbers will be added to the state
                                      let input = e.target.value;
                                      let replacedPhone = input.replace(/[^0-9]/g, "");
                                      setPhone(replacedPhone);
                                      setErrorPhone("");
                                    }}
                                    value={phone}
                                  />
                                  <FormText color="danger" tag="span">
                                    {errorPhone}
                                  </FormText>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Label sm="2">Email</Label>
                              <Col sm="5">
                                <FormGroup>
                                  <Input
                                    type="email"
                                    autoComplete="off"
                                    defaultValue={email}
                                    onChange={(e) => {
                                      setEmail(e.target.value);
                                      setErrorEmail("");
                                    }}
                                  />
                                  <FormText color="danger" tag="span">
                                    {errorEmail}
                                  </FormText>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Label sm="2">Status</Label>
                              <Col sm="5">
                                <FormGroup>
                                  <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="singleSelect"
                                    value={selectedStatus}
                                    onChange={(value) => setSelectedStatus(value)}
                                    options={STATUSOPTIONS}
                                    placeholder="Select status"
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Label sm="2"> Last Contacted Staff</Label>
                              <Col sm="5">
                                <FormGroup>
                                  <Input
                                    type="text"
                                    autoComplete="off"
                                    value={lastContactedStaff}
                                    onChange={(e) => {
                                      setLastContactedStaff(e.target.value);
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                      <FormGroup align="right">
                        <Button color="default" outline onClick={() => handleSubmit()}>
                          Save Changes
                        </Button>
                      </FormGroup>
                    </CardBody>
                  </TabPane>
                  <TabPane tabId="comments">
                    <CardHeader>
                      <CardTitle tag="h4">Comments</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Form>
                        <Row>
                          <Col>
                            <CommentsTab comments={commentsHistory} isLoading={isLoading} />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label>Add a comment</Label>
                              <Input
                                value={`${addComment}`}
                                onKeyDown={(e) => {
                                  // console.log(e);
                                  if (e.keyCode === 13) {
                                    e.preventDefault();
                                    handleAddComment(props.match.params.id, addComment);
                                  }
                                }}
                                onChange={(e) => setAddComment(e.target.value)}
                                placeholder="Add a comment for this user"
                                type="text"
                              />
                            </FormGroup>
                            <FormText color="danger" tag="span">
                              {errorAddComment}
                            </FormText>
                          </Col>
                        </Row>
                        <FormGroup align="right">
                          <Button
                            color="default"
                            outline
                            onClick={() => handleAddComment(props.match.params.id, addComment)}
                          >
                            Add Comment
                          </Button>
                        </FormGroup>
                      </Form>
                    </CardBody>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      {alert}
    </div>
  );
}

export default CreateNewSalesLead;
