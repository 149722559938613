import React from "react";
import ReactTable from "react-table";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";

//import { fs } from "components/Firebase/firebase.js";
import * as moment from "moment-timezone";
import ReactDatetime from "react-datetime";
import { Refresh } from "@mui/icons-material";
import api from "../../../components/API/api";
import { Link } from "react-router-dom";
import AgencyContext from "../../../context/AgencyContext";
import { startOfDay } from "utils/dateUtils";
import { startOfNextDay } from "utils/dateUtils";

const scheduleColours = "#ffe4c6";

class EmployeeStatistics extends React.Component {
  static contextType = AgencyContext;
  constructor(props) {
    super(props);
    this.state = {
      employeeStatisticsData: [],
      employeeInfoData: [],
      date: new Date(),
      dailyCount: [],
      showAll: false,

      //for calculating total
      sunChequeTotal: 0,
      monChequeTotal: 0,
      tueChequeTotal: 0,
      wedChequeTotal: 0,
      thuChequeTotal: 0,
      friChequeTotal: 0,
      satChequeTotal: 0,

      sunSchTotal: 0,
      monSchTotal: 0,
      tueSchTotal: 0,
      wedSchTotal: 0,
      thuSchTotal: 0,
      friSchTotal: 0,
      satSchTotal: 0,

      totalCheque: 0,
      totalSchedule: 0,

      dates: [],
    };
  }

  componentDidMount = () => {
    this.loadDate();
    this.loadDailyCount();
  };

  getThisWeek = (date) => {
    return {
      start: moment(date).startOf("week").toDate(),
      end: moment(date).endOf("week").toDate(),
    };
  };

  loadDate = async (date = this.state.date, showAll = this.state.showAll) => {
    this.setState({ loading: true });

    const dates = this.getThisWeek(date);
    const { data } = await api().get(`/log/staff/`, {
      params: {
        ...dates,
        showAll,
      },
    });
    console.log("data", data);
    const result = this.calculateStats({ ...data, ...dates });
    this.setState({ loading: false, employeeStatisticsData: result });
  };

  loadDailyCount = async (date = this.state.date) => {
    try {
      this.setState({ loading: true });
      const dates = this.getThisWeek(date);
      const { data } = await api().get("/log/dailyCount/", {
        params: {
          ...dates,
        },
      });
      console.log("DAILY COUNT LOGS", data);
      const result = this.calculateDailyStats({ ...data, ...dates });
      console.log("DAILY COUNT", result);
      this.setState({ loading: false, dailyCount: result });
    } catch (err) {
      this.setState({ loading: false });
      console.log(err);
    }
  };

  calculateStats = ({ logs, staff, start, end }) => {
    for (let i = 0; i < staff.length; i++) {
      const recruiter = staff[i];
      recruiter.day = [];
      const recruiterLogs = logs
        .filter((l) => l.user === recruiter._id)
        .map((l) => ({ ...l, createdAt: moment(l.createdAt) }));
      recruiter.totalSch = 0; // total scheduled per week
      let today = moment(start);
      while (today <= moment(end)) {
        const tomorrow = moment(today).add(1, "day");
        const filteredLogs = recruiterLogs.filter((l) => l.createdAt.isBetween(today, tomorrow));
        const metrics = {
          schedule: filteredLogs.filter(
            (l) =>
              (l.action.startsWith("/selected-for-work") &&
                l.method === "POST" &&
                (l.data === new RegExp(`"status":1`, "i") || new RegExp(`"status":2`, "i"))) ||
              (l.action.startsWith("/selected-for-work") &&
                l.method === "PATCH" &&
                (l.data === new RegExp(`"status":1`, "i") || new RegExp(`"status":2`, "i"))),
          ).length,
          unschedule: filteredLogs.filter(
            (l) =>
              l.action.startsWith("/selected-for-work") &&
              l.method === "PATCH" &&
              l.data === new RegExp(`"status":4`, "i"),
          ).length,
          cheque: filteredLogs.filter(
            (l) =>
              (l.action.startsWith("/cheque-pickup") && l.method === "PATCH") ||
              (l.action.startsWith("/cheque-pickup/admin") && l.method === "POST"),
          ).length,
        };
        metrics.schedule = (
          <Link
            to={`/${
              this.context.agency.agency.slug
            }/admin/employee-details-statistics?date=${moment(today).toISOString()}&staff=${
              recruiter._id
            }&action=schedule`}
          >
            {metrics.schedule}
          </Link>
        );
        metrics.unschedule = (
          <Link
            to={`/${
              this.context.agency.agency.slug
            }/admin/employee-details-statistics?date=${moment(today).toISOString()}&staff=${
              recruiter._id
            }&action=unschedule`}
          >
            {metrics.unschedule}
          </Link>
        );
        metrics.cheque = (
          <Link
            to={`/${
              this.context.agency.agency.slug
            }/admin/employee-details-statistics?date=${moment(today).toISOString()}&staff=${
              recruiter._id
            }&action=cheques`}
          >
            {metrics.cheque}
          </Link>
        );
        recruiter.totalSch =
          recruiter.totalSch +
          filteredLogs.filter(
            (l) =>
              (l.action.startsWith("/selected-for-work") &&
                l.method === "POST" &&
                (l.data === new RegExp(`"status":1`, "i") || new RegExp(`"status":2`, "i"))) ||
              (l.action.startsWith("/selected-for-work") &&
                l.method === "PATCH" &&
                (l.data === new RegExp(`"status":1`, "i") || new RegExp(`"status":2`, "i"))),
          ).length; // add per week per user
        recruiter.day.push(metrics);
        today = today.add(1, "day");
      }
    }
    // console.log("staff", staff);
    return staff;
  };

  calculateDailyStats = ({ logs, start, end }) => {
    let list = [
      { name: "SELF SCHEDULED" },
      { name: "TOTAL SCHEDULED" },
      { name: "UNSCHEDULED" },
      { name: "CHEQUES" },
    ];
    for (let i = 0; i < list.length; i++) {
      const row = list[i];
      row.day = [];
      const recruiterLogs = logs.map((l) => ({ ...l, createdAt: moment(l.createdAt) }));
      let today = moment(start);
      while (today <= moment(end)) {
        const tomorrow = moment(today).add(1, "day");
        const filteredLogs = recruiterLogs.filter((l) => l.createdAt.isBetween(today, tomorrow));
        if (row.name === "SELF SCHEDULED") {
          const metrics = {
            count: filteredLogs.filter(
              (l) => l.action.startsWith("/selected-for-work") && l.method === "PATCH",
            ).length,
          };

          // console.log(metrics);
          row.day.push(metrics);
        } else if (row.name === "TOTAL SCHEDULED") {
          const metrics = {
            count: filteredLogs.filter(
              (l) =>
                (l.action.startsWith("/selected-for-work") &&
                  l.method === "POST" &&
                  (l.data === new RegExp(`"status":1`, "i") || new RegExp(`"status":2`, "i"))) ||
                (l.action.startsWith("/selected-for-work") &&
                  l.method === "PATCH" &&
                  (l.data === new RegExp(`"status":1`, "i") || new RegExp(`"status":2`, "i"))),
            ).length,
          };

          // console.log(metrics);
          row.day.push(metrics);
        } else if (row.name === "UNSCHEDULED") {
          const metrics = {
            count: filteredLogs.filter(
              (l) =>
                l.action.startsWith("/selected-for-work") &&
                l.method === "PATCH" &&
                l.data === new RegExp(`"status":4`, "i"),
            ).length,
          };
          row.day.push(metrics);
        } else if (row.name === "CHEQUES") {
          const metrics = {
            count: filteredLogs.filter(
              (l) =>
                (l.action.startsWith("/cheque-pickup") && l.method === "PATCH") ||
                (l.action.startsWith("/cheque-pickup/admin") && l.method === "POST"),
            ).length,
          };

          // console.log(metrics);
          row.day.push(metrics);
        } else {
          //
        }

        today = today.add(1, "day");
      }
    }
    console.log(list);
    return list;
  };

  getTrProps = (state, rowInfo, instance) => {
    if (rowInfo) {
      // console.log(rowInfo);
      if (rowInfo.row._index === 0) {
        return {
          style: {
            color: "black",
            fontWeight: "bold",
          },
        };
      } else {
        return {};
      }
    } else {
      return {};
    }
  };

  getDailyTrProps = (state, rowInfo, instance) => {
    if (rowInfo) {
      // console.log(rowInfo);
      if (rowInfo.row._index !== -1) {
        return {
          style: {
            color: "black",
            fontWeight: "bold",
          },
        };
      } else {
        return {};
      }
    } else {
      return {};
    }
  };

  renderColumns = () => {
    const col = [
      {
        Header: "Employee",
        accessor: "email",
        maxWidth: 220,
      },
    ];
    const date = this.state.date ? this.state.date : new Date();
    const { start, end } = this.getThisWeek(date);

    let today = moment(start);
    let day = 0;
    while (today < moment(end)) {
      const tomorrow = moment(today).add(1, "day");
      const color = (day + 1) % 2 === 1;
      col.push({
        Header: (
          <span>
            {today.format("ddd")}
            <br></br>sch
          </span>
        ),
        accessor: `day[${day}].schedule`,
        maxWidth: 60,
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              background: color ? scheduleColours : "white",
            },
          };
        },
      });
      col.push({
        Header: (
          <span>
            {today.format("ddd")}
            <br></br>usch
          </span>
        ),
        accessor: `day[${day}].unschedule`,
        maxWidth: 60,
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              background: color ? scheduleColours : "white",
            },
          };
        },
      });
      col.push({
        Header: (
          <span>
            {today.format("ddd")}
            <br></br>chq
          </span>
        ),
        accessor: `day[${day}].cheque`,
        maxWidth: 60,
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              background: color ? scheduleColours : "white",
            },
          };
        },
      });
      day++;
      today = today.add(1, "day");
    }
    col.push({
      Header: "Total Scheduled",
      accessor: "totalSch",
    });
    return col;
  };

  renderDailyColumns = () => {
    const col = [
      {
        Header: "",
        accessor: "name",
        maxWidth: 120,
      },
    ];
    const date = this.state.date ? this.state.date : new Date();
    const { start, end } = this.getThisWeek(date);

    let today = moment(start);
    let day = 0;
    while (today < moment(end)) {
      const tomorrow = moment(today).add(1, "day");
      const color = (day + 1) % 2 === 1;

      col.push({
        Header: (
          <span>
            {today.format("dddd")}
            {/* <br></br>Self */}
          </span>
        ),
        accessor: `day[${day}].count`,
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              background: color ? scheduleColours : "white",
            },
          };
        },
      });
      col.push({
        Header: (
          <span>
            {today.format("ddd")}
            {/* <br></br>Total */}
          </span>
        ),
        accessor: `$day[${day}].total`,
        maxWidth: 60,
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              background: color ? scheduleColours : "white",
            },
          };
        },
      });
      day++;
      today = today.add(1, "day");
    }
    return col;
  };

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card className="no-transition">
                <CardHeader>
                  <Row>
                    <Col md="6">
                      <CardTitle tag="h4">Employee Statistics</CardTitle>
                    </Col>
                    <Col align="right">
                      <Button
                        onClick={() => {
                          // fetch signature proof
                          this.loadDate(this.state.date);
                          this.loadDailyCount(this.state.date);
                        }}
                        color="default"
                        size="md"
                        className="btn"
                        outline
                      >
                        <Refresh style={{ marginLeft: -1 }} />
                      </Button>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 5 }}>
                    <Col md="6">
                      <ReactDatetime
                        inputProps={{
                          className: "form-control",
                          placeholder: "Date Picker Here",
                        }}
                        timeFormat={false}
                        isValidDate={this.disableFutureDates}
                        value={this.state.date}
                        onChange={(date) => {
                          this.setState({ date: date });
                          this.loadDate(date);
                          this.loadDailyCount(date);
                        }}
                      />
                    </Col>
                    <Col align="right">
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={this.state.showAll}
                            onChange={() => {
                              this.setState({ showAll: !this.state.showAll });
                              this.loadDate(this.state.date, !this.state.showAll);
                            }}
                          />
                          <span className="form-check-sign" />
                          Display All Staff
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col align="center">
                      <ReactTable
                        data={this.state.dailyCount}
                        filterable={false}
                        sortable={false}
                        columns={this.renderDailyColumns()}
                        defaultPageSize={4}
                        showPaginationTop={false}
                        showPaginationBottom={false}
                        className="-striped -highlight primary-pagination"
                        getTrProps={this.getDailyTrProps}
                        loading={this.state.loading}
                      />
                    </Col>
                  </Row>
                  <ReactTable
                    data={this.state.employeeStatisticsData}
                    filterable
                    columns={this.renderColumns()}
                    defaultPageSize={20}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight primary-pagination"
                    getTrProps={this.getTrProps}
                    loading={this.state.loading}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default EmployeeStatistics;
