import React, { useMemo, useEffect, useState, useContext } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { Button } from "reactstrap";

import { FormGroup, Input } from "@mui/material";

import { makeStyles } from "@mui/styles";
import RegisterContext from "../../../context/RegisterContext";

let colors = {
  danger: "#f7765f",
  base: "#9A9A9A",
};

function useResponsiveFontSize() {
  const getFontSize = () => (window.innerWidth < 450 ? "16px" : "18px");
  const [fontSize, setFontSize] = useState(getFontSize);

  useEffect(() => {
    const onResize = () => {
      setFontSize(getFontSize());
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  });

  return fontSize;
}

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: colors.base,
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
          width: "100%",
        },
        invalid: {
          color: colors.danger,
        },
      },
    }),
    [fontSize],
  );

  return options;
};

const PaymentForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const registerContext = useContext(RegisterContext)
  const options = useOptions();

  const useStyles = makeStyles((theme) => ({
    input: {
      ...options.style.base,
      backgroundColor: "transparent",
      border: "none",
      display: "block",
      margin: 0,
      outline: "none",
      padding: 0,
    },
    placeholder: {
      "&::placeholder": {
        color: "#aab7c4",
      },
    },
  }));

  const handleSubmit = async () => {

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
    });
    if (!error) {
      props.confirm(paymentMethod);
    } else {
      console.log(error.message);
    }

  };

  return (
    <form className="register-form">
      <FormGroup>
        <span>Card number</span>
        <CardNumberElement
          options={options}
          className="credit-card-form"
          onChange={e => console.log(e)}
          onFocus={() => {
            props.setCard({ ...props.card, focus: "number" });
          }}
        />
      </FormGroup>
      <div className="d-flex">
        <FormGroup style={{ flex: 2 }}>
          Expiration date
          <CardExpiryElement
            className="credit-card-form"
            options={options}
            onFocus={() => {
              props.setCard({ ...props.card, focus: "expiry" });
            }}
          />
        </FormGroup>
        <div style={{ flex: 1 }}></div>
        <FormGroup style={{ flex: 1 }}>
          CVC
          <CardCvcElement
            className="credit-card-form"
            options={options}
            onFocus={() => {
              props.setCard({ ...props.card, focus: "cvc" });
            }}
          />
        </FormGroup>
      </div>
      <Button
        className="mt-4"
        color="primary"
        block
        onClick={() => {
          handleSubmit();
        }}
      >
        COMPLETE ORDER
      </Button>
    </form>
  );
};

export default PaymentForm;
