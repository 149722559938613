import RecurringDays, { RECURRING_DAYS_ARRAY } from "components/RecurringDays";
import { useModal } from "context/ModalContext";
import useFetchBusiness from "hooks/useFetchBusiness";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import api from "../../../components/API/api";
import { shiftTimeType } from "../../../utils/dateUtils";
import ShiftFunctions from "./ShiftFunction";
import { shiftFunctionList } from "./ShiftFunction";
// import AddEmailList from "./AddEmailList";

function CreateShifts(props) {
  const initialState = {
    startTime: null,
    endTime: null,
    requiredPeople: "",
    description: "",
    qualifications: [],
    shiftName: "",
    expires: null,
    selectedBusiness: null,
    pay: "",
    estimatedPayDate: null,
    qualificationsList: [],
    recurrent: true,
    shiftFunctions: [],
    shiftBoard: true,
    emails: [],
  };
  const businesses = useFetchBusiness();
  const { addAlert } = useModal();
  const [data, setData] = useState({ ...initialState });
  const [error, setError] = useState({
    descriptionError: "",
    qualificationsError: "",
    shiftNameError: "",
    selectedBusinessError: "",
    availabilityError: "",
    recurrentError: "",
    startTimeError: "",
    endTimeError: "",
    shiftFunctionError: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [availability, setAvailability] = useState([...RECURRING_DAYS_ARRAY]);
  const [shiftFunctions, setShiftFunctions] = useState([]);
  useEffect(() => {
    fetchQualifications();
  }, []);

  const fetchQualifications = async () => {
    try {
      const { data } = await api().get("/qualification");
      // const qualifications = res.data.qualifications;
      console.log("qualifications data", data);

      setData((oldData) => {
        return {
          ...oldData,
          qualificationsList: data.qualifications.map((q) => {
            return {
              label: q.name,
              value: q._id,
            };
          }),
        };
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let hasError = false;
    //Setting initial error in tempErrors
    let tempErrors = { ...error };
    if (data.recurrent) {
      availability.forEach((a) => {
        if (a.active) {
          if (!a.start || !a.end) {
            hasError = true;
            tempErrors = {
              ...tempErrors,
              recurrentError: "* Must have both start and end time for active recurring days",
            };
          }
        }
      });
      if (!tempErrors.recurrentError) {
        if (!availability.filter((a) => a.active).length) {
          tempErrors = {
            ...tempErrors,
            recurrentError: "* Must have at least one day active",
          };
        }
      }
    }

    if (!data.recurrent && !data.startTime) {
      tempErrors = { ...tempErrors, startTimeError: "*Must Enter Start Time" };
      hasError = true;
    } else {
      tempErrors = { ...tempErrors, startTimeError: "" };
    }
    if (!data.recurrent && !data.endTime) {
      tempErrors = { ...tempErrors, endTimeError: "*Must Enter End Time" };
      hasError = true;
    } else {
      tempErrors = { ...tempErrors, endTimeError: "" };
    }

    if (data.shiftName == null || data.shiftName.trim() == "") {
      tempErrors = { ...tempErrors, shiftNameError: "* Must Enter Shift Name" };
      hasError = true;
    } else {
      tempErrors = { ...tempErrors, shiftNameError: "" };
    }

    if (data.description == null || data.description.trim() == "") {
      tempErrors = { ...tempErrors, descriptionError: "* Must Enter Description " };
      hasError = true;
    } else {
      tempErrors = { ...tempErrors, descriptionError: "" };
    }

    if (data.qualifications == null || data.qualifications == "") {
      tempErrors = { ...tempErrors, qualificationsError: "* Must Enter Qualification " };
      hasError = true;
    } else {
      tempErrors = { ...tempErrors, qualificationsError: "" };
    }

    if (data.selectedBusiness == null || data.selectedBusiness == "") {
      tempErrors = { ...tempErrors, selectedBusinessError: "* Must Enter Business Name " };
      hasError = true;
    } else {
      tempErrors = { ...tempErrors, selectedBusinessError: "" };
    }
    if (shiftFunctions.length) {
      for (const _sf of shiftFunctions) {
        if (!_sf.name) {
          tempErrors = { ...tempErrors, shiftFunctionError: "* Must select a Shift function." };
          hasError = true;
          break;
        }
      }
    }

    if (hasError) {
      setError({ ...error, ...tempErrors });
      return;
    } else {
      prepareAndSendDataToServer(data);
    }
  };

  const prepareAndSendDataToServer = async (data) => {
    setIsLoading(true);
    let dataToSend = null;
    let url;
    let reloadQualificationsArray = data.qualificationsList;
    let tempQualificationsArray = data.qualifications
      ? data.qualifications.map((q) => q.value)
      : [];
    let tempBusinessArray = data.initialReactSelectBusinessData;

    // get the qualifications values

    let weekSchedule = null;
    if (data.recurrent) {
      weekSchedule = availability.filter((a) => {
        if (a.active) {
          a.start = {
            hour: moment(a.start).hour(),
            minute: moment(a.start).minute(),
            second: 0,
          };
          a.end = {
            hour: moment(a.end).hour(),
            minute: moment(a.end).minute(),
            second: 0,
          };
          delete a.active;
          return a;
        }
      });
    }

    if (data.recurrent) {
      // enpoint for recurrent shift
      url = "/recurrent-shift";
      dataToSend = {
        business: data.selectedBusiness.value,
        noRequiredWorkers: parseInt(data.requiredPeople),
        shiftName: data.shiftName,
        description: data.description,
        qualifications: tempQualificationsArray,
        payRate: parseFloat(data.pay),
        payBasis: "Hourly",
        weekSchedule,
        shiftBoard: data.shiftBoard,
        shiftFunctions,
      };
      if (data.expires) {
        dataToSend.expires = data.expires.toISOString();
      }
    } else {
      url = "/shift";
      let shiftTimingType;
      if (shiftTimeType(data.startTime) === "Morning") {
        shiftTimingType = 0;
      } else if (shiftTimeType(data.startTime) === "Afternoon") {
        shiftTimingType = 1;
      } else if (shiftTimeType(data.startTime) === "Night") {
        shiftTimingType = 2;
      }

      console.log("shift timing type", shiftTimeType(data.startTime));

      dataToSend = {
        business: data.selectedBusiness.value,
        noRequiredWorkers: parseInt(data.requiredPeople),
        shiftName: data.shiftName,
        description: data.description,
        shiftTimingType: shiftTimingType,
        qualifications: tempQualificationsArray,
        payRate: parseFloat(data.pay),
        payBasis: "Hourly",
        estimatedPayDate:
          data.estimatedPayDate !== null && !data.recurrent
            ? data.estimatedPayDate.toISOString()
            : null,
        urgent: false,
        isPublic: true,
        start: data.startTime.toISOString(),
        end: data.endTime.toISOString(),
      };
    }

    try {
      const resp = await api({
        headers: { client_timezone: moment.tz?.guess(), client_timestamp: moment().format() },
      }).post(url, dataToSend);
      setData({
        ...initialState,
        qualificationsList: data.qualificationsList,
      });
      addAlert({
        title: "Shift Successfully Created!",
        success: true,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const disablePreviousDates = (current) => {
    // const today = new Date();
    let yesterday = new Date(moment().subtract(1, "days"));
    return current.isAfter(yesterday);
  };

  const loadingOverlay = () => {
    return (
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
          background: "transparent",
        }}
        title={<Spinner color="light" style={{ width: "3rem", height: "3rem" }} />}
        showConfirm={false}
      />
    );
  };

  return (
    <div className="content" style={{ marginTop: "130px" }}>
      <Row>
        <Col md="12">
          <Card className="no-transition">
            <CardHeader>
              <CardTitle tag="h4">Create Shift</CardTitle>
            </CardHeader>
            <CardBody>
              <Form action="/" className="form-horizontal" method="get">
                <Row>
                  <Label sm="2">* Recurrent</Label>
                  <Row
                    className="checkbox-radios"
                    sm="5"
                    style={{ marginLeft: "1.5%", marginTop: "0.8%" }}
                  >
                    <div className="form-check-radio" style={{ marginRight: 8 }}>
                      <Label check>
                        <Input
                          defaultValue={true}
                          id="N"
                          name="recurrentRadio"
                          type="radio"
                          onChange={() => setData({ ...data, recurrent: false })}
                          checked={!data.recurrent}
                        />
                        No <span className="form-check-sign" />
                      </Label>
                    </div>
                    <div className="form-check-radio">
                      <Label check>
                        <Input
                          defaultValue={false}
                          id="Y"
                          name="recurrentRadio"
                          type="radio"
                          checked={data.recurrent}
                          onChange={() => setData({ ...data, recurrent: true })}
                        />
                        Yes <span className="form-check-sign" />
                      </Label>
                    </div>
                  </Row>
                </Row>
                {data.recurrent && (
                  //only for trained people there is specific business and shift
                  <>
                    <Row>
                      <Label sm="2">Recurring Days</Label>
                      <Col sm="10">
                        <RecurringDays
                          onChange={(_availability) => {
                            setAvailability(_availability);
                          }}
                        />
                        <FormText color="danger" tag="span">
                          {error.recurrentError}
                        </FormText>
                      </Col>
                    </Row>
                  </>
                )}
                {!data.recurrent && (
                  <Row style={{ paddingTop: "20px" }}>
                    <Label sm="2">* Start Time</Label>
                    <Col md="6">
                      <ReactDatetime
                        isValidDate={disablePreviousDates}
                        value={data.startTime}
                        onChange={(date) => {
                          // console.log("Start Time: ", date);
                          setData({ ...data, startTime: date });
                          setError({ ...error, startTimeError: "" });
                        }}
                      />
                      <FormText color="danger" tag="span">
                        {error.startTimeError}
                      </FormText>
                    </Col>
                  </Row>
                )}
                {!data.recurrent && (
                  <Row style={{ paddingTop: "20px" }}>
                    <Label sm="2">* End Time </Label>
                    <Col md="6">
                      <ReactDatetime
                        isValidDate={disablePreviousDates}
                        value={data.endTime}
                        onChange={(date) => {
                          // console.log("End Time: ", date);
                          setData({ ...data, endTime: date });
                          setError({ ...error, endTimeError: "" });
                        }}
                      />
                      <FormText color="secondary" tag="span">
                        {/* {data.recurrent */}
                        {data.recurrent &&
                          `(For Recurrent shifts end time is date till you want the shift to run. if it is going to run forever
                        please set it to farther date.)`}
                        {/* : null} */}
                      </FormText>
                      <FormText color="danger" tag="span">
                        {error.endTimeError}
                      </FormText>
                    </Col>
                  </Row>
                )}

                {!data.recurrent && (
                  <Row style={{ paddingTop: "20px" }}>
                    <Label sm="2">Required Number of Workers (Optional)</Label>
                    <Col sm="6">
                      <FormGroup>
                        <Input
                          type="number"
                          min="1"
                          value={data.requiredPeople}
                          onChange={(e) => {
                            setData({ ...data, requiredPeople: e.target.value });
                            setError({ ...error, requiredPeopleError: "" });
                            // console.log("Number of People: ", e.target.value);
                          }}
                        />
                        {/* <FormText color="danger" tag="span">
                            {error.requiredPeopleError}
                          </FormText> */}
                      </FormGroup>
                    </Col>
                  </Row>
                )}

                {data.recurrent && (
                  <Row style={{ paddingTop: "20px" }}>
                    <Label sm="2">Expiry Date (Optional) </Label>
                    <Col md="6">
                      <ReactDatetime
                        isValidDate={disablePreviousDates}
                        value={data.expires}
                        timeFormat={false}
                        onChange={(date) => {
                          // console.log("End Time: ", date);
                          setData({ ...data, expires: date });
                        }}
                      />
                    </Col>
                  </Row>
                )}
                <Row style={{ paddingTop: "20px" }}>
                  <Label sm="2">* Name</Label>
                  <Col sm="6">
                    <FormGroup>
                      <Input
                        type="text"
                        placeholder="Please provide shift name."
                        value={data.shiftName}
                        onChange={(e) => {
                          setData({
                            ...data,
                            shiftName: e.target.value,
                          });
                          setError({ ...error, shiftNameError: "" });
                          // console.log("Description: ", e.target.value);
                        }}
                      />
                      <FormText color="danger" tag="span">
                        {error.shiftNameError}
                      </FormText>
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={{ paddingTop: "20px" }}>
                  <Label sm="2">* Description</Label>
                  <Col sm="6">
                    <FormGroup>
                      <Input
                        type="textarea"
                        // placeholder="Please provide some description."
                        style={{ maxHeight: "200px" }}
                        rows="5"
                        value={data.description}
                        onChange={(e) => {
                          setData({
                            ...data,
                            description: e.target.value,
                          });
                          setError({ ...error, descriptionError: "" });
                          // console.log("Description: ", e.target.value);
                        }}
                      />
                      <FormText color="danger" tag="span">
                        {error.descriptionError}
                      </FormText>
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={{ paddingTop: "4px" }}>
                  <Label sm="2">* Qualifications</Label>
                  <Col sm="6">
                    <FormGroup>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="qualificationsSelect"
                        isMulti
                        value={data.qualifications}
                        onChange={(value) => {
                          setData({ ...data, qualifications: value });
                          setError({ ...error, qualificationsError: "" });
                          // console.log("Qualifications:", value);
                        }}
                        options={data.qualificationsList}
                        placeholder="Select Qualifications"
                      />
                      <FormText color="danger" tag="span">
                        {error.qualificationsError}
                      </FormText>
                    </FormGroup>
                  </Col>
                </Row>

                <Row style={{ paddingTop: "4px" }}>
                  <Label sm="2">* Business Name</Label>
                  <Col sm="6">
                    <FormGroup>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="businesses"
                        value={data.selectedBusiness}
                        onChange={(value) => {
                          setData({ ...data, selectedBusiness: value });
                          setError({ ...error, selectedBusinessError: "" });
                        }}
                        options={businesses || []}
                        placeholder="Select Business"
                      />
                      <FormText color="danger" tag="span">
                        {error.selectedBusinessError}
                      </FormText>
                    </FormGroup>
                  </Col>
                </Row>
                {/* {!data.recurrent && ( */}
                <Row style={{ paddingTop: "4px" }}>
                  <Label sm="2">Pay/Hour (Optional)</Label>
                  <Col sm="6">
                    <FormGroup>
                      <Input
                        type="number"
                        min="15"
                        value={data.pay}
                        onChange={(e) => {
                          setData({
                            ...data,
                            pay: e.target.value,
                          });
                          // setError({ ...error, payError: "" });
                        }}
                      />
                      {/* <FormText color="danger" tag="span">
                            {error.payError}
                          </FormText> */}
                    </FormGroup>
                  </Col>
                </Row>
                {/* )} */}

                {!data.recurrent && (
                  <Row style={{ paddingTop: "16px" }}>
                    <Label sm="2">Estimated Pay Date (Optional)</Label>
                    <Col sm="6">
                      <ReactDatetime
                        isValidDate={disablePreviousDates}
                        timeFormat={false}
                        value={data.estimatedPayDate}
                        onChange={(date) => {
                          //console.log("date: ", e.target.value);
                          setData({
                            ...data,
                            estimatedPayDate: date,
                          });
                          // setError({ ...error, estimatedPayDateError: "" });
                        }}
                      />
                      {/* <FormText color="danger" tag="span">
                            {error.estimatedPayDateError}
                          </FormText> */}
                    </Col>
                  </Row>
                )}

                <Row style={{ paddingTop: "20px" }}>
                  <Label sm="2">Shift Functions</Label>
                  <Col md="6">
                    <ShiftFunctions
                      defaultValue={data.shiftFunctions}
                      onChange={(shiftFunctions) =>
                        //
                        setShiftFunctions(shiftFunctions)
                      }
                    />
                    <FormText color="danger" tag="span">
                      {error.shiftFunctionError}
                    </FormText>
                  </Col>
                </Row>
                <Row style={{ paddingTop: "20px" }}>
                  <Label sm="2">Show in Shift Board</Label>
                  <Col md="6" className="checkbox-radios">
                    <FormGroup check inline>
                      <Label check>
                        <Input
                          type="checkbox"
                          onChange={() => setData({ ...data, shiftBoard: !data.shiftBoard })}
                          checked={data.shiftBoard}
                          id={"shiftBoard"}
                        />
                        <span className="form-check-sign" />
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                {/* <Row style={{ paddingTop: "30px" }}>
                  <Label md="2">Emails</Label>
                  <Col md="8">
                    <FormGroup>
                      <AddEmailList
                        onChangeOptions={(options) => {
                          setData({ ...data, emails: options });
                        }}
                        initialData={data.emails}
                      />
                    </FormGroup>
                  </Col>
                </Row> */}
                <Row style={{ paddingTop: "4px" }}>
                  <Col sm="9"></Col>
                  <Col sm="3">
                    <Button color="success" onClick={handleSubmit}>
                      Create Shift
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {isLoading && loadingOverlay()}
    </div>
  );
}

export default CreateShifts;
