/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import { CSVLink } from "react-csv";
import { filterCaseInsensitive } from "components/Utilities/utilities";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  FormText,
  Spinner,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Label,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import { Visibility, PersonAdd, Clear, Check, CallEnd, Delete } from "@mui/icons-material";
import { Modal } from "@mui/material";

import * as utils from "components/Utilities/utilities.js";
import Select from "react-select";
import { stringifyTimeStamp } from "components/Utilities/utilities.js";
import { Redirect, Link } from "react-router-dom";
import LoadingOverlay from "components/OverLay/LoadingOverlay";
import moment from "moment";

import api from "components/API/api";
import { Input } from "reactstrap";
import AgencyContext from '../../../context/AgencyContext';
import ReactDatetime from 'react-datetime';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import StaffingContext from "../../../context/StaffingContext";

// Bring out of class to manage it's own state.

const AllAgencies = (props) => {

  const staffingContext = useContext(StaffingContext);
  
  const [agencyData, setAgencyData] = useState([])
  const [lastTableState, setLastTableState] = useState(null)
  const [openModal, setOpenModal] = useState({
    userModal: false,
    removeUser: false,
    confirmDelete: false
  })

  const [isLoading, setIsLoading] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)
  const [alert, setAlert] = useState(null)

  const [pages, setPages] = useState(0)
  const [tableQuery, setTableQuery] = useState("")
  const [sorted, setSorted] = useState([])

  useEffect(() => {
    console.log("useEffect")
    if (lastTableState) {
      fetchData()
    }
  }, [tableQuery])

  const RenderActionButtons = (agency) => {
    return (
      <div className="actions-right">
        <Button
        tag={Link}
        //to={`/${staffingContext.agency.agency.slug}/admin/user-profile/${agency._id}`}
          onClick={() => {
            //handleOpenProfile(agency._id);
          }}
          color="info"
          size="md"
          className="btn-link btn-icon"
        >
          <Visibility style={{ marginLeft: -1 }} />
        </Button>
      </div>
    );
  };

  const handleOpenProfile = (id) => {
    //let path = `/${staffingContext.agency.agency.slug}/admin/user-profile/${id}`;
    //props.history.push(path);
  };

  //Loading Overlay
  const hideAlert = () => {
    setAlert(null)
  };

  const fetchData = async (state = lastTableState) => {
    if (!state) {
      return
    }
    setLoadingTable(true)
    setLastTableState(state)

    try {
      let field = null;
      let sort = null;
      if (state.sorted.length > 0) {
        field = state.sorted[0].id;
        sort = state.sorted[0].desc ? "desc" : "asc";
      }
      const { data } = await api().get("/agency", {
        params: {
          page: state.page + 1,
          size: state.pageSize,
          field,
          sort,
          query: tableQuery,
        },
      });

      let agencies = data.agencies || [];
      //if (onlyTrained) {
      agencies = agencies.map((u) => ({
        ...u,
        active: u.active?"Yes":"No",
        actions: RenderActionButtons(u),
      }));
      console.log("data", data);
      setAgencyData(agencies)
      setPages(data.noPages)
      setLoadingTable(false)
    } catch (error) {
      setLoadingTable(false)
      console.log(error)
    }
  };

  return (
    <>
      <div className="content">
        {isLoading && <LoadingOverlay />}
        <Row>
          <Col md="12">
            <FormGroup>
              {/* <CSVLink
                  data={userData}
                  headers={userDataHeaders}
                  filename={"User_Data.csv"}
                >
                  <Button color="default" outline>
                    Export All
                    </Button>
                </CSVLink> */}
            </FormGroup>
            <Card className="no-transition">
              <CardHeader>
                <CardTitle tag="h4">All Agencies</CardTitle>
              </CardHeader>
              <CardBody>
                <h4>Filters</h4>
                <div style={{ textAlign: "right" }}>
                        <FormGroup row className="align-items-right">
                          <Col sm="7"></Col>
                          <Label for="Search" sm={2} style={{ color: "black", fontSize: "14px" }}>
                            Search
                          </Label>
                          <Col sm={3}>
                            <Input
                              type="text"
                              id="Search"
                              value={tableQuery}
                              onChange={(e) =>
                                setTableQuery(e.target.value)
                              }
                            />
                          </Col>
                        </FormGroup>
                      </div>

                <ReactTable
                  columns={[
                    {
                      Header: "Name",
                      accessor: "name",
                    },
                    {
                      Header: "Address",
                      accessor: "address",
                    },
                    {
                      Header: "City",
                      accessor: "city",
                    },
                    {
                      Header: "Contact name",
                      accessor: "contactName",
                    },
                    {
                      Header: "Phone",
                      accessor: "phone",
                    },
                    {
                      Header: "Employees",
                      accessor: "employees",
                    },
                    {
                      Header: "Slug",
                      accessor: "slug",
                    },
                    {
                      Header: "Active",
                      accessor: "active",
                    },
                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  manual
                  loading={loadingTable}
                  pages={pages}
                  data={agencyData}
                  defaultPageSize={10}
                  showPaginationTop
                  showPaginationBottom
                  onSortedChange={(newSort, column) => {
                    setSorted(newSort)
                  }}
                  onFetchData={fetchData}
                  className="-striped -highlight primary-pagination"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        {alert}
      </div>
    </>
  )
}

export default AllAgencies;
