import { Modal } from "@mui/material";
//import { fs } from "components/Firebase/firebase.js";
import { Comment, Feedback } from "@mui/icons-material";
import api, { useEventSource } from "components/API/api";
import * as moment from "moment-timezone";
import React from "react";
import ReactTable from "react-table";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Row,
} from "reactstrap";
import AgencyContext from "./../../../context/AgencyContext";
class ChequeIssuesHistory extends React.Component {
  static contextType = AgencyContext;
  constructor(props) {
    super(props);
    this.state = {
      chequeData: [],

      //Selected data
      selectedChequeTicket: null,
      selectedDocId: null,
      selectedComments: null,
      selectedFirstName: null,
      selectedLastName: null,
      selectedAdminNoteHistory: [],
      selectedSingleAdminNote: null,

      //Modals
      confirmViewCommentsModalOpen: false,
      confirmAdminNotesModalOpen: false,
      confirmViewSingleAdminNote: false,
      alert: null,

      reactTableState: {
        page: 1,
        size: 10,
        field: "name",
        sort: "asc",
        filter: "",
      },
      pages: -1,
      loading: false,
      newNote: "",
      timer: null,
    };
    this.source = null;
  }

  // componentDidMount() {
  //   console.log(this.context);
  //   // this.loadCheques();
  //   let agency =
  //     this.context.agency && this.context.agency.agency && this.context.agency.agency._id;
  //   this.source = useEventSource(`/cheque-issues/listen?agency=${agency}`, (data) => {
  //     if (data.changed) {
  //       this.setState({ loading: true });
  //       this.loadCheques();
  //     }
  //   });
  // }

  componentWillUnmount() {
    // this.source && this.source.close();
    clearTimeout(this.state.timer);
  }

  // loadCheques = () => {
  //   let tempArr = [];
  //   this.chequeDataIssuesHistoryRef.get().then((snapshot) => {
  //     snapshot.forEach((doc, i) => {
  //       let tempData = doc.data()
  //       if (tempData.pickedUp === true || tempData.issueStatus === 4 || tempData.issueStatus === 5) {
  //         //Compatibility checks, we dont want old data

  //         let dataToPush = {
  //           chequeTicket: tempData.chequeTicket,
  //           firstName: tempData.firstName,
  //           lastName: tempData.lastName,
  //           location: tempData.location,
  //           shift: tempData.shift,
  //           supervisor: tempData.supervisor,
  //           line: tempData.lineNumber,
  //           phone: tempData.phone,
  //           email: tempData.email,
  //           lastAdminEdit: tempData.lastAdminEdit,
  //           pinBadgeNumber: tempData.pinBadgeNumber,
  //         }

  //         if(tempData.createdDate){
  //           dataToPush.createdDate = stringifyTimeStamp(tempData.createdDate.toDate())
  //         } else {
  //           dataToPush.createdDate = ""
  //         }

  //         if (tempData.issueStatus === 5) { //Issue dismissed
  //           dataToPush.issueStatus = "Dismissed"
  //         } else { //Issue completed
  //           dataToPush.issueStatus = "Completed"
  //         }
  //         // issueStatus === 4 means the issue is RESOLVED
  //         // issueStatus === 5 means the issue is DISMISSED

  //         //Creates a button, when clicked, opens a modal + textarea to display full comment.
  //         //Text area allows for scrolling, so longer comments can be easily viewed
  //         if (tempData.adminNoteHistory) {
  //           let tempAdminNotesArr = []
  //           tempData.adminNoteHistory.forEach((note) => {
  //             let AdminNoteToPush = {
  //               adminNote: this.renderSingleAdminNote(note.comment),
  //               admin: note.admin,
  //               time: note.time,
  //               comment: note.comment
  //             }
  //             tempAdminNotesArr.push(AdminNoteToPush)
  //           });
  //           dataToPush.adminNoteHistory = tempAdminNotesArr
  //         } else {
  //           //compatibility with old data
  //           if (tempData.adminNotes) {
  //             dataToPush.adminNoteHistory = [{
  //               adminNote: this.renderSingleAdminNote(tempData.adminNotes),
  //               admin: "No admin recorded",
  //               time: "before Oct 9 2020",
  //               comment: tempData.adminNotes
  //             }];
  //           } else {
  //             //If no data detected
  //             dataToPush.adminNoteHistory = [];
  //           }
  //         }

  //         dataToPush.actions = this.renderActionButton(
  //           tempData.chequeTicket,
  //           doc.id,
  //           tempData.firstName,
  //           tempData.lastName,
  //           tempData.comments,
  //           dataToPush.adminNoteHistory
  //         );
  //         tempArr.unshift(dataToPush);
  //       }
  //     })
  //     let sortedData = tempArr.sort((a, b) => a.chequeTicket < b.chequeTicket ? 1 : -1)
  //     this.setState({ chequeData: sortedData });
  //   })
  // }

  loadCheques = async (filterOptions = this.state.reactTableState) => {
    try {
      const { data } = await api().get("/cheque-issues", {
        params: {
          ...filterOptions,
          processed: false,
        },
      });
      //Looping through the business data for action button implementation
      let tempChequeIssuesData = data.chequeRequest.map((request) => {
        request.actions = this.renderActionButton(
          request.chequeTicket,
          request._id,
          request.firstName,
          request.lastName,
          request.comment,
          request.notes,
        );
        request.issueStatus = request.status;
        request.createdDate = moment(request.createdAt).format("YYYY-MM-DD, hh:mm a");
        request.weekStart = moment(request.weekStart).format("YYYY-MM-DD");
        request.weekEnd = moment(request.weekEnd).format("YYYY-MM-DD");
        request.lastAdminEdit = request.lastEditedBy || "No edits yet";
        return request;
      });
      this.setState({
        chequeData: tempChequeIssuesData,
        pages: data.noPages,
        loading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  };

  renderActionButton = (chequeTicket, docId, firstName, lastName, comments, adminNoteHistory) => {
    return (
      <div className="actions-right">
        <Button
          onClick={() => {
            this.handleOpenAdminNotesModal(
              chequeTicket,
              docId,
              firstName,
              lastName,
              adminNoteHistory,
            );
          }}
          color="info"
          size="md"
          className="btn-link remove btn-icon"
        >
          <Comment style={{ marginLeft: -1 }} />
        </Button>{" "}
        <Button
          onClick={() => {
            this.handleOpenViewCommentsModal(chequeTicket, docId, firstName, lastName, comments);
          }}
          color="info"
          size="md"
          className="btn-link remove btn-icon"
        >
          <Feedback style={{ marginLeft: -1 }} />
        </Button>{" "}
      </div>
    );
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  //Opens Modal containing USER COMMENTS in a readonly format
  handleOpenViewCommentsModal = (chequeTicket, docId, firstName, lastName, comments) => {
    this.setState({
      confirmViewCommentsModalOpen: true,
      selectedChequeTicket: chequeTicket,
      selectedDocId: docId,
      selectedFirstName: firstName,
      selectedLastName: lastName,
      selectedComments: comments,
    });
  };

  handleCloseViewCommentsModal = () => {
    this.setState({
      confirmViewCommentsModalOpen: false,
      selectedChequeTicket: null,
      selectedDocId: null,
      selectedFirstName: null,
      selectedLastName: null,
      selectedComments: null,
    });
  };

  viewCommentsModal = () => {
    return (
      <Modal
        open={this.state.confirmViewCommentsModalOpen}
        onClose={this.handleCloseViewCommentsModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 350, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>
                {this.state.selectedFirstName} {this.state.selectedLastName}'s comments for ticket
                with ID: {this.state.selectedChequeTicket}.
              </h5>
            </div>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <textarea
                name="comments"
                cols="40"
                rows="5"
                autoComplete="off"
                readOnly={true}
                value={this.state.selectedComments}
              ></textarea>
            </div>
          </div>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button
                color="secondary"
                style={{ marginRight: "12px" }}
                onClick={() => this.handleCloseViewCommentsModal()}
              >
                Close
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  //Opens Modal containing ADMIN NOTES ONLY
  handleOpenAdminNotesModal = (chequeTicket, docId, firstName, lastName, adminNoteHistory) => {
    this.setState({
      confirmAdminNotesModalOpen: true,
      selectedChequeTicket: chequeTicket,
      selectedDocId: docId,
      selectedFirstName: firstName,
      selectedLastName: lastName,
      selectedAdminNoteHistory: adminNoteHistory,
    });
  };

  handleCloseAdminNotesModal = () => {
    this.setState({
      confirmAdminNotesModalOpen: false,
      selectedChequeTicket: null,
      selectedDocId: null,
      selectedFirstName: null,
      selectedLastName: null,
      selectedAdminNoteHistory: [],
    });
  };

  adminNotesModal = () => {
    return (
      <Modal
        open={this.state.confirmAdminNotesModalOpen}
        onClose={this.handleCloseAdminNotesModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 600, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>
                Admin notes for {this.state.selectedFirstName} {this.state.selectedLastName}'s
                ticket with ID: {this.state.selectedChequeTicket}.
              </h5>
            </div>
            <Form>
              <Row>
                <Col>
                  <ReactTable
                    data={this.state.selectedAdminNoteHistory}
                    filterable
                    columns={[
                      {
                        Header: "Admin",
                        accessor: "admin",
                        maxWidth: 160,
                      },
                      {
                        Header: "Comment",
                        accessor: "adminNote",
                      },
                      {
                        Header: "Time",
                        accessor: "time",
                        maxWidth: 160,
                      },
                    ]}
                    defaultPageSize={3}
                    showPaginationTop={true}
                    showPaginationBottom={false}
                    showPageSizeOptions={false}
                    className="-striped -highlight primary-pagination"
                  />
                </Col>
              </Row>
            </Form>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  //Opens Modal with a textarea containing an admin's comment (needed because longer comments DO NOT display correctly on the table)
  handleOpenViewSingleAdminNote = (comment) => {
    this.setState({
      confirmViewSingleAdminNote: true,
      selectedComment: comment,
      selectedSingleAdminNote: this.renderSingleAdminNote(comment),
    });
  };

  handleCloseViewSingleAdminNote = () => {
    this.setState({
      confirmViewSingleAdminNote: false,
      selectedSingleAdminNote: null,
      selectedComment: null,
    });
  };

  viewSingleAdminNote = () => {
    return (
      <Modal
        open={this.state.confirmViewSingleAdminNote}
        onClose={this.handleCloseViewSingleAdminNote}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 350, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <textarea
              name="singleAdminComment"
              cols="40"
              rows="5"
              autoComplete="off"
              readOnly={true}
              value={this.state.selectedComment}
            ></textarea>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  renderSingleAdminNote = (comment) => {
    return (
      <Button
        onClick={() => {
          this.handleOpenViewSingleAdminNote(comment);
        }}
        className="form-control"
        size="sm"
        style={{
          maxHeight: "40px",
          position: "relative",
          height: "40px",
          fontWeight: 400,
          marginTop: -7,
          textAlign: "left",
          backgroundColor: "transparent",
          color: "#2c2c2c",
          textTransform: "none",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {comment}
      </Button>
    );
  };

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card className="no-transition">
                <CardHeader>
                  <CardTitle tag="h4">Cheque Issues History</CardTitle>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={this.state.chequeData}
                    filterable={true}
                    getTrProps={(state, rowInfo, column) => {
                      if (rowInfo && rowInfo.row) {
                        if (rowInfo.row._original.issueStatus === "Completed") {
                          return {
                            style: {
                              color: "green",
                            },
                          };
                        } else if (rowInfo.row._original.issueStatus === "Dismissed") {
                          return {
                            style: {
                              color: "red",
                            },
                          };
                        } else {
                          return {
                            style: {
                              color: "black",
                            },
                          };
                        }
                      }
                      return {};
                    }}
                    columns={[
                      {
                        Header: "hdID",
                        accessor: "hdId",
                      },
                      {
                        Header: "Created Date",
                        accessor: "createdDate",
                      },
                      {
                        Header: "First Name",
                        accessor: "firstName",
                      },
                      {
                        Header: "Last Name",
                        accessor: "lastName",
                      },
                      {
                        Header: "Phone",
                        accessor: "phone",
                      },
                      {
                        Header: "Email",
                        accessor: "email",
                      },
                      {
                        Header: "Workplace",
                        accessor: "businessName",
                        filterable: false,
                      },
                      {
                        Header: "Shift",
                        accessor: "shift",
                      },
                      {
                        Header: "Supervisor",
                        accessor: "supervisor",
                      },
                      {
                        Header: "Line",
                        accessor: "lineNumber",
                      },
                      {
                        Header: "Pin or Badge",
                        accessor: "pin",
                      },
                      {
                        Header: "Last Edit By",
                        accessor: "lastEditName",
                        filterable: false,
                        sortable: false,
                      },
                      {
                        Header: "Status",
                        accessor: "issueStatus",
                        sortable: false,
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                        width: 105,
                      },
                    ]}
                    pages={this.state.pages}
                    defaultPageSize={10}
                    showPaginationTop
                    showPaginationBottom
                    loading={this.state.loading}
                    /*
                      You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                    */
                    className="-striped -highlight primary-pagination"
                    manual // informs React Table that you'll be handling sorting and pagination server-side
                    onFetchData={(state, instance) => {
                      // let searchParams = {
                      //   page: state.page + 1,
                      //   size: state.pageSize,
                      //   field: state.sorted.length ? state.sorted[0].id : "",
                      //   sort: state.sorted.length && state.sorted[0].desc ? "desc" : "asc",
                      //   // filter: state.filtered,
                      // };
                      // this.setState({
                      //   reactTableState: searchParams,
                      // });
                      // this.loadCheques(searchParams);

                      let searchParams = {
                        page: state.page + 1,
                        size: state.pageSize,
                        field: state.sorted.length ? state.sorted[0].id : "chequeTicket",
                        sort: state.sorted.length && state.sorted[0].desc ? "desc" : "asc",
                        filter: state.filtered,
                      };
                      this.setState({
                        reactTableState: searchParams,
                      });
                      clearTimeout(this.state.timer);
                      this.setState({
                        timer: setTimeout(() => {
                          this.setState({ loading: true });
                          this.loadCheques(searchParams);
                        }, 1000),
                      });
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {this.viewCommentsModal()}
          {this.adminNotesModal()}
          {this.viewSingleAdminNote()}
          {this.state.alert}
        </div>
      </>
    );
  }
}

export default ChequeIssuesHistory;
