// Modal Props cannot be undefined in version 5 of @mui/material
import { Modal } from "@mui/material";
import {
  AccessTime,
  Block,
  CallEnd,
  Check,
  Clear,
  ClearAll,
  Comment,
  DirectionsBus,
  Equalizer,
  EventBusy,
  Forum,
  Mail,
  PersonAdd,
  SpeakerNotesOff,
  Star,
  Visibility,
} from "@mui/icons-material";
import api from "components/API/api";
import RenderAvailability, {
  initialAvailability,
} from "components/Availability/RenderAvailability";
import CustomCalendar from "components/Calendar/CustomCalendar";
import LoadingOverlay from "components/OverLay/LoadingOverlay";
import { useModal } from "context/ModalContext";
import useFetchBusiness from "hooks/useFetchBusiness";
import { useHasPermissions } from "hooks/usePermissions";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import ReactTable from "react-table";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  FormText,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
import { midDay, startOfDay, startOfNextDay } from "utils/dateUtils.js";
import { statusEnum } from "utils/statusVariables";
import AgencyContext from "../../../context/AgencyContext";
import LastNameCell from "./AllUsers/LastNameCell";
import PinCell from "./AllUsers/PinCell";
import VaccinationCell from "./AllUsers/VaccinationCell";
import CommentsTab from "./UserProfile/Comments.jsx";
import DailyStatsModal from "./AllUsers/DailyStatsModal";
import usePermissions from "hooks/usePermissions";
import {
  DriversAddressList,
  statusEnumText,
  workStatusEnum,
  workStatusEnumText,
} from "./AllUsers/allUsersConstants";
import AllUsersModals from "./AllUsers/AllUsersModals";

export const USER_MODAL_TYPES = {
  ADD_USER: "addUser",
  REASSIGN_USER: "reAssignUser",
  CONFIRM_USER: "confirmUser",
  REMOVE_USER: "removeUser",
};

const AllUsers = () => {
  const agencyContext = useContext(AgencyContext);
  const { role } = usePermissions();
  const hideSomeFields = role === "Call Centre Staff" ? true : false;
  const [userData, setUserData] = useState([]);
  const [userCount, setUserCount] = useState({
    trained: "-",
    untrained: "-",
    publicPool: "-",
    unverified: "-",
    active: "-",
  });
  const [shiftsList, setShiftsList] = useState([]);
  const [openCommonModal, setOpenCommonModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [openModal, setOpenModal] = useState({
    confirmDelete: false,
    workerComments: false,
    availability: false,
    availabilityFilter: false,
    workerRating: false,
    assignDriver: false,
  });
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedWorkerComments, setSelectedWorkerComments] = useState([]);
  const [addComment, setAddComment] = useState("");
  const [selectedHdId, setSelectedHdId] = useState(null);
  const [selectedAvailability, setSelectedAvailability] = useState(null);
  const [selectedShift, setSelectedShift] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [canViewDiscipline] = useState(useHasPermissions({ action: "View", module: "Discipline" }));
  const [pageTabs, setPageTabs] = useState(localStorage?.getItem("workersPagetabs") || "active");
  const [pages, setPages] = useState(-1);
  const [timer, setTimer] = useState(null);
  const [filter, setFilter] = useState({
    date: moment(),
    businessId: "all",
    status: "",
    availabilityFilter: initialAvailability,
  });
  const [driversList, setDriversList] = useState([]);
  const [fetched, setFetched] = useState({ business: false, userData: false });
  const [reactTableState, setReactTableState] = useState(
    JSON.parse(localStorage?.getItem("reactTableState")) || {
      page: 1,
      size: 5,
      field: "hdId",
      sort: "desc",
      filter: [],
    },
  );
  const [firstUseEffect, setFirstUseEffect] = useState(true);
  const [note, setNote] = useState("");
  const [errorNote, setErrorNote] = useState(null);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [errorSelectedDriver, setErrorSelectedDriver] = useState(null);
  const [errorSelectedShift, setErrorSelectedShift] = useState(null);
  const { addAlert } = useModal();
  const [dailyStatModal, setDailyStatModal] = useState(false);
  const businessList = useFetchBusiness();
  const [pageNumber, setPageNumber] = useState(
    parseInt(localStorage.getItem("workersPageNumber")) || 0,
  );
  const [tableFilter, setTableFilter] = useState(
    JSON.parse(localStorage?.getItem("workersFilter")) || [],
  );

  useEffect(() => {
    fetchUserCount();
    fetchShifts();
    fetchDrivers();
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (userData.length > 0 && businessList.length > 0) {
      setUserData((tempUserData) => {
        return tempUserData.map((u) => {
          if (u.workerPin && u.workerPin.length > 0) {
            //if workerPin has business that doesn't exist in the list; don't show it
            u.workerPin = u.workerPin.filter((w) => businessList.some((b) => b._id === w.business));
            u.business = u.workerPin
              .map((w) => {
                let business = businessList.find((b) => b._id === w.business);
                // return business && businessName;
                return (
                  business && (
                    <div
                      style={{ color: w.preferred ? "#51cbce" : null, cursor: "pointer" }}
                      onClick={() => {
                        const data = {
                          workerId: u._id,
                          pinId: w._id,
                          preferred: !w.preferred,
                        };
                        api()
                          .patch("/users/pin", data)
                          .then(({ data }) => {
                            fetchData();
                          })
                          .catch((e) => {
                            console.log(e);
                          });
                      }}
                    >
                      {business && business.name}
                      {w.preferred ? <Star style={{ width: 15 }} /> : null}
                    </div>
                  )
                );
              })
              .filter((n) => n);
            u.pin = u.workerPin.map((w) => w.pin || "-").join(", ");
            u.workerPin = u.workerPin
              .map((w) => {
                let business = businessList.find((b) => b._id === w.business);
                w.businessName = business && business.name;
                return w;
              })
              .filter((w) => w.businessName);
          }
          let isUserUntrained = true;
          if (u.qualifications.length >= 1) {
            isUserUntrained = false;
          }
          return {
            ...u,
            actions: RenderActionButtons(u, isUserUntrained),
          };
        });
      });
    }
  }, [fetched, businessList]);

  useEffect(() => {
    if (reactTableState && !firstUseEffect) {
      fetchData();
      setLoadingTable(true);
    }
  }, [filter, pageTabs]);

  useEffect(() => {
    if (reactTableState && !firstUseEffect) {
      fetchShifts();
    }
  }, [filter.date]);

  /**
   * 1. update admin actions in log
   * 2. Params @userId string
   * 3. No output returned
   */
  const updateAdminUpdateTime = (userId) => {
    api()
      .patch("/users", { lastStatusUpdate: new Date(), userId: userId })
      .then(({ data }) => {
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        console.log("couldn't update user last updated time");
      });
  };

  const RenderActionButtons = (user) => {
    let selectedForWorkstatus;
    const hdId = user.hdId;
    const selected = user.selectForWork && user.selectForWork.length > 0;
    if (selected) {
      selectedForWorkstatus = user.selectForWork[0].status;
    }

    const { status, availability } = user;
    const deactivatedUser = pageTabs === "deactivatedUser";
    return (
      <div className="actions-right">
        {!deactivatedUser ? (
          <>
            {status === statusEnum.noResponse ? (
              <Button
                onClick={() => {
                  updateStatus(statusEnum.available, user._id);
                }}
                disabled={
                  pageTabs === "untrained" || (hideSomeFields && user.verified !== "Verified")
                }
                color="warning"
                size="md"
                className="btn-link btn-icon"
              >
                <CallEnd style={{ marginLeft: -1 }} />
              </Button>
            ) : status === statusEnum.unavailable ? (
              <Button
                onClick={() => {
                  updateStatus(statusEnum.available, user._id);
                }}
                disabled={
                  pageTabs === "untrained" || (hideSomeFields && user.verified !== "Verified")
                }
                color="danger"
                size="md"
                className="btn-link btn-icon"
              >
                <Clear style={{ marginLeft: -1 }} />
              </Button>
            ) : selected ? (
              <>
                {selectedForWorkstatus === statusEnum?.selectedForWork?.selected && (
                  <Button
                    onClick={() => {
                      handleOpenCommonModal(hdId, USER_MODAL_TYPES.REMOVE_USER);
                    }}
                    color={"success"}
                    size="md"
                    className="btn-link btn-icon"
                  >
                    <Check style={{ marginLeft: -1 }} />
                  </Button>
                )}

                {selectedForWorkstatus === statusEnum?.selectedForWork?.Backup && (
                  <Button
                    onClick={() => {
                      handleOpenCommonModal(hdId, USER_MODAL_TYPES.REMOVE_USER);
                    }}
                    color="warning"
                    size="md"
                    className="btn-link btn-icon"
                  >
                    <Check style={{ marginLeft: -1 }} />
                  </Button>
                )}

                {selectedForWorkstatus === statusEnum?.selectedForWork?.Applied && (
                  <Button
                    onClick={() => {
                      handleOpenCommonModal(hdId, USER_MODAL_TYPES.CONFIRM_USER);
                    }}
                    color={"warning"}
                    size="md"
                    className="btn-link btn-icon"
                    disabled={hideSomeFields && user.documentStatus?.status !== "Verified"}
                  >
                    <Mail style={{ marginLeft: -1 }} />
                  </Button>
                )}

                {selectedForWorkstatus === statusEnum?.selectedForWork?.Rejected && (
                  <Button
                    onClick={() => {
                      handleOpenCommonModal(hdId, USER_MODAL_TYPES.REASSIGN_USER);
                    }}
                    disabled={
                      pageTabs === "untrained" || (hideSomeFields && user.verified !== "Verified")
                    }
                    color={"warning"}
                    size="md"
                    className="btn-link btn-icon"
                  >
                    Block
                    <Block style={{ marginLeft: -1 }} />
                  </Button>
                )}

                {selectedForWorkstatus === statusEnum?.selectedForWork?.UnScheduled && (
                  <Button
                    onClick={() => {
                      handleOpenCommonModal(hdId, USER_MODAL_TYPES.REASSIGN_USER);
                    }}
                    disabled={
                      pageTabs === "untrained" || (hideSomeFields && user.verified !== "Verified")
                    }
                    color={"warning"}
                    size="md"
                    className="btn-link btn-icon"
                  >
                    <EventBusy style={{ marginLeft: -1 }} />
                  </Button>
                )}

                {(selectedForWorkstatus === statusEnum?.selectedForWork?.Dropped ||
                  selectedForWorkstatus === statusEnum?.selectedForWork?.computerDropped) && (
                  <Button
                    onClick={() => {
                      handleOpenCommonModal(hdId, USER_MODAL_TYPES.REASSIGN_USER);
                    }}
                    disabled={
                      pageTabs === "untrained" || (hideSomeFields && user.verified !== "Verified")
                    }
                    color={"warning"}
                    size="md"
                    className="btn-link btn-icon"
                  >
                    <SpeakerNotesOff style={{ marginLeft: -1 }} />
                  </Button>
                )}
              </>
            ) : (
              <Button
                onClick={() => {
                  handleOpenCommonModal(hdId, USER_MODAL_TYPES.ADD_USER);
                }}
                color="secondary"
                size="md"
                disabled={
                  pageTabs === "untrained" || (hideSomeFields && user.verified !== "Verified")
                }
                className="btn-link btn-icon"
              >
                <PersonAdd style={{ marginLeft: -1 }} />
              </Button>
            )}
            <Button
              onClick={() => {
                handleOpenDriverAssignModal(user._id);
              }}
              color="default"
              size="md"
              className="btn-link btn-icon"
            >
              <DirectionsBus style={{ marginLeft: -1 }} />
            </Button>
            <Button
              onClick={() => {
                handleOpenWorkerCommentsModal(user._id);
              }}
              color="info"
              size="md"
              className="btn-link btn-icon"
            >
              <Comment style={{ marginLeft: -1 }} />
            </Button>
            <Button
              onClick={() => {
                handleOpenAvailabilityModal(user._id, availability);
              }}
              color="info"
              size="md"
              className="btn-link btn-icon"
            >
              <AccessTime style={{ marginLeft: -1 }} />
            </Button>
            <Button
              tag={Link}
              to={`/${agencyContext.agency?.agency?.slug}/admin/user-profile/${user._id}/?tab=chat`}
              color="default"
              size="md"
              className="btn-link btn-icon"
            >
              <Forum style={{ marginLeft: -1 }} />
            </Button>
          </>
        ) : null}
        <Button
          tag={Link}
          to={`/${agencyContext.agency?.agency?.slug}/admin/user-profile/${user._id}`}
          color="info"
          size="md"
          className="btn-link btn-icon"
        >
          <Visibility style={{ marginLeft: -1 }} />
        </Button>
      </div>
    );
  };

  const updateStatus = async (status, userId) => {
    try {
      const data = { status, userId, lastStatusUpdate: new Date() };
      const resp = await api().patch("/users", data);
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  // open common user modals
  const handleOpenCommonModal = (hdId, modalType) => {
    setSelectedHdId(hdId);
    setOpenCommonModal(true);
    setModalType(modalType);
  };

  // Opens modal to assign user to driver
  const handleOpenDriverAssignModal = (user) => {
    setOpenModal({ assignDriver: true });
    setSelectedUserId(user);
  };

  const handleCloseDriverAssignModal = () => {
    setOpenModal({ assignDriver: false });
    setSelectedDriver(null);
    setSelectedUserId(null);
    setSelectedShift(null);
    setNote(null);
  };

  ////////////////////////////////////////////////////
  //ASSIGN USER to Driver
  ////////////////////////////////////////////////////
  const DriverAssignModal = () => {
    return (
      <Modal
        open={openModal.assignDriver}
        onClose={handleCloseDriverAssignModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 450, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <Row>
              <Col md="12">
                <h5>Please Select Worker and Date to Assign.</h5>
              </Col>
            </Row>

            <Row>
              <Col md="3">Select Driver</Col>
              <Col md="9">
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  options={driversList}
                  defaultValue={selectedDriver}
                  onChange={(value) => {
                    setSelectedDriver(value);
                    setErrorSelectedDriver(null);
                  }}
                  placeholder="Select driver"
                />
              </Col>
            </Row>

            {errorSelectedDriver && (
              <Row>
                <Col md="3"></Col>
                <Col md="9">
                  <FormText color="danger" tag="span">
                    {errorSelectedDriver}
                  </FormText>
                </Col>
              </Row>
            )}

            <Row style={{ marginTop: 10 }}>
              <Col md="3">Pickup Location</Col>
              <Col md="9">
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  options={DriversAddressList}
                  defaultValue={note}
                  onChange={(value) => {
                    setNote(value.value);
                    setErrorNote(null);
                  }}
                  placeholder="Select Pickup Location"
                />
              </Col>
            </Row>
            {errorNote && (
              <Row>
                <Col md="3"></Col>
                <Col md="9">
                  <FormText color="danger" tag="span">
                    {errorNote}
                  </FormText>
                </Col>
              </Row>
            )}

            <Row style={{ marginTop: 10 }}>
              <Col md="3">Select Shift Timing</Col>
              <Col md="9">
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  options={[
                    { value: "Morning", label: "Morning" },
                    { value: "Afternoon", label: "Afternoon" },
                    { value: "Night", label: "Night" },
                  ]}
                  defaultValue={selectedShift}
                  onChange={(value) => {
                    setSelectedShift(value.value);
                    setErrorSelectedShift(null);
                  }}
                  placeholder="Select Shift Timing"
                />
              </Col>
            </Row>
            {errorSelectedShift && (
              <Row>
                <Col md="3"></Col>
                <Col md="9">
                  <FormText color="danger" tag="span">
                    {errorSelectedShift}
                  </FormText>
                </Col>
              </Row>
            )}

            <div style={{ justifyContent: "space-between", display: "flex", marginTop: 15 }}>
              <Button onClick={() => handleCloseDriverAssignModal()}>Cancel</Button>
              <Button color="success" onClick={() => assignUser()}>
                Assign
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  //asign user function
  const assignUser = async () => {
    try {
      let hasError = false;

      if (!selectedDriver?.value || selectedDriver?.value.trim() === "") {
        hasError = true;
        setErrorSelectedDriver("* please select a driver");
      }
      if (!note || note.trim() === "") {
        hasError = true;
        setErrorNote("* please add a note to driver e.g pick up location");
      }

      if (!selectedShift) {
        hasError = true;
        setErrorSelectedShift("* please select the shift");
      }
      if (hasError) {
        return;
      } else {
        let dataToSend = {
          driver: selectedDriver.value,
          employee: selectedUserId,
          date: midDay(filter.date),
          start: startOfDay(filter.date),
          end: startOfNextDay(filter.date),
          note: note,
          //shiftTiming: shiftTimeType(selectedShift?.shift?.start),
          shiftTiming: selectedShift,
        };
        await api().post("/driver-pickup/assign-worker", dataToSend);
        handleCloseDriverAssignModal();
        addAlert({
          title: "Worker assigned successfully",
          success: true,
        });
      }
    } catch (error) {
      console.log(error);
      handleCloseDriverAssignModal();
      addAlert({
        title: error.response.data.message || error.message,
        error: true,
      });
    }
  };

  const workerCommentsModal = () => {
    return (
      <Modal
        open={openModal.workerComments}
        onClose={handleCloseWorkerCommentsModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 500, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>Worker Comment History</h5>
            </div>
            <CommentsTab comments={selectedWorkerComments} isLoading={isLoading} />
            <Input
              value={addComment}
              onChange={(e) => setAddComment(e.target.value)}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  e.preventDefault();
                  handleAddComment(selectedUserId, addComment);
                }
              }}
              placeholder="Add a comment for this user"
              type="text"
            />
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button color="secondary" onClick={handleCloseWorkerCommentsModal}>
                Close
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  handleAddComment(selectedUserId, addComment);
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  const AvailabilityModal = (props) => {
    const initialAvailability = [...props.selectedAvailability];
    const [tempAvailability, setTempAvailability] = useState(
      initialAvailability.map((a) => {
        return { ...a };
      }),
    );

    return (
      <Modal
        open={openModal.availability}
        onClose={handleCloseWorkerAvailabilityModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 500, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex", margin: 20 }}>
              <h5>Update User Availability</h5>
            </div>
            <RenderAvailability
              availability={tempAvailability}
              onChange={(newAvailability) => {
                setTempAvailability(newAvailability);
              }}
            />

            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button
                color="secondary"
                onClick={() => {
                  handleCloseWorkerAvailabilityModal();
                }}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  handleCloseWorkerAvailabilityModal();

                  const data = {
                    availability: tempAvailability,
                    userId: selectedUserId,
                  };

                  setIsLoading(true);
                  api()
                    .patch("/users", data)
                    .then(({ data }) => {
                      setIsLoading(false);
                      let newUsers = userData.map((u) => {
                        if (selectedUserId === u._id) u.availability = tempAvailability;
                        return {
                          ...u,
                          actions: RenderActionButtons(u),
                        };
                      });
                      setUserData(newUsers);
                      addAlert({
                        title: "successfully updated availibility",
                        success: true,
                      });
                    })
                    .catch((e) => {
                      setIsLoading(false);
                      addAlert({
                        title: e.response?.data?.message || e.message,
                        error: true,
                      });
                    });
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  const AvailabilityFilterModal = (props) => {
    const initialAvailability = [...props.availability] || [];
    const [tempAvailability, setTempAvailability] = useState(
      initialAvailability.map((a) => {
        return { ...a };
      }),
    );

    return (
      <Modal
        open={openModal.availabilityFilter}
        onClose={handleCloseAvailabilityFilter}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 500, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex", margin: 20 }}>
              <h5>Availability Filter(get all users with selected available days)</h5>
            </div>
            <RenderAvailability
              availability={tempAvailability}
              onChange={(newAvailability) => {
                setTempAvailability(newAvailability);
              }}
            />

            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button
                color="secondary"
                onClick={() => {
                  handleCloseAvailabilityFilter();
                }}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  setFilter({ ...filter, availabilityFilter: tempAvailability });
                  handleCloseWorkerAvailabilityModal();
                }}
              >
                Save Availability Filter
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  // Opens modal to view worker comments
  const handleOpenAvailabilityModal = (_id, availability) => {
    setSelectedUserId(_id);
    setSelectedAvailability(availability);
    setOpenModal({ availability: true });
  };

  const handleOpenAvailabilityFilter = () => {
    setOpenModal({ availabilityFilter: true });
  };

  const handleCloseAvailabilityFilter = () => {
    setOpenModal({});
  };

  const handleCloseWorkerAvailabilityModal = () => {
    setOpenModal({});
    setSelectedAvailability([]);
    setSelectedUserId(null);
  };

  const handleOpenWorkerCommentsModal = (_id) => {
    setSelectedUserId(_id);
    setOpenModal({ workerComments: true });
    loadCommentHistory(_id);
    setAddComment("");
  };

  const handleCloseWorkerCommentsModal = () => {
    setOpenModal({});
    setSelectedWorkerComments([]);
    setAddComment("");
  };

  const loadCommentHistory = (_id) => {
    setIsLoading(true);
    api()
      .get(`/user-note?userId=${_id}&fieldType=User`)
      .then((res) => {
        const data = res.data.notes;
        let tempArray = [];
        data.map((q) => {
          let tempDate = q.createdAt.split(".")[0];
          tempArray.push({
            noteBy: q.noteBy,
            note: q.note,
            time: tempDate.split("T")[0] + " " + tempDate.split("T")[1],
          });
        });
        setSelectedWorkerComments(tempArray);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("error getting comment history for worker: ", _id, err);
        setIsLoading(false);
      });
  };

  const handleAddComment = (_id, newNote) => {
    setIsLoading(true);
    api()
      .post(`/user-note`, {
        user: _id,
        commentField: "User",
        commentFieldId: _id,
        note: newNote,
      })
      .then((res) => {
        let tempCommentHistory = selectedWorkerComments;
        let tempDate = res.data.createdAt.split(".")[0];
        let newComment = {
          note: newNote,
          noteBy: agencyContext.agency.user.firstName + " " + agencyContext.agency.user.lastName,
          time: tempDate.split("T")[0] + " " + tempDate.split("T")[1],
        };
        tempCommentHistory.push(newComment);

        //update status update time
        updateAdminUpdateTime(_id);
        handleCloseWorkerCommentsModal();
        setAddComment("");
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const fetchUserCount = async () => {
    try {
      const fetchTrainedUsers = api().get("/users/count", { params: { onlyTrained: true } });
      const fetchUntrainedUsers = api().get("/users/count", { params: { onlyUntrained: true } });
      const fetchActiveUsers = api().get("/users/count", {
        params: { appActive: true, onlyTrained: true },
      });
      const fetchUnverifiedUsers = api().get("/users/count", {
        params: { onlyTrained: true, onlyUnverified: true },
      });

      const [noTrainedUsers, noUntrainedUsers, noActiveUsers, noUnverified] = await Promise.all([
        fetchTrainedUsers,
        fetchUntrainedUsers,
        fetchActiveUsers,
        fetchUnverifiedUsers,
      ]);
      setUserCount({
        active: noActiveUsers.data?.count,
        trained: noTrainedUsers.data?.count,
        untrained: noUntrainedUsers.data?.count,
        unverified: noUnverified.data?.count,
        publicPool: "-",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchShifts = async () => {
    try {
      if (!moment(startOfNextDay(filter.date)).isAfter(new Date())) return;
      const { data } = await api().get("/shift/list", {
        params: {
          start: startOfDay(filter.date),
          end: startOfNextDay(filter.date),
        },
      });
      setShiftsList(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAvailabilityFilter = (availability) => {
    if (
      availability.some((a) => {
        return a.active;
      })
    ) {
      return availability;
    }
    return null;
  };

  const fetchData = async (filterOptions = reactTableState) => {
    try {
      const onlyTrained =
        pageTabs === "trained" || pageTabs === "active" || pageTabs === "unverified";
      const onlyUntrained = pageTabs === "untrained";
      const onlyDisciplined = pageTabs === "disciplined";
      const appActive = pageTabs === "active";
      const publicPool = pageTabs === "publicPool";
      const pendingVerification = pageTabs === "unverified";
      const deactivatedUser = pageTabs === "deactivatedUser";
      const { data } = await api().get("/users", {
        params: {
          ...filterOptions,
          filter: filterOptions.filter
            .map((a) => ({ ...a }))
            .map((f) => {
              if (f.id === "workStatus") {
                switch (f.value?.charAt(0).toLowerCase()) {
                  case "p":
                    f.value = workStatusEnum.permanentResident;
                    break;
                  case "s":
                    f.value = workStatusEnum.studentVisa;
                    break;
                  case "w":
                    f.value = workStatusEnum.workPermit;
                    break;
                  default:
                }
              }
              return f;
            }),
          onlyUntrained,
          onlyTrained,
          onlyDisciplined,
          publicPool,
          appActive,
          start: startOfDay(filter.date),
          end: startOfNextDay(filter.date),
          businessId: filter.businessId,
          status: filter.status,
          availability: getAvailabilityFilter(filter.availabilityFilter),
          pendingVerification,
          deactivatedUser,
        },
      });
      setPages(data.noPages);
      let users = data.users || [];

      setUserData(
        users?.map((u, i) => {
          return {
            ...u,
            displayLastName:
              u.displayLastName || u.duplicated ? u.lastName + "-" + u.duplicated : u.lastName,
            createdAt: moment(u.createdAt).format("YYYY-MM-DD"),
            dob: moment(u.dob).add(5, "hours").format("MM-DD-YYYY"),
            lastAppLogin: u.lastAppLogin
              ? moment(u.lastAppLogin).format("YYYY-MM-DD, hh:mm a")
              : "",
            lastAppActive: u.lastAppActive ? moment(u.lastAppActive).calendar() : "",
            actions: RenderActionButtons(u),
            rowColor:
              u.status === statusEnum.suspended
                ? "#cf4e32"
                : u.totalWarning === 1
                ? "#CCCC00"
                : u.totalWarning >= 2
                ? "#FFA500"
                : null,
            backgroundColor:
              u.documentStatus.expiresIn <= 0
                ? "#fcded7"
                : u.documentStatus.expires < 30
                ? "#fceed7"
                : null,
            lastStatusUpdate:
              u.lastStatusUpdate && moment(u.lastStatusUpdate).format("DD MMM, hh:mm a"),
            workerPin: u.workerPin,
            verified: u.documentStatus?.status,
            selectedToWorkByName:
              u.selectForWork.length > 0 ? u.selectForWork[0].selectedToWorkByName : "",
            comment: u.comment ? u.comment.note : null,
            documentStatus: u.documentStatus,
          };
        }),
      );

      setFetched({ business: false, userData: true });
      setLoadingTable(false);
      setIsLoading(false);
      setFirstUseEffect(false);
    } catch (error) {
      setLoadingTable(false);
      setIsLoading(false);
      setFirstUseEffect(false);
      console.log(error);
    }
  };

  const fetchDrivers = async () => {
    setIsLoading(true);
    const { data } = await api().get("/users/get-drivers", {
      params: {
        start: startOfDay(new Date()),
        end: startOfNextDay(new Date()),
      },
    });
    setIsLoading(false);
    let users = data.users || [];
    users = users.map((u, index) => ({
      label: u.firstName + `${" "}` + u.lastName,
      value: u._id,
    }));
    setDriversList(users);
  };

  //Renders the nav for each of the tabs. Title and Name are strings
  const renderNavItem = (tabTitle, tabId) => {
    return (
      <NavItem>
        <NavLink
          data-toggle="tab"
          role="tablist"
          className={pageTabs === tabId ? "active" : ""}
          onClick={() => {
            setPageTabs(tabId);
            localStorage.setItem("workersPagetabs", tabId);
          }}
        >
          <i className="now-ui-icons ui-2_settings-90" />
          {tabTitle}
        </NavLink>
      </NavItem>
    );
  };

  return (
    <>
      {isLoading && <LoadingOverlay />}
      <div className="content">
        <Card className="no-transition">
          <CardHeader>
            <Row>
              <Col className="d-flex justify-content-between">
                <CardTitle tag="h4">All Employees</CardTitle>
                {hideSomeFields ? null : (
                  <Button
                    onClick={() => {
                      setDailyStatModal(true);
                    }}
                  >
                    Daily Stats
                    <Equalizer />
                  </Button>
                )}
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Nav
              className="nav-pills-primary nav-pills-icons justify-content-center"
              pills
              role="tablist"
            >
              {renderNavItem(`Active Employees (${userCount.active})`, "active")}
              {renderNavItem(`Trained Employees (${userCount.trained})`, "trained")}
              {renderNavItem(`Unqualified Employees (${userCount.untrained})`, "untrained")}
              {canViewDiscipline && renderNavItem("Disciplined", "disciplined")}
              {/* {renderNavItem(`Public Pool (${userCount.publicPool})`, "publicPool")} */}
              {renderNavItem(`Unverified (${userCount.unverified})`, "unverified")}
              {renderNavItem(`Deleted Employees`, "deactivatedUser")}
            </Nav>
            <Row className="mt-1 justify-content-between">
              <Col lg="4" md="5">
                <CustomCalendar
                  inputProps={{
                    placeholder: "Date Picker Here",
                  }}
                  initialDate={filter.date}
                  onChangeDate={(date) => {
                    setFilter({ ...filter, date: moment(date) });
                  }}
                  myDate={filter.date}
                />
              </Col>
              <Col md="4" className="d-flex justify-content-end">
                <FormGroup row className="align-items-right">
                  <Button onClick={handleOpenAvailabilityFilter} outline color="info" size="md">
                    Availability Filter
                    <AccessTime style={{ marginLeft: -1 }} />
                  </Button>
                  {filter.availabilityFilter.some((a) => {
                    return a.active;
                  }) && (
                    <Button
                      onClick={() => {
                        setFilter({ ...filter, availabilityFilter: initialAvailability });
                      }}
                      color="warning"
                      size="md"
                      className="btn-link btn-icon"
                    >
                      Clear All
                      <ClearAll style={{ marginLeft: -1 }} />
                    </Button>
                  )}
                  <Col sm={9}></Col>
                </FormGroup>
              </Col>
            </Row>
            <Row className="justify-content-between">
              <Col xs="auto" sm="6" md="4">
                <FormGroup row className="align-items-right">
                  <Label for="Search" sm={3} style={{ color: "black", fontSize: "14px" }}>
                    Business
                  </Label>
                  <Col sm={9}>
                    <Input
                      type="select"
                      name="businessId"
                      onChange={(e) => setFilter({ ...filter, businessId: e.target.value })}
                      value={filter.businessId}
                    >
                      <option value="all">All Employees</option>
                      <option value="none">Never Worked</option>
                      {businessList &&
                        businessList.length > 0 &&
                        businessList.map((b) => {
                          return (
                            <option key={b._id} value={b._id}>
                              {b.name}
                            </option>
                          );
                        })}
                    </Input>
                  </Col>
                </FormGroup>
              </Col>

              <Col lg="4" md="4">
                <div style={{ textAlign: "right" }}>
                  <FormGroup row className="align-items-right">
                    <Label for="Search" sm={3} style={{ color: "black", fontSize: "14px" }}>
                      Worker Status
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="select"
                        name="status"
                        onChange={(e) => {
                          setFilter({ ...filter, status: e.target.value });
                        }}
                        value={filter.status}
                      >
                        <option key={10} value="">
                          All
                        </option>
                        {Object.values(statusEnum)
                          // .filter((s) => s !== statusEnum.suspended) //don't show suspended status on drw
                          .map((b, i) => {
                            return (
                              <option key={i} value={b}>
                                {statusEnumText[i]}
                              </option>
                            );
                          })}
                      </Input>
                    </Col>
                  </FormGroup>
                </div>
              </Col>
            </Row>
            <Row>
              <p>
                BLUE text workers have a SIN that will expire in SIXTY (60) days or less
                <br></br>
                PURPLE text workers have a SIN that will expire in THIRTY (30) days or less
                <br></br>
                RED workers have an expired or missing SIN
              </p>
            </Row>
            <ReactTable
              filterable
              getTrProps={(state, rowInfo, instance) => {
                if (rowInfo?.row?._original?.rowColor || rowInfo?.row?._original?.backgroundColor) {
                  return {
                    style: {
                      color: rowInfo?.row?._original?.rowColor,
                      background: rowInfo?.row?._original?.backgroundColor,
                    },
                  };
                }
                return {};
              }}
              columns={[
                {
                  Header: "HD Id",
                  accessor: "hdId",
                  maxWidth: 70,
                },
                {
                  Header: "Registry Date",
                  accessor: "createdAt",
                  filterable: false,
                },
                {
                  Header: "Pin",
                  accessor: "pin",
                  maxWidth: 80,
                  Cell: (row) => {
                    return (
                      <PinCell
                        value={row.value}
                        workerPin={row.original.workerPin}
                        // _id={row.original.workerPin && row.original.workerPin._id}
                        onChange={() => {
                          fetchData();
                        }}
                        workerId={row.original._id}
                        businessList={businessList}
                        agencyId={agencyContext.agency.agency._id}
                      />
                    );
                  },
                },
                {
                  Header: "First Name",
                  accessor: "firstName",
                  maxWidth: 180,
                },
                {
                  Header: "Last Name",
                  accessor: "displayLastName",
                  maxWidth: 180,
                  Cell: (row) => {
                    return (
                      <LastNameCell
                        value={row.value}
                        _id={row.original._id}
                        onChange={(newValue, _id) => {
                          setUserData(
                            userData.map((u) => ({
                              ...u,
                              displayLastName: u._id === _id ? newValue : u.displayLastName,
                            })),
                          );
                        }}
                      />
                    );
                  },
                },
                {
                  Header: "BirthDate",
                  accessor: "dob",
                  filterable: false,
                  maxWidth: 110,
                },
                {
                  Header: "Gender",
                  accessor: "gender",
                  maxWidth: 90,
                },
                {
                  Header: "Phone",
                  accessor: "phone",
                  Cell: (row) => {
                    let telephone = "tel:" + row.original.phone;
                    return (
                      <a
                        href={telephone}
                        style={{
                          color: "#51bcda",
                          textDecoration: "underline",
                          fontWeight: 400,
                        }}
                      >
                        {row.original.phone}
                      </a>
                    );
                  },
                  maxWidth: 140,
                },
                {
                  Header: "Sin",
                  accessor: "sin",
                  maxWidth: 100,
                },
                {
                  Header: "verified",
                  accessor: "verified",
                  getProps: (state, rowInfo, column) => {
                    return {
                      style: {
                        backgroundColor:
                          rowInfo?.row?.verified === "Verified"
                            ? "#D2FFCB" //light green
                            : rowInfo?.row?.verified === "Invalid" ||
                              rowInfo?.row?.verified === "Expired"
                            ? "#EE9999" //light red
                            : rowInfo?.row?.verified === "Pending"
                            ? "#ffffc2" // light yellow
                            : rowInfo?.row?.verified === "Incomplete"
                            ? "#FFCBCB"
                            : "", // no data
                        fontWeight: 400,
                      },
                    };
                  },
                  filterable: false,
                  sortable: false,
                  maxWidth: 70,
                },
                {
                  Header: "Selected By",
                  accessor: "selectedToWorkByName",
                },
                {
                  Header: "Email",
                  accessor: "email",
                  maxWidth: 180,
                },
                {
                  Header: "Business",
                  accessor: "business",
                  filterable: false,
                  maxWidth: 220,
                },
                {
                  Header: "City",
                  accessor: "city",
                  maxWidth: 110,
                },
                {
                  Header: "Address",
                  accessor: "address",
                  maxWidth: 110,
                },
                {
                  Header: "Vaccination Status",
                  accessor: "vaccineStatus",
                  maxWidth: 180,
                  getProps: (state, rowInfo, column) => {
                    return {
                      style: {
                        background:
                          rowInfo && rowInfo.row.vaccineStatus == 2
                            ? "#D2FFCB" //light green, fully vaccinated
                            : rowInfo && rowInfo.row.vaccineStatus == 1
                            ? "#CBE6FF" //light blue, first dose
                            : rowInfo && rowInfo.row.vaccineStatus == 0
                            ? "#FFCBCB" //light red, no vaccine
                            : "", //no data
                      },
                    };
                  },
                  Cell: (row) => {
                    // setOpenModal({ vaccineModal: true })
                    return (
                      <VaccinationCell
                        value={row.value}
                        _id={row.original._id}
                        onChange={(newValue, _id) => {
                          setUserData(
                            userData.map((u) => ({
                              ...u,
                              vaccineStatus: u._id === _id ? newValue : u.vaccineStatus,
                            })),
                          );
                        }}
                      />
                    );
                  },
                },
                {
                  Header: "Work Status",
                  accessor: "workStatus",
                  maxWidth: 220,
                  Cell: (row) => {
                    return <div>{workStatusEnumText[row.value]}</div>;
                  },
                },
                {
                  Header: "Comment",
                  accessor: "comment",
                  filterable: false,
                },
                {
                  Header: "Last Active(app)",
                  accessor: "lastAppActive",
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                  width: 300,
                  sortable: false,
                  filterable: false,
                },
              ]}
              manual
              loading={loadingTable}
              pages={pages}
              data={userData}
              onPageChange={(pageIndex) => {
                localStorage.setItem("workersPageNumber", pageIndex);
                setPageNumber(pageIndex);
              }}
              page={pageNumber}
              filtered={tableFilter}
              onFilteredChange={(change) => {
                localStorage.setItem("workersFilter", JSON.stringify(change));
                setTableFilter(change);
              }}
              defaultPageSize={10}
              pageSizeOptions={[10, 15, 30, 60]}
              showPaginationTop
              showPaginationBottom
              onFetchData={(state, instance) => {
                let searchParams = null;
                if (firstUseEffect && JSON.parse(localStorage?.getItem("reactTableState"))) {
                  searchParams = JSON.parse(localStorage?.getItem("reactTableState"));
                } else {
                  searchParams = {
                    page: state.page + 1,
                    size: state.pageSize,
                    field: state.sorted.length ? state.sorted[0].id : "hdId",
                    sort: state.sorted.length ? (state.sorted[0].desc ? "desc" : "asc") : "desc",
                    filter: state.filtered,
                  };
                }
                // console.log(searchParams);
                localStorage.setItem("reactTableState", JSON.stringify(searchParams));
                setReactTableState({ ...searchParams });
                clearTimeout(timer);
                if (
                  searchParams?.filter.every((f) => f?.value?.length >= 2) ||
                  searchParams?.filter.every((f) => f?.value?.length === 0) ||
                  searchParams?.filter.some((f) => {
                    if (f.id === "workStatus") {
                      return true;
                    } else if (f.id === "gender") {
                      return true;
                    }
                    return false;
                  }) ||
                  firstUseEffect
                ) {
                  setTimer(
                    setTimeout(() => {
                      setLoadingTable(true);
                      fetchData(searchParams);
                    }, 1000),
                  );
                }
              }}
              className="-striped -highlight primary-pagination"
            />
          </CardBody>
        </Card>
        <AllUsersModals
          userData={userData}
          shiftsList={shiftsList}
          selectedHdId={selectedHdId}
          filter={filter}
          fetchData={fetchData}
          fetchShifts={fetchShifts}
          updateAdminUpdateTime={updateAdminUpdateTime}
          openCommonModal={openCommonModal}
          modalType={modalType}
          setModalType={setModalType}
          setOpenCommonModal={setOpenCommonModal}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
        {DriverAssignModal()}
        {workerCommentsModal()}
        {selectedAvailability && (
          <AvailabilityModal selectedAvailability={selectedAvailability} userData={userData} />
        )}
        <AvailabilityFilterModal availability={filter.availabilityFilter} />
        {dailyStatModal && (
          <DailyStatsModal
            handleCloseModal={() => {
              setDailyStatModal(false);
            }}
            openModal={dailyStatModal}
          />
        )}
      </div>
    </>
  );
};

export default AllUsers;
