/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
//import { Prompt } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import MaskedInput from "react-text-mask";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Spinner,
} from "reactstrap";

import AgencyContext from "./../../../context/AgencyContext";
import api from "../../../components/API/api";

class CreateNewQualification extends Component {
  static contextType = AgencyContext;
  constructor(props) {
    super(props);
    this.defaultState = {
      progress: 0,
      qualificationName: null,
      selectedTrainingList: [],
      //Errors
      errorqualificationName: "",
      errorSelectedTrainingList: "",

      //data
      trainingList: [],

      //Loading
      isLoading: false,
      isMandatory: false,
      errorIsMandatory: "",
    };
    this.state = this.defaultState;
  }

  componentDidMount() {
    this.searchPromise("");
  }

  //React-select-async accept promise and resolve it itself.
  searchPromise = (inputValue) => {
    return api()
      .get(`/training?query=${inputValue}`)
      .then((result) => {
        console.log(result);
        let tempResult = result.data.trainings.map((item) => {
          item.label = item.name;
          item.value = item._id;
          return item;
        });
        this.setState({ trainingList: tempResult }); //not compulsory
        //set this data so that while typing it doesn't fade away previous results immediately
        //instead it will preserve previous result until new data is loaded
        //check AsyncSelect down below how it is being used as initial data
        return tempResult;
      });
  };

  componentWillUnmount() {
    //window.removeEventListener("beforeunload", this.handleUserLeave);
  }

  handleUserLeave = (e) => {
    const confirmationMessage = "Data would be sent if you leave the page";
    (e || window.event).returnValue = confirmationMessage;
    return confirmationMessage;
  };

  handleSubmit = () => {
    let tempState = this.state; // Copy of state
    let hasError = false;
    // Start of Error Handling
    if (tempState.qualificationName == null || tempState.qualificationName == "") {
      this.setState({ errorqualificationName: "* Must have a qualification name." });
      hasError = true;
    } else {
      this.setState({ errorqualificationName: "" });
    }

    if (tempState.selectedTrainingList == null || tempState.selectedTrainingList == "") {
      this.setState({ errorSelectedTrainingList: "* Must have a training list " });
      hasError = true;
    } else {
      this.setState({ errorSelectedTrainingList: "" });
    }

    if (hasError) {
      return;
    } else {
      this.prepareAndSendDataToServer(tempState); // runs function after loading is set as true.
    }
  };

  prepareAndSendDataToServer = async (tempState) => {
    this.setState({ isLoading: true }); // Start the loading
    // to load the values again after creating manager without fectching again
    //Upload the changelog into the deeper user history collection! This uploads the admin email, timestamp, and action taken
    let dataToSend = null; // Data prepared to send.
    let ReloadTrainingList = this.state.trainingList;
    dataToSend = {
      name: tempState.qualificationName,
      requiredTraining: tempState.selectedTrainingList.map((item) => item.value),
      automatic: false,
      mandatory: this.state.isMandatory,
    };
    try {
      const resp = await api().post("/qualification", dataToSend);
      //to make all permission to default active state
      this.setState({
        ...this.defaultState,
        trainingList: ReloadTrainingList,
        isLoading: false,
        alert: (
          <SweetAlert
            success
            title="Qualification successfully created"
            onConfirm={this.hideAlert}
          />
        ),
      });
    } catch (error) {
      this.setState({ isLoading: false });
      console.log(error);
    }
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handleRadio = (event) => {
    const target = event.target;
    this.setState({
      [target.name]: target.value,
    });
  };

  loadingOverlay = () => {
    return (
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
          background: "transparent",
        }}
        title={<Spinner color="light" style={{ width: "3rem", height: "3rem" }} />}
        onConfirm={this.hideAlert}
        showConfirm={false}
      />
    );
  };

  agencyName = () => {
    const name = this.context.agency.agency.slug;
    return name.charAt(0).toUpperCase() + name.slice(1);
  };

  render() {
    return (
      <div className="content">
        {/* <Prompt //prevent page jumps, temporarily disabled for testing
          when={true}
          message={() => "All the data will lost if you leave"}
        />*/}
        {this.state.isLoading ? (
          this.loadingOverlay()
        ) : (
          <Row>
            <Col md="12">
              <Card className="no-transition">
                <CardHeader>
                  <CardTitle tag="h4">Create New Qualification for {this.agencyName()}</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form action="/" className="form-horizontal" method="get">
                    <Row>
                      <Label sm="2">* Qualification Name</Label>

                      <Col sm="5">
                        <FormGroup>
                          <Input
                            type="text"
                            autoComplete="off"
                            onChange={(e) => this.setState({ qualificationName: e.target.value })}
                          />
                          <FormText color="danger" tag="span">
                            {this.state.errorqualificationName}
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Label sm="2">* Qualification Type</Label>
                      <Col sm="5">
                        <FormGroup>
                          <Input
                            type="text"
                            autoComplete="off"
                            onChange={(e) => this.setState({rSelectedTrainingList: e.target.value })}
                          />
                          <FormText color="danger" tag="span">
                            {this.state.errorSelectedTrainingList}
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row> */}
                    <Row>
                      <Label sm="2">* Training List</Label>
                      <Col sm="5">
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          className="react-select primary"
                          classNamePrefix="react-select"
                          defaultOptions={this.state.trainingList.map((p) => ({
                            label: p.name,
                            value: p._id,
                          }))}
                          loadOptions={this.searchPromise}
                          onChange={(value) => this.setState({ selectedTrainingList: value })}
                          placeholder="Search by training name"
                        />
                        <FormText color="danger" tag="span">
                          {this.state.errorSelectedTrainingList}
                        </FormText>
                      </Col>
                    </Row>

                    <Row>
                      <Label sm="2">* Mandatory</Label>
                      <div className="d-flex  mt-1">
                        <Row
                          className="checkbox-radios"
                          sm="5"
                          style={{ marginLeft: "1.5%", marginTop: "0.8%" }}
                        >
                          <div className="form-check-radio" style={{ marginRight: 8 }}>
                            <Label check>
                              <Input
                                defaultValue={false}
                                id="N"
                                name="expiryRadio"
                                type="radio"
                                onChange={() => this.setState({ isMandatory: false })}
                                checked={this.state.isMandatory === false}
                              />
                              No <span className="form-check-sign" />
                            </Label>
                          </div>
                          <div className="form-check-radio">
                            <Label check>
                              <Input
                                defaultValue={true}
                                id="Y"
                                name="expiryRadio"
                                type="radio"
                                checked={this.state.isMandatory}
                                onChange={() => this.setState({ isMandatory: true })}
                              />
                              Yes <span className="form-check-sign" />
                            </Label>

                            <FormText color="danger" tag="span">
                              {this.state.errorIsMandatory}
                            </FormText>
                          </div>
                        </Row>

                        <Label>
                          (Users will have to complete all mandatory qualifications at the time of
                          account creation.)
                        </Label>
                      </div>
                    </Row>

                    <Row>
                      <Col sm="9"></Col>
                      <Col sm="3">
                        <Button color="success" onClick={() => this.handleSubmit()}>
                          Create Qualification
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}

        {this.state.alert}
      </div>
    );
  }
}

export default CreateNewQualification;
