/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Spinner,
} from "reactstrap";

import { validateEmail } from "components/Utilities/utilities.js";
import * as constants from "constants.js";
import { Modal } from "@mui/material";
import Axios from "axios";
import api from "../../../components/API/api";

class CreateNewBusiness extends Component {
  constructor(props) {
    super(props);
    this.state = {
      businessName: null,
      businessAddress: null,
      city: null,
      employees: null,
      contactName: null,
      phone: null,
      email: null,
      intersection: null,
      entryType: "automatic", // use google maps to fill the address details

      //Errors
      businessNamError: "",
      businessAddressError: "",
      cityError: "",
      employeesError: "",
      contactNameError: "",
      intersectionError: "",
      phoneError: "",
      emailError: "",

      // Loading
      isLoading: false,
      businessLocationError: "",
      longitude: "",
      latitude: "",
    };
  }

  // componentDidMount() {
  //   // Load data to local storage
  //   //popup warning if user reloads or closes the page
  //   window.addEventListener("beforeunload", this.handleUserLeave);
  // }
  // componentWillUnmount() {
  //   window.removeEventListener("beforeunload", this.handleUserLeave);
  // }

  // handleUserLeave = (e) => {
  //   const confirmationMessage = "Data would be sent if you leave the page";
  //   (e || window.event).returnValue = confirmationMessage;
  //   return confirmationMessage;
  // };

  handleSubmit = () => {
    this.setState({ isLoading: true });
    let tempState = this.state; // Copy of state
    let hasError = false;

    // Start of Error Handling
    if (this.state.businessName == null || this.state.businessName.trim() == "") {
      this.setState({ businessNameError: "* Must have a business name." });
      hasError = true;
    } else {
      this.setState({ businessNameError: "" });
    }

    // if (this.state.employees == null || this.state.employees == "") {
    //   this.setState({ employeesError: "* Must have a employee number." });
    //   hasError = true;
    // } else {
    //   this.setState({ employeesError: "" });
    // }

    // if (this.state.city == null || this.state.city.trim() == "") {
    //   this.setState({ cityError: "* Must have a business city." });
    //   hasError = true;
    // } else {
    //   this.setState({ cityError: "" });
    // }

    // if (this.state.businessAddress == null || this.state.businessAddress.trim() == "") {
    //   this.setState({ businessAddressError: "* Must have a business address." });
    //   hasError = true;
    // } else {
    //   this.setState({ businessAddressError: "" });
    // }

    // if (this.state.intersection == null || this.state.intersection == "") {
    //   this.setState({ intersectionError: "* Must have a nearest intersection." });
    //   hasError = true;
    // } else {
    //   this.setState({ intersectionError: "" });
    // }

    // if (this.state.contactName == null || this.state.contactName == "") {
    //   this.setState({ contactNameError: "* Must have a contact name." });
    //   hasError = true;
    // } else {
    //   this.setState({ contactNameError: "" });
    // }

    // if (this.state.phone == null || this.state.phone == "") {
    //   this.setState({ phoneError: "* Must have a phone number for the business contact." });
    //   hasError = true;
    // } else {
    //   this.setState({ phoneError: "" });
    // }

    // if (
    //   this.state.email == null ||
    //   this.state.email == "" ||
    //   validateEmail(this.state.email) == false
    // ) {
    //   this.setState({ emailError: "* Please have a valid email." });
    //   hasError = true;
    // } else {
    //   this.setState({ emailError: "" });
    // }

    if (hasError) {
      this.setState({ isLoading: false });
      return;
    } else {
      // let currentDate = new Date();
      // let dateString = JSON.stringify(currentDate);
      // let replacedDate = dateString.replace(/^\"|\.[0-9]{3}$/g, "").split("T");
      // let updateDate = replacedDate[0];
      // let replacedTime = replacedDate[1].split(".");
      // let time = replacedTime[0];
      // let updateDateTime = updateDate.concat(": ").concat(time);

      //Insert new business in the database
      let dataToSend = {
        name: tempState.businessName,
        address: tempState.businessAddress,
        city: tempState.city,
        employees: tempState.employees,
        contactName: tempState.contactName,
        phone: tempState.phone,
        email: tempState.email,
        intersection: tempState.intersection,
        latitude: this.state.latitude,
        longitude: this.state.longitude,
      };
      console.log(dataToSend);
      api()
        .post("/business", dataToSend)
        .then(() => {
          console.log("successfully saved Data");
          this.setState({
            isLoading: false,
            alert: (
              <SweetAlert
                success
                title="Business successfully created"
                onConfirm={this.hideAlert}
              />
            ),
          });
        })
        .catch((err) => {
          console.log("Error setting documents", err);
          this.setState({
            isLoading: false,
            alert: (
              <SweetAlert
                warning
                title={`Error creating business, ${err.message}`}
                onConfirm={this.hideAlert}
              />
            ),
          });
        });
    }
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  loadingOverlay = () => {
    return (
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
          background: "transparent",
        }}
        title={<Spinner color="light" style={{ width: "3rem", height: "3rem" }} />}
        onConfirm={this.hideAlert}
        showConfirm={false}
      />
    );
  };
// fetch the address using google geocoding API
  searchAddress = async () => {
    try {
      this.setState({ isLoading: true });
      const address = this.state.businessAddress.split(" ").join("+");
      Axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyCM3d16VZW2AZpLRd3KhW2IwCHiSWcnggg`,
      ).then((res) => {
        console.log("address", res);
        if (res?.data?.results?.length > 0 && res?.data?.results[0]?.formatted_address) {
          let tempLocation = {};
          res?.data?.results[0]?.address_components.map((c) => {
            if (c.types.includes("locality")) {
              tempLocation.city = c.long_name;
            }
          });
          // console.log(tempLocation);
          this.setState({
            businessAddress: res?.data?.results[0]?.formatted_address,
            latitude: res?.data?.results[0]?.geometry?.location?.lat,
            longitude: res?.data?.results[0]?.geometry?.location?.lng,
            city: tempLocation.city,
          });
        } else
          this.setState({
            isLoading: false,
            alert: (
              <SweetAlert
                warning
                title={`Address not found, please try again`}
                onConfirm={this.hideAlert}
              />
            ),
          });
      });
      this.setState({ isLoading: false });
    } catch (err) {
      this.setState({
        isLoading: false,
        alert: (
          <SweetAlert
            warning
            title={`Address not found, ${err.message}`}
            onConfirm={this.hideAlert}
          />
        ),
      });
    }
  };

  render() {
    return (
      <div className="content">
        {this.state.isLoading ? (
          this.loadingOverlay()
        ) : (
          <Row>
            <Col md="12">
              <Card className="no-transition">
                <CardHeader>
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Col md="10">
                      <CardTitle tag="h4">Create New Business</CardTitle>
                    </Col>
                    <Col md="2">
                      <div>
                        <Label>
                          <Input
                            type="checkbox"
                            style={{ marginTop: 2 }}
                            onChange={(e) => {
                              this.setState({
                                entryType:
                                  this.state.entryType === "automatic" ? "manual" : "automatic",
                              });
                            }}
                          ></Input>
                          <h6>Manual Entry</h6>
                        </Label>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form action="/" className="form-horizontal" method="get">
                    <Row>
                      <Label sm="2">* Business Name</Label>
                      <Col sm="5">
                        <FormGroup>
                          <Input
                            defaultValue={this.state && this.state.businessName}
                            type="text"
                            autoComplete="off"
                            onChange={(e) =>
                              this.setState({ businessName: e.target.value, businessNameError: "" })
                            }
                          />
                          <FormText color="danger" tag="span">
                            {this.state.businessNameError}
                          </FormText>
                        </FormGroup>
                      </Col>
                      <Label sm="2">Contact Name</Label>
                      <Col sm="3">
                        <FormGroup>
                          <Input
                            type="text"
                            autoComplete="off"
                            defaultValue={this.state && this.state.contactName}
                            onChange={(e) =>
                              this.setState({ contactName: e.target.value, contactNameError: "" })
                            }
                          />
                          <FormText color="danger" tag="span">
                            {this.state.contactNameError}
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Business City</Label>
                      <Col sm="5">
                        <FormGroup>
                          <Input
                            type="text"
                            autoComplete="off"
                            disabled={this.state.entryType === "automatic"}
                            value={this.state.city}
                            onChange={(e) => this.setState({ city: e.target.value, cityError: "" })}
                          />
                          <FormText color="danger" tag="span">
                            {this.state.cityError}
                          </FormText>
                        </FormGroup>
                      </Col>
                      <Label sm="2">Number of Employees</Label>
                      <Col sm="3">
                        <FormGroup>
                          <Input
                            defaultValue={this.state && this.state.noEmployee}
                            type="number"
                            autoComplete="off"
                            onChange={(e) =>
                              this.setState({ employees: e.target.value, employeesError: "" })
                            }
                          />
                          <FormText color="danger" tag="span">
                            {this.state.employeesError}
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row style={{ display: "flex", alignItems: "center" }}>
                      <Label sm="2">Business Address</Label>
                      <Col sm="5">
                        <FormGroup>
                          <Input
                            type="text"
                            defaultValue={this.state && this.state.businessAddress}
                            autoComplete="off"
                            value={this.state.businessAddress}
                            onChange={(e) =>
                              this.setState({
                                businessAddress: e.target.value,
                                businessAddressError: "",
                              })
                            }
                            // onBlur={(e) => {
                            //   if (e.target.value.length > 5) this.searchAddress();
                            // }}
                          />
                          <FormText color="danger" tag="span">
                            {this.state.businessAddressError}
                          </FormText>
                        </FormGroup>
                      </Col>

                      {this.state.entryType === "automatic" && (
                        <Col sm="3">
                          <FormGroup>
                            <Button color="success"  size="sm" onClick={() => this.searchAddress()}>
                              Look up
                            </Button>
                          </FormGroup>
                        </Col>
                      )}
                    </Row>
                    <Row style={{ marginTop: "-20px", marginBottom: "20px" }}>
                      <Label sm="2"></Label>
                      <Col sm="10">
                        <FormText tag="span">
                          {
                            "Please enter address like 35 Woodbine and hit lookup to fill the fields"
                          }
                        </FormText>
                      </Col>
                    </Row>

                    <Row>
                      <Label sm="2">Contact Phone Number</Label>
                      <Col sm="5">
                        <FormGroup>
                          <Input
                            type="number"
                            autoComplete="off"
                            onChange={(e) =>
                              this.setState({ phone: e.target.value, phoneError: "" })
                            }
                          />
                          <FormText color="danger" tag="span">
                            {this.state.phoneError}
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Contact Email</Label>
                      <Col sm="5">
                        <FormGroup>
                          <Input
                            type="email"
                            autoComplete="off"
                            onChange={(e) =>
                              this.setState({ email: e.target.value, emailError: "" })
                            }
                          />
                          <FormText color="danger" tag="span">
                            {this.state.emailError}
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Label sm="2">Nearest Intersection</Label>
                      <Col sm="5">
                        <FormGroup>
                          <Input
                            type="text"
                            autoComplete="off"
                            onChange={(e) =>
                              this.setState({ intersection: e.target.value, intersectionError: "" })
                            }
                          />
                          <FormText color="danger" tag="span">
                            {this.state.intersectionError}
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Label sm="2">Latitude</Label>
                      <Col sm="5">
                        <FormGroup>
                          <Input
                            value={this.state.latitude}
                            disabled={this.state.entryType === "automatic"}
                            onChange={(e) =>
                              this.setState({
                                latitude: e.target.value,
                                businessLocationError: "",
                              })
                            }
                            placeholder="Latitude"
                            type="number"
                          />
                          <FormText color="danger" tag="span">
                            {this.state.emailError}
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Longitude</Label>
                      <Col sm="5">
                        <FormGroup>
                          <Input
                            value={this.state.longitude}
                            disabled={this.state.entryType === "automatic"}
                            onChange={(e) =>
                              this.setState({
                                longitude: e.target.value,
                                businessLocationError: "",
                              })
                            }
                            placeholder="Longitude"
                            type="text"
                          />
                          <FormText color="danger" tag="span">
                            {this.state.businessLocationError}
                          </FormText>
                        </FormGroup>
                      </Col>
                      {/* <Col className="pl-1" md="12">
                          <FormGroup>
                            <label>Business Location</label>
                            <Map
                              latitude={this.state.latitude}
                              longitude={this.state.longitude}
                              onChange={(latlng) => {
                                console.log(latlng);
                                this.setState({
                                  latitude: latlng.lat,
                                  longitude: latlng.lng,
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                       */}
                    </Row>

                    <Row>
                      <Col sm="9"></Col>
                      <Col sm="3">
                        <Button color="success" onClick={() => this.handleSubmit()}>
                          Create Business
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
        {this.state.alert}
      </div>
    );
  }
}

export default CreateNewBusiness;
