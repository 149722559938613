/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import Select from "react-select";
//import { Prompt } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import MaskedInput from "react-text-mask";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Spinner,
} from "reactstrap";

import { validateEmail, getCurrentDate } from "components/Utilities/utilities.js";
import api from "../../../components/API/api";
import LoadingOverlay from "../../../components/OverLay/LoadingOverlay";

class CreateNewAdmin extends Component {
  constructor(props) {
    super(props);
    this.defaultState = {
      progress: 0,
      firstName: null,
      lastName: null,
      email: null,
      phone: null,

      //Errors
      errorFirstName: "",
      errorLastName: "",
      errorPhone: "",
      errorEmail: "",

      //Loading
      isLoading: false,
    };
    this.state = this.defaultState;
  }

  componentDidMount() {
  
  }

  
  componentWillUnmount() {
    //window.removeEventListener("beforeunload", this.handleUserLeave);
  }

  handleUserLeave = (e) => {
    const confirmationMessage = "Data would be sent if you leave the page";
    (e || window.event).returnValue = confirmationMessage;
    return confirmationMessage;
  };

  handleSubmit = () => {
    let tempState = this.state; // Copy of state
    let hasError = false;
    // Start of Error Handling
    if (tempState.firstName == null || tempState.firstName == "") {
      this.setState({ errorFirstName: "* Must have a first name." });
      hasError = true;
    } else {
      this.setState({ errorFirstName: "" });
    }

    if (tempState.lastName == null || tempState.lastName == "") {
      this.setState({ errorLastName: "* Must have a last name." });
      hasError = true;
    } else {
      this.setState({ errorLastName: "" });
    }

    if (tempState.phone == null || tempState.phone == "") {
      this.setState({ errorPhone: "* Must have a phone number." }); // No phone number
      hasError = true;
    } else {
      if (tempState.phone.length != 10 || tempState.phone < 0) {
        this.setState({
          errorPhone: "* Please input a valid phone number in the format ###-###-####.",
        }); //invalid phone number
        hasError = true;
      } else {
        this.setState({ errorPhone: "" });
      }
    }

    if (tempState.email == null || tempState.email == "") {
      this.setState({ errorEmail: "* Must have an email" }); // No email text
    } else {
      if (tempState.email != null && validateEmail(tempState.email) === false) {
        this.setState({ errorEmail: "* Please input a valid email" }); //invalid email text
        hasError = true;
      } else {
        this.setState({ errorEmail: "" });
      }
    }

    if (hasError) {
      return;
    } else {
      this.prepareAndSendDataToServer(tempState); // runs function after loading is set as true.
    }
  };

  prepareAndSendDataToServer = async (tempState) => {
    this.setState({ isLoading: true }); // Start the loading
    // to load the values again after creating manager without fectching again
    let ReloadPermissionsList = this.state.permissionsList;

    const dataToSend = {
      firstName: tempState.firstName,
      lastName: tempState.lastName,
      phone: tempState.phone,
      email: tempState.email,
      profileType: "Admin",
    };

    try {
      const resp = await api().post("/users/register-admin", dataToSend);
      //to make all permission to default active state
      this.setState({
        ...this.defaultState,
        isLoading: false,
        alert: <SweetAlert success title="Admin successfully created" onConfirm={this.hideAlert} />,
      });
    } catch (error) {
      this.setState({ isLoading: false }); // Stop the loading
      console.log(error);
    }
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handleRadio = (event) => {
    const target = event.target;
    this.setState({
      [target.name]: target.value,
    });
  };

  _renderPermission = (p) => {
    return (
      <Row>
        <Col sm="3">
          <FormGroup check inline>
            <Label check>
              <Input
                type="checkbox"
                checked={p.active}
                onChange={() => this.toggleCheckMark(p.name)}
              />
              <span className="form-check-sign" />
              {p.displayName}
            </Label>
          </FormGroup>
        </Col>

        {
          /* eslint-disable */
          p.active
            ? p.actions[0].map((a) =>
              // to delete none from actions list
              a.action === "None" ? null : (
                <Col sm="2">
                  <FormGroup check inline>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={a.active}
                        onChange={() => this.toggleActions(a.action, p.name)}
                      />
                      <span className="form-check-sign" />
                      {a.action}
                    </Label>
                  </FormGroup>
                </Col>
              ),
            )
            : null
        }
      </Row>
    );
  };

  toggleCheckMark = (name) => {
    let tempPermission = this.state.permissionsList;
    tempPermission.map((p) => {
      p.name === name ? (p.active = !p.active) : null;
    });
    this.setState({ selectedPermission: tempPermission });
  };

  toggleActions = (a, name) => {
    let selected = null;
    let tempPermission = this.state.selectedPermission;
    tempPermission.map((p) => {
      if (p.name === name) {
        p.actions[0].map((act) => {
          act.action === a ? (act.active = !act.active) : null;
          this.checkAllActions(p.actions[0], p);
        });
      }
    });
    this.setState({ selectedPermission: tempPermission });
  };

  // to check if all actions are unchecked or not
  checkAllActions = (actions, p) => {
    let selected = null;
    actions.map((act) => (act.active ? (selected = true) : null));
    if (selected) {
      console.log(selected);
    } else {
      // if they unselect all actions, uncheck the module and set all actions active: true(default state) in module
      p.active = false;
      actions.map((act) => (act.active = true));
    }
  };

  render() {
    return (
      <div className="content">
        {/* <Prompt //prevent page jumps, temporarily disabled for testing
          when={true}
          message={() => "All the data will lost if you leave"}
        />*/}
        {this.state.isLoading ? (
          <LoadingOverlay/>
        ) : (
            <Row>
              <Col md="12">
                <Card className="no-transition">
                  <CardHeader>
                    <CardTitle tag="h4">Create New Admin</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form action="/" className="form-horizontal" method="get">
                      <Row>
                        <Label sm="2">* First Name</Label>

                        <Col sm="5">
                          <FormGroup>
                            <Input
                              type="text"
                              autoComplete="off"
                              onChange={(e) => this.setState({ firstName: e.target.value })}
                            />
                            <FormText color="danger" tag="span">
                              {this.state.errorFirstName}
                            </FormText>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2">* Last Name</Label>
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              type="text"
                              autoComplete="off"
                              onChange={(e) => this.setState({ lastName: e.target.value })}
                            />
                            <FormText color="danger" tag="span">
                              {this.state.errorLastName}
                            </FormText>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Label sm="2">* Phone Number</Label>
                        <Col sm="5">
                          <FormGroup>
                            <MaskedInput
                              mask={[
                                "(",
                                /[1-9]/,
                                /\d/,
                                /\d/,
                                ")",
                                "-",
                                /\d/,
                                /\d/,
                                /\d/,
                                "-",
                                /\d/,
                                /\d/,
                                /\d/,
                                /\d/,
                              ]}
                              className="form-control"
                              autoComplete="off"
                              maxLength="15"
                              onChange={(e) => {
                                //Allows the user to type in any format. Only numbers will be added to the state
                                let input = e.target.value;
                                let replacedPhone = input.replace(/[^0-9]/g, "");
                                this.setState({ phone: replacedPhone });
                              }}
                            />
                            <FormText color="danger" tag="span">
                              {this.state.errorPhone}
                            </FormText>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2">* Email</Label>
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              type="email"
                              autoComplete="off"
                              onChange={(e) => this.setState({ email: e.target.value })}
                            />
                            <FormText color="danger" tag="span">
                              {this.state.errorEmail}
                            </FormText>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="9"></Col>
                        <Col sm="3">
                          <Button color="success" onClick={() => this.handleSubmit()}>
                            Create Admin
                        </Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

        {this.state.alert}
      </div>
    );
  }
}

export default CreateNewAdmin;
