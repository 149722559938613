import React, { useReducer } from "react";
import { Route, Switch } from "react-router-dom";

import LoginPage from "views/Staffing/auth/LoginPage.jsx";
import ForgotPage from "views/Staffing/auth/ForgotPage.jsx";

import StaffingContext from "../../context/StaffingContext";
import staffingReducer from "../../reducers/staffingReducer";

import Settings from "views/Staffing/pages/Settings.js";
import Error404 from "views/Staffing/pages/Error404.jsx";
import RegisterLayout from "./RegisterLayout.jsx";
import Dashboard from "views/Staffing/dashboard/Dashboard.jsx";
import AddBusiness from "views/Staffing/business-detail/AddBusiness.jsx";
import BusinessDetail from "views/Staffing/business-detail/businessDetail.jsx";
import SelectAgencyPage from "views/Staffing/auth/SelectAgencyPage.jsx";

import AgencyAdmin from "./AgencyAdmin";

// const initialState = () => {
//   const state = localStorage.getItem("staffingUser");
//   if (state) {
//     return { user: JSON.parse(state) };
//   }
//   return { user: null };
// };

function Admin(props) {
  return (
    // <StaffingContext.Provider value={{ staffing, staffingDispatch }}>
    <Switch>
      <Route path="/staffing" exact render={(props) => <StaffingLandingPage {...props} />} />
      <Route path="/staffing/login" render={(props) => <LoginPage {...props} />} />
      <Route path="/staffing/select-agency" render={(props) => <SelectAgencyPage {...props} />} />
      <Route path="/staffing/forgot" render={(props) => <ForgotPage {...props} />} />
      <Route path="/staffing/register" render={(props) => <RegisterLayout {...props} />} />
      <Route path="/staffing/error" render={(props) => <Error404 {...props} />} />

      <Route path="/staffing/admin" render={(props) => <AgencyAdmin {...props} />} />
      <Route path="/staffing/agency/settings" render={(props) => <Settings {...props} />} />
      <Route path="/staffing/dashboard" render={(props) => <Dashboard {...props} />} />
      <Route path="/staffing/add-business" render={(props) => <AddBusiness {...props} />} />
      {/* <Route path="/staffing/progress-bar" render={(props) => <ProgressBar {...props} />} /> */}

      <Route path="/staffing/:businessId" render={(props) => <BusinessDetail {...props} />} />

      <Route render={(props) => <Error404 {...props} />} />
    </Switch>
    // </StaffingContext.Provider>
  );
}

export default Admin;
