import React, { useState, useEffect } from "react";
import {
  ExpandMore,
  AddCircleOutline,
  MoreVert,
  ChevronLeft,
  ChevronRight,
  Today,
} from "@mui/icons-material";
import ReactDatetime from "react-datetime";
import moment from "moment";
import PropTypes from "prop-types";

export default function CustomCalendar({
  style,
  initialDate,
  onChangeDate,
  disableFutureDate,
  myDate,
}) {
  //
  const [dateFormat, setDateFormat] = useState(null);
 


  // disable future dates
  const disableFutureDates = (current) => {
    let today = moment();
    return current.isBefore(today);
  };

  // add month to date
  const addMonthToDate = (props) => {
    let tempDate = new Date(props.date);
    let currentMonth = tempDate.getMonth();
    let currentYear = tempDate.getFullYear();
    if (props.case === "next") {
      let month = currentMonth + 1;
      return new Date(currentYear, month,1,1,1);
    } else {
      let month = currentMonth - 1;
      return new Date(currentYear, month,1,1,1);
    }
  };

  return (
    <div className="d-flex justify-content-between" style={{ ...style }}>
      <div
        style={{
          marginTop: "0.5rem",
          cursor: "pointer"
        }}
        onClick={() => {
            console.log(addMonthToDate({date: myDate, case: "previous"}))
            onChangeDate(addMonthToDate({date: myDate, case: "previous"}));
        }}
      >
        <ChevronLeft />
        {"Previous Month"}
      </div>
      <div style={{ cursor: "pointer" }}>
        <ReactDatetime
          isValidDate={disableFutureDate ? disableFutureDates : null}
          inputProps={{
            placeholder: "Date Picker Here",
          }}
          closeOnSelect={true}
          viewMode={"months"}
          dateFormat={dateFormat ? dateFormat : true}
          timeFormat={false}
          value={myDate}
          onChange={date=>onChangeDate(date)}
        />
        {/* <Today /> */}
      </div>
      <div
        style={{
          cursor: "pointer",
          marginTop: "0.5rem",
        }}
        onClick={() => {
            onChangeDate(addMonthToDate({ date: myDate, case: "next" }));
        }}
      >
        {"Next Month"}
        <ChevronRight />
      </div>
    </div>
  );
}

CustomCalendar.propTypes = {
  style: PropTypes.object,
  initialDate: PropTypes.object,
  onChangeDate: PropTypes.func,
  disableFutureDate: PropTypes.bool,
  myDate: PropTypes.object, //for controlled input
  pageTabs: PropTypes.string,
};
