import { Check, MarkChatRead } from "@mui/icons-material";
import api from "components/API/api";
import LoadingOverlay from "components/OverLay/LoadingOverlay";
import { useSocket } from "context/SocketContext";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import AgencyContext from "../../../context/AgencyContext";

const LIMIT = 15;

const TABS = [
  { name: "All", id: "all" },
  // { name: "Un Answered", id: "unAnswered" },
  // { name: "Answered", id: "answered" },
  { name: "Blocked", id: "blocked" },
];

const AllMessages = () => {
  const agency = useContext(AgencyContext);
  const [isLoading, setIsLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const { socket, resetNewMessage, newMessage, setUnreadMessage } = useSocket();
  const [page, setPage] = useState(1);
  const [pageTabs, setPageTabs] = useState(TABS[0].id);

  useEffect(() => {
    setIsLoading(true);
    connectSocket(socket);
  }, [newMessage, page, pageTabs]);

  useEffect(() => {
    resetNewMessage();
    checkForUnreadMessages();
  }, []);

  const checkForUnreadMessages = () => {
    api()
      .get("/message/some-unread")
      .then(({ data }) => {
        // console.log('data', data)
        setUnreadMessage(data.unread);
      })
      .catch((e) => {
        console.log(e.response?.data?.message || e.message);
      });
  };

  const connectSocket = (socket) => {
    if (socket) {
      const skip = (page - 1) * LIMIT;
      if (skip < 0) {
        setIsLoading(false);
        return;
      }
      socket.emit(
        "joinAllMessages",
        {
          limit: LIMIT,
          skip,
          blocked: pageTabs === "blocked" ? true : false,
          answered: pageTabs === "answered" ? true : false,
          unanswered: pageTabs === "unAnswered" ? true : false,
        },
        (list) => {
          setIsLoading(false);
          // console.log("messages", list);
          setMessages(list);
        },
      );
    }
  };

  const handleUpdateMessage = async (messageId) => {
    try {
      await api().patch(`/message/${messageId}`, {
        updates: {
          unread: false,
        },
      });
      connectSocket(socket);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {isLoading && <LoadingOverlay />}

      <div
        className="content"
        onClick={() => {
          resetNewMessage();
        }}
      >
        <Card className="no-transition">
          <CardHeader>
            <CardTitle tag="h4">All Messages</CardTitle>
          </CardHeader>
          <CardBody>
            <Nav
              className="nav-pills-primary nav-pills-icons justify-content-center"
              pills
              role="tablist"
            >
              {TABS.map((tab) => (
                <NavItem>
                  <NavLink
                    data-toggle="tab"
                    role="tablist"
                    className={pageTabs === tab.id ? "active" : ""}
                    onClick={() => setPageTabs(tab.id)}
                  >
                    <i className="now-ui-icons objects_umbrella-13" />
                    {tab.name}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <TabContent className="tab-space tab-subcategories" activeTab={pageTabs}>
              <TabPane tabId="profile"></TabPane>
            </TabContent>
            <ListGroup>
              {messages.map((message) => {
                return (
                  <ListGroupItem key={message._id} className="justify-content-between">
                    <Row>
                      <Col>
                        <p>
                          <b>{message.workerName}</b> {moment(message.createdAt).fromNow()}
                          {message.unread && message.worker === message.senderId && (
                            <Badge style={{ marginLeft: "4px", backgroundColor: "#51CBCE" }}>
                              New
                            </Badge>
                          )}
                          {message.worker !== message.senderId ? (
                            !message.unread ? (
                              <>
                                <Badge style={{ marginLeft: "8px" }} color={"primary"}>
                                  Answered
                                </Badge>
                                <Button color={"info"} size="md" className="btn-link btn-icon">
                                  <Check style={{ marginLeft: -1 }} />
                                </Button>
                              </>
                            ) : (
                              <Badge style={{ marginLeft: "8px" }} color={"primary"}>
                                Answered
                              </Badge>
                            )
                          ) : null}
                          <br />
                          {message.message}
                        </p>
                      </Col>
                      <Col style={{ textAlign: "right" }} xs="auto">
                        {message.unread && message.worker === message.senderId && (
                          <Button
                            style={{ marginRight: 30 }}
                            color={"info"}
                            size="md"
                            className="btn-link btn-icon"
                            onClick={() => handleUpdateMessage(message._id)}
                          >
                            <MarkChatRead style={{ marginLeft: -1 }} />
                          </Button>
                        )}
                        <Button
                          tag={Link}
                          color="primary"
                          to={`/${agency.agency.agency.slug}/admin/user-profile/${message.worker}?tab=chat`}
                        >
                          Open
                        </Button>
                      </Col>
                    </Row>
                  </ListGroupItem>
                );
              })}
            </ListGroup>
          </CardBody>
        </Card>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            disabled={page <= 1}
            onClick={() => {
              setPage((page) => page - 1);
            }}
          >
            Previous
          </Button>
          <div
            style={{
              color: "#eee",
              backgroundColor: "#333",
              borderRadius: "50%",
              width: "40px",
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "10px 20px",
            }}
          >
            {page}
          </div>
          <Button
            onClick={() => {
              setPage((page) => page + 1);
            }}
            disabled={messages.length < LIMIT}
          >
            Next
          </Button>
        </div>
      </div>
    </>
  );
};
export default AllMessages;
