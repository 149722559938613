import { Modal } from "@mui/material";
import { Delete } from "@mui/icons-material";
import api from "components/API/api";
import { useHasPermissions } from "hooks/usePermissions";
import React, { useContext, useRef, useState } from "react";
import Select from "react-select";
import { Button, Col, FormGroup, Input, Label, Spinner } from "reactstrap";
import AgencyContext from "context/AgencyContext";

const PinComponent = ({
  workerPin,
  workerId,
  onSuccess,
  onDelete,
  manual,
  onChange,
  showDelete,
  businessList,
  agency,
}) => {
  const agencyContext = useContext(AgencyContext);
  const [loading, setLoading] = useState(false);
  const [business, setBusiness] = useState(null);
  const [value, setValue] = useState(workerPin && workerPin.pin);
  const pinRef = useRef(workerPin && workerPin.pin);
  const [openModal, setOpenModal] = useState({
    confirmDeleteBusinessModal: false,
  });
  const [canDeleteWorkerExtended] = useState(
    useHasPermissions({ action: "Delete", module: "WorkerExtended" }),
  );

  //check logged in user permissions
  // const userRole = agencyContext.agency.profile.role;

  // Opens modal to delete business from worker profile
  const handleOpenDeleteBusinessModal = () => {
    setOpenModal({ confirmDeleteBusinessModal: true });
  };

  const handleCloseDeleteBusinessModal = () => {
    setOpenModal({});
  };

  // Delete businness Modal from worker profile
  const deleteBusinessModal = () => {
    let businessName = workerPin ? workerPin.businessName : null;
    return (
      <Modal
        open={openModal.confirmDeleteBusinessModal}
        onClose={handleCloseDeleteBusinessModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: 400, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>
                Deleting pin will delete the worker from {businessName}. Are you sure want to delete
                this worker's pin?
              </h5>
            </div>
            <div style={{ justifyContent: "space-between", display: "flex" }}></div>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button onClick={() => handleCloseDeleteBusinessModal()}>Cancel</Button>
              <Button color="danger" onClick={() => removeBusinessFromWorkerProfile()}>
                Remove
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  // remove business from worker profile logic
  const removeBusinessFromWorkerProfile = async () => {
    try {
      await api().delete(`users/pin?userId=${workerId}&pinId=${workerPin._id}`);
      handleCloseDeleteBusinessModal();
      onDelete(true, workerPin._id);
    } catch (err) {
      console.log(err);
      handleCloseDeleteBusinessModal();
      onDelete(false, workerPin._id);
      console.log("Error while deleting business");
    }
  };

  return (
    <FormGroup row className="align-items-right">
      {manual ? (
        <Col sm={5}>
          <Select
            className="react-select primary"
            classNamePrefix="react-select"
            name="singleSelect"
            value={business}
            onChange={(value) => setBusiness(value)}
            id="dayOfWeek"
            options={businessList.map((b) => ({ value: b._id, label: b.name }))}
            placeholder="Select a Business"
          />
        </Col>
      ) : (
        <Label for="Search" sm={5}>
          {workerPin.businessName}
        </Label>
      )}{" "}
      <Col sm={3}>
        <Input
          type="text"
          name="pin"
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
      </Col>
      <Col sm={showDelete ? 3 : 4}>
        <Button
          onClick={() => {
            setLoading(true);

            if (manual) {
              const data = {
                user: workerId,
                agency: agency,
                pin: value,
                business: business && business.value,
              };
              api()
                .post("/users/pin", data)
                .then(({ data }) => {
                  setLoading(false);
                  // setOpenPinModal(false);
                  // onChange();
                  onSuccess(true, { pin: value, businessName: business.label });
                  setValue("");
                  setBusiness(null);
                })
                .catch(() => {
                  setLoading(false);
                  // setOpenPinModal(false);
                  onSuccess(false, value);
                  setValue("");
                  setBusiness(null);
                });
            } else {
              const data = {
                workerId: workerId,
                pinId: workerPin._id,
                pin: value,
              };
              api()
                .patch("/users/pin", data)
                .then((res) => {
                  setLoading(false);
                  // setOpenPinModal(false);
                  //onChange();
                  setValue(value);
                  onSuccess(true, value);
                })
                .catch(() => {
                  setLoading(false);
                  // setOpenPinModal(false);
                  onSuccess(false, value);
                  setValue(pinRef.current);
                });
            }
          }}
          color="success"
          size="md"
          outline
          className="btn m-0 w-100"
        >
          {manual ? "Add" : "Update"} {loading ? <Spinner size="sm" /> : null}
        </Button>
      </Col>{" "}
      {canDeleteWorkerExtended && showDelete && (
        <Col sm={1} style={{ marginTop: "-10px" }}>
          <Button
            color="danger"
            size="md"
            className="btn-link remove btn-icon"
            onClick={() => handleOpenDeleteBusinessModal()}
          >
            <Delete style={{ marginLeft: -1 }} />
          </Button>
        </Col>
      )}
      {deleteBusinessModal()}
    </FormGroup>
  );
};

export default PinComponent;
