export const PermissionActionEnum = {
  View: "View",
  Add: "Add",
  Edit: "Edit",
  Create: "Create",
  Delete: "Delete",
  Warning: "Warning",
  Suspend: "Suspend",
  Deactivate: "Deactivate",
  Lock: "Lock",
  ChangeRequired: "ChangeRequired",
  Rate: "Rate",
  Export: "Export",
  Chat: "Chat",
  PasswordReset: "PasswordReset",
  EmployeeStats: "EmployeeStats",
  ManagerPassword: "ManagerPassword",
  Sms: "Sms",
  Email: "Email",
  App: "App Notification",
  SensitiveInfo: "Sensitive Info",
  ShiftVisibility: "Shift Visibility",
  SettingsView: "Settings View",
  SettingsEdit: "Settings Edit",
};

export const PermissionModuleEnum = {
  LiveBusiness: "LiveBusiness",
  AdminLiveBusiness: "AdminLiveBusiness",
  SelectForWork: "SelectForWork",
  Appointments: "Appointments",
  Shifts: "Shifts",
  Announcement: "Announcement",
  Business: "Business",
  BusinessSales: "BusinessSales",
  BusinessStaff: "BusinessStaff",
  Cheque: "Cheque",
  Log: "Log",
  Notification: "Notification",
  Training: "Training",
  Qualification: "Qualification",
  Users: "Users",
  Messages: "Messages",
  WorkerExtended: "WorkerExtended",
  Discipline: "Discipline",
  AdvancedWorkerSearch: "AdvancedWorkerSearch",
  Staff: "Staff",
  Driver: "Driver",
  Analytics: "Analytics",
  Payroll: "Payroll",
  SignIn: "SignIn",
};

export const callCentreStaffPermissionTemplate = [
  {
    permission: PermissionModuleEnum.LiveBusiness,
    displayName: "Live Business",
    categories: [
      { type: PermissionActionEnum.View, active: true },
      { type: PermissionActionEnum.Edit, active: true },
      { type: PermissionActionEnum.ChangeRequired, active: true },
      { type: PermissionActionEnum.Export, active: true },
    ],
    active: true,
  },
  {
    permission: PermissionModuleEnum.SelectForWork,
    displayName: "Select For Work",
    categories: [
      { type: PermissionActionEnum.View, active: true },
      { type: PermissionActionEnum.Add, active: true },
      { type: PermissionActionEnum.Delete, active: true },
    ],
    active: true,
  },
  {
    permission: PermissionModuleEnum.Shifts,
    displayName: "Shifts",
    categories: [
      { type: PermissionActionEnum.View, active: true },
      { type: PermissionActionEnum.Edit, active: true },
    ],
    active: true,
  },

  {
    permission: PermissionModuleEnum.Users,
    displayName: "Workers",
    categories: [
      { type: PermissionActionEnum.View, active: true },
      { type: PermissionActionEnum.Edit, active: true },
      { type: PermissionActionEnum.Create, active: true },
      { type: PermissionActionEnum.Delete, active: true },
      { type: PermissionActionEnum.SensitiveInfo, active: true },
    ],
    active: true,
  },
  {
    permission: PermissionModuleEnum.Driver,
    displayName: "Manage Driver",
    categories: [
      { type: PermissionActionEnum.View, active: true },
      { type: PermissionActionEnum.Edit, active: true },
    ],
    active: true,
  },
  {
    permission: PermissionModuleEnum.Discipline,
    displayName: "Discipline",
    categories: [
      { type: PermissionActionEnum.Warning, active: true },
      { type: PermissionActionEnum.Suspend, active: true },
      { type: PermissionActionEnum.Deactivate, active: true },
      { type: PermissionActionEnum.View, active: true },
    ],
    active: true,
  },
  {
    permission: PermissionModuleEnum.Messages,
    displayName: "Worker Chat",
    categories: [
      { type: PermissionActionEnum.View, active: true },
      { type: PermissionActionEnum.Chat, active: true },
    ],
    active: true,
  },
  {
    permission: PermissionModuleEnum.Notification,
    displayName: "Notification",
    categories: [
      { type: PermissionActionEnum.View, active: true },
      { type: PermissionActionEnum.Edit, active: false },
      { type: PermissionActionEnum.Create, active: false },
      { type: PermissionActionEnum.Sms, active: false },
      { type: PermissionActionEnum.Email, active: false },
      { type: PermissionActionEnum.App, active: false },
    ],
    active: true,
  },
];

const permissionTemplate = [
  {
    permission: PermissionModuleEnum.LiveBusiness,
    displayName: "Live Business",
    categories: [
      { type: PermissionActionEnum.View, active: false },
      { type: PermissionActionEnum.Edit, active: false },
      { type: PermissionActionEnum.Lock, active: false },
      { type: PermissionActionEnum.ChangeRequired, active: false },
      { type: PermissionActionEnum.Export, active: false },
      { type: PermissionActionEnum.ShiftVisibility, active: false },
    ],
    active: false,
  },
  {
    permission: PermissionModuleEnum.AdminLiveBusiness,
    displayName: "Admin Live Business",
    categories: [
      { type: PermissionActionEnum.View, active: false },
      { type: PermissionActionEnum.Edit, active: false },
    ],
    active: false,
  },
  {
    permission: PermissionModuleEnum.SelectForWork,
    displayName: "Select For Work",
    categories: [
      { type: PermissionActionEnum.View, active: false },
      { type: PermissionActionEnum.Add, active: false },
      { type: PermissionActionEnum.Delete, active: false },
    ],
    active: false,
  },
  {
    permission: PermissionModuleEnum.Appointments,
    displayName: "Appointments",
    categories: [{ type: PermissionActionEnum.View, active: false }],
    active: false,
  },
  {
    permission: PermissionModuleEnum.Shifts,
    displayName: "Shifts",
    categories: [
      { type: PermissionActionEnum.View, active: false },
      { type: PermissionActionEnum.Edit, active: false },
      { type: PermissionActionEnum.Create, active: false },
      { type: PermissionActionEnum.Delete, active: false },
    ],
    active: false,
  },
  {
    permission: PermissionModuleEnum.Announcement,
    displayName: "Announcement",
    categories: [{ type: PermissionActionEnum.Edit, active: false }],
    active: false,
  },
  {
    permission: PermissionModuleEnum.Business,
    displayName: "Business",
    categories: [
      { type: PermissionActionEnum.View, active: false },
      { type: PermissionActionEnum.Edit, active: false },
      { type: PermissionActionEnum.Create, active: false },
      { type: PermissionActionEnum.Delete, active: false },
    ],
    active: false,
  },
  {
    permission: PermissionModuleEnum.BusinessSales,
    displayName: "Business Sales",
    categories: [
      { type: PermissionActionEnum.View, active: false },
      { type: PermissionActionEnum.Edit, active: false },
      { type: PermissionActionEnum.Create, active: false },
      { type: PermissionActionEnum.Delete, active: false },
    ],
    active: false,
  },
  {
    permission: PermissionModuleEnum.BusinessStaff,
    displayName: "Business Staff",
    categories: [
      { type: PermissionActionEnum.View, active: false },
      { type: PermissionActionEnum.Edit, active: false },
      { type: PermissionActionEnum.Create, active: false },
      { type: PermissionActionEnum.Delete, active: false },
    ],
    active: false,
  },

  {
    permission: PermissionModuleEnum.Cheque,
    displayName: "Cheque",
    categories: [
      { type: PermissionActionEnum.View, active: false },
      { type: PermissionActionEnum.Edit, active: false },
      { type: PermissionActionEnum.Delete, active: false },
    ],
    active: false,
  },
  {
    permission: PermissionModuleEnum.Log,
    displayName: "Log",
    categories: [{ type: PermissionActionEnum.View, active: false }],
    active: false,
  },
  {
    permission: PermissionModuleEnum.Notification,
    displayName: "Notification",
    categories: [
      { type: PermissionActionEnum.View, active: false },
      { type: PermissionActionEnum.Edit, active: false },
      { type: PermissionActionEnum.Create, active: false },
      { type: PermissionActionEnum.Sms, active: false },
      { type: PermissionActionEnum.Email, active: false },
      { type: PermissionActionEnum.App, active: false },
    ],
    active: false,
  },
  {
    permission: PermissionModuleEnum.Training,
    displayName: "Training",
    categories: [
      { type: PermissionActionEnum.View, active: false },
      { type: PermissionActionEnum.Edit, active: false },
      { type: PermissionActionEnum.Create, active: false },
      { type: PermissionActionEnum.Delete, active: false },
    ],
    active: false,
  },
  {
    permission: PermissionModuleEnum.Qualification,
    displayName: "Qualification",
    categories: [
      { type: PermissionActionEnum.View, active: false },
      { type: PermissionActionEnum.Edit, active: false },
      { type: PermissionActionEnum.Create, active: false },
      { type: PermissionActionEnum.Delete, active: false },
    ],
    active: false,
  },
  {
    permission: PermissionModuleEnum.Users,
    displayName: "Workers",
    categories: [
      { type: PermissionActionEnum.View, active: false },
      { type: PermissionActionEnum.Edit, active: false },
      { type: PermissionActionEnum.Create, active: false },
      { type: PermissionActionEnum.Delete, active: false },
      { type: PermissionActionEnum.Rate, active: false },
      { type: PermissionActionEnum.SensitiveInfo, active: false },
    ],
    active: false,
  },
  {
    permission: PermissionModuleEnum.Messages,
    displayName: "Worker Chat",
    categories: [
      { type: PermissionActionEnum.View, active: false },
      { type: PermissionActionEnum.Chat, active: false },
    ],
    active: false,
  },

  {
    permission: PermissionModuleEnum.WorkerExtended,
    displayName: "Worker Extended",
    categories: [
      { type: PermissionActionEnum.View, active: false },
      { type: PermissionActionEnum.Edit, active: false },
      { type: PermissionActionEnum.Create, active: false },
      { type: PermissionActionEnum.Delete, active: false },
    ],
    active: false,
  },
  {
    permission: PermissionModuleEnum.Discipline,
    displayName: "Discipline",
    categories: [
      { type: PermissionActionEnum.Warning, active: false },
      { type: PermissionActionEnum.Suspend, active: false },
      { type: PermissionActionEnum.Deactivate, active: false },
      { type: PermissionActionEnum.View, active: false },
    ],
    active: false,
  },
  {
    permission: PermissionModuleEnum.AdvancedWorkerSearch,
    displayName: "Advanced Worker Search",
    categories: [{ type: PermissionActionEnum.View, active: false }],
    active: false,
  },

  {
    permission: PermissionModuleEnum.Staff,
    displayName: "Manage Staff",
    categories: [
      { type: PermissionActionEnum.View, active: false },
      { type: PermissionActionEnum.Edit, active: false },
      { type: PermissionActionEnum.Create, active: false },
      { type: PermissionActionEnum.Delete, active: false },
      { type: PermissionActionEnum.PasswordReset, active: false },
      { type: PermissionActionEnum.EmployeeStats, active: false },
    ],
    active: false,
  },
  {
    permission: PermissionModuleEnum.Driver,
    displayName: "Manage Driver",
    categories: [
      { type: PermissionActionEnum.View, active: false },
      { type: PermissionActionEnum.Edit, active: false },
      { type: PermissionActionEnum.Create, active: false },
      { type: PermissionActionEnum.Delete, active: false },
    ],
    active: false,
  },

  {
    permission: PermissionModuleEnum.Analytics,
    displayName: "Analytics",
    categories: [{ type: PermissionActionEnum.View, active: false }],
    active: false,
  },
  {
    permission: PermissionModuleEnum.Payroll,
    displayName: "Payroll",
    categories: [
      { type: PermissionActionEnum.View, active: false },
      { type: PermissionActionEnum.Edit, active: false },
      { type: PermissionActionEnum.Create, active: false },
      { type: PermissionActionEnum.Delete, active: false },
      { type: PermissionActionEnum.SettingsView, active: false },
      { type: PermissionActionEnum.SettingsEdit, active: false },
    ],
    active: false,
  },
  {
    permission: PermissionModuleEnum.SignIn,
    displayName: "Sign in / Sign out",
    categories: [{ type: PermissionActionEnum.Create, active: false }],
    active: false,
  },
];

export default permissionTemplate;
