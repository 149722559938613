import React, { Component } from "react";
//import { Prompt } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import MaskedInput from "react-text-mask";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Spinner,
} from "reactstrap";

import { validateEmail } from "components/Utilities/utilities.js";
import api from "../../../components/API/api";

class CreateNewDriver extends Component {
  constructor(props) {
    super(props);
    this.defaultState = {
      progress: 0,
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      password: null,
      vehicleCapacity: null,
      //Errors
      errorFirstName: "",
      errorLastName: "",
      errorPhone: "",
      errorEmail: "",
      errorPassword: "",
      errorVehicleCapacity: "",

      //Loading
      isLoading: false,
    };
    this.state = this.defaultState;
  }

  // componentDidMount() {
  //  this.fetchProfiles();
  // }

  componentWillUnmount() {
    //window.removeEventListener("beforeunload", this.handleUserLeave);
  }

  componentDidUpdate() {
    //console.log(this.state.permissionsList, this.state.role);
  }

  handleUserLeave = (e) => {
    const confirmationMessage = "Data would be sent if you leave the page";
    (e || window.event).returnValue = confirmationMessage;
    return confirmationMessage;
  };

  handleSubmit = () => {
    let tempState = this.state; // Copy of state
    let hasError = false;
    // Start of Error Handling
    if (tempState.firstName == null || tempState.firstName.trim() == "") {
      this.setState({ errorFirstName: "* Must have a first name." });
      hasError = true;
    } else {
      this.setState({ errorFirstName: "" });
    }

    if (tempState.lastName == null || tempState.lastName.trim() == "") {
      this.setState({ errorLastName: "* Must have a last name." });
      hasError = true;
    } else {
      this.setState({ errorLastName: "" });
    }

    if (tempState.phone == null || tempState.phone.trim() == "") {
      this.setState({ errorPhone: "* Must have a phone number." }); // No phone number
      hasError = true;
    } else {
      if (tempState.phone.length != 10 || tempState.phone < 0) {
        this.setState({
          errorPhone: "* Please input a valid phone number in the format ###-###-####.",
        }); //invalid phone number
        hasError = true;
      } else {
        this.setState({ errorPhone: "" });
      }
    }

    if (tempState.email == null || tempState.email.trim() == "") {
      this.setState({ errorEmail: "* Must have an email" }); // No email text
    } else {
      if (tempState.email != null && validateEmail(tempState.email) === false) {
        this.setState({ errorEmail: "* Please input a valid email" }); //invalid email text
        hasError = true;
      } else {
        this.setState({ errorEmail: "" });
      }
    }

    if (tempState.password == null || tempState.role == "") {
      this.setState({ errorPassword: "* Must have a default Password." });
      hasError = true;
    } else {
      this.setState({ errorPassword: "" });
    }

    // if (tempState.vehicleCapacity == null || tempState.vehicleCapacity.trim() == "") {
    //   this.setState({ errorVehicleCapacity: "* Must have a vehicle capacity." }); // No phone number
    //   hasError = true;
    // }

    if (hasError) {
      this.setState({
        alert: (
          <SweetAlert
            warning
            title="Please ensure all mandatory fields (*) are filled"
            onConfirm={this.hideAlert}
          />
        ),
      });
      return;
    } else {
      this.prepareAndSendDataToServer(tempState); // runs function after loading is set as true.
    }
  };

  prepareAndSendDataToServer = async (tempState) => {
    this.setState({ isLoading: true }); // Start the loading

    let dataToSend = {
      firstName: tempState.firstName,
      lastName: tempState.lastName,
      phone: tempState.phone,
      email: tempState.email,
      password: tempState.password,
      vehicleCapacity: parseInt(tempState.vehicleCapacity),
    };

    try {
      const { data } = await api().post("/users/register-driver", dataToSend);
      this.setState({
        ...this.defaultState,
        isLoading: false,
        alert: (
          <SweetAlert success title="Driver successfully created" onConfirm={this.hideAlert} />
        ),
      });
    } catch (error) {
      this.setState({ isLoading: false }); // Stop the loading
      this.setState({
        alert: (
          <SweetAlert
            error
            title={error?.response?.data?.message || error.message}
            onConfirm={this.hideAlert}
          />
        ),
      });
    }
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handleRadio = (event) => {
    const target = event.target;
    this.setState({
      [target.name]: target.value,
    });
  };

  loadingOverlay = () => {
    return (
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
          background: "transparent",
        }}
        title={<Spinner color="light" style={{ width: "3rem", height: "3rem" }} />}
        onConfirm={this.hideAlert}
        showConfirm={false}
      />
    );
  };

  render() {
    return (
      <div className="content">
        {/* <Prompt //prevent page jumps, temporarily disabled for testing
          when={true}
          message={() => "All the data will lost if you leave"}
        />*/}
        {this.state.isLoading ? (
          this.loadingOverlay()
        ) : (
          <Row>
            <Col md="12">
              <Card className="no-transition">
                <CardHeader>
                  <Row>
                    <Col md="8">
                      <CardTitle tag="h4">Create New Driver for Agency</CardTitle>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form action="/" className="form-horizontal" method="get">
                    <Row>
                      <Label sm="2">* First Name</Label>

                      <Col sm="5">
                        <FormGroup>
                          <Input
                            type="text"
                            autoComplete="off"
                            onChange={(e) => this.setState({ firstName: e.target.value })}
                          />
                          <FormText color="danger" tag="span">
                            {this.state.errorFirstName}
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">* Last Name</Label>
                      <Col sm="5">
                        <FormGroup>
                          <Input
                            type="text"
                            autoComplete="off"
                            onChange={(e) => this.setState({ lastName: e.target.value })}
                          />
                          <FormText color="danger" tag="span">
                            {this.state.errorLastName}
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Label sm="2">* Phone Number</Label>
                      <Col sm="5">
                        <FormGroup>
                          <MaskedInput
                            mask={[
                              "(",
                              /[1-9]/,
                              /\d/,
                              /\d/,
                              ")",
                              "-",
                              /\d/,
                              /\d/,
                              /\d/,
                              "-",
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                            ]}
                            className="form-control"
                            autoComplete="off"
                            maxLength="15"
                            onChange={(e) => {
                              //Allows the user to type in any format. Only numbers will be added to the state
                              let input = e.target.value;
                              let replacedPhone = input.replace(/[^0-9]/g, "");
                              this.setState({ phone: replacedPhone });
                            }}
                          />
                          <FormText color="danger" tag="span">
                            {this.state.errorPhone}
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">* Email</Label>
                      <Col sm="5">
                        <FormGroup>
                          <Input
                            type="email"
                            autoComplete="off"
                            onChange={(e) => this.setState({ email: e.target.value })}
                          />
                          <FormText color="danger" tag="span">
                            {this.state.errorEmail}
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">* Password</Label>
                      <Col sm="5">
                        <FormGroup>
                          <Input
                            placeholder="Minimum 8 digits required"
                            type="password"
                            autoComplete="off"
                            onChange={(e) => this.setState({ password: e.target.value })}
                          />
                          <FormText color="danger" tag="span">
                            {this.state.errorPassword}
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Vehicle Capacity (Optional)</Label>
                      <Col sm="5">
                        <FormGroup>
                          <Input
                            placeholder="Vehicle Capacity"
                            type="number"
                            min={1}
                            autoComplete="off"
                            onChange={(e) => this.setState({ vehicleCapacity: e.target.value })}
                          />
                          <FormText color="danger" tag="span">
                            {this.state.errorVehicleCapacity}
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="9"></Col>
                      <Col sm="3">
                        <Button color="success" onClick={() => this.handleSubmit()}>
                          Create Driver
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}

        {this.state.alert}
      </div>
    );
  }
}

export default CreateNewDriver;
