import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  CardTitle,
  Label,
  Row,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { Spinner as ReactSpinner } from "reactstrap";

import api from "components/API/api";
import StaffingContext from "context/StaffingContext";
// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import { useEffect } from "react";
import AgencyContext from "context/AgencyContext";

function LoginPage(props) {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("register-page");
    document.body.classList.add("full-screen");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("register-page");
      document.body.classList.remove("full-screen");
    };
  });

  const staffingContext = useContext(StaffingContext);
  const { agency, agencyDispatch } = useContext(AgencyContext);

  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [loginData, setLoginData] = useState({});
  const [loginResponse, setLoginResponse] = useState(null);
  const [twoFactorAuth, setTwoFactorAuth] = useState(false);
  const [otpField, setOtpField] = useState("");
  const [otpError, setOtpError] = useState(null);
  const [trust, setTrust] = useState(false);

  useEffect(() => {
    if (localStorage.Token) {
      let token = JSON.parse(localStorage.getItem("Token"));
      if (token) {
        api()
          .get("/users/login/validate-token")
          .then(({ data }) => {
            let token = JSON.parse(localStorage.getItem("Token"));
            processLoginData({ user: data, token: token });
          })
          .catch((e) => {
            localStorage.Token = null;
            staffingContext.staffingDispatch({ type: "REMOVE_USER" });
            agencyDispatch({ type: "REMOVE_USER" });
          });
      }
    }
  }, []);

  const loginSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let dataToSend = {
      ...loginData,
    };
    if (otpField && otpField.length >= 5) {
      dataToSend.otp = otpField;
      dataToSend.save = trust;
    }
    try {
      const { data } = await api().post("/users/login", dataToSend);
      if (data.action === "2factor") {
        setTwoFactorAuth(true);
        setLoading(false);
      } else {
        processLoginData(data);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setAlert(
        <SweetAlert
          error
          title="Your Login credentials are incorrect. Please try again."
          onConfirm={() => setAlert(null)}
        />,
      );
    }
  };

  const processLoginData = (data) => {
    // setLoginResponse(data);
    setLoading(false);
    //get the profile type and set the profile type in context as well.
    if (data.token) {
      localStorage.setItem("Token", JSON.stringify(data.token));
      if (data.agencies.length === 1 && data.agencies[0].role === "Staff") {
        goToAdminPanel(data.agencies[0].slug, data);
      } else {
        staffingContext.staffingDispatch({ type: "SET_USER", payload: { user: data.user } });
        props.history.push(`/staffing/select-agency`);
      }
    } else {
      throw new Error();
    }
  };

  const goToOwnerAdminPanel = () => {
    props.history.push(`/staffing/admin`);
  };

  const goToSuperAdminPanel = () => {
    props.history.push(`/super/admin`);
  };

  const goToAdminPanel = (slug, loginResponse) => {
    if (!loginResponse) return;
    const { user, agencies } = loginResponse;
    const agency = agencies.find((a) => a.slug === slug);

    // let profile = user.profile.find(
    //   (p) => p.role === "Admin" || (p.role === "Owner" && p.agency.slug === slug),
    // );
    // if (!profile) {
    //   profile = user.profile.find((p) => p.role === "Staff" && p.agency.slug === slug);
    // }

    let profile = user.profile.find(
      (p) =>
        (p.role === "Admin" ||
          p.role === "Owner" ||
          p.role === "AgencyAdmin" ||
          p.role === "Supervisor" ||
          p.role === "Recruiter" ||
          p.role == "Staff") &&
        p.agency.slug === slug,
    );
    agencyDispatch({ type: "SET_USER", payload: { user: user } });
    agencyDispatch({
      type: "SET_PROFILE",
      payload: { profile: profile },
    });
    localStorage.setItem("AgencyId", agency.agencyId);
    props.history.push(`/${slug}/admin/all-users`);
  };

  const loginForm = () => {
    return (
      <Row>
        <Col className="ml-auto mr-auto" lg="4" md="6" sm="6">
          <Card style={{ backgroundColor: "white" }} className="card-register">
            <CardTitle tag="h3">Welcome</CardTitle>
            <Form className="register-form">
              <label>Email</label>
              <Input
                className="no-border"
                placeholder="Email"
                type="email"
                name="email"
                onChange={(e) => {
                  setLoginData({ ...loginData, email: e.target.value });
                }}
              />
              <label>Password</label>
              <Input
                className="no-border"
                placeholder="Password"
                type="password"
                name="password"
                onChange={(e) => {
                  setLoginData({ ...loginData, password: e.target.value });
                }}
              />
              <Button block className="btn-round btn-primary" onClick={loginSubmit}>
                Login
              </Button>
            </Form>
            <div className="forgot">
              <Button className="btn-link text-danger" href="/staffing/forgot">
                Forgot password?
              </Button>
            </div>
            <div>
              <div style={{ textAlign: "center" }} className="text-primary">
                <CardTitle tag="h3">Need an account?</CardTitle>
              </div>
              {/* <div className="division">
                      <div className="line l" style={{ border: "1px solid white" }} />
                      <span>New to (Company Name)</span>
                      <div className="line l" style={{ border: "1px solid white" }} />
                    </div> */}

              <Button
                block
                className="btn-round btn-secondary"
                onClick={() => {
                  props.history.push("/staffing/register");
                }}
              >
                Register
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
    );
  };

  const selectDashboardForm = () => {
    return (
      <Row>
        <Col className="ml-auto mr-auto" lg="4" md="6" sm="6">
          <Card className="card-register no-transition">
            <CardTitle tag="h3">Select your dashboard:</CardTitle>
            {loginResponse.agencies.some((a) => a.role === "Admin") && (
              <>
                <CardTitle tag="p">Access Admin panel:</CardTitle>
                <Button block className="btn-round btn-primary" onClick={goToSuperAdminPanel}>
                  Admin panel
                </Button>
              </>
            )}
            {loginResponse.agencies.some((a) => a.role === "Admin" || a.role === "Owner") && (
              <>
                <CardTitle tag="p">Access Owner panel:</CardTitle>
                <Button block className="btn-round btn-primary" onClick={goToOwnerAdminPanel}>
                  Manage Subscriptions
                </Button>
              </>
            )}
            <CardTitle tag="p">Access agency panel:</CardTitle>
            {loginResponse.agencies
              .filter(
                (a) =>
                  a.role === "Admin" ||
                  a.role === "Owner" ||
                  a.role === "Staff" ||
                  a.role === "Supervisor" ||
                  a.role === "Recruiter",
              )
              .map((a) => {
                return (
                  <Button
                    block
                    className="btn-round btn-primary"
                    onClick={() => goToAdminPanel(a.slug, loginResponse)}
                  >
                    {a.agency}
                  </Button>
                );
              })}
          </Card>
        </Col>
      </Row>
    );
  };

  const twoFactorAuthForm = () => {
    return (
      <Row>
        <Col className="ml-auto mr-auto" lg="4" md="6" sm="6">
          <Card className="card-login no-transition">
            <CardHeader style={{ paddingTop: "10px" }}>
              <h3 className="header text-center">Two-Factor Authentication</h3>
              <p>
                We have detected a new login to your account. Your account is protected with
                two-factor authentication. We've sent you an email. Please enter the code below.
              </p>
            </CardHeader>
            <CardBody>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="nc-icon nc-single-02" />
                  </InputGroupText>
                </InputGroupAddon>

                <Input
                  onChange={(e) => setOtpField(e.target.value)}
                  value={otpField}
                  style={{
                    fontFamily: "Trebuchet MS, Helvetica, sans-serif",
                  }}
                  placeholder="Token"
                  type="text"
                />
                <p>{otpError}</p>
              </InputGroup>
              <InputGroup className="mt-2 d-flex justify-content-center">
                <Label check>
                  <Input type="checkbox" checked={trust} onChange={() => setTrust(!trust)} />
                  <span className="form-check-sign" />
                  Trust this device
                </Label>
              </InputGroup>
            </CardBody>
            <CardFooter>
              <Button
                block
                className="btn-round mb-3"
                // color="warning"
                // href="#pablo"
                onClick={loginSubmit}
                // style={{backgroundColor : '#004A8C'}}
                style={{ backgroundColor: "rgb(30,30,30)" }}
              >
                Continue
              </Button>
              <Button
                block
                className="btn-round mb-3"
                // color="warning"
                // href="#pablo"
                onClick={loginSubmit}
                // style={{backgroundColor : '#004A8C'}}
                style={{ backgroundColor: "rgb(30,30,30)" }}
              >
                Resend Code
              </Button>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <ColorNavbar />
      <div className="wrapper">
        <div
          className="page-header"
          style={{
            backgroundImage: "url(" + require("assets/img/sections/bruno-abatti.jpg") + ")",
          }}
        >
          <div className="filter" />
          <Container>
            {loading ? (
              <div className="d-flex justify-content-center">
                <ReactSpinner className="text-center" type="border" color="secondary" size="6" />
              </div>
            ) : (
              <>
                {!loginResponse && !twoFactorAuth && loginForm()}
                {!loginResponse && twoFactorAuth && twoFactorAuthForm()}
                {loginResponse && selectDashboardForm()}
              </>
            )}
            <div className="demo-footer text-center mt-5" style={{ position: "relative" }}>
              <h6>
                © {new Date().getFullYear()}, made with <i className="fa fa-heart heart" /> by
                Omadatek
              </h6>
            </div>
          </Container>
        </div>
      </div>
      {alert}
    </>
  );
}

export default LoginPage;
