import moment, { min } from "moment-timezone";
export const getDay = (date) => {
  let day = moment(date).day() - 1;
  if (day === -1) day = 6; //moment js day starts from sunday being 0, but our normal convention is monday as 0
  return day;
};

export const startOfDay = (date = new Date()) => {
  let start = moment(date);
  start.set({ hour: 0, minute: 0, second: 0 });
  return start.toDate();
};

export const startOfNextDay = (date = new Date()) => {
  let start = moment(date);
  start.add(1, "days");
  start.set({ hour: 0, minute: 0, second: 0 });
  return start.toDate();
};

export const midDay = (date = new Date()) => {
  let start = moment(date);
  start.set({ hour: 13, minute: 0, second: 0 });
  return start.toDate();
};

export const morningTimeRange = (date = new Date()) => {
  let start = moment(date);
  start.set({ hour: 0, minute: 0, second: 0 });
  let end = moment(date);
  end.set({ hour: 11, minute: 59, second: 0 });
  return { start, end };
};
export const afternoonTimeRange = (date = new Date()) => {
  let start = moment(date);
  start.set({ hour: 11, minute: 59, second: 0 });
  let end = moment(date);
  end.set({ hour: 18, minute: 59, second: 0 });
  return { start, end };
};
export const nightTimeRange = (date = new Date()) => {
  let start = moment(date);
  start.set({ hour: 18, minute: 59, second: 0 });
  let end = moment(date);
  end.set({ hour: 23, minute: 59, second: 0 });
  return { start, end };
};

export const shiftTimeType = (start) => {
  let morning = morningTimeRange(start),
    afternoon = afternoonTimeRange(start);

  if (moment(start).isBetween(morning.start, morning.end)) return "Morning";
  else if (moment(start).isBetween(afternoon.start, afternoon.end)) return "Afternoon";
  else return "Night";
};

export const offsetToronto = (date = new Date()) => {
  let currentTimeZoneUtcOffset = moment().tz(moment.tz?.guess()).utcOffset(),
    hoursOffset = currentTimeZoneUtcOffset / 60,
    minuteOffset = currentTimeZoneUtcOffset % 60;

  //destination timeZone utc offset
  let torontoOffset = moment().tz("America/Toronto").utcOffset(),
    torontoHoursOffset = -torontoOffset / 60,
    torontoMinuteOffset = -torontoOffset % 60;

  return moment(date)
    .add(hoursOffset + torontoHoursOffset, "hour")
    .add(minuteOffset + torontoMinuteOffset, "minute");
};
