import { Clear, Mail, Visibility } from "@mui/icons-material";
import CustomCalendar from "components/Calendar/CustomCalendar";
import usePermissions from "hooks/usePermissions";
import moment from "moment-timezone";
import React, { useContext, useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import ReactDatetime from "react-datetime";
// reactstrap components
import { Button, Card, CardBody, CardTitle, CardHeader, Col, Input, Label, Row } from "reactstrap";
import { shiftTimeType } from "utils/dateUtils";
import { afternoonTimeRange, morningTimeRange, nightTimeRange } from "utils/dateUtils";
import { startOfDay, startOfNextDay } from "utils/dateUtils.js";
import api from "components/API/api";
import { Link } from "react-router-dom";
import AgencyContext from "context/AgencyContext";
import BusinessTab from "components/BusinessTab";
import ShiftTable from "./ShiftTable";
import ShiftTab from "./ShiftTabs";
import ConfirmUserModal from "./ConfirmUserModal";
import RemoveUserModal from "./RemoveUserModal";

const selectedForWorkStatus = {
  Applied: 0 /****when user applies to a shift */,
  selected: 1 /***when user is selected for shift */,
  Backup: 2, //when backup is created
  Rejected: 3, //when rejected
  UnScheduled: 4, //when unscheduled by admins
  Dropped: 5, //when shift dropped by user
};

export const unScheduleReasonText = [
  "Employee bailed",
  "Employee didn't pickup call",
  "Employee can't go for other reasons.",
  "Employee was scheduled by Mistake.",
  "Shift Cancelled due to less work",
  "Shift was Cancelled by plant",
  "Employee was sent back",
];

const LiveSelectedUsers = (props) => {
  const agencyContext = useContext(AgencyContext);
  let { isAdmin, role } = usePermissions();
  const hideSomeFields = role === "Call Centre Staff" ? true : false;
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [pages, setPages] = useState(0);
  const [timer, setTimer] = useState(null);
  const [loadingTable, setLoadingTable] = useState(false);
  const [isFirstMount, setIsFirstMount] = useState(true);
  const [filter, setFilter] = useState({
    startDate: new Date(),
    endDate: new Date(),
    query: "",
    businessId: null,
    date: moment().add(1, "days"),
    fromTime: null,
    toTime: null,
  });
  const [isAdvancedView, setIsAdvancedView] = useState(false);
  const [selectedForWorkData, setSelectedForWorkData] = useState([]);
  const [selectedShiftFilter, setSelectedShiftFilter] = useState("All");
  const [cantAddToThisBusiness, setCantAddToThisBusiness] = useState(false);
  const [selectedHdId, setSelectedHdId] = useState(null);
  const [selectedShift, setSelectedShift] = useState(null);
  const [openModal, setOpenModal] = useState({
    userModal: false,
    removeModal: false,
  });

  const [reactTableState, setReactTableState] = useState({
    page: 1,
    size: 5,
    field: "hdId",
    sort: "desc",
    filter: [],
  });
  const [addUserToNewBusiness, setAddUserToNewBusiness] = useState(false);
  const [shiftsList, setShiftsList] = useState([]);
  const [selectedShiftFunction, setSelectedShiftFunction] = useState(null);

  const fetchShifts = async () => {
    try {
      const { data } = await api().get("/shift/list", {
        params: {
          start: startOfDay(filter.startDate),
          end: startOfNextDay(filter.startDate),
        },
      });
      setShiftsList(data);
    } catch (error) {
      console.log("Failed to fetch shifts", error);
    }
  };

  useEffect(() => {
    fetchShifts();
  }, [filter.startDate]);

  useEffect(() => {
    let timeRange;
    switch (selectedShiftFilter) {
      case "Morning":
        timeRange = morningTimeRange(filter.startDate);
        break;
      case "Afternoon":
        timeRange = afternoonTimeRange(filter.startDate);
        break;
      case "Night":
        timeRange = nightTimeRange(filter.startDate);
        break;
      default:
        timeRange = {
          start: null,
          end: null,
        };
    }
    setFilter({
      ...filter,
      fromTime: timeRange.start && moment(timeRange.start).format(),
      toTime: timeRange.end && moment(timeRange.end).format(),
    });
  }, [selectedShiftFilter, filter.startDate]);

  useEffect(() => {
    if (!isFirstMount) {
      fetchData();
    }
  }, [filter.startDate, filter.endDate, filter.businessId, filter.fromTime, filter.toTime]);

  const fetchData = async (filterOptions = reactTableState) => {
    setLoadingTable(true);
    isFirstMount && setIsFirstMount(false);
    try {
      const businessId = filter.businessId || null;
      const { data } = await api().get("/selected-for-work", {
        params: {
          ...filterOptions,
          status: 0, // to get only applied workers
          from: filter.fromTime || startOfDay(filter.startDate),
          till:
            filter.toTime ||
            (isAdvancedView && filter.endDate
              ? startOfNextDay(filter.endDate)
              : startOfNextDay(filter.startDate)),
          businessId,
        },
      });
      let users = data.selectedForWorks || [];
      // hide unverified workers from trained and active tabs for call centre employees only
      // HIDDEN at rishi's request
      // if(hideSomeFields)
      // users = users.filter(u => u.user.documentStatus.status === "Verified")
      setSelectedForWorkData(transformRawData(users));
      setPages(data.noPages);
      setLoadingTable(false);
    } catch (error) {
      console.log("Failed to fetch data", error);
      setLoadingTable(false);
    }
  };

  useEffect(() => {
    if (shiftsList.length && selectedHdId) {
      const selectedForWork = selectedForWorkData.find(
        (_selected) => _selected.hdId === selectedHdId,
      );
      const shift = shiftsList.find((_shift) => _shift._id === selectedForWork?.shift);

      if (!shift) return;
      let timeOfDay = shiftTimeType(shift.start);
      setSelectedShift({
        label:
          shift.businessName +
          " : " +
          timeOfDay +
          ` @${moment(shift.start).format("hh:mm a")}-${moment(shift.end).format("hh:mm a")}`,
        value: shift._id,
        business: shift.business,
        shift,
      });
    }
  }, [shiftsList, selectedHdId]);

  const transformRawData = (shiftRequest) => {
    return shiftRequest.map((d) => ({
      _id: d._id,
      userId: d.user._id,
      hdId: d.user.hdId,
      firstName: d.user.firstName,
      lastName: d.user.lastName,
      duplicated: d.user.duplicated,
      address: d.user.address,
      phone: d.user.phone,
      city: d.user.city,
      workerPin: d.user.workerPin,
      verified: d.user?.documentStatus?.status,
      business: d.business,
      businessName: d.businessName,
      shift: d.shift._id,
      shiftName: d.shift.shiftName,
      shiftFunctions: d.shift?.shiftFunctions,
      status: d.status,
      lastAppActive: d.user.lastAppActive && moment(d.user.lastAppActive).calendar(),
      start: d.start,
      end: d.end,
      startTime: isAdvancedView
        ? moment(d.start).format("L hh:mm a")
        : moment(d.start).format("hh:mm a"),
      endTime: isAdvancedView ? moment(d.end).format("L hh:mm a") : moment(d.end).format("hh:mm a"),
      lastAppLoginTime: d.user.lastAppLogin
        ? moment(d.user.lastAppLogin).format("MMM Do YYYY, h:mm a")
        : null,
      rowColor:
        d.status === selectedForWorkStatus.Backup
          ? "#51cbce"
          : d.user?.sinExpiry
          ? moment(d?.user.sinExpiry).isBefore(moment().add(30, "days"), "days")
            ? "#6A0DA0"
            : moment(d?.user.sinExpiry).isBefore(moment().add(60, "days"), "days")
            ? "#000080"
            : null
          : null,
      sinExpiry: d.user.sinExpiry,
      shiftFunction: d.shiftFunction,
      actions: renderActionButtons(d),
    }));
  };

  const updateAdminUpdateTime = (userId) => {
    api()
      .patch("/users", { lastStatusUpdate: new Date(), userId: userId })
      .then(() => setIsLoading(false))
      .catch((e) => {
        setIsLoading(false);
        console.log("couldn't update user last updated time", e);
      });
  };

  const renderActionButtons = (selectedForWork) => {
    const hdId = selectedForWork.user.hdId;
    return (
      <div className="actions-right">
        <Button
          onClick={() => {
            handleOpenConfirmUserModal(hdId, selectedForWork);
          }}
          disabled={hideSomeFields && selectedForWork.user?.documentStatus?.status !== "Verified"}
          color={"warning"}
          size="md"
          className="btn-link btn-icon"
        >
          <Mail />
        </Button>
        <Button
          tag={Link}
          to={`/${agencyContext.agency.agency.slug}/admin/user-profile/${selectedForWork.user._id}`}
          color="info"
          size="md"
          className="btn-link btn-icon"
        >
          <Visibility />
        </Button>

        <Button
          onClick={() => {
            handleOpenRemoveUserModal(hdId);
          }}
          color={"danger"}
          size="md"
          className="btn-link btn-icon"
        >
          <Clear />
        </Button>
      </div>
    );
  };

  const confirmUser = async (update, shiftFunction, shift) => {
    if (isLoading) {
      return;
    }
    try {
      const selectedUser = selectedForWorkData.find((u) => u.hdId === selectedHdId);
      const userId = selectedUser.userId;
      const selectedForWorkId = selectedUser?._id;
      if (selectedForWorkId) {
        setIsLoading(true);
        const updates = {
          status: update,
          shiftFunction,
        };
        if (shift) {
          updates.shift = shift._id;
          updates.start = shift.start;
          updates.end = shift.end;
          updates.business = shift.business;
          updates.businessName = shift.businessName;
          updates.userId = userId;
        } else {
          updates.userId = userId;
          updates.start = selectedUser.start;
          updates.end = selectedUser.end;
        }
        const resp = await api().patch(`/selected-for-work/${selectedForWorkId}`, updates);
        updateAdminUpdateTime(userId);
        setAlert(
          <SweetAlert
            success
            title="User successfully updated"
            onConfirm={hideAlert}
            showConfirm={true}
          />,
        );
        setIsLoading(false);
        handleCloseConfirmUserModal();
        handleCloseRemoveUserModal();
        fetchData();
      }
    } catch (error) {
      handleCloseConfirmUserModal();
      handleCloseRemoveUserModal();
      setIsLoading(false);
      setAlert(
        <SweetAlert
          danger
          title={"User couldn't be added"}
          onConfirm={hideAlert}
          showConfirm={true}
        >
          {error.response?.data?.message || "There was a technical error. please contact support."}
        </SweetAlert>,
      );
      console.log("Failed to confirm user", error);
    }
  };

  //Loading Overlay
  const hideAlert = () => {
    setAlert(null);
  };

  // Opens modal to remove user to
  const handleOpenRemoveUserModal = (hdId) => {
    setSelectedHdId(hdId);
    setSelectedShift(null);
    setOpenModal({ removeUser: true });
  };

  const handleCloseRemoveUserModal = () => {
    setOpenModal({});
  };

  // Opens modal to confirm  user
  const handleOpenConfirmUserModal = (hdId, selectedForWork) => {
    setSelectedShiftFunction({
      label: selectedForWork?.shiftFunction?.name,
      value: selectedForWork?.shiftFunction?._id,
      shiftFunction: selectedForWork?.shiftFunction,
    });
    setSelectedHdId(hdId);
    setOpenModal({ confirmUser: true });
  };

  const handleCloseConfirmUserModal = () => {
    setCantAddToThisBusiness(false);
    setAddUserToNewBusiness(false);
    setOpenModal({});
    setSelectedShift(null);
    setSelectedHdId(null);
    setSelectedShiftFunction(null);
  };

  return (
    <div className="content">
      <Card className="no-transition">
        <CardHeader>
          <Row>
            <Col md="10">
              <CardTitle tag="h4">Applied Workers</CardTitle>
            </Col>
            <Col>
              {isAdmin ? (
                <Label style={{ fontSize: 15 }}>
                  <Input
                    type="checkbox"
                    style={{ marginTop: 2, marginRight: 10 }}
                    onChange={(e) => {
                      setIsAdvancedView(e.target.checked);
                    }}
                  />
                  Advanced filter
                </Label>
              ) : null}
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <div className="d-flex justify-content-between">
            {isAdvancedView ? <h4>Filters</h4> : null}
          </div>
          <Row className="mt-1 justify-content-between">
            {!isAdvancedView ? (
              <>
                <Col md="1"></Col>
                <Col md="6">
                  <CustomCalendar
                    inputProps={{ placeholder: "Date Picker Here" }}
                    initialDate={filter.startDate}
                    onChangeDate={(date) => {
                      setFilter({ ...filter, startDate: moment(date) });
                    }}
                  />
                </Col>
                <Col md="1"></Col>
              </>
            ) : (
              <>
                <Col md="4">
                  <Label>Start date</Label>
                  <ReactDatetime
                    inputProps={{ placeholder: "Date Picker Here" }}
                    timeFormat={false}
                    value={filter.startDate}
                    onChange={(startDate) => {
                      setFilter({ ...filter, startDate });
                    }}
                  />
                </Col>
                <Col md="4">
                  <Label>End date</Label>
                  <ReactDatetime
                    inputProps={{ placeholder: "Date Picker Here" }}
                    timeFormat={false}
                    value={filter.endDate}
                    onChange={(endDate) => {
                      setFilter({ ...filter, endDate });
                    }}
                  />
                </Col>
              </>
            )}
          </Row>
          <BusinessTab
            onSelect={(business) => {
              setFilter({ ...filter, businessId: business?.value || null });
            }}
          />
          {!isAdvancedView && (
            <div className="d-flex justify-content-center pt-2">
              <ShiftTab
                selectedShiftFilter={selectedShiftFilter}
                setSelectedShiftFilter={setSelectedShiftFilter}
              />
            </div>
          )}
          <Card className="card-plain card-subcategories">
            <ShiftTable
              selectedForWorkData={selectedForWorkData}
              pages={pages}
              reactTableState={reactTableState}
              setReactTableState={setReactTableState}
              timer={timer}
              setTimer={setTimer}
              loadingTable={loadingTable}
              setLoadingTable={setLoadingTable}
              fetchData={fetchData}
            />
          </Card>
        </CardBody>
      </Card>
      {alert}
      <ConfirmUserModal
        selectedForWorkData={selectedForWorkData}
        selectedShift={selectedShift}
        setSelectedShift={setSelectedShift}
        selectedHdId={selectedHdId}
        agencyContext={agencyContext}
        openModal={openModal}
        handleCloseConfirmUserModal={handleCloseConfirmUserModal}
        shiftsList={shiftsList}
        selectedShiftFunction={selectedShiftFunction}
        addUserToNewBusiness={addUserToNewBusiness}
        setAddUserToNewBusiness={setAddUserToNewBusiness}
        cantAddToThisBusiness={cantAddToThisBusiness}
        setCantAddToThisBusiness={setCantAddToThisBusiness}
        shiftTimeType={shiftTimeType}
        confirmUser={confirmUser}
        setSelectedShiftFunction={setSelectedShiftFunction}
      />
      <RemoveUserModal
        openModal={openModal}
        confirmUser={confirmUser}
        handleCloseRemoveUserModal={handleCloseRemoveUserModal}
      />
    </div>
  );
};

export default LiveSelectedUsers;
