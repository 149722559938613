import React from "react";
import { Label, Col } from "reactstrap";
import CSVReader from "react-csv-reader";
import api from "components/API/api";
import SweetAlert from "react-bootstrap-sweetalert";
import { CSVReaderParseOptions } from "./CreateNewUserDataset";

/**
 * Description : use CSVReader to read csv file with manually input user data and create user in bulk
 * @param {FUNCTION} setAlert
 * @param {FUNCTION} hideAlert {HIDE PROMPT ALERT AFTER USER CLICKED}
 * @param {FUNCTION} setIsLoading
 * @returns Response for success / fail to create user in bulk
 */
const BulkCreateUser = ({ setAlert, hideAlert, setIsLoading }) => {
  const handleOnFileLoadedCSVReader = async (data) => {
    let tempArr = [];

    try {
      setIsLoading(true);
      data.forEach((tempState) => {
        let tempEmergencyPhone2 = null;
        if (tempState.emergencyPhone2) {
          tempEmergencyPhone2 = tempState.emergencyPhone2.toString();
        }

        tempState.emergency = [
          {
            //mandatory, first emergency contact
            firstName: tempState.emergencyFirstName,
            lastName: tempState.emergencyLastName,
            relationship: tempState.emergencyRelationship,
            phone: tempState.emergencyPhone.toString(),
          },
          {
            //optional, second emergency contact
            firstName: tempState.emergencyFirstName2,
            lastName: tempState.emergencyLastName2,
            relationship: tempState.emergencyRelationship2,
            phone: tempEmergencyPhone2,
          },
        ];
        if (tempState.workStatusValue === "citizen") {
          tempState.workStatus = 0;
        } else if (tempState.workStatusValue === "workPermit") {
          tempState.workStatus = 1;
        } else if (tempState.workStatusValue === "studentVisa") {
          tempState.workStatus = 2;
        } else {
          tempState.workStatus = null;
        }
        tempState.availability = [
          //setting everything to true
          { active: true, m: true, a: true, n: true },
          { active: true, m: true, a: true, n: true },
          { active: true, m: true, a: true, n: true },
          { active: true, m: true, a: true, n: true },
          { active: true, m: true, a: true, n: true },
          { active: true, m: true, a: true, n: true },
          { active: true, m: true, a: true, n: true },
        ];
        tempState.profileType = "Worker";
        tempState.confirmEmail = tempState.email;
        tempState.sin = tempState.sin.toString();
        tempState.phone = tempState.phone.toString();
        tempState.selected = false;
        tempArr.push(tempState);
      });

      const res = await api().post("/users/bulk-register-worker", { users: tempArr });

      setIsLoading(false);
      setAlert(
        <SweetAlert
          success
          title="Users successfully created!"
          onConfirm={() => {
            hideAlert();
          }}
        />,
      );
    } catch (error) {
      console.log("Failed to handleOnFileLoadedCSVReader", error);
      const description = error.response && error.response.data && error.response.data.message;
      setIsLoading(false);
      setAlert(
        <SweetAlert
          danger
          title="An error occurred"
          text={`${error.response}`}
          onConfirm={hideAlert}
        >
          {description}
        </SweetAlert>,
      );
    }
  };

  return (
    <Col lg="3" sm="4">
      <Label>Upload CSV to Bulk Create Workers</Label>
      <CSVReader
        cssClass="react-csv-input"
        label=""
        onFileLoaded={handleOnFileLoadedCSVReader}
        parserOptions={CSVReaderParseOptions}
      />
    </Col>
  );
};

export default BulkCreateUser
