import React, { useContext, useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";

import api from "components/API/api";

import SubscriptionPlans from "./SubscriptionPlans";

function SelectPlan(props) {
  const [type, setType] = useState(1);
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    api()
      .get("./subscription/list")
      .then((response) => {
        setPlans(response.data.plans);
      })
      .catch((e) => console.log("error fetching plans", e));
  }, []);

  return (
    <>
      <div className="section section-pricing cd-section" id="pricing">
        {/* ********* PRICING 2 ********* */}
        <div className="pricing-2" style={{ marginTop: "-100px" }}>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <h2 className="title">Pick the best plan for you</h2>
                <h5 className="description">
                  You have Free Unlimited Updates and Premium Support on each type.
                </h5>
                <br />
                <Nav className="nav-pills-danger justify-content-center" pills>
                  <NavItem>
                    <NavLink
                      className={type === 1 ? "active" : ""}
                      href="#pablo"
                      onClick={(e) => {
                        console.log(type);
                        e.preventDefault();
                        setType(1);
                      }}
                    >
                      Monthly
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={type === 2 ? "active" : ""}
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setType(2);
                      }}
                    >
                      Yearly
                    </NavLink>
                  </NavItem>
                </Nav>
                {/* Pill panes */}
                <TabContent activeTab={"pill-" + type}>
                  <TabPane tabId={"pill-" + type} />
                  <TabPane tabId={"pill-" + type} />
                </TabContent>
              </Col>
            </Row>
            <div className="space-top" />
            <SubscriptionPlans
              onSelect={props.onSelect}
              planType={type == 1 ? "Monthly" : "Yearly"}
              plans={(() => {
                return plans.filter((plan) => {
                  if (plan.package === "Yearly" && type === 2) {
                    return true;
                  } else if (plan.package === "Monthly" && type === 1) {
                    return true;
                  }
                  return false;
                });
              })()}
            />
          </Container>
        </div>
        {/* ********* END PRICING 2 ********* */}
      </div>
    </>
  );
}

export default SelectPlan;
