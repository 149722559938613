import api from "components/API/api";
import AgencyContext from "context/AgencyContext";
import React, { useContext, useEffect } from "react";

const useFetchBusiness = () => {
  const { agency, agencyDispatch } = useContext(AgencyContext);
  const businesses = agency?.businesses;
  const fetchBusiness = async () => {
    const { data } = await api().get("/business/list", {
      params: { slug: agency?.agency?.slug },
    });
    // console.log(data)
    data.sort((a, b) => a.name.localeCompare(b.name));
    agencyDispatch({
      type: "SET_BUSINESS_LIST",
      payload: {
        businesses: [...data.map((b) => ({ ...b, value: b._id, label: b.name }))],
      },
    });
  };
  useEffect(() => {
    if (!businesses.length) {
      fetchBusiness();
    }
  }, []);

  return businesses;
};

export default useFetchBusiness;
