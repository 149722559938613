const staffingRegisterReducer = (state, action) => {
  switch (action.type) {
    case "SET_USER":
      return { ...state, user: action.payload.user };
    case "SET_AGENCY":
      return { ...state, agency: action.payload.agency };
    case "SET_PLAN":
      return { ...state, plan: action.payload.plan };
    case "SET_PAYMENT_SUCCESS":
      return { ...state, success: action.payload.success, paymentData: action.payload.paymentData };
    case "SET_PROGRESS_STEP":
      let currentState = { ...state, progressBarIndex: action.payload.progressBarIndex };
      // switch (action.payload.progressBarIndex) {
      //   case 0:
      //     currentState = { ...currentState, disabled: [false, false, false, false, false] };
      //   case 1:
      //     currentState = { ...currentState, disabled: [true, false, false, false, false] };
      //   case 2:
      //     currentState = { ...currentState, disabled: [true, true, false, false, false] };
      //   case 3:
      //     currentState = { ...currentState, disabled: [true, true, true, true, true, false] };
      //   case 4:
      //     currentState = { ...currentState, disabled: [true, true, true, true, true, false] };
      //   default:
      //     currentState = { ...currentState, disabled: [false, false, false, false, false] };
      // }
      if (state.success)
        currentState = {
          ...currentState,
          allowNavigation: [false, false, false, false, false, false],
        };
      else if (state.user && state.agency && state.plan)
        currentState = {
          ...currentState,
          allowNavigation: [true, true, true, true, false, false],
        };
      else if (state.user && state.agency)
        currentState = {
          ...currentState,
          allowNavigation: [true, true, true, false, false, false],
        };
      else if (state.user)
        currentState = {
          ...currentState,
          allowNavigation: [true, true, false, false, false, false],
        };
      else {
        currentState = {
          ...currentState,
          allowNavigation: [true, false, false, false, false, false],
        };
      }
      console.log(currentState);
      return currentState;

    default:
      return state;
  }
};

export default staffingRegisterReducer;
