export const checkAccess = function (targetModule, targetAction, userPermissions) {
  if (!userPermissions || !targetAction || !targetModule) return false;
  return userPermissions.some((permission) => {
    return (
      permission.module === targetModule &&
      targetAction.some((action) => {
        // console.log("check", action, permission.action, action === permission.action);
        return action === permission.action;
      })
    );
  });
};

export const checkAccessSideBar = function (
  targetModule,
  targetAction,
  defaultAccessRole,
  userPermissions,
  role,
) {
  if (
    defaultAccessRole.some((r) => {
      if (r == role) return true;
    })
  ) {
    return true;
  } else {
    if (userPermissions && role) {
      return checkAccess(targetModule, targetAction, userPermissions);
    } else {
      return false;
    }
  }
};
