import React, { Component } from "react";
import Select from "react-select";
//import { Prompt } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import MaskedInput from "react-text-mask";
import Resizer from "react-image-file-resizer";
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Spinner,
} from "reactstrap";

import { validateEmail, validateSin } from "components/Utilities/utilities.js";
import axios from "axios";

import api from "../../../components/API/api";
import { Modal } from "@mui/material";

import permissionsGuideList from "./PermissionsGuide.jsx";
import RenderPermission from "./RenderPermission.jsx";
import permissionTemplate from "./permissionTemplate.js";
import roles from "enums/role";

const workStatusOptions = [
  {
    value: 0,
    label: "Permanent Residence/Citizen",
  },
  { value: 1, label: "Work Permit" },
  {
    value: 2,
    label: "Student Visa",
  },
];

class CreateNewStaff extends Component {
  constructor(props) {
    super(props);
    this.defaultState = {
      progress: 0,
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      password: null,
      role: null,
      //Errors
      errorFirstName: "",
      errorLastName: "",
      errorPhone: "",
      errorEmail: "",
      errorPassword: "",
      errorRole: "",
      // profile Data
      permissionsList: permissionTemplate,
      selectedPermission: [],
      gender: null,
      dob: null, // must be 18 +
      address: null,
      city: null,
      province: null,
      postalCode: null,
      sin: null,
      sinToSend: null,
      legallyWork: null,
      selected: false,
      // completedTraining: null,
      workStatus: null,
      emergencyFirstName: null,
      emergencyLastName: null,
      emergencyPhone: null,
      emergencyRelationship: null,
      emergencyFirstName2: null, //the second emergeny contact is optional, so no error handling or checks
      emergencyLastName2: null,
      emergencyPhone2: null,
      emergencyRelationship2: null,
      email: null,
      confirmEmail: null,
      isWorker: false,
      //Loading
      isLoading: false,

      //modal data
      viewPermissionInfoModalOpen: false,
    };
    this.state = this.defaultState;
  }

  // componentDidMount() {
  //  this.fetchProfiles();
  // }

  componentWillUnmount() {
    //window.removeEventListener("beforeunload", this.handleUserLeave);
  }

  componentDidUpdate() {
    //console.log(this.state.permissionsList, this.state.role);
  }

  handleUserLeave = (e) => {
    const confirmationMessage = "Data would be sent if you leave the page";
    (e || window.event).returnValue = confirmationMessage;
    return confirmationMessage;
  };

  handleSubmit = () => {
    let tempState = this.state; // Copy of state
    let hasError = false;
    // Start of Error Handling
    if (tempState.firstName == null || tempState.firstName.trim() == "") {
      this.setState({ errorFirstName: "* Must have a first name." });
      hasError = true;
    } else {
      this.setState({ errorFirstName: "" });
    }

    if (tempState.lastName == null || tempState.lastName.trim() == "") {
      this.setState({ errorLastName: "* Must have a last name." });
      hasError = true;
    } else {
      this.setState({ errorLastName: "" });
    }

    if (tempState.phone == null || tempState.phone.trim() == "") {
      this.setState({ errorPhone: "* Must have a phone number." }); // No phone number
      hasError = true;
    } else {
      if (tempState.phone.length != 10 || tempState.phone < 0) {
        this.setState({
          errorPhone: "* Please input a valid phone number in the format ###-###-####.",
        }); //invalid phone number
        hasError = true;
      } else {
        this.setState({ errorPhone: "" });
      }
    }

    if (tempState.email == null || tempState.email.trim() == "") {
      this.setState({ errorEmail: "* Must have an email" }); // No email text
    } else {
      if (tempState.email != null && validateEmail(tempState.email) === false) {
        this.setState({ errorEmail: "* Please input a valid email" }); //invalid email text
        hasError = true;
      } else {
        this.setState({ errorEmail: "" });
      }
    }

    if (tempState.password == null || tempState.role == "") {
      this.setState({ errorPassword: "* Must have a default Password." });
      hasError = true;
    } else {
      this.setState({ errorPassword: "" });
    }

    if (tempState.role == null || tempState.role == "") {
      this.setState({ errorRole: "* Must set user role. " });
      hasError = true;
    } else {
      this.setState({ errorRole: "" });
    }

    if (tempState.isWorker) {
      if (tempState.gender == null || tempState.gender.trim() == "") {
        this.setState({ errorGender: "* Must select a gender." });
        hasError = true;
      } else {
        this.setState({ errorGender: "" });
      }

      console.log(new Date(tempState.dob).getYear(), new Date().getYear());
      if (!tempState.dob) {
        this.setState({ errorDob: "* Must have date of birth." });
        hasError = true;
      } else if (new Date().getYear() - new Date(tempState.dob).getYear() < 18) {
        this.setState({ errorDob: "* Must be 18 or older." });
        hasError = true;
      } else {
        this.setState({ errorDob: "" });
      }

      if (tempState.phone == null || tempState.phone.trim() == "") {
        this.setState({ errorPhone: "* Must have a phone number." }); // No phone number
        hasError = true;
      } else {
        if (tempState.phone.length != 10 || tempState.phone < 0) {
          this.setState({
            errorPhone: "* Please input a valid phone number in the format ###-###-####.",
          }); //invalid phone number
          hasError = true;
        } else {
          this.setState({ errorPhone: "" });
        }
      }

      if (tempState.address == null || tempState.address.trim() == "") {
        this.setState({ errorAddress: "* Must have a street address." });
        hasError = true;
      } else {
        this.setState({ errorAddress: "" });
      }

      if (tempState.city == null || tempState.city.trim() == "") {
        this.setState({ errorCity: "* Must have a city." });
        hasError = true;
      } else {
        this.setState({ errorCity: "" });
      }

      if (tempState.province == null || tempState.province == "") {
        this.setState({ errorProvince: "* Must have a province." });
        hasError = true;
      } else {
        this.setState({ errorProvince: "" });
      }

      if (tempState.postalCode == null || tempState.postalCode.trim() == "") {
        this.setState({ errorPostalCode: "* Must have a postal code." });
        hasError = true;
      } else {
        let regExp = "^[A-Za-z][0-9][A-Za-z][0-9][A-Za-z][0-9]$"; //logic for postal code format A1B2C3, capital letters only
        if (tempState.postalCode.match(regExp) == null || tempState.postalCode.length != 6) {
          this.setState({
            errorPostalCode: "* Please input a valid postal code in the format A1A1A1.",
          }); //invalid postal code
          hasError = true;
        } else {
          this.setState({ errorPostalCode: "" });
        }
      }

      if (tempState.sin == null || tempState.sin.trim() == "") {
        this.setState({ errorSin: "* Must have a SIN." }); //No sin
        hasError = true;
      } else {
        if (validateSin(tempState.sin) === false || tempState.sin.length != 9) {
          this.setState({ errorSin: "* Please input a valid SIN in the format ###-###-###" }); //invalid sin
          hasError = true;
        } else if (validateSin(tempState.sin) === true) {
          this.setState({ errorSin: "" });
        }
      }

      if (tempState.legallyWork == null) {
        this.setState({ errorLegallyWork: "* Must select yes or no" });
        hasError = true;
      } else {
        this.setState({ errorLegallyWork: "" });
      }

      if (tempState.workStatus == null) {
        this.setState({ errorWorkStatus: "* Must select a work status" });
        hasError = true;
      } else {
        this.setState({ errorWorkStatus: "" });
      }

      if (tempState.emergencyFirstName == null) {
        this.setState({
          errorEmergencyFirstName: "* Must have a first name for the emergency contact.",
        });
        hasError = true;
      } else {
        this.setState({ errorEmergencyFirstName: "" });
      }

      if (tempState.emergencyLastName == null) {
        this.setState({
          errorEmergencyLastName: "* Must have a last name for the emergency contact.",
        });
        hasError = true;
      } else {
        this.setState({ errorEmergencyLastName: "" });
      }

      if (tempState.emergencyPhone == null || tempState.emergencyPhone.trim() == "") {
        this.setState({
          errorEmergencyPhone: "* Must have a phone number for the emergency contact.",
        }); // No phone number
        hasError = true;
      } else {
        if (tempState.emergencyPhone.length != 10 || tempState.emergencyPhone < 0) {
          this.setState({
            errorEmergencyPhone:
              "* Please input a valid phone number in the format ###-###-#### for the emergency contact.",
          }); //invalid phone number
          hasError = true;
        } else {
          this.setState({ errorEmergencyPhone: "" });
        }
      }

      if (tempState.emergencyRelationship == null) {
        this.setState({
          errorEmergencyRelationship: "* Must have a relationship for the emergency contact.",
        });
        hasError = true;
      } else {
        this.setState({ errorEmergencyRelationship: "" });
      }

      if (tempState.image1 == null) {
        this.setState({
          errorImage1:
            "* Must upload an image (jpeg, jpg, or png) for the front of the government license",
        });
        hasError = true;
      } else {
        this.setState({ errorImage1: "" });
      }

      if (tempState.image3 == null) {
        this.setState({
          errorImage3: "* Must upload an image (jpeg, jpg, or png) for the front of the SIN card",
        });
        hasError = true;
      } else {
        this.setState({ errorImage3: "" });
      }

      if (tempState.image5 == null && tempState.showWorkPermit == true) {
        this.setState({
          errorImage5:
            "* Must upload an image (jpeg, jpg, or png) for the front of the work permit",
        });
        hasError = true;
      } else {
        this.setState({ errorImage5: "" });
      }

      if (tempState.image7 == null && tempState.showStudentVisa == true) {
        this.setState({
          errorImage7:
            "* Must upload an image (jpeg, jpg, or png) for the front of the student visa",
        });
        hasError = true;
      } else {
        this.setState({ errorImage7: "" });
      }
    }

    if (hasError) {
      this.setState({
        alert: (
          <SweetAlert
            warning
            title="Please ensure all mandatory fields (*) are filled"
            onConfirm={this.hideAlert}
          />
        ),
      });
      return;
    } else {
      this.prepareAndSendDataToServer(tempState); // runs function after loading is set as true.
    }
  };

  prepareAndSendDataToServer = async (tempState) => {
    this.setState({ isLoading: true }); // Start the loading
    // to load the values again after creating manager without fectching again
    let ReloadPermissionsList = this.state.permissionsList;
    //Upload the changelog into the deeper user history collection! This uploads the admin email, timestamp, and action taken
    let permissionsArray = [];

    console.log(tempState.permissionsList);

    if (tempState.permissionsList) {
      let array = this.state.permissionsList;
      array.map((m) => {
        if (m.active) {
          let module = m.permission;
          m.categories.map((a) => {
            // to filter none from actions
            if (a.active && !(a.action === "None")) {
              let permission = {
                module: module,
                action: a.type,
              };
              permissionsArray.push(permission);
            }
          });
        }
      });
    }

    let emergency = [
      {
        firstName: tempState.emergencyFirstName,
        lastName: tempState.emergencyLastName,
        phone: tempState.emergencyPhone,
        relationship: tempState.emergencyRelationship,
      },
    ];

    if (tempState.emergencyFirstName2) {
      emergency.push({
        firstName: tempState.emergencyFirstName2,
        lastName: tempState.emergencyLastName2,
        phone: tempState.emergencyPhone2,
        relationship: tempState.emergencyRelationship2,
      });
    }

    // console.log(permissionsArray);
    let dataToSend = {
      firstName: tempState.firstName,
      lastName: tempState.lastName,
      phone: tempState.phone,
      email: tempState.email,
      password: tempState.password,
      profileType: "Staff",
      roleDisplayName: tempState.role,
      permissions: permissionsArray,
      isWorker: tempState.isWorker,
    };

    if (this.state.isWorker) {
      dataToSend = {
        ...dataToSend,
        gender: tempState.gender,
        dob: new Date(tempState.dob),
        address: tempState.address,
        city: tempState.city,
        province: tempState.province.value,
        postalCode: tempState.postalCode,
        sin: tempState.sin,
        legallyWork: tempState.legallyWork,
        workStatus: tempState.workStatus.value,
        emergency,
      };
    }
    try {
      const { data } = await api().post("/users/register-manager", dataToSend);
      //to make all permission to default active state
      ReloadPermissionsList.map((p) => (p.active = false));
      if (this.state.isWorker) {
        try {
          await this.uploadImages(data.user);
        } catch (error) {
          console.log(error);
          this.setState({
            isLoading: false,
            alert: (
              <SweetAlert
                warning
                title="An error occurred while uploading user images"
                text={`${error.response}`}
                onConfirm={this.hideAlert}
              ></SweetAlert>
            ),
          });
        }
      }

      this.setState({
        ...this.defaultState,
        permissionsList: ReloadPermissionsList,
        isLoading: false,
        alert: <SweetAlert success title="Staff successfully created" onConfirm={this.hideAlert} />,
      });
    } catch (error) {
      this.setState({ isLoading: false }); // Stop the loading
      this.setState({
        alert: (
          <SweetAlert
            error
            title={error?.response?.data?.error?.message || error.message}
            onConfirm={this.hideAlert}
          />
        ),
      });
    }
  };

  resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1200,
        1200,
        "JPEG",
        50,
        0,
        (uri) => {
          resolve(uri);
        },
        "blob",
      );
    });

  uploadFile = (file, signedRequest) => {
    axios
      .put(signedRequest, file)
      .then(() => {
        console.log("successfully uploaded the image");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  signAndUpLoadPromise = (userId, imageName, image) => {
    let signedPromise = api()
      .get("/image/sign-s3/put", {
        params: {
          "file-path": `userDocs/${userId}/${imageName}`,
          "file-type": image.type,
        },
      })
      .then(({ data }) => {
        console.log(data);
        this.uploadFile(image, data.signedUrl);
        return true;
      });
    return signedPromise;
  };

  uploadImages = async (user) => {
    let promises = [];
    if (this.state.image1 != null) {
      promises.push(this.signAndUpLoadPromise(user._id, "1.jpeg", this.state.image1));
    }
    if (this.state.image3 != null) {
      promises.push(this.signAndUpLoadPromise(user._id, "3.jpeg", this.state.image3));
    }
    if (this.state.image5 != null) {
      promises.push(this.signAndUpLoadPromise(user._id, "5.jpeg", this.state.image5));
    }
    if (this.state.image7 != null) {
      promises.push(this.signAndUpLoadPromise(user._id, "7.jpeg", this.state.image7));
    }
    if (this.state.signature != null) {
      const response = await fetch(this.state.signature);
      const blob = await response.blob();

      promises.push(this.signAndUpLoadPromise(user._id, "signature.jpeg", blob));
    }

    try {
      await Promise.all(
        promises.map((promise) => {
          promise
            .then((response) => {
              // console.log(response);
              console.log("successfully uploaded images");
            })
            .catch((message) => {
              console.log(message);
            });
        }),
      ).then(() => {
        this.setState({
          isLoading: false,
        });
      });
    } catch (error) {
      this.setState({ isLoading: false });
      this.setState({
        alert: (
          <SweetAlert
            danger
            title={`An error has occured uploading your images: ${error.code}.`}
            onConfirm={() => {
              this.setState({ alert: null });
              this.goToTraining(user);
            }}
          >
            {error.code}
          </SweetAlert>
        ),
      });
      return false;
    }
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  addImageToState = async (stateName, image) => {
    const resizedImage = await this.resizeFile(image);
    this.setState({ [stateName]: resizedImage });
  };

  removeImageFromState = (stateName) => {
    this.setState({ [stateName]: null });
  };

  handleRadio = (event) => {
    const target = event.target;
    this.setState({
      [target.name]: target.value,
    });
  };

  loadingOverlay = () => {
    return (
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
          background: "transparent",
        }}
        title={<Spinner color="light" style={{ width: "3rem", height: "3rem" }} />}
        onConfirm={this.hideAlert}
        showConfirm={false}
      />
    );
  };

  handleOpenViewPermissionInfoModal = () => {
    this.setState({
      viewPermissionInfoModalOpen: true,
    });
  };

  handleCloseViewPermissionInfoModal = () => {
    this.setState({
      viewPermissionInfoModalOpen: false,
    });
  };

  viewPermissionInfoModal = () => {
    return (
      <Modal
        open={this.state.viewPermissionInfoModalOpen}
        onClose={this.handleCloseViewPermissionInfoModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          overflow: "scroll",
          marginTop: 20,
        }}
      >
        <FormGroup style={{ width: 1000, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>Guide to User Permissions</h5>
            </div>
            <div>
              {permissionsGuideList.map((permission) => {
                return (
                  <>
                    <Row style={{ paddingBottom: 15 }} md="10">
                      <Col md="2">
                        <span style={{ fontWeight: "bold" }}>{permission.name}</span>
                      </Col>
                      <Col md="10">{permission.description}</Col>
                    </Row>
                  </>
                );
              })}
            </div>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <Button onClick={() => this.handleCloseViewPermissionInfoModal()}>Close</Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  _renderWorkPermit = () => {
    if (this.state.showWorkPermit == true) {
      return (
        <Row>
          <Label sm="2">* Work Permit</Label>
          <Col sm="4">
            <CardTitle tag="p">* (Front)</CardTitle>
            <ImageUpload
              addImageToState={this.addImageToState}
              imageState="image5"
              removeImageFromState={this.removeImageFromState}
            />
            <FormText color="danger" tag="span">
              {this.state.errorImage5}
            </FormText>
          </Col>
        </Row>
      );
    }
  };

  _renderStudentVisa = () => {
    if (this.state.showStudentVisa == true) {
      return (
        <Row>
          <Label sm="2">* Student Visa</Label>
          <Col sm="4">
            <CardTitle tag="p">* (Front)</CardTitle>
            <ImageUpload
              addImageToState={this.addImageToState}
              imageState="image7"
              removeImageFromState={this.removeImageFromState}
            />
            <FormText color="danger" tag="span">
              {this.state.errorImage7}
            </FormText>
          </Col>
        </Row>
      );
    }
  };

  render() {
    return (
      <div className="content">
        {/* <Prompt //prevent page jumps, temporarily disabled for testing
          when={true}
          message={() => "All the data will lost if you leave"}
        />*/}
        {this.state.isLoading ? (
          this.loadingOverlay()
        ) : (
          <Row>
            <Col md="12">
              <Card className="no-transition">
                <CardHeader>
                  <Row>
                    <Col md="8">
                      <CardTitle tag="h4">Create New Staff for Agency</CardTitle>
                    </Col>
                    <Col md="4">
                      <FormGroup align="right">
                        <Button
                          onClick={() => this.handleOpenViewPermissionInfoModal()}
                          color="info"
                        >
                          Permissions Guide
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form action="/" className="form-horizontal" method="get">
                    <Row>
                      <Label sm="2">* First Name</Label>

                      <Col sm="5">
                        <FormGroup>
                          <Input
                            type="text"
                            autoComplete="off"
                            onChange={(e) => this.setState({ firstName: e.target.value })}
                          />
                          <FormText color="danger" tag="span">
                            {this.state.errorFirstName}
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">* Last Name</Label>
                      <Col sm="5">
                        <FormGroup>
                          <Input
                            type="text"
                            autoComplete="off"
                            onChange={(e) => this.setState({ lastName: e.target.value })}
                          />
                          <FormText color="danger" tag="span">
                            {this.state.errorLastName}
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Label sm="2">* Phone Number</Label>
                      <Col sm="5">
                        <FormGroup>
                          <MaskedInput
                            mask={[
                              "(",
                              /[1-9]/,
                              /\d/,
                              /\d/,
                              ")",
                              "-",
                              /\d/,
                              /\d/,
                              /\d/,
                              "-",
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                            ]}
                            className="form-control"
                            autoComplete="off"
                            maxLength="15"
                            onChange={(e) => {
                              //Allows the user to type in any format. Only numbers will be added to the state
                              let input = e.target.value;
                              let replacedPhone = input.replace(/[^0-9]/g, "");
                              this.setState({ phone: replacedPhone });
                            }}
                          />
                          <FormText color="danger" tag="span">
                            {this.state.errorPhone}
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">* Email</Label>
                      <Col sm="5">
                        <FormGroup>
                          <Input
                            type="email"
                            autoComplete="off"
                            onChange={(e) => this.setState({ email: e.target.value })}
                          />
                          <FormText color="danger" tag="span">
                            {this.state.errorEmail}
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">* Password</Label>
                      <Col sm="5">
                        <FormGroup>
                          <Input
                            placeholder="Minimum 8 digits required"
                            type="password"
                            autoComplete="off"
                            onChange={(e) => this.setState({ password: e.target.value })}
                          />
                          <FormText color="danger" tag="span">
                            {this.state.errorPassword}
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">* Role</Label>
                      <Col sm="5">
                        <FormGroup>
                          {/* <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            options={Object.values(roles)}
                            value={this.state.role}
                            onChange={(value) => {
                              let permissionList = permissionTemplate(value.value);
                              this.setState({
                                role: value,
                                permissionsList: permissionList,
                              });
                            }}
                          /> */}

                          <Input
                            type="text"
                            autoComplete="off"
                            onChange={(e) => {
                              //
                              this.setState({ role: e.target.value });
                            }}
                            value={this.state.role}
                          />
                          <FormText color="danger" tag="span">
                            {this.state.errorRole}
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>

                    {/* {this.state.role ? ( */}
                    <Row>
                      <Label sm="2"> Permissions</Label>
                      <Col className="checkbox-radios" sm="10">
                        {this.state.permissionsList.map((p, i) => {
                          return (
                            <RenderPermission
                              displayName={p.displayName}
                              categories={p.categories}
                              active={p.active}
                              onChange={(active, categories) => {
                                //to make sure only a selected permission gets updated
                                let temp = [...this.state.permissionsList];
                                let x = temp.find((t) => {
                                  return t.permission === p.permission;
                                });
                                x.active = active;
                                x.categories = categories;
                                this.setState({
                                  permissionsList: temp,
                                });
                              }}
                              key={i}
                            />
                          );
                        })}
                      </Col>
                    </Row>
                    {/* ) : null} */}
                    <Row>
                      <Label sm="2">Agency Worker</Label>
                      <Col sm="5">
                        <FormGroup check inline>
                          <Label check>
                            <Input
                              type="checkbox"
                              checked={this.state.isWorker}
                              onChange={() => {
                                this.setState({ isWorker: !this.state.isWorker });
                              }}
                            />
                            <span className="form-check-sign" />
                            This staff member will be scheduled to work in agency as a worker
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    {this.state.isWorker && (
                      <>
                        <div
                          style={{
                            borderColor: "#D8CBC7",
                            borderStyle: "solid",
                            borderWidth: "0.3px",
                            width: "100%",
                            height: 0,
                            marginTop: "35px",
                            marginBottom: "25px",
                          }}
                        />
                        <Row>
                          <Label sm="2">* Gender</Label>
                          <Col className="checkbox-radios" sm="10">
                            <div className="form-check-radio" style={{ marginTop: 8 }}>
                              <Label check>
                                <Input
                                  defaultValue="M"
                                  id="M"
                                  name="genderRadio"
                                  type="radio"
                                  onChange={() => this.setState({ gender: "M" })}
                                  checked={this.state.gender === "M"}
                                />
                                Male <span className="form-check-sign" />
                              </Label>
                            </div>
                            <div className="form-check-radio">
                              <Label check>
                                <Input
                                  defaultValue="F"
                                  id="F"
                                  name="genderRadio"
                                  type="radio"
                                  checked={this.state.gender === "F"}
                                  onChange={() => this.setState({ gender: "F" })}
                                />
                                Female <span className="form-check-sign" />
                              </Label>
                              <FormText color="danger" tag="span">
                                {this.state.errorGender}
                              </FormText>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Label sm="2">* Date of Birth</Label>
                          <Col sm="5">
                            <FormGroup>
                              <Input
                                type="date"
                                autoComplete="off"
                                maxLength="3"
                                onChange={(e) => {
                                  console.log(e.target.value);
                                  this.setState({ dob: e.target.value });
                                }}
                                value={this.state.dob || ""}
                                id="dob"
                              />
                              <FormText color="danger" tag="span">
                                {this.state.errorDob}
                              </FormText>
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Label sm="2">* Phone Number</Label>
                          <Col sm="5">
                            <FormGroup>
                              <MaskedInput
                                mask={[
                                  "(",
                                  /[1-9]/,
                                  /\d/,
                                  /\d/,
                                  ")",
                                  "-",
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  "-",
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                ]}
                                className="form-control"
                                autoComplete="off"
                                maxLength="15"
                                placeholder="(xxx)-xxx-xxxx"
                                onChange={(e) => {
                                  //Allows the user to type in any format. Only numbers will be added to the state
                                  let input = e.target.value;
                                  let replacedPhone = input.replace(/[^0-9]/g, "");
                                  this.setState({ phone: replacedPhone });
                                }}
                                value={this.state.phone}
                              />
                              <FormText color="danger" tag="span">
                                {this.state.errorPhone}
                              </FormText>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Label sm="2">* Street Address</Label>
                          <Col sm="5">
                            <FormGroup>
                              <Input
                                type="text"
                                autoComplete="off"
                                onChange={(e) => this.setState({ address: e.target.value })}
                                value={this.state.address}
                              />
                              <FormText color="danger" tag="span">
                                {this.state.errorAddress}
                              </FormText>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Label sm="2">* City</Label>
                          <Col sm="5">
                            <FormGroup>
                              <Input
                                type="text"
                                autoComplete="off"
                                onChange={(e) => this.setState({ city: e.target.value })}
                                value={this.state.city}
                              />
                              <FormText color="danger" tag="span">
                                {this.state.errorCity}
                              </FormText>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Label sm="2">* Province</Label>
                          <Col sm="5">
                            <FormGroup>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="singleSelect"
                                value={this.state.province}
                                onChange={(value) => this.setState({ province: value })}
                                options={[
                                  { value: "Alberta", label: "Alberta" },
                                  { value: "British Columbia", label: "British Columbia" },
                                  { value: "Manitoba", label: "Manitoba" },
                                  { value: "New Brunswick", label: "New Brunswick" },
                                  {
                                    value: "Newfoundland and Labrador",
                                    label: "Newfoundland and Labrador",
                                  },
                                  {
                                    value: "Northwest Territories",
                                    label: "Northwest Territories",
                                  },
                                  { value: "Nova Scotia", label: "Nova Scotia" },
                                  { value: "Nunavut", label: "Nunavut" },
                                  { value: "Ontario", label: "Ontario" },
                                  { value: "Prince Edward Island", label: "Prince Edward Island" },
                                  { value: "Quebec", label: "Quebec" },
                                  { value: "Saskatchewan", label: "Saskatchewan" },
                                  { value: "Yukon", label: "Yukon" },
                                ]}
                                placeholder="Select a province"
                              />
                              <FormText color="danger" tag="span">
                                {this.state.errorProvince}
                              </FormText>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Label sm="2">* Postal Code</Label>
                          <Col sm="5">
                            <FormGroup>
                              <MaskedInput
                                mask={[/[A-Za-z]/, /\d/, /[A-Za-z]/, " ", /\d/, /[A-Za-z]/, /\d/]}
                                className="form-control"
                                autoComplete="off"
                                maxLength="8"
                                placeholder="A1B 2C3"
                                onChange={(e) => {
                                  let input = e.target.value;
                                  let replacedPostalCode = input.replace(/[^a-zA-Z_0-9-]/g, "");
                                  this.setState({ postalCode: replacedPostalCode });
                                }}
                                value={this.state.postalCode}
                              />
                              <FormText color="danger" tag="span">
                                {this.state.errorPostalCode}
                              </FormText>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Label sm="2">* Social Insurance Number</Label>
                          <Col sm="5">
                            <FormGroup>
                              <MaskedInput
                                mask={[
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  " ",
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  " ",
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                ]}
                                className="form-control"
                                autoComplete="off"
                                maxLength="12"
                                placeholder="xxx xxx xxx"
                                onChange={(e) => {
                                  //Allows the user to type in any format. Only numbers will be added to the state
                                  let input = e.target.value;
                                  let replacedSin = input.replace(/[^0-9]/g, "");
                                  this.setState({ sin: replacedSin });
                                }}
                                value={this.state.sin}
                              />
                              <FormText color="danger" tag="span">
                                {this.state.errorSin}
                              </FormText>
                            </FormGroup>
                          </Col>
                        </Row>

                        {/* <Row>
                      <Label sm="2">* Was general employee training completed?</Label>
                      <Col className="checkbox-radios" sm="10">
                        <div className="form-check-radio" style={{ marginTop: 8 }}>
                          <Label check>
                            <Input
                              id="Yes"
                              name="trainingRadio"
                              type="radio"
                              onChange={() => this.setState({ completedTraining: true })}
                            />
                            Yes <span className="form-check-sign" />
                          </Label>
                        </div>
                        <div className="form-check-radio">
                          <Label check>
                            <Input
                              defaultValue
                              id="No"
                              name="trainingRadio"
                              type="radio"
                              onChange={() => this.setState({ completedTraining: false })}
                            />
                            No <span className="form-check-sign" />
                          </Label>
                          <FormText color="danger" tag="span">
                            {this.state.errorCompletedTraining}
                          </FormText>
                        </div>
                      </Col>
                    </Row> */}
                        <Row>
                          <Label sm="2">* Legally Allowed to Work?</Label>
                          <Col className="checkbox-radios" sm="10">
                            <div className="form-check-radio" style={{ marginTop: 8 }}>
                              <Label check>
                                <Input
                                  id="Yes"
                                  name="40hRadio"
                                  type="radio"
                                  onChange={() => this.setState({ legallyWork: true })}
                                />
                                Yes <span className="form-check-sign" />
                              </Label>
                            </div>
                            <div className="form-check-radio">
                              <Label check>
                                <Input
                                  defaultValue
                                  id="No"
                                  name="40hRadio"
                                  type="radio"
                                  onChange={() => this.setState({ legallyWork: false })}
                                />
                                No <span className="form-check-sign" />
                              </Label>
                              <FormText color="danger" tag="span">
                                {this.state.errorLegallyWork}
                              </FormText>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Label sm="2">* Work Status</Label>
                          <Col sm="5">
                            <FormGroup>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="singleSelect"
                                value={this.state.workStatus}
                                onChange={(value) => {
                                  this.setState({ workStatus: value });
                                  if (value.value === 1) {
                                    this.setState({
                                      showWorkPermit: true,
                                      showStudentVisa: false,
                                      image7: null,
                                      image8: null,
                                    });
                                  } else if (value.value === 2) {
                                    this.setState({
                                      showStudentVisa: true,
                                      showWorkPermit: false,
                                      image5: null,
                                      image6: null,
                                    });
                                  } else {
                                    this.setState({
                                      showWorkPermit: false,
                                      showStudentVisa: false,
                                      image5: null,
                                      image6: null,
                                      image7: null,
                                      image8: null,
                                    });
                                  }
                                }}
                                options={workStatusOptions}
                                placeholder="Select a work status"
                              />
                              <FormText color="danger" tag="span">
                                {this.state.errorWorkStatus}
                              </FormText>
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Label sm="2">
                            * Government Issued Photo ID (passport/driver's license)
                          </Label>
                          <Col sm="4">
                            <CardTitle tag="p">* (Front)</CardTitle>
                            <ImageUpload
                              addImageToState={this.addImageToState}
                              imageState="image1"
                              removeImageFromState={this.removeImageFromState}
                            />
                            <FormText color="danger" tag="span">
                              {this.state.errorImage1}
                            </FormText>
                          </Col>
                          {/*
                    <Col sm="4">
                      <CardTitle tag="p">(Back, optional)</CardTitle>
                      <ImageUpload
                        addImageToState={this.addImageToState}
                        imageState="image2"
                        removeImageFromState={this.removeImageFromState}
                      />
                      <FormText color="danger" tag="span">
                      </FormText>
                    </Col>
                    */}
                        </Row>

                        <Row>
                          <Label sm="2">* Proof of Sin</Label>
                          <Col sm="4">
                            <CardTitle tag="p">* (Front)</CardTitle>
                            <ImageUpload
                              addImageToState={this.addImageToState}
                              imageState="image3"
                              removeImageFromState={this.removeImageFromState}
                            />
                            <FormText color="danger" tag="span">
                              {this.state.errorImage3}
                            </FormText>
                          </Col>
                          {/*
                    <Col sm="4">
                      <CardTitle tag="p">(Back, optional)</CardTitle>
                      <ImageUpload
                        addImageToState={this.addImageToState}
                        imageState="image4"
                        removeImageFromState={this.removeImageFromState}
                      />
                      <FormText color="danger" tag="span">
                      </FormText>
                    </Col>*/}
                        </Row>
                        {this._renderWorkPermit()}
                        {this._renderStudentVisa()}

                        <Row>
                          <Label sm="2">Qualifications (Optional)</Label>
                          <Col sm="4">
                            <FormGroup>
                              <Select
                                className="react-select"
                                classNamePrefix="react-select"
                                placeholder=" Qualifications"
                                name="multipleSelect"
                                closeMenuOnSelect={false}
                                isMulti
                                value={this.state.qualifications}
                                onChange={(value) => {
                                  this.setState({
                                    qualifications: value,
                                  });
                                }}
                                options={this.state.qualificationsList}
                              />
                            </FormGroup>
                            <FormText color="danger" tag="span">
                              {this.state.errorQualifications}
                            </FormText>
                          </Col>
                        </Row>
                        <div
                          style={{
                            borderColor: "#D8CBC7",
                            borderStyle: "solid",
                            borderWidth: "0.3px",
                            width: "100%",
                            height: 0,
                            marginTop: "35px",
                            marginBottom: "25px",
                          }}
                        />
                        <Row>
                          <Label sm="2">
                            * Emergency Contact:<br></br>First Name
                          </Label>
                          <Col sm="5">
                            <FormGroup>
                              <Input
                                type="text"
                                autoComplete="off"
                                value={this.state.emergencyFirstName}
                                onChange={(e) =>
                                  this.setState({ emergencyFirstName: e.target.value })
                                }
                              />
                              <FormText color="danger" tag="span">
                                {this.state.errorEmergencyFirstName}
                              </FormText>
                            </FormGroup>
                          </Col>
                          <Label sm="2">* Emergency Contact: Phone Number</Label>
                          <Col sm="3">
                            <FormGroup>
                              <MaskedInput
                                mask={[
                                  "(",
                                  /[1-9]/,
                                  /\d/,
                                  /\d/,
                                  ")",
                                  "-",
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  "-",
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                ]}
                                className="form-control"
                                autoComplete="off"
                                maxLength="15"
                                placeholder="(xxx)-xxx-xxxx"
                                onChange={(e) => {
                                  //Allows the user to type in any format. Only numbers will be added to the state
                                  let input = e.target.value;
                                  let replacedEmergencyPhone = input.replace(/[^0-9]/g, "");
                                  this.setState({ emergencyPhone: replacedEmergencyPhone });
                                }}
                              />
                              <FormText color="danger" tag="span">
                                {this.state.errorEmergencyPhone}
                              </FormText>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Label sm="2">
                            * Emergency Contact:<br></br>Last Name
                          </Label>
                          <Col sm="5">
                            <FormGroup>
                              <Input
                                type="text"
                                autoComplete="off"
                                value={this.state.emergencyLastName}
                                onChange={(e) =>
                                  this.setState({ emergencyLastName: e.target.value })
                                }
                              />
                              <FormText color="danger" tag="span">
                                {this.state.errorEmergencyLastName}
                              </FormText>
                            </FormGroup>
                          </Col>
                          <Label sm="2">* Emergency Contact: Relationship</Label>
                          <Col sm="3">
                            <FormGroup>
                              <Input
                                type="text"
                                autoComplete="off"
                                value={this.state.emergencyRelationship}
                                onChange={(e) =>
                                  this.setState({ emergencyRelationship: e.target.value })
                                }
                              />
                              <FormText color="danger" tag="span">
                                {this.state.errorEmergencyRelationship}
                              </FormText>
                            </FormGroup>
                          </Col>
                        </Row>
                        <div
                          style={{
                            width: "100%",
                            height: 0,
                            marginTop: "35px",
                            marginBottom: "25px",
                          }}
                        />
                        <Row>
                          <Label sm="2">
                            Emergency Contact 2:<br></br>First Name
                          </Label>
                          <Col sm="5">
                            <FormGroup>
                              <Input
                                type="text"
                                autoComplete="off"
                                placeholder="(Optional)"
                                value={this.state.emergencyFirstName2}
                                onChange={(e) =>
                                  this.setState({ emergencyFirstName2: e.target.value })
                                }
                              />
                              <FormText color="danger" tag="span">
                                {this.state.errorEmergencyFirstName2}
                              </FormText>
                            </FormGroup>
                          </Col>
                          <Label sm="2">Emergency Contact 2: Phone Number</Label>
                          <Col sm="3">
                            <FormGroup>
                              <MaskedInput
                                mask={[
                                  "(",
                                  /[1-9]/,
                                  /\d/,
                                  /\d/,
                                  ")",
                                  "-",
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  "-",
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                ]}
                                className="form-control"
                                autoComplete="off"
                                maxLength="15"
                                placeholder="(xxx)-xxx-xxxx"
                                value={this.state.emergencyPhone2}
                                onChange={(e) => {
                                  //Allows the user to type in any format. Only numbers will be added to the state
                                  let input = e.target.value;
                                  let replacedEmergencyPhone2 = input.replace(/[^0-9]/g, "");
                                  this.setState({ emergencyPhone2: replacedEmergencyPhone2 });
                                }}
                              />
                              <FormText color="danger" tag="span">
                                {this.state.errorEmergencyPhone2}
                              </FormText>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Label sm="2">
                            Emergency Contact 2:<br></br>Last Name
                          </Label>
                          <Col sm="5">
                            <FormGroup>
                              <Input
                                type="text"
                                autoComplete="off"
                                placeholder="(Optional)"
                                value={this.state.emergencyLastName2}
                                onChange={(e) =>
                                  this.setState({ emergencyLastName2: e.target.value })
                                }
                              />
                              <FormText color="danger" tag="span">
                                {this.state.errorEmergencyLastName2}
                              </FormText>
                            </FormGroup>
                          </Col>
                          <Label sm="2">Emergency Contact 2: Relationship</Label>
                          <Col sm="3">
                            <FormGroup>
                              <Input
                                type="text"
                                autoComplete="off"
                                placeholder="(Optional)"
                                value={this.state.emergencyRelationship2}
                                onChange={(e) =>
                                  this.setState({ emergencyRelationship2: e.target.value })
                                }
                              />
                              <FormText color="danger" tag="span">
                                {this.state.errorEmergencyRelationship2}
                              </FormText>
                            </FormGroup>
                          </Col>
                        </Row>
                        <div
                          style={{
                            borderColor: "#D8CBC7",
                            borderStyle: "solid",
                            borderWidth: "0.3px",
                            width: "100%",
                            height: 0,
                            marginTop: "35px",
                            marginBottom: "25px",
                          }}
                        />
                      </>
                    )}
                    <Row>
                      <Col sm="9"></Col>
                      <Col sm="3">
                        <Button color="success" onClick={() => this.handleSubmit()}>
                          Create Staff
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}

        {this.state.alert}
        {this.viewPermissionInfoModal()}
      </div>
    );
  }
}

export default CreateNewStaff;
