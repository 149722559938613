import React, { useReducer, useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import Error404 from "views/Staffing/pages/Error404.jsx";
import Dashboard from "views/Staffing/dashboard/Dashboard.jsx";
import Profile from "views/Staffing/agency-profile/ProfilePage";
import StaffingContext from "../../context/StaffingContext";

import BusinessDetail from "views/Staffing/business-detail/businessDetail.jsx";
import { useContext } from "react";

function Admin(props) {
  const staffingContext = useContext(StaffingContext);
  useEffect(() => {
    if (staffingContext.staffing.user && JSON.parse(localStorage.Token)) {
      //TODO:Verify token
      console.log(staffingContext.staffing.user, localStorage.Token);
      //if valid Continue
      const user = staffingContext.staffing.user;
    } else {
      // No user, lets logout
      props.history.push(`/staffing/login`);
    }
  }, []);
  return (
    <Switch>
      <Route path="/staffing/admin" exact render={(props) => <Dashboard {...props} />} />
      <Route path="/staffing/admin/dashboard" exact render={(props) => <Dashboard {...props} />} />
      <Route path="/staffing/admin/profile" exact render={(props) => <Profile {...props} />} />
      <Route
        path="/staffing/admin/:businessId"
        exact
        render={(props) => <BusinessDetail {...props} />}
      />
      <Route render={(props) => <Error404 {...props} />} />
    </Switch>
  );
}

export default Admin;
