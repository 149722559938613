/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

import { Button } from "reactstrap";

import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";

// import { store, fs } from "../Firebase/firebase";

import * as constants from "constants.js";

class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
      hdId: 0,
      alert: null,
    };
  }

  componentDidMount() {
    // fs.collection("counters")
    //   .doc("users")
    //   .get()
    //   .then((doc) => {
    //     this.setState({ hdId: doc.data().total + constants.systemUserIdStart });
    //   });
  }

  handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
      let regExpPng = "^[d][a][t][a][:][i][m][a][g][e][/][p][n][g]";
      let regExpJpg = "^[d][a][t][a][:][i][m][a][g][e][/][j][p][g]";
      let regExpJpeg = "^[d][a][t][a][:][i][m][a][g][e][/][j][p][e][g]";
      console.log("reader result jpeg ", reader.result.match(regExpJpeg));
      console.log("reader result jpg ", reader.result.match(regExpJpg));
      console.log("reader result png ", reader.result.match(regExpPng));

      if (
        reader.result.match(regExpPng) === null &&
        reader.result.match(regExpJpeg) === null &&
        reader.result.match(regExpJpg) === null
      ) {
        console.log("this image isnt a jpeg/jpg or png file!");
      } else {
        console.log("jpeg/jpg or png detected");
        this.props.addImageToState(this.props.imageState, file); // Sends image data to the parent component as soon as it changed. Putting function here to ensure no errors.
      }
    };
    reader.readAsDataURL(file);
  };

  handleSubmit = () => {
    let { file } = this.state;
    /*let uploadTask = store
      .ref(`hdId:${this.state.hdId}/${file.name}`)
      .put(file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        console.log(error);
        alert("something wrong");
      },
      this.uploadSuccessedAlert()
      // this.state.file is the file/image uploaded
      // in this function you can save the image (this.state.file) on form submit
      // you have to call it yourself
    );*/
  };

  handleClick = () => {
    this.refs.fileInput.click();
  };

  handleRemove = () => {
    this.setState({
      file: null,
      imageName: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
    });
    this.props.removeImageFromState(this.props.imageState);
    this.refs.fileInput.value = null;
  };

  render() {
    return (
      <div className="fileinput text-center">
        <input
          type="file"
          onChange={this.handleImageChange}
          ref="fileInput"
          accept="image/x-png,image/jpeg"
        />
        <div className={"thumbnail" + (this.props.avatar ? " img-circle" : "")}>
          <img src={this.state.imagePreviewUrl} alt="..." />
        </div>
        <div>
          {this.state.file === null ? (
            <Button className="btn-round" onClick={() => this.handleClick()}>
              {this.props.avatar ? "Add Photo" : "Select image"}
            </Button>
          ) : (
            <span>
              <Button className="btn-round" onClick={() => this.handleClick()}>
                Change
              </Button>
              {this.props.avatar ? <br /> : null}
              <Button color="danger" className="btn-round" onClick={() => this.handleRemove()}>
                <i className="fa fa-times" />
                Remove
              </Button>
            </span>
          )}
        </div>
      </div>
    );
  }
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
};

export default ImageUpload;
