import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AgencyContext from "context/AgencyContext";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Spinner,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Form,
  FormText,
  Button,
} from "reactstrap";
import ReactDatetime from "react-datetime";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import api from "components/API/api";

export default function ChequePickupSettings() {
  const agencyContext = useContext(AgencyContext);
  const [isSameStart, setIsSameStart] = useState(false);
  const [isSameEnd, setIsSameEnd] = useState(false);

  const [defaultSlotInterval, setDefaultSlotInterval] = useState(15);
  const [noPeopleAllowed, setNoPeopleAllowed] = useState(15);

  const [rules, setRules] = useState(null);

  const [error, setError] = useState({
    // shiftTypeError: "",
    // dateError: "",
    availabilityError: "",
    defaultSlotIntervalError: "",
    defaultNOPeopleAllowedError: "",
  });

  const [alert, setAlert] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [availability, setAvailability] = useState([
    {
      // 0: Monday
      active: false,
      day: 0,
      start: null,
      end: null,
    },
    {
      active: false,
      day: 1,
      start: null,
      end: null,
    },
    {
      active: false,
      day: 2,
      start: null,
      end: null,
    },
    {
      active: false,
      day: 3,
      start: null,
      end: null,
    },
    {
      active: false,
      day: 4,
      start: null,
      end: null,
    },
    {
      active: false,
      day: 5,
      start: null,
      end: null,
    },
    {
      // 6: Sunday
      active: false,
      day: 6,
      start: null,
      end: null,
    },
  ]);

  const history = useHistory();

  const toggleCheckMark = (day) => {
    setAvailability((prevAvailability) => {
      return prevAvailability.map((a, i) => {
        let _a = prevAvailability.find((a) => a.active && a.start);
        let _aEnd = prevAvailability.find((a) => a.active && a.end);

        a.active = i === day ? !a.active : a.active;
        if (i === day && _a && a.active && isSameStart) {
          a.start = _a.start;
        }
        if (i === day && _aEnd && a.active && isSameEnd) {
          a.end = _aEnd.end;
        }
        return a;
      });
    });
  };

  const onInitialize = () => {
    history.push(`/${agencyContext.agency.agency.slug}/admin/cheque-pickup-calendar`);
  };

  const _renderAvailability = (dayOfWeekText, day) => {
    return (
      <Row>
        <Col sm="2">
          <FormGroup check inline>
            <Label check>
              <Input
                type="checkbox"
                onChange={() => toggleCheckMark(day)}
                checked={
                  availability[day].active
                  // true
                }
                id={dayOfWeekText === "Monday" ? "availability" : null}
              />
              <span className="form-check-sign" />
              {dayOfWeekText}
            </Label>
          </FormGroup>
        </Col>

        {availability[day].active ? (
          <Col sm="8">
            <FormGroup check inline>
              <Label check>
                {/* <Input
                    type="checkbox"
                    checked={availability[day].m}
                    onChange={() => toggleShifts("m", day)}
                  />
                  <span className="form-check-sign" /> */}
                Start
                <ReactDatetime
                  // initialValue={availability[day].start}
                  value={availability[day].start}
                  onChange={(date) => {
                    setAvailability((prevAvailability) => {
                      return prevAvailability.map((a, i) => {
                        a.start = !isSameStart ? (i === day ? date : a.start) : date;
                        return a;
                      });
                    });
                  }}
                  dateFormat={false}
                />
              </Label>
            </FormGroup>

            <FormGroup check inline>
              <Label check>
                {/* <Input
                    type="checkbox"
                    checked={availability[day].a}
                    onChange={() => toggleShifts("a", day)}
                  />
                  <span className="form-check-sign" /> */}
                End
                <ReactDatetime
                  value={availability[day].end}
                  onChange={(date) => {
                    setAvailability((prevAvailability) => {
                      return prevAvailability.map((a, i) => {
                        a.end = !isSameEnd ? (i === day ? date : a.end) : date;
                        return a;
                      });
                    });
                  }}
                  dateFormat={false}
                />
              </Label>
            </FormGroup>
          </Col>
        ) : null}
      </Row>
    );
  };

  const getAppointmentRule = async function () {
    setIsLoading(true);
    try {
      let { data } = await api().get(`/appointment-rule/${agencyContext.agency.agency._id}`);
      console.log(data);
      setAvailability(data.defaultTimes.map((times) => ({ ...times, active: true })));
      setRules(data);
      setDefaultSlotInterval(data.defaultSlotInterval);
      setNoPeopleAllowed(data.noPeopleAllowed);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let hasError = false;
    // console.log(data);
    //Setting initial error in tempErrors
    let tempErrors = { ...error };

    availability.forEach((a) => {
      if (a.active) {
        if (!a.start || !a.end) {
          hasError = true;
          tempErrors = {
            ...tempErrors,
            recurrentError: "* Must have both start and end time for active recurring days",
          };
        }
      }
    });

    if (!defaultSlotInterval || defaultSlotInterval <= 5) {
      tempErrors = {
        ...tempErrors,
        defaultSlotIntervalError: "*Must set default slot interval of 10 minutes",
      };
      hasError = true;
    } else {
      tempErrors = { ...tempErrors, defaultSlotIntervalError: "" };
    }

    if (!noPeopleAllowed || noPeopleAllowed <= 0) {
      tempErrors = { ...tempErrors, defaultSlotIntervalError: "*Must set atleast 1 person" };
      hasError = true;
    } else {
      tempErrors = { ...tempErrors, defaultSlotIntervalError: "" };
    }

    if (hasError) {
      setError({ ...error, ...tempErrors });
      return;
    } else {
      let sendData = {
        defaultSlotInterval: defaultSlotInterval,
        defaultTimes: availability.map((a) => {
          let _temp = { day: a.day };
          _temp.start = { hour: moment(a.start).hour(), min: moment(a.start).minute() };
          _temp.end = { hour: moment(a.end).hour(), min: moment(a.end).minute() };
          return _temp;
        }),
        noPeopleAllowed: noPeopleAllowed,
        agency: agencyContext.agency.agency._id,
        timeZone: moment.tz.guess(),
      };

      console.log(sendData);
      prepareAndSendDataToServer(sendData);
    }
  };

  useEffect(() => {
    getAppointmentRule();
  }, []);

  const prepareAndSendDataToServer = async (data) => {
    setIsLoading(true);

    try {
      await api().post("appointment-rule", data);
      setAlert(
        <SweetAlert
          success
          title="Successfully Initialized booking system. Please allow up to a day for the system to run properly."
          onConfirm={() => {
            hideAlert();
            onInitialize(true);
          }}
        />,
      );
      setIsLoading(false);
    } catch (error) {
      setAlert(
        <SweetAlert error title="Error initializing booking system." onConfirm={hideAlert} />,
      );
      setIsLoading(false);
      console.log(error);
    }
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const loadingOverlay = () => {
    return (
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
          background: "transparent",
        }}
        title={<Spinner color="light" style={{ width: "3rem", height: "3rem" }} />}
        onConfirm={hideAlert}
        showConfirm={false}
      />
    );
  };

  return (
    <>
      {isLoading && loadingOverlay()}
      <Row className="pt-5">
        <Col md="12">
          <Card className="no-transition">
            <CardHeader>
              <CardTitle tag="h4">
                {rules ? "Update Booking System" : "Initialize Booking System"}
              </CardTitle>
            </CardHeader>
            <CardBody>
              {rules && "Updates will take place after 2 weeks."}
              <Form action="/" className="form-horizontal" method="get">
                <Row>
                  <Label sm="2">Recurring Days</Label>
                  <Col className="checkbox-radios" sm="10">
                    <Row>
                      <Col sm="2">
                        <FormGroup check inline>
                          <Label check>
                            <Input
                              type="checkbox"
                              onChange={() =>
                                setAvailability((prevAvailability) => {
                                  let fullyAvailable =
                                    prevAvailability.filter((a) => a.active).length >= 7;
                                  let _a1 = prevAvailability.find((a) => a.active && a.start);
                                  let _a2 = prevAvailability.find((a) => a.active && a.end);
                                  return prevAvailability.map((a) => {
                                    if (fullyAvailable) {
                                      a.active = false;
                                    } else {
                                      a.active = true;
                                      if (isSameStart && _a1) a.start = _a1.start;

                                      if (isSameEnd && _a2) a.end = _a2.end;
                                    }

                                    return a;
                                  });
                                })
                              }
                              checked={availability.filter((a) => a.active).length >= 7}
                              id="allAvailability"
                            />
                            <span className="form-check-sign" />
                            All
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col sm="2">
                        <FormGroup check inline>
                          <Label check>
                            <Input
                              type="checkbox"
                              onChange={() => {
                                let sameTime = !isSameStart;
                                sameTime &&
                                  setAvailability((prevAvailability) => {
                                    //finding start and end time for all other active shifts
                                    let _a = prevAvailability.find((a) => a.active && a.start);

                                    return prevAvailability.map((a) => {
                                      if (a.active && _a) {
                                        a.start = _a.start;
                                      }

                                      return a;
                                    });
                                  });
                                setIsSameStart(sameTime);
                              }}
                              checked={isSameStart}
                              id="sameStart"
                            />
                            <span className="form-check-sign" />
                            Same Start Time
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col sm="2">
                        <FormGroup check inline>
                          <Label check>
                            <Input
                              type="checkbox"
                              onChange={() => {
                                let sameTime = !isSameEnd;
                                sameTime &&
                                  setAvailability((prevAvailability) => {
                                    let _aEnd = prevAvailability.find((a) => a.active && a.end);
                                    return prevAvailability.map((a) => {
                                      if (a.active && _aEnd) {
                                        a.end = _aEnd.end;
                                      }
                                      return a;
                                    });
                                  });
                                setIsSameEnd(sameTime);
                              }}
                              checked={isSameEnd}
                              id="sameEnd"
                            />
                            <span className="form-check-sign" />
                            Same End Time
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>

                    {_renderAvailability("Monday", 0)}
                    {_renderAvailability("Tuesday", 1)}
                    {_renderAvailability("Wednesday", 2)}
                    {_renderAvailability("Thursday", 3)}
                    {_renderAvailability("Friday", 4)}
                    {_renderAvailability("Saturday", 5)}
                    {_renderAvailability("Sunday", 6)}
                    <FormText color="danger" tag="span">
                      {error.recurrentError}
                    </FormText>
                  </Col>
                </Row>

                <Row style={{ paddingTop: "4px" }}>
                  <Label sm="3">* Time slot interval</Label>
                  <Col sm="6">
                    <Input
                      type="number"
                      value={defaultSlotInterval}
                      onChange={(e) => {
                        setDefaultSlotInterval(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row style={{ paddingTop: "4px" }}>
                  <Label sm="3">* Number of people allowed for a time slot</Label>
                  <Col sm="6">
                    <Input
                      type="number"
                      value={noPeopleAllowed}
                      onChange={(e) => {
                        setNoPeopleAllowed(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row style={{ paddingTop: "4px" }}>
                  <Col sm="9"></Col>
                  <Col sm="3">
                    {!isLoading ? (
                      rules ? (
                        <Button
                          color="success"
                          onClick={() => {
                            setAlert(
                              <SweetAlert
                                warning
                                title="This feature is coming soon!"
                                onConfirm={() => {
                                  hideAlert();
                                  // onInitialize(true);
                                }}
                              />,
                            );
                          }}
                        >
                          Update
                        </Button>
                      ) : (
                        <Button color="success" onClick={handleSubmit}>
                          Initialize
                        </Button>
                      )
                    ) : null}
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {alert})
    </>
  );
}
