import React, { Component } from "react";
import Select from "react-select";
//import { Prompt } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import MaskedInput from "react-text-mask";
import Resizer from "react-image-file-resizer";
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Spinner,
} from "reactstrap";

import { validateEmail, validateSin } from "components/Utilities/utilities.js";
import axios from "axios";

import api from "components/API/api";
import { Modal } from "@mui/material";

import permissionsGuideList from "../PermissionsGuide.jsx";
import RenderPermission from "../RenderPermission.jsx";
import { callCentreStaffPermissionTemplate } from "../permissionTemplate.js";
import roles from "enums/role";



class CreateCallCentreStaff extends Component {
  constructor(props) {
    super(props);
    this.defaultState = {
      progress: 0,
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      password: null,
      role: "Call Centre Staff",
      //Errors
      errorFirstName: "",
      errorLastName: "",
      errorPhone: "",
      errorEmail: "",
      errorPassword: "",
      errorRole: "",
      // profile Data
      permissionsList: callCentreStaffPermissionTemplate,
      selectedPermission: [],
      isLoading: false,

      //modal data
      viewPermissionInfoModalOpen: false,
    };
    this.state = this.defaultState;
  }

  // componentDidMount() {
  //  this.fetchProfiles();
  // }

  componentWillUnmount() {
    //window.removeEventListener("beforeunload", this.handleUserLeave);
  }

  componentDidUpdate() {
    //console.log(this.state.permissionsList, this.state.role);
  }

  handleUserLeave = (e) => {
    const confirmationMessage = "Data would be sent if you leave the page";
    (e || window.event).returnValue = confirmationMessage;
    return confirmationMessage;
  };

  handleSubmit = () => {
    let tempState = this.state; // Copy of state
    let hasError = false;
    // Start of Error Handling
    if (tempState.firstName == null || tempState.firstName.trim() == "") {
      this.setState({ errorFirstName: "* Must have a first name." });
      hasError = true;
    } else {
      this.setState({ errorFirstName: "" });
    }

    if (tempState.lastName == null || tempState.lastName.trim() == "") {
      this.setState({ errorLastName: "* Must have a last name." });
      hasError = true;
    } else {
      this.setState({ errorLastName: "" });
    }

    if (tempState.phone == null || tempState.phone.trim() == "") {
      this.setState({ errorPhone: "* Must have a phone number." }); // No phone number
      hasError = true;
    } else {
      if (tempState.phone.length != 10 || tempState.phone < 0) {
        this.setState({
          errorPhone: "* Please input a valid phone number in the format ###-###-####.",
        }); //invalid phone number
        hasError = true;
      } else {
        this.setState({ errorPhone: "" });
      }
    }

    if (tempState.email == null || tempState.email.trim() == "") {
      this.setState({ errorEmail: "* Must have an email" }); // No email text
    } else {
      if (tempState.email != null && validateEmail(tempState.email) === false) {
        this.setState({ errorEmail: "* Please input a valid email" }); //invalid email text
        hasError = true;
      } else {
        this.setState({ errorEmail: "" });
      }
    }

    if (tempState.password == null || tempState.role == "") {
      this.setState({ errorPassword: "* Must have a default Password." });
      hasError = true;
    } else {
      this.setState({ errorPassword: "" });
    }

    if (tempState.role == null || tempState.role == "") {
      this.setState({ errorRole: "* Must set user role. " });
      hasError = true;
    } else {
      this.setState({ errorRole: "" });
    }

    if (hasError) {
      this.setState({
        alert: (
          <SweetAlert
            warning
            title="Please ensure all mandatory fields (*) are filled"
            onConfirm={this.hideAlert}
          />
        ),
      });
      return;
    } else {
      this.prepareAndSendDataToServer(tempState); // runs function after loading is set as true.
    }
  };

  prepareAndSendDataToServer = async (tempState) => {
    this.setState({ isLoading: true }); // Start the loading
    // to load the values again after creating manager without fectching again
    let ReloadPermissionsList = this.state.permissionsList;
    //Upload the changelog into the deeper user history collection! This uploads the admin email, timestamp, and action taken
    let permissionsArray = [];

    console.log(tempState.permissionsList);

    if (tempState.permissionsList) {
      let array = this.state.permissionsList;
      array.map((m) => {
        if (m.active) {
          let module = m.permission;
          m.categories.map((a) => {
            // to filter none from actions
            if (a.active && !(a.action === "None")) {
              let permission = {
                module: module,
                action: a.type,
              };
              permissionsArray.push(permission);
            }
          });
        }
      });
    }

    console.log(permissionsArray);
    let dataToSend = {
      firstName: tempState.firstName,
      lastName: tempState.lastName,
      phone: tempState.phone,
      email: tempState.email,
      password: tempState.password,
      profileType: "CallCentreStaff",
      roleDisplayName: tempState.role,
      permissions: permissionsArray,
    };

    try {
      const { data } = await api().post("/users/register-call-centre-staff", dataToSend);
      //to make all permission to default active state
      this.setState({
        ...this.defaultState,
        permissionsList: callCentreStaffPermissionTemplate,
        isLoading: false,
        alert: (
          <SweetAlert
            success
            title="Call Centre Staff successfully created"
            onConfirm={this.hideAlert}
          />
        ),
      });
    } catch (error) {
      this.setState({ isLoading: false }); // Stop the loading
      this.setState({
        alert: (
          <SweetAlert
            error
            title={error?.response?.data?.error?.message || error.message}
            onConfirm={this.hideAlert}
          />
        ),
      });
    }
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  loadingOverlay = () => {
    return (
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
          background: "transparent",
        }}
        title={<Spinner color="light" style={{ width: "3rem", height: "3rem" }} />}
        onConfirm={this.hideAlert}
        showConfirm={false}
      />
    );
  };

  handleOpenViewPermissionInfoModal = () => {
    this.setState({
      viewPermissionInfoModalOpen: true,
    });
  };

  handleCloseViewPermissionInfoModal = () => {
    this.setState({
      viewPermissionInfoModalOpen: false,
    });
  };

  viewPermissionInfoModal = () => {
    return (
      <Modal
        open={this.state.viewPermissionInfoModalOpen}
        onClose={this.handleCloseViewPermissionInfoModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          overflow: "scroll",
          marginTop: 20,
        }}
      >
        <FormGroup style={{ width: 1000, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>Guide to User Permissions</h5>
            </div>
            <div>
              {permissionsGuideList.map((permission) => {
                return (
                  <>
                    <Row style={{ paddingBottom: 15 }} md="10">
                      <Col md="2">
                        <span style={{ fontWeight: "bold" }}>{permission.name}</span>
                      </Col>
                      <Col md="10">{permission.description}</Col>
                    </Row>
                  </>
                );
              })}
            </div>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <Button onClick={() => this.handleCloseViewPermissionInfoModal()}>Close</Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  render() {
    return (
      <div className="content">
        {this.state.isLoading ? (
          this.loadingOverlay()
        ) : (
          <Row>
            <Col md="12">
              <Card className="no-transition">
                <CardHeader>
                  <Row>
                    <Col md="8">
                      <CardTitle tag="h4">Create New Call Centre Staff</CardTitle>
                    </Col>
                    <Col md="4">
                      <FormGroup align="right">
                        <Button
                          onClick={() => this.handleOpenViewPermissionInfoModal()}
                          color="info"
                        >
                          Permissions Guide
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form action="/" className="form-horizontal" method="get">
                    <Row>
                      <Label sm="2">* First Name</Label>

                      <Col sm="5">
                        <FormGroup>
                          <Input
                            type="text"
                            autoComplete="off"
                            onChange={(e) => this.setState({ firstName: e.target.value })}
                          />
                          <FormText color="danger" tag="span">
                            {this.state.errorFirstName}
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">* Last Name</Label>
                      <Col sm="5">
                        <FormGroup>
                          <Input
                            type="text"
                            autoComplete="off"
                            onChange={(e) => this.setState({ lastName: e.target.value })}
                          />
                          <FormText color="danger" tag="span">
                            {this.state.errorLastName}
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Label sm="2">* Phone Number</Label>
                      <Col sm="5">
                        <FormGroup>
                          <MaskedInput
                            mask={[
                              "(",
                              /[1-9]/,
                              /\d/,
                              /\d/,
                              ")",
                              "-",
                              /\d/,
                              /\d/,
                              /\d/,
                              "-",
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                            ]}
                            className="form-control"
                            autoComplete="off"
                            maxLength="15"
                            onChange={(e) => {
                              //Allows the user to type in any format. Only numbers will be added to the state
                              let input = e.target.value;
                              let replacedPhone = input.replace(/[^0-9]/g, "");
                              this.setState({ phone: replacedPhone });
                            }}
                          />
                          <FormText color="danger" tag="span">
                            {this.state.errorPhone}
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">* Email</Label>
                      <Col sm="5">
                        <FormGroup>
                          <Input
                            type="email"
                            autoComplete="off"
                            onChange={(e) => this.setState({ email: e.target.value })}
                          />
                          <FormText color="danger" tag="span">
                            {this.state.errorEmail}
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">* Password</Label>
                      <Col sm="5">
                        <FormGroup>
                          <Input
                            placeholder="Minimum 8 digits required"
                            type="password"
                            autoComplete="off"
                            onChange={(e) => this.setState({ password: e.target.value })}
                          />
                          <FormText color="danger" tag="span">
                            {this.state.errorPassword}
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">* Role</Label>
                      <Col sm="5">
                        <FormGroup>
                          <Input
                            type="text"
                            autoComplete="off"
                            onChange={(e) => {
                              //
                              this.setState({ role: e.target.value });
                            }}
                            disabled
                            value={this.state.role}
                          />
                          <FormText color="danger" tag="span">
                            {this.state.errorRole}
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>

                    {/* {this.state.role ? ( */}
                    <Row>
                      <Label sm="2"> Permissions</Label>
                      <Col className="checkbox-radios" sm="10">
                        {this.state.permissionsList.map((p, i) => {
                          return (
                            <RenderPermission
                              disabled = {true}
                              displayName={p.displayName}
                              categories={p.categories}
                              active={p.active}
                              onChange={(active, categories) => {
                                //to make sure only a selected permission gets updated
                                let temp = [...this.state.permissionsList];
                                let x = temp.find((t) => {
                                  return t.permission === p.permission;
                                });
                                x.active = active;
                                x.categories = categories;
                                this.setState({
                                  permissionsList: temp,
                                });
                              }}
                              key={i}
                            />
                          );
                        })}
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="9"></Col>
                      <Col sm="3">
                        <Button color="success" onClick={() => this.handleSubmit()}>
                          Create Call Centre Staff
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}

        {this.state.alert}
        {this.viewPermissionInfoModal()}
      </div>
    );
  }
}

export default CreateCallCentreStaff;
