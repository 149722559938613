import React, { useContext, useState, useEffect } from "react";
import { Delete, Lock, LockOpen, Warning } from "@mui/icons-material";
import api from "components/API/api";
import RenderAvailability from "components/Availability/RenderAvailability.jsx";
import Permission from "components/permissions/Permission.jsx";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import MaskedInput from "react-text-mask";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import AgencyContext from "../../../context/AgencyContext";
import PinComponent from "./AllUsers/PinComponent";
import { provinceOptions, workStatusOptions } from "./allUserUtilities";
import ChatTab from "./UserProfile/Messages/ChatTab.jsx";
import CommentsTab from "./UserProfile/Comments.jsx";
// import ChangeLog from "./UserProfile/ChangeLog";
import UserShiftHistory from "./UserProfile/UserShiftHistory.jsx";
import UserTrainingHistory from "./UserProfile/UserTrainingHistory.jsx";
import { PermissionActionEnum } from "../AllStaff/permissionTemplate";
import UserRideHistory from "./UserProfile/UserDriverRideHistory";
import Images from "./UserProfile/Images";
import UserChequeHistory from "./UserProfile/UserChequeHistory";
import ReactDatetime from "react-datetime";
import { handleSubmitErrors, prepareAndSendDataToServer } from "./UserProfile/SaveChangesFunctions";
import {
  genderOptions,
  legalToWorkOptions,
  maskedPostalCode,
  tabList,
  userProfileSpinner,
  USER_PROFILE_MODULE_TYPE,
} from "./UserProfile/UserProfileConstants";
import { handleAddComment } from "./UserProfile/AddComment";
import UserProfileCommonModal from "./UserProfile/UserProfileCommonModal";
import UserAppointments from "./UserProfile/UserAppointments";

function UserProfile(props) {
  const agencyContext = useContext(AgencyContext);
  const [userDeactivated, setUserDeactivated] = useState(false);
  const [userNotFound, setUserNotFound] = useState(false);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    dob: "",
    age: "",
    gender: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    province: "",
    postalCode: "",
    sin: "",
    sinExpiry: null,
    workStatus: null,
    verified: false,
    qualifications: [],
    workerPin: [],
    legallyWork: "",
    availability: [],
    lastAppActive: null,
    blockChat: false,
    emergencyFirstName: "",
    emergencyLastName: "",
    emergencyPhone: "",
    emergencyRelationship: "",
    suspendedFor: null,
    suspendedDate: null,
    totalWarning: 0,
    suspend: null,
    warn: null,
    documents: [],
  });

  const [qualificationsList, setQualificationsList] = useState([]);
  const [commentHistory, setCommentHistory] = useState([]);
  const [addComment, setAddComment] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [pageTabs, setPageTabs] = useState("editProfile");
  const [openCommonModal, setOpenCommonModal] = useState(false);
  const [modalType, setModalType] = useState(null);

  //error handling
  const [error, setError] = useState({
    hdId: "",
    firstName: "",
    lastName: "",
    pinNumber: "",
    badgeNumber: "",
    availability: "",
    gender: "",
    dob: "",
    age: "",
    phone: "",
    email: "",
    address: "",
    city: "",
    province: "",
    postalCode: "",
    sin: "",
    duplicated: null,
    sinRegistrationNumber: "",
    sinExpiry: "",
    qualifications: "",
    legallyWork: "",
    workStatus: "",
    emergencyFirstName: "",
    emergencyLastName: "",
    emergencyPhone: "",
    emergencyRelationship: "",
    emergencyFirstName2: "",
    emergencyLastName2: "",
    emergencyPhone2: "",
    emergencyRelationship2: "",
    addComment: "",
    smsBlocked: "",
  });

  const [userFetched, setUserFetched] = useState(false);
  const [qualificationFetched, setQualificationFetched] = useState(false);
  const [businessFetched, setBusinessFetched] = useState(false);
  const [businesses, setBusinesses] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState([]);
  const [originalBusiness, setOriginalBusiness] = useState([]);
  const [bannedBusinessIds, setBannedBusinessIds] = useState([]);
  const [bannedBusiness, setBannedBusiness] = useState([]);
  const [loggedUserRole, setLoggedUserRole] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [alert, setAlert] = useState(null);
  const userParamId = props.match.params.id;

  useEffect(() => {
    loadUser();
    loadQualificationsList();
    if (props.location.search.includes("tab=chat")) {
      setPageTabs("chat");
    }
    const role = agencyContext.agency?.profile?.role;
    setLoggedUserRole(role);
    setDisabled(role === "CallCentreStaff" ? true : false);
  }, []);

  useEffect(() => {
    if (pageTabs && pageTabs === "comments") {
      loadCommentHistory();
    }

    if (userFetched && qualificationFetched && businessFetched) {
      setUserData({
        ...userData,
        qualifications: userData.qualifications.map((q) => {
          return qualificationsList.find((_q) => _q.value === q);
        }),
        workerPin: userData.workerPin
          .map((w) => {
            let business = businesses.find((b) => b._id === w.business);
            w.businessName = business && business.name;
            return w;
          })
          .filter((w) => w.businessName),
      });
    }
  }, [pageTabs, userFetched, qualificationFetched, businessFetched]);

  const handleSubmit = () => {
    setIsLoading(true);
    let hasError = false;
    let tempError = error;

    handleSubmitErrors({ userData, hasError, tempError });

    if (hasError) {
      setIsLoading(false);
      setError(tempError);
      return;
    } else {
      prepareAndSendDataToServer({
        userParamId,
        userData,
        setIsLoading,
        setAlert,
        hideAlert,
      });
    }
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const loadingOverlay = () => {
    return (
      <div style={{ display: "flex" }}>
        <Spinner color="primary" style={userProfileSpinner} />
      </div>
    );
  };

  const loadQualificationsList = () => {
    api()
      .get("/qualification")
      .then((res) => {
        const data = res.data?.qualifications;
        let tempArray = [];
        data.map((q) => {
          tempArray.push({
            label: q.name,
            value: q._id,
          });
        });
        setQualificationFetched(true);
        setQualificationsList(tempArray);
      })
      .catch((err) => console.log(err));
  };

  const loadCommentHistory = () => {
    setIsLoading(true);
    api()
      .get(`/user-note?userId=${props.match.params.id}&fieldType=User`)
      .then((res) => {
        setIsLoading(false);
        const data = res.data.notes;
        let tempArray = [];
        data.map((q) => {
          let tempDate = q.createdAt.split(".")[0];
          tempArray.push({
            noteBy: q.noteBy,
            note: q.note,
            time: tempDate.split("T")[0] + " " + tempDate.split("T")[1],
          });
        });

        setCommentHistory(tempArray);
      })
      .catch((err) => console.log(err));
  };

  const loadUser = async () => {
    setIsLoading(false);
    try {
      // load business first
      const tempOriginalBusinessList = await loadBusiness();
      const { data } = await api().get(`/users/worker/${props.match.params.id}`);
      const user = data;
      const userDeactivated = user.profile?.length
        ? user.profile.some((profile) => !profile.active)
        : false;

      setUserDeactivated(userDeactivated);

      setUserData({
        ...userData,
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        gender:
          user.gender === "F"
            ? { label: "Female", value: "F" }
            : { label: "Male", value: "M" } || "",
        email: user.email || "",
        phone: user.phone || "",
        address: user.address || "",
        city: user.city || "",
        province: { value: user.province, label: user.province } || "",
        postalCode: user.postalCode || "",
        sin: user.sin || "",
        workStatus: workStatusOptions.find((s) => s.value === user.workStatus),
        verified: user?.verified ? true : false,
        blockChat: user?.blockChat ? true : false,
        dob: moment(user.dob).add(5, "hours").format("MM-DD-YYYY") || "",
        age: moment().diff(user.dob, "years") || "",
        duplicated: data.duplicated,
        workerPin: data.workerPin,
        hdId: data.hdId,
        availability: data.availability,
        suspendedFor: data.suspendedFor,
        suspendedDate: data.suspendedDate,
        totalWarning: data.totalWarning,
        sinExpiry: data?.sinExpiry ? moment(data.sinExpiry).format("YYYY-MM-DD") : "",
        documents: data.documents || [],
        lastAppActive: data.lastAppActive, // last app active state
        qualifications: data.qualifications,
        emergencyFirstName: user.emergency[0]?.firstName || "",
        emergencyLastName: user.emergency[0]?.lastName || "",
        emergencyPhone: user.emergency[0]?.phone || "",
        emergencyRelationship: user.emergency[0]?.relationship || "",
        emergencyFirstName2: user.emergency[1]?.firstName || "",
        emergencyLastName2: user.emergency[1]?.lastName || "",
        emergencyPhone2: user.emergency[1]?.phone || "",
        emergencyRelationship2: user.emergency[1]?.relationship || "",
        legallyWork: user.legallyWork
          ? { label: "Yes", value: true }
          : { label: "No", value: true },
      });

      setUserFetched(true);

      setBannedBusiness(
        data.userBans?.length
          ? data.userBans.map((business) => {
              let search = tempOriginalBusinessList?.find((b) => b.value === business)?.label;
              if (search) return search;
            })
          : [],
      );

      setBannedBusinessIds(
        data.userBans?.length
          ? data.userBans.map((business) => {
              let search = tempOriginalBusinessList?.find((b) => b.value === business);
              if (search) return search;
            })
          : [],
      );

      setBusinesses(
        data.userBans?.length
          ? tempOriginalBusinessList?.filter((business) => {
              let search = data.userBans.find((b) => b === business._id);
              if (search) return false;
              else return true;
            })
          : tempOriginalBusinessList,
      );

      let availability = user.availability;
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setUserNotFound(true);
    }
  };

  const loadBusiness = async () => {
    try {
      const { data } = await api().get("/business/list");
      const tempData = data.map((d) => ({
        ...d,
        value: d._id,
        label: d.name,
      }));
      setOriginalBusiness(tempData);
      setBusinesses(tempData);
      setBusinessFetched(true);
      return tempData;
    } catch (error) {
      console.log("Failed to load business", error);
    }
  };

  const handleOpenCommonModal = (modalType) => {
    setOpenCommonModal(true);
    setModalType(modalType);
  };

  //Renders the nav for each of the tabs.
  const renderNavTabs = () => {
    return (
      <>
        {tabList.map((tab, i) => {
          if (tab.id === "images") {
            return (
              <Permission
                module="Users"
                action={PermissionActionEnum.SensitiveInfo}
                error="You don't have enough permission to view user images."
                hide={true}
              >
                <NavItem>
                  <NavLink
                    data-toggle="tab"
                    role="tablist"
                    className={pageTabs === tab.id ? "active" : ""}
                    onClick={() => setPageTabs(tab.id)}
                    tabIndex={i}
                  >
                    <i className="now-ui-icons ui-2_settings-90" />
                    {tab.name}
                  </NavLink>
                </NavItem>
              </Permission>
            );
          }
          if (
            (tab.id === "payHistory" || tab.id === "changeLog") &&
            loggedUserRole === "CallCentreStaff"
          ) {
            return null;
          }
          return (
            <NavItem>
              <NavLink
                data-toggle="tab"
                role="tablist"
                className={pageTabs === tab.id ? "active" : ""}
                onClick={() => setPageTabs(tab.id)}
                tabIndex={i}
              >
                <i className="now-ui-icons ui-2_settings-90" />
                {tab.name}
              </NavLink>
            </NavItem>
          );
        })}
      </>
    );
  };

  const handleWorkStatusChange = (value) => {
    setUserData({
      ...userData,
      workStatus: value,
    });
    if (value.value === 1)
      setAlert(
        <SweetAlert
          info
          title={"Please make sure work permit image and work permit expiry exists in Images"}
          onConfirm={hideAlert}
        />,
      );
    if (value.value === 2)
      setAlert(
        <SweetAlert
          info
          title={"Please make sure study permit image and study permit expiry exists in Images"}
          onConfirm={hideAlert}
        />,
      );
  };

  const handleWorkerPin = ({ success, value }) => {
    let updatedValue = value;
    if (!success) {
      updatedValue = w.pin;
    }
    setUserData({
      ...userData,
      workerPin: userData.workerPin.map((_w) => {
        if (_w._id === w._id) {
          _w.pin = updatedValue;
        }
        return _w;
      }),
    });
  };

  const handleWorkerPinDelete = ({ success, value }) => {
    if (!success) return;
    else {
      setUserData({
        ...userData,
        workerPin: userData.workerPin.filter((pin) => pin._id !== value),
      });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddComment({
        userParamId,
        agencyContext,
        addComment,
        setAddComment,
        error,
        setError,
        commentHistory,
        setCommentHistory,
        setIsLoading,
      });
    }
  };

  return (
    <div className="content">
      {userNotFound ? (
        <div>Error 404: No user found</div>
      ) : (
        <Row>
          <Col>
            <Card className="no-transition">
              <CardHeader>
                <Row>
                  {userData.firstName && (
                    <Col md="8">
                      <CardTitle tag="h4">
                        {userData.firstName} {userData.lastName}({userData.hdId}) Profile
                      </CardTitle>
                    </Col>
                  )}
                  {userDeactivated ? (
                    <Col md="12" align="center">
                      <p>The user account is not active.</p>
                      <Permission module="Discipline" action="Suspend">
                        <Button
                          onClick={() =>
                            handleOpenCommonModal(USER_PROFILE_MODULE_TYPE.REACTIVATE_USER)
                          }
                          color="success"
                          size="md"
                          className="btn"
                          outline
                        >
                          <LockOpen />
                          Re-activate User?
                        </Button>
                      </Permission>
                    </Col>
                  ) : (
                    <Col md="12" align="center">
                      <Permission
                        module="Discipline"
                        action="Deactivate"
                        error="Not enough Permission to unban user from a business."
                      >
                        <Button
                          onClick={() =>
                            handleOpenCommonModal(USER_PROFILE_MODULE_TYPE.UNBLOCK_USER)
                          }
                          color="success"
                          size="md"
                          className="btn"
                          outline
                        >
                          <LockOpen />
                          UnBan User
                        </Button>
                      </Permission>
                      <Permission
                        module="Discipline"
                        action="Deactivate"
                        error="Not enough Permission to ban user from a business."
                      >
                        <Button
                          onClick={() => handleOpenCommonModal(USER_PROFILE_MODULE_TYPE.BLOCK_USER)}
                          color="warning"
                          size="md"
                          className="btn"
                          outline
                        >
                          <Lock />
                          Ban User
                        </Button>
                      </Permission>
                      <Permission module="Discipline" action="Warning">
                        <Button
                          onClick={() => {
                            setUserData({ ...userData, warn: true });
                            handleOpenCommonModal(USER_PROFILE_MODULE_TYPE.WARN_USER);
                          }}
                          color="warning"
                          size="md"
                          className="btn"
                          outline
                        >
                          <Warning />
                          Warn User
                        </Button>
                      </Permission>

                      <Permission module="Discipline" action="Suspend">
                        <Button
                          onClick={() => {
                            setUserData({ ...userData, suspend: true });
                            handleOpenCommonModal(USER_PROFILE_MODULE_TYPE.WARN_USER);
                          }}
                          color="danger"
                          size="md"
                          className="btn"
                          outline
                        >
                          <Warning />
                          Suspend
                        </Button>
                      </Permission>
                      <Permission
                        module="Discipline"
                        action="Deactivate"
                        error="Not enough Permission to deactivate user."
                      >
                        <Button
                          onClick={() =>
                            handleOpenCommonModal(USER_PROFILE_MODULE_TYPE.DELETE_USER)
                          }
                          color="danger"
                          size="md"
                          className="btn"
                          outline
                        >
                          <Delete />
                          Deactivate Worker
                        </Button>
                      </Permission>
                    </Col>
                  )}
                </Row>
              </CardHeader>
              <CardBody>
                <Nav
                  className="nav-pills-primary nav-pills-icons justify-content-center"
                  pills
                  role="tablist"
                >
                  {renderNavTabs()}
                </Nav>
                {isLoading ? (
                  loadingOverlay()
                ) : (
                  <TabContent className="tab-space tab-subcategories" activeTab={pageTabs}>
                    <TabPane tabId="payHistory">
                      {pageTabs === "payHistory" ? (
                        <UserChequeHistory
                          userId={props.match.params.id}
                          agency={agencyContext.agency.agency && agencyContext.agency.agency._id}
                        />
                      ) : null}
                    </TabPane>
                    <TabPane tabId="shiftHistory">
                      {pageTabs === "shiftHistory" ? (
                        <UserShiftHistory
                          userId={props.match.params.id}
                          agency={agencyContext.agency.agency && agencyContext.agency.agency._id}
                        />
                      ) : null}
                    </TabPane>
                    <TabPane tabId="trainingHistory">
                      <UserTrainingHistory
                        userId={props.match.params.id}
                      />
                    </TabPane>
                    <TabPane tabId="appointments">
                        {pageTabs === "appointments" && (
                          <UserAppointments
                            userId={props.match.params.id}
                            agency={agencyContext?.agency?.agency?._id}
                          />
                        )}
                      </TabPane>
                    <TabPane tabId="rideHistory">
                      {pageTabs === "rideHistory" && (
                        <UserRideHistory
                          userId={props.match.params.id}
                          agency={agencyContext?.agency?.agency?._id}
                        />
                      )}
                    </TabPane>
                    <TabPane tabId="comments">
                      <CardHeader>
                        <CardTitle tag="h4">Comments</CardTitle>
                      </CardHeader>
                      <CardBody>
                        <Form>
                          <CommentsTab comments={commentHistory} isLoading={isLoading} />
                          <FormGroup>
                            <Label>Add a comment</Label>
                            <Input
                              value={addComment}
                              onKeyPress={handleKeyPress}
                              onChange={(e) => setAddComment(e.target.value)}
                              placeholder="Add a comment for this user"
                              type="text"
                            />
                          </FormGroup>
                          <FormText color="danger" tag="span">
                            {error.addComment}
                          </FormText>

                          <FormGroup align="right">
                            <Button
                              color="default"
                              outline
                              onClick={() =>
                                handleAddComment({
                                  userParamId,
                                  agencyContext,
                                  addComment,
                                  setAddComment,
                                  error,
                                  setError,
                                  commentHistory,
                                  setCommentHistory,
                                  setIsLoading,
                                })
                              }
                            >
                              Add Comment
                            </Button>
                          </FormGroup>
                        </Form>
                      </CardBody>
                    </TabPane>
                    <TabPane tabId="chat">
                      <CardHeader>
                        <CardTitle tag="h4">Chat</CardTitle>
                      </CardHeader>
                      <CardBody>
                        {pageTabs === "chat" && (
                          <ChatTab
                            receiverId={props.match.params.id}
                            blockUserChat={userData.blockChat}
                            receiverFirstName={userData.firstName}
                            receiverLastName={userData.lastName}
                            pageTabs={pageTabs}
                            lastAppActive={userData.lastAppActive}
                          />
                        )}
                      </CardBody>
                    </TabPane>
                    <TabPane tabId="editProfile">
                      <CardBody>
                        <Form>
                          <Row>
                            <Col className="pr-1" md="4">
                              <Label>First Name</Label>
                              <Input
                                value={userData.firstName}
                                onChange={(e) =>
                                  setUserData({
                                    ...userData,
                                    firstName: e.target.value,
                                  })
                                }
                                disabled={disabled}
                                placeholder="First Name"
                                type="text"
                              />
                              <FormText color="danger" tag="span">
                                {error.firstName}
                              </FormText>
                            </Col>
                            <Col className="px-1" md="4">
                              <Label>Last Name</Label>
                              <Input
                                value={userData.lastName}
                                onChange={(e) =>
                                  setUserData({
                                    ...userData,
                                    lastName: e.target.value,
                                  })
                                }
                                disabled={disabled}
                                placeholder="Last Name"
                                type="text"
                              />
                              <FormText color="danger" tag="span">
                                {error.lastName}
                              </FormText>
                            </Col>
                            <Col className="pl-1" md="4">
                              <Label>Extension</Label>
                              <Input
                                value={userData.duplicated}
                                onChange={(e) =>
                                  setUserData({
                                    ...userData,
                                    duplicated: e.target.value,
                                  })
                                }
                                placeholder="-"
                                type="text"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col className="pr-1" md="4">
                              {disabled ? (
                                <>
                                  <Label>Date of Birth (YYYY-MM-DD)</Label>
                                  <Input
                                    value={moment(userData.dob).format("YYYY-MM-DD")}
                                    disabled={true}
                                    placeholder="Date of Birth"
                                    type="date"
                                  />
                                </>
                              ) : (
                                <>
                                  <Label>Date of Birth</Label>
                                  <ReactDatetime
                                    value={userData.dob}
                                    onChange={(date) => {
                                      setUserData({
                                        ...userData,
                                        dob: date,
                                        age: moment().diff(date, "years"),
                                      });
                                    }}
                                    closeOnSelect={true}
                                    timeFormat={false}
                                  />
                                </>
                              )}
                              <FormText color="danger" tag="span">
                                {error.dob}
                              </FormText>
                            </Col>
                            <Col className="px-1" md="4">
                              <Label>Age</Label>
                              <Input readOnly value={userData.age} placeholder="Age" type="text" />
                              <FormText color="danger" tag="span">
                                {error.age}
                              </FormText>
                            </Col>
                            <Col className="pl-1" md="4">
                              <Label>Gender</Label>
                              <Select
                                className="react-select"
                                classNamePrefix="react-select"
                                placeholder="Gender"
                                name="singleSelect"
                                isDisabled={disabled}
                                closeMenuOnSelect={false}
                                value={userData.gender}
                                onChange={(value) => {
                                  setUserData({
                                    ...userData,
                                    gender: value,
                                  });
                                }}
                                options={genderOptions}
                              />
                              <FormText color="danger" tag="span">
                                {error.gender}
                              </FormText>
                            </Col>
                          </Row>
                          {/**
                             String required since schema is type string 
                             https://bitbucket.org/omadatek/staffingbackend/src/master/src/integration/db/models/User.ts
                           */}
                          <Row>
                            <Col className="pr-1" md="4">
                              <Label>Phone Number</Label>
                              <Input
                                value={userData.phone}
                                onChange={(e) =>
                                  setUserData({
                                    ...userData,
                                    phone: e.target.value,
                                  })
                                }
                                maxLength="10"
                                placeholder="Phone Number"
                                type="text"
                              />
                              <FormText color="danger" tag="span">
                                {error.phone}
                              </FormText>
                            </Col>
                            <Col className="px-1" md="4">
                              <Label>Street Address</Label>
                              <Input
                                value={userData.address}
                                onChange={(e) =>
                                  setUserData({
                                    ...userData,
                                    address: e.target.value,
                                  })
                                }
                                placeholder="Home Address"
                                type="text"
                                disabled={disabled}
                              />
                              <FormText color="danger" tag="span">
                                {error.address}
                              </FormText>
                            </Col>
                            <Col className="pl-1" md="4">
                              <Label>Email address</Label>
                              <Input
                                value={userData.email}
                                onChange={(e) =>
                                  setUserData({
                                    ...userData,
                                    email: e.target.value,
                                  })
                                }
                                placeholder="Email"
                                type="email"
                              />
                              <FormText color="danger" tag="span">
                                {error.email}
                              </FormText>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="pr-1" md="4">
                              <Label>City</Label>
                              <Input
                                value={userData.city}
                                onChange={(e) =>
                                  setUserData({
                                    ...userData,
                                    city: e.target.value,
                                  })
                                }
                                placeholder="City"
                                type="text"
                                disabled={disabled}
                              />
                              <FormText color="danger" tag="span">
                                {error.city}
                              </FormText>
                            </Col>
                            <Col className="px-1" md="4">
                              <Label>Province</Label>
                              <Select
                                className="react-select"
                                classNamePrefix="react-select"
                                name="singleSelect"
                                isDisabled={disabled}
                                value={userData.province}
                                onChange={(value) =>
                                  setUserData({
                                    ...userData,
                                    province: value,
                                  })
                                }
                                placeholder="Select a province"
                                options={provinceOptions}
                              />
                              <FormText color="danger" tag="span">
                                {error.province}
                              </FormText>
                            </Col>
                            <Col className="pl-1" md="4">
                              <Label>Postal Code</Label>
                              <MaskedInput
                                mask={maskedPostalCode}
                                id="postalCode"
                                disabled={disabled}
                                className="form-control"
                                autoComplete="off"
                                maxLength="8"
                                placeholder="A1B2C3"
                                onChange={(e) => {
                                  setUserData({
                                    ...userData,
                                    postalCode: e.target.value,
                                  });
                                }}
                                value={userData.postalCode || ""}
                              />
                              <FormText color="danger" tag="span">
                                {error.postalCode}
                              </FormText>
                            </Col>
                          </Row>
                          {/**
                             String required since schema is type string 
                             https://bitbucket.org/omadatek/staffingbackend/src/master/src/integration/db/models/User.ts
                           */}
                          <Row>
                            <Col className="pr-1" md="4">
                              <Label>Social Insurance Number</Label>
                              <Input
                                value={userData.sin}
                                disabled
                                maxLength="9"
                                placeholder="Social Insurance Number"
                                type="text"
                              />
                              <FormText color="danger" tag="span">
                                {error.sin}
                              </FormText>
                            </Col>
                            <Col className="px-1" md="4">
                              {userData.sin?.startsWith("9") ||
                              userData.sin?.trim() === "000000000" ? (
                                <FormGroup>
                                  <Label>*Sin Expiry Date</Label>
                                  <Input
                                    disabled
                                    type="date"
                                    autoComplete="off"
                                    maxLength="3"
                                    value={userData.sinExpiry}
                                    id="sinExpiry"
                                  />
                                  <FormText color="danger" tag="span">
                                    {error.sinExpiry}
                                  </FormText>
                                </FormGroup>
                              ) : null}
                            </Col>
                            <Col className="pl-1" md="4">
                              <Label>Qualifications List</Label>
                              <Select
                                className="react-select"
                                classNamePrefix="react-select"
                                placeholder=" Qualifications"
                                name="multipleSelect"
                                closeMenuOnSelect={false}
                                isMulti
                                isDisabled={disabled}
                                value={userData.qualifications}
                                onChange={(value) => {
                                  setUserData({
                                    ...userData,
                                    qualifications: value,
                                  });
                                }}
                                options={qualificationsList}
                              />
                              <FormText color="danger" tag="span">
                                {error.qualifications}
                              </FormText>
                            </Col>
                            <Col className="pr-1" md="4">
                              <Label>Legal to work in canada ?</Label>
                              <Select
                                className="react-select"
                                classNamePrefix="react-select"
                                placeholder="Legal status"
                                name="singleSelect"
                                isDisabled={disabled}
                                closeMenuOnSelect={false}
                                value={userData.legallyWork}
                                onChange={(value) => {
                                  setUserData({
                                    ...userData,
                                    legallyWork: value,
                                  });
                                }}
                                options={legalToWorkOptions}
                              />
                              <FormText color="danger" tag="span">
                                {error.legallyWork}
                              </FormText>
                            </Col>
                            <Col className="px-1" md="4">
                              <Label>Work Status</Label>
                              <Select
                                className="react-select"
                                classNamePrefix="react-select"
                                placeholder="Select a work status"
                                name="singleSelect"
                                closeMenuOnSelect={false}
                                id="workStatus"
                                isDisabled={disabled}
                                value={userData.workStatus}
                                onChange={(value) => handleWorkStatusChange(value)}
                                options={workStatusOptions}
                              />
                              <FormText color="danger" tag="span">
                                {error.workStatus}
                              </FormText>
                            </Col>
                            <Col className="pl-1" md="4">
                              <Label>SMS Notification</Label>
                              <FormGroup check>
                                <Label check>
                                  <Input
                                    disabled={disabled}
                                    type="checkbox"
                                    checked={userData.smsBlocked}
                                    onChange={() =>
                                      setUserData({ ...userData, smsBlocked: !userData.smsBlocked })
                                    }
                                  />
                                  <span className="form-check-sign" />
                                  SMS Blocked
                                </Label>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="pr-1" md="6">
                              <FormGroup>
                                <Label>Availability</Label>
                                <RenderAvailability
                                  availability={
                                    userData.availability?.length ? userData.availability : void 0
                                  }
                                  onChange={(newAvailability) => {
                                    setUserData({
                                      ...userData,
                                      availability: newAvailability,
                                    });
                                  }}
                                  disabled={disabled}
                                />
                              </FormGroup>
                              <FormText color="danger" tag="span">
                                {error.availability}
                              </FormText>
                            </Col>
                            <Col className="pl-1" md="6">
                              <FormGroup>
                                <Label>Worker's Pin</Label>
                                {userData.workerPin && userData.workerPin?.length > 0
                                  ? userData.workerPin.map((w) => {
                                      return (
                                        <div key={w._id}>
                                          {w ? (
                                            <PinComponent
                                              workerPin={w}
                                              workerId={props.match.params.id}
                                              onSuccess={(success, value) =>
                                                handleWorkerPin({ success, value })
                                              }
                                              onDelete={(success, value) =>
                                                handleWorkerPinDelete({ success, value })
                                              }
                                              showDelete={true}
                                            />
                                          ) : null}
                                        </div>
                                      );
                                    })
                                  : null}
                                <PinComponent
                                  workerId={props.match.params.id}
                                  onSuccess={(success, value) => {
                                    if (success) {
                                      setUserData({
                                        ...userData,
                                        workerPin: [...workerPin, value],
                                      });
                                    }
                                  }}
                                  manual={true}
                                  businessList={businesses}
                                  agency={agencyContext.agency.agency?._id}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col className="pr-1" md="6">
                              <Label>Emergency Contact 1: First Name</Label>
                              <Input
                                value={userData.emergencyFirstName}
                                onChange={(e) =>
                                  setUserData({
                                    ...userData,
                                    emergencyFirstName: e.target.value,
                                  })
                                }
                                placeholder="Emergency Contact 1: First Name"
                                type="text"
                                disabled={disabled}
                              />
                              <FormText color="danger" tag="span">
                                {error.emergencyFirstName}
                              </FormText>
                            </Col>
                            <Col className="pl-1" md="6">
                              <Label>Emergency Contact 1: Last Name</Label>
                              <Input
                                value={userData.emergencyLastName}
                                onChange={(e) =>
                                  setUserData({
                                    ...userData,
                                    emergencyLastName: e.target.value,
                                  })
                                }
                                placeholder="Emergency Contact 1: Last Name"
                                type="text"
                                disabled={disabled}
                              />
                              <FormText color="danger" tag="span">
                                {error.emergencyLastName}
                              </FormText>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="pr-1" md="6">
                              <Label>Emergency Contact 1: Phone Number</Label>
                              <Input
                                value={userData.emergencyPhone}
                                onChange={(e) =>
                                  setUserData({
                                    ...userData,
                                    emergencyPhone: e.target.value,
                                  })
                                }
                                maxLength="10"
                                placeholder="Emergency Contact 1: Phone Number"
                                type="text"
                                disabled={disabled}
                              />
                              <FormText color="danger" tag="span">
                                {error.emergencyPhone}
                              </FormText>
                            </Col>
                            <Col className="pl-1" md="6">
                              <Label>Emergency Contact 1: Relationship</Label>
                              <Input
                                value={userData.emergencyRelationship}
                                onChange={(e) =>
                                  setUserData({
                                    ...userData,
                                    emergencyRelationship: e.target.value,
                                  })
                                }
                                placeholder="Emergency Contact 1: Relationship"
                                type="text"
                                disabled={disabled}
                              />
                              <FormText color="danger" tag="span">
                                {error.emergencyRelationship}
                              </FormText>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="pr-1" md="6">
                              <Label>Emergency Contact 2: First Name</Label>
                              <Input
                                value={userData.emergencyFirstName2}
                                onChange={(e) =>
                                  setUserData({
                                    ...userData,
                                    emergencyFirstName2: e.target.value,
                                  })
                                }
                                placeholder="Emergency Contact 2: First Name"
                                type="text"
                                disabled={disabled}
                              />
                            </Col>
                            <Col className="pl-1" md="6">
                              <Label>Emergency Contact 2: Last Name (Optional)</Label>
                              <Input
                                value={userData.emergencyLastName2}
                                onChange={(e) =>
                                  setUserData({
                                    ...userData,
                                    emergencyLastName2: e.target.value,
                                  })
                                }
                                placeholder="Emergency Contact 2: Last Name"
                                type="text"
                                disabled={disabled}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col className="pr-1" md="6">
                              <Label>Emergency Contact 2: Phone Number (Optional)</Label>
                              <Input
                                value={userData.emergencyPhone2}
                                onChange={(e) =>
                                  setUserData({
                                    ...userData,
                                    emergencyPhone2: e.target.value,
                                  })
                                }
                                maxLength="10"
                                placeholder="Emergency Contact 2: Phone Number"
                                type="text"
                                disabled={disabled}
                              />
                            </Col>
                            <Col className="pl-1" md="6">
                              <Label>Emergency Contact 2: Relationship (Optional)</Label>
                              <Input
                                value={userData.emergencyRelationship2}
                                onChange={(e) =>
                                  setUserData({
                                    ...userData,
                                    emergencyRelationship2: e.target.value,
                                  })
                                }
                                placeholder="Emergency Contact 2: Relationship"
                                type="text"
                                disabled={disabled}
                              />
                            </Col>
                          </Row>
                        </Form>
                        <FormGroup align="right">
                          <Permission
                            module="Users"
                            action="Edit"
                            error="You don't have enough permission to update the user data."
                          >
                            <Button color="default" outline onClick={() => handleSubmit()}>
                              Save Changes
                            </Button>
                          </Permission>
                        </FormGroup>
                      </CardBody>
                    </TabPane>
                    {/* Hide from admin panel. Currently would display blank page. 
                        Might use in future */}
                    {/* <TabPane tabId="changeLog">
                      {pageTabs === "changeLog" 
                      ? <ChangeLog email={userData.email} /> 
                      : null}
                    </TabPane> */}
                    <TabPane tabId="images">
                      <CardBody>
                        {pageTabs === "images" && (
                          <Images
                            documents={userData.documents}
                            firstName={userData.firstName}
                            lastName={userData.lastName}
                            hdId={userData.hdId}
                          />
                        )}
                      </CardBody>
                    </TabPane>
                  </TabContent>
                )}
              </CardBody>
            </Card>
            <Button
              md="4"
              className="mt-5"
              color="danger"
              onClick={() => handleOpenCommonModal(USER_PROFILE_MODULE_TYPE.PERMANENT_DELETE_USER)}
            >
              Permanently Delete from Database
            </Button>
          </Col>
        </Row>
      )}
      <UserProfileCommonModal
        modalType={modalType}
        openCommonModal={openCommonModal}
        setOpenCommonModal={setOpenCommonModal}
        selectedBusiness={selectedBusiness}
        setSelectedBusiness={setSelectedBusiness}
        setIsLoading={setIsLoading}
        setAlert={setAlert}
        hideAlert={hideAlert}
        loadUser={loadUser}
        userData={userData}
        setUserData={setUserData}
        businesses={businesses}
        bannedBusiness={bannedBusiness}
        userParamId={userParamId}
        bannedBusinessIds={bannedBusinessIds}
        userSuspendStatus={userData.suspend}
        userWarnStatus={userData.warn}
      />
      {alert}
    </div>
  );
}

export default UserProfile;
