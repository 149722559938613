import { Modal } from "@mui/material";
import React, { useContext, useState } from "react";
import Button from "reactstrap/lib/Button";
import FormGroup from "reactstrap/lib/FormGroup";
import SweetAlert from "react-bootstrap-sweetalert";
const ModalContext = React.createContext();

export default ModalContext;

const ModalTemplate = ({ onSuccess, onCancel, options, children }) => {
  return (
    <Modal
      open={true}
      onClose={() => {
        onCancel();
      }}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <FormGroup
        style={{
          width: options?.width || "350px",
          backgroundColor: "#fff",
          borderRadius: 10,
          padding: "10px",
        }}
      >
        {children}
        <div style={{ padding: 15 }}>
          <div style={{ justifyContent: "space-between", display: "flex" }}>
            <Button onClick={() => onCancel()}>{options?.cancelText || "Cancel"}</Button>
            <Button color="success" onClick={() => onSuccess()}>
              {options?.successText || "Update"}
            </Button>
          </div>
        </div>
      </FormGroup>
    </Modal>
  );
};

export const ModalProvider = ({ children }) => {
  const [modals, setModals] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const addModal = ({ body, onSuccess, options, onCancel }) => {
    setModals(() => {
      return [...modals, { body, onSuccess, onCancel, options }];
    });
  };
  const addAlert = ({ title, style, showCancel = false, warning, success, error, children }) => {
    //
    setAlerts(() => {
      return [...alerts, { title, style, showCancel, warning, success, error, children }];
    });
  };
  return (
    <ModalContext.Provider value={{ addModal, addAlert }}>
      {children}
      {modals.map((modal, i) => (
        <ModalTemplate
          key={i}
          options={modal.options}
          onSuccess={() => {
            //
            modal.onSuccess();
            setModals((_modals) => _modals.filter((_modal) => _modal !== modal));
          }}
          onCancel={() => {
            modal.onCancel && modal.onCancel();
            setModals((_modals) => _modals.filter((_modal) => _modal !== modal));
          }}
        >
          {modal.body}
        </ModalTemplate>
      ))}
      {alerts.map((alert, i) => (
        <SweetAlert
          showCancel={alert.showCancel}
          style={alert.style}
          title={alert.title}
          onConfirm={() => setAlerts((_alerts) => _alerts.filter((_alert) => _alert !== alert))}
          onCancel={() => setAlerts((_alerts) => _alerts.filter((_alert) => _alert !== alert))}
          error={alert.error}
          warning={alert.warning}
          success={alert.success}
        >
          {alert?.children}
        </SweetAlert>
      ))}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const modalContext = useContext(ModalContext);
  return modalContext;
};
