import React from 'react'
import { Modal } from "@mui/material";
import {Button, FormGroup} from "reactstrap";

const GlobalModal = (props) => {
  return (
    <Modal
      open={props.open}
      onClose={props.close}
      style={props.modalStyle ? props.modalStyle : modalStyle}
    >
      <FormGroup style={props.formGroupGeneral ? props.formGroupGeneral : formGroupGeneral}>
        <h5 style={props.modalHeader ? props.modalHeader : modalHeader}>
          {props.title}
        </h5>
        <p>{props.desc}</p>
        
          {/* modal second title */}
        {props.secTitle
        ?  <h6 style={props.modalHeader ? props.modalHeader : modalHeader}>
          {props.secTitle}
          </h6>
        : null}

        {/* Modal specific children */}
+       {props.children}
        
        <div style={props.formGroupBody ? props.formGroupBody : formGroupBody}>
          <Button onClick={props.cancelAction}>Cancel</Button>
          <Button
            color={props.btnColor}
            onClick={props.action}
          >
            {props.btnMessage}
          </Button>
        </div>
      </FormGroup>
    </Modal>
  )
}

export default GlobalModal


const formGroupGeneral = { width: 500, backgroundColor: "#fff", borderRadius: 10, padding: 15  }
const modalStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}
const modalHeader = {  textAlign: "center" }
const formGroupBody = { justifyContent: "space-between", display: "flex" }
const optionText = { fontSize: 16, padding: 15 }