/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import { Redirect } from "react-router-dom";
import { CSVLink } from "react-csv";
import SweetAlert from "react-bootstrap-sweetalert";
import { filterCaseInsensitive } from "components/Utilities/utilities";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Spinner,
  Label,
} from "reactstrap";

import { Visibility, PersonAdd, AddCircleOutline, Clear } from "@mui/icons-material";
import { Modal } from "@mui/material";

import * as utils from "components/Utilities/utilities.js";
import api from "../../../components/API/api";
import { Input } from "reactstrap";
import AgencyContext from "../../../context/AgencyContext";
class AllBusinessesStaff extends React.Component {
  static contextType = AgencyContext;
  constructor(props) {
    super(props);
    this.state = {
      staffData: [],
      staffDataHeaders: [
        {
          label: "Staff HDID",
          key: "hdId",
        },
        {
          label: "Staff firstName",
          key: "firstName",
        },
        {
          label: "Staff lastName",
          key: "lastName",
        },
        {
          label: "Contact Email",
          key: "email",
        },
        {
          label: "Contact Phone Number",
          key: "phone",
        },
      ],
      confirmDeleteModalOpen: false,

      selectedStaffId: null,
      selectedDocId: null,
      selectedProfileId: null,
      selectedFirstName: null,
      selectedLastName: null,
      businessList: [],

      isLoading: false,
      openModal: false,

      tableQuery: "",

      //pagination parameters
      loading: "false",
      pages: 0,
    };
  }

  componentDidMount() {
    this.fetchBusiness()
  }

  RenderActionButtons = (hdId, docId, firstName, lastName) => {
    return (
      <div className="actions-right">
        <Button
          onClick={() => {
            // this.setState({ profileBId: bId },
            this.handleOpenProfile(docId);
          }}
          color="info"
          size="md"
          className="btn-link btn-icon"
        >
          <Visibility style={{ marginLeft: -1 }} />
        </Button>{" "}
        <Button
          onClick={() => {
            this.setState(
              {
                selectedStaffId: hdId,
                selectedDocId: docId,
                selectedFirstName: firstName,
                selectedLastName: lastName,
              },
              this.handleOpenDeleteModal(),
            );
          }}
          color="danger"
          size="md"
          className="btn-link remove btn-icon"
        >
          <Clear style={{ marginLeft: -1 }} />
        </Button>{" "}
      </div>
    );
  };

  //Loading Overlay
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  loadingOverlay = () => {
    return (
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
          background: "transparent",
        }}
        title={<Spinner color="light" style={{ width: "3rem", height: "3rem" }} />}
        onConfirm={this.hideAlert}
        showConfirm={false}
      />
    );
  };

  handleOpenProfile = (docId) => {
    let path = `/super/admin/business-staff-profile/${docId}`;
    this.props.history.push(path);
  };

  handleOpenDeleteModal = () => {
    this.setState({ confirmDeleteModalOpen: true });
  };

  handleCloseDeleteModal = () => {
    this.setState({ confirmDeleteModalOpen: false });
  };

  deleteStaff = async (hdId, userId, profileId) => {
    try {
      const resp = await api().delete("/users/delete-manager", {
        params: {
          userId: userId,
          profileId: profileId,
        },
      });
      this.fetchData();
      this.setState(
        {
          isLoading: false,
          alert: (
            <SweetAlert
              success
              title="Staff successfully deleted"
              onConfirm={this.hideAlert}
              showConfirm={true}
            />
          ),
        },
        this.handleCloseDeleteModal(),
      );
    } catch (error) {
      console.log("error", error);
    }
  };

  confirmDeleteBody = () => {
    // console.log(this.state.selectedLastName)
    return (
      <Modal
        open={this.state.confirmDeleteModalOpen}
        onClose={this.handleCloseDeleteModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        // aria-labelledby="simple-modal-title"
        // aria-describedby="simple-modal-description"
      >
        <FormGroup style={{ width: 300, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>
                Delete {this.state.selectedFirstName} {this.state.selectedLastName}?
              </h5>
            </div>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button onClick={() => this.handleCloseDeleteModal()}>Cancel</Button>
              <Button
                color="danger"
                onClick={() =>
                  this.deleteStaff(
                    this.state.selectedStaffId,
                    this.state.selectedDocId,
                    this.state.selectedProfileId,
                  )
                }
              >
                Delete
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  fetchBusiness = async () => {
    try {
      const { data } = await api().get("/business/list");
      console.log("fetchBusiness",data)
      this.setState({businessList: data}, () => this.updateAllUsersBusinessNames());
    } catch (error) {
      console.log(error);
    }
  };

  fetchData = async (state) => {
    this.setState({ loadingTable: true });
    if (!state) {
      state = this.state.lastTableState;
    }
    this.setState({ lastTableState: state });
    console.log("state", state);

    let field = null;
    let sort = null;
    if (state.sorted.length > 0) {
      field = state.sorted[0].id;
      sort = state.sorted[0].desc ? "desc" : "asc";
    }
    const { data } = await api().get("/users/get-business-staff", {
      params: {
        page: state.page + 1,
        size: state.pageSize,
        field,
        sort,
        query: this.state.tableQuery,
        all:true,
      },
    });
    console.log(data);
    let users = data.users || [];
    users = users.map((u, index) => ({
      ...u,
      businessName: this.getBusinessName(u),
      businessProfile: this.getBusinessProfile(u),
      actions: this.RenderActionButtons(u.hdId, u._id, u.firstName, u.lastName),
    }));

    console.log("data", data);
    this.setState({
      staffData: users,
      pages: data.noPages,
      loadingTable: false,
    });
  };

  updateAllUsersBusinessNames = () => {
    console.log("updateAllUsersBusinessNames", this.state.businessList)
    const users = this.state.staffData.map((u) => ({
      ...u,
      businessName: this.getBusinessName(u)
    }));
    this.setState({staffData: users})
  }

  getBusinessName = (user) => {
    console.log("this.state.businessList", this.state.businessList)
    const profile = user.profile.find(p => p.role === "BusinessStaff" || p.role === "BusinessOwner")
    const business = this.state.businessList.find(b => b._id === profile.business)

    return business? business.name : null
  }

  getBusinessProfile = (user) => {
    let profile = ""
    if(user.profile.some(p => p.role === "BusinessOwner")){
      profile += "Business Owner"
    }
    if(user.profile.some(p => p.role === "BusinessStaff")){
      if(profile.length > 0){
        profile+=", "
      }
      profile += "Business Staff"
    }
    return profile
  }

  render() {
    //<Button color="default" outline>Export All</Button>
    return (
      <>
        <div className="content">
          {
            this.state.isLoading ? (
              this.loadingOverlay()
            ) : (
              <Row>
                <Col md="12">
                  <FormGroup>
                    <CSVLink
                      data={this.state.staffData}
                      headers={this.state.staffDataHeaders}
                      filename={"Staff_Data.csv"}
                    >
                      <Button color="default" outline>
                        Export All
                      </Button>
                    </CSVLink>
                  </FormGroup>
                  <Card className="no-transition">
                    <CardHeader>
                      <CardTitle tag="h4">All Businesses Staff</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div style={{ textAlign: "right" }}>
                        <FormGroup row className="align-items-right">
                          <Col sm="7"></Col>
                          <Label for="Search" sm={2} style={{ color: "black", fontSize: "14px" }}>
                            Search
                          </Label>
                          <Col sm={3}>
                            <Input
                              type="text"
                              id="Search"
                              value={this.state.tableQuery}
                              onChange={(e) =>
                                this.setState({ tableQuery: e.target.value }, () =>
                                  this.fetchData(),
                                )
                              }
                            />
                          </Col>
                        </FormGroup>
                      </div>
                      <ReactTable
                        columns={[
                          {
                            Header: "ID",
                            accessor: "hdId",
                            maxWidth: 70,
                          },
                          {
                            Header: "FirstName",
                            accessor: "firstName",
                          },
                          {
                            Header: "LastName",
                            accessor: "lastName",
                          },
                          {
                            Header: "Contact Email",
                            accessor: "email",
                          },
                          {
                            Header: "Contact Phone",
                            accessor: "phone",
                          },
                          {
                            Header: "Business",
                            accessor: "businessName",
                            sortable: false,
                            filterable: false,
                          },
                          {
                            Header: "Profile",
                            accessor: "businessProfile",
                            sortable: false,
                            filterable: false,
                          },
                          {
                            Header: "Actions",
                            accessor: "actions",
                            sortable: false,
                            filterable: false,
                            maxWidth: 110,
                          },
                        ]}
                        manual
                        loading={this.state.loadingTable}
                        pages={this.state.pages}
                        data={this.state.staffData}
                        onFetchData={this.fetchData}
                        sorted={this.state.sorted}
                        defaultPageSize={10}
                        showPaginationTop
                        showPaginationBottom
                        /*
                        You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                      */
                        className="-striped -highlight primary-pagination"
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )
            
          }
          {this.state.alert}
          {this.confirmDeleteBody()}
        </div>
      </>
    );
  }
}

export default AllBusinessesStaff;
