import { Modal } from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import { Button, Col, FormGroup, FormText, Input, Label, Row } from "reactstrap";
import api from "components/API/api";
import moment from "moment";
import Select from "react-select";
import { statusEnum, UnScheduleReasonList } from "utils/statusVariables";
import { USER_MODAL_TYPES } from "../AllUsers";
import { shiftTimeType } from "utils/dateUtils";
import { useModal } from "context/ModalContext";
import AgencyContext from "context/AgencyContext";
import { startOfDay, startOfNextDay } from "utils/dateUtils.js";

/**
 * 1. Modal is combination of four All users modals types in @USER_MODAL_TYPES, Modifying the worker's shift status and managing worker shift applications
 * 2. Params 
 *      @param userData - Object type - userInfo
 *      @param selectedHdId - string -  selected userHDID
 *      @param filter - object - 
 *          example type {
              date: moment(),
              businessId: "all",
              status: "",
              availabilityFilter: initialAvailability,
            }
 *      @param fetchShifts - function - to fetch shifts data for day
 *      @param updateAdminUpdateTime - function - to log staff actions
 *      @param shiftsList - array - available shiftlist array for current day
 *      @param modalType - string - one of @USER_MODAL_TYPES enum from => { "addUser", "reAssignUser", "confirmUser", "removeUser" }
 *      @param openCommonModal - function - to open modal types
 *      @param isLoading - boolean - true/false
 *      @param setIsLoading - function - to set loading state
 *      @param setModalType - useState set function to set the modal type
 * 3. Returns Modal based on modalType
 */
function AllUsersModals({
  userData,
  selectedHdId,
  filter,
  fetchData,
  fetchShifts,
  updateAdminUpdateTime,
  shiftsList,
  modalType,
  openCommonModal,
  setOpenCommonModal,
  isLoading,
  setIsLoading,
  setModalType,
}) {
  const agencyContext = useContext(AgencyContext);
  const { addAlert } = useModal();
  const [action, setAction] = useState("");
  let selectedForWorkstatus;
  let unScheduleReason;
  const [unscheduleReason, setUnscheduleReason] = useState(null);
  const [addUserToNewBusiness, setAddUserToNewBusiness] = useState(false);
  const [cantAddToThisBusiness, setCantAddToThisBusiness] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const selectedUser = userData.find((u) => u.hdId === selectedHdId);
  const userId = userData.find((u) => u.hdId === selectedHdId)?._id;
  const expiresIn = selectedUser?.documentStatus?.expiresIn;
  const canWork = selectedUser?.documentStatus?.allowedToWork;
  const sinExpiringIn30Days = expiresIn < 30 && expiresIn > 0;
  const [selectedShift, setSelectedShift] = useState(null);
  const [selectedShiftFunction, setSelectedShiftFunction] = useState(null);
  const selectedUserIsUntrained = !selectedUser?.qualifications?.length;
  const selectedShiftData = shiftsList.find((s) => selectedShift && s._id === selectedShift.value);
  const selectedForWorkData = selectedUser?.selectForWork && selectedUser?.selectForWork.length > 0;
  if (selectedForWorkData) {
    selectedForWorkstatus = selectedUser.selectForWork[0].status;
    UnScheduleReasonList.find((reason) => {
      if (reason.value === selectedUser?.selectForWork[0]?.unscheduleReason) {
        unScheduleReason = reason.label;
      }
    });
  }

  useEffect(() => {
    if (modalType === USER_MODAL_TYPES.CONFIRM_USER) {
      const selectedForWork = selectedUser?.selectForWork[0];
      const shiftFunction = selectedForWork?.shiftFunction;
      if (shiftFunction) {
        setSelectedShiftFunction({
          label: shiftFunction.name,
          value: shiftFunction?._id,
          shiftFunction: shiftFunction,
        });
      }
      const shift = shiftsList.find((_shift) => _shift._id === selectedForWork?.shift);
      if (shift) {
        let timeOfDay = shiftTimeType(shift.start);
        setSelectedShift({
          label:
            shift.businessName +
            " : " +
            timeOfDay +
            ` @${moment(shift.start).format("hh:mm a")}-${moment(shift.end).format("hh:mm a")}`,
          value: shift._id,
          business: shift.business,
          shift,
        });
      }
    }
  }, [modalType]);

  // styles for shift list
  const reactSelectStyles = {
    option: (styles, { data, isFocused, isSelected }) => {
      if (data.shift && data.shift.closed) {
        return {
          ...styles,
          color: "#c5093d",
          backgroundColor: isFocused ? "#eee" : null,
        };
      }
      if (
        selectedUser.workerPin &&
        selectedUser.workerPin.some(
          (wPin) =>
            wPin.business === data.business && wPin.agency !== agencyContext.agency.agency._id,
        )
      ) {
        //if the user hasn't been selected for work in another business then make the color red
        return {
          ...styles,
          color: "#f5593d",
          backgroundColor: isFocused ? "#eee" : null,
        };
      }
      if (
        selectedUser.workerPin &&
        selectedUser.workerPin.filter((wPin) => wPin.agency === agencyContext.agency.agency._id)
          .length > 0 &&
        !selectedUser.workerPin.some((wPin) => wPin.business === data.business)
      ) {
        //if the user hasn't been selected for work in another business then make the color red
        return {
          ...styles,
          color: "#f5593d",
          backgroundColor: isFocused ? "#eee" : null,
        };
      }
      if (selectedUser.workerPin && selectedUser.workerPin.length > 0) {
        if (
          selectedUser.workerPin.some((wPin) => wPin.business === data.business && wPin.preferred)
        ) {
          //if the user hasn't been selected for work in another business then make the color red
          return {
            ...styles,
            color: "#51cbce",
            backgroundColor: isFocused ? "#eee" : null,
          };
        }
      }
      return {
        ...styles,
        cursor: "pointer",
        color: isSelected ? "#000" : null,
        backgroundColor: isFocused || isSelected ? "#eee" : null,
      };
    },
  };

  const handleCloseCommonModal = () => {
    setFormErrors({});
    setSelectedShift(null);
    setAddUserToNewBusiness(false);
    setCantAddToThisBusiness(false);
    setUnscheduleReason("");
    setOpenCommonModal(false);
    setModalType(null);
  };

  /**
   * 1. updating user status as No Response/ Unavailable
   * 2. Params
   *        @status number => from @statusEnum
   *        @userId string
   * 3. No output returned
   */
  const updateStatus = async (status, userId) => {
    try {
      const data = { status, userId, lastStatusUpdate: new Date() };
      await api().patch("/users", data);
      pullLatestData("User Status Successfully updated");
    } catch (error) {
      console.log(error);
      showErrorMessage(error);
    }
  };

  /**
   * 1. add log, update UI with new data, add alert, close modal
   * 2. Params @successMsg string like "User Successfully updated"
   * 3. No output returned
   */
  const pullLatestData = (successMsg) => {
    updateAdminUpdateTime(userId);
    fetchShifts();
    handleCloseCommonModal();
    fetchData();
    addAlert({
      title: successMsg,
      success: true,
    });
    setIsLoading(false);
  };

  /**
   * 1. show error msg alert and close the modal
   * 2. Params @error object returns from a server
   * 3. No output returned
   */
  const showErrorMessage = (error) => {
    handleCloseCommonModal();
    setIsLoading(false);
    addAlert({
      title: error.response?.data?.message,
      error: true,
      children:
        error.response?.data?.message || "There was a technical error. please contact support.",
    });
  };

  /**
   * 1. Display available shift to select for user
   * 2. No Params
   * 3. Returns a component to add user to shift
   */
  const SelectingUserToWorkComponent = () => {
    return (
      <>
        <div style={{ justifyContent: "center", display: "flex" }}>
          <h5>
            Add {selectedUser.firstName} {selectedUser.lastName} to Live Selected Employees?
          </h5>
        </div>
        {sinExpiringIn30Days ? (
          <p style={{ color: "#6A0DA0" }}>
            The user's sin number is expiring soon. Please update the sin number through user's
            profile.
          </p>
        ) : null}
        {moment(filter.date).add(1, "days").isAfter(new Date()) ? (
          <Select
            classNamePrefix="react-select"
            name="selectShift"
            value={selectedShift}
            onChange={(value) => {
              setSelectedShift(value);
              if (
                selectedUser?.userBans?.length &&
                selectedUser.userBans.find((b) => b === value.business)
              ) {
                setCantAddToThisBusiness(true);
              } else if (
                selectedUser.workerPin &&
                selectedUser.workerPin.filter(
                  (wPin) => wPin.agency === agencyContext.agency.agency._id,
                ).length > 0 &&
                !selectedUser.workerPin.some((wPin) => wPin.business === value.business)
              ) {
                setAddUserToNewBusiness(true);
                setCantAddToThisBusiness(false);
              } else if (
                selectedUser.workerPin.some(
                  (wPin) =>
                    wPin.business === value.business &&
                    wPin.agency !== agencyContext.agency.agency._id,
                )
              ) {
                setAddUserToNewBusiness(false);
                setCantAddToThisBusiness(true);
              } else {
                setAddUserToNewBusiness(false);
                setCantAddToThisBusiness(false);
              }
            }}
            styles={reactSelectStyles}
            options={shiftsList.map((b) => {
              let timeOfDay = shiftTimeType(b.start);
              return {
                label:
                  b.businessName +
                  " : " +
                  timeOfDay +
                  ` @${moment(b.start).format("hh:mm a")}-${moment(b.end).format("hh:mm a")}`,
                value: b._id,
                business: b.business,
                shift: b,
              };
            })}
            placeholder="Select shift"
            style={{ margin: "15px 0" }}
          />
        ) : (
          "You cannot add workers to past shifts."
        )}

        {selectedUserIsUntrained ? (
          <>
            <Label style={{ color: "black", fontWeight: "bold" }}>
              Are you sure want to schedule unqualified workers ?
            </Label>
          </>
        ) : // addUserToNewBusiness ||
        selectedShift?.shift?.closed ? (
          <>
            <Label style={{ color: "black", fontWeight: "bold" }}>
              {selectedShift?.shift?.closed && addUserToNewBusiness
                ? "* (This shift is already closed and this user already works at another location.)"
                : selectedShift?.shift?.closed
                ? "* (This shift is already closed.)"
                : addUserToNewBusiness
                ? "* (This user already works at another location.)"
                : ""}
            </Label>
          </>
        ) : null}
        {cantAddToThisBusiness && (
          <div>
            <p style={{ color: "red" }}>
              This employee can't be added to this shift. He/she works at this business from another
              agency.
            </p>
            (or)
            <p style={{ color: "red" }}>This user might have banned from this business.</p>
          </div>
        )}
        {addUserToNewBusiness && (
          <div>
            <p style={{ color: "red" }}>This employee already works at another location.</p>
          </div>
        )}
        {selectedShiftData && (
          <div style={{ marginTop: "8px" }}>
            <h5>Shift</h5>
            <p>
              <b>Business:</b>
              {selectedShiftData.businessName}
            </p>
            <p>
              <b>Start:</b>
              {moment(selectedShiftData.start).format("LLLL")}
            </p>
            <p>
              <b>End:</b>
              {moment(selectedShiftData.end).format("LLLL")}
            </p>
            {selectedShiftData.shiftFunctions?.length ? (
              <Select
                classNamePrefix="react-select"
                name="selectShiftFunction"
                value={selectedShiftFunction}
                onChange={(value) => {
                  //
                  setSelectedShiftFunction(value);
                }}
                options={selectedShiftData.shiftFunctions?.map((sf) => {
                  return {
                    label: sf.name,
                    value: sf._id,
                    shiftFunction: sf,
                  };
                })}
                placeholder="Select shift Function"
                style={{ margin: "15px 0" }}
              />
            ) : null}
          </div>
        )}
        <div style={{ justifyContent: "space-between", display: "flex" }}>
          <Col>
            <FormText color="danger" tag="span">
              {formErrors.selectedData}
            </FormText>
          </Col>
        </div>
        <div style={{ textAlign: "center" }}>
          <FormText color="danger" tag="span">
            {formErrors.errorSelectedDate}
          </FormText>
        </div>
      </>
    );
  };

  /**
   * 1. Add selected user to available shifts
   * 2. No Params
   * 3. Returns a component to add user to shift with action buttons
   */
  const AddModal = () => {
    return (
      <>
        {selectedUser &&
          (canWork ? (
            <FormGroup style={{ width: 500, backgroundColor: "#fff", borderRadius: 10 }}>
              <div style={{ padding: 25 }}>
                <SelectingUserToWorkComponent />
                <div style={{ justifyContent: "space-between", display: "flex" }}>
                  <Button onClick={handleCloseCommonModal}>Cancel</Button>

                  <Button
                    color="info"
                    disabled={cantAddToThisBusiness}
                    onClick={() => {
                      addUserToSelected(statusEnum.selectedForWork.Backup, selectedShiftFunction);
                    }}
                  >
                    Backup
                  </Button>

                  <Button
                    color="success"
                    disabled={cantAddToThisBusiness}
                    onClick={() => {
                      addUserToSelected(statusEnum.selectedForWork.selected, selectedShiftFunction);
                    }}
                  >
                    Add User
                  </Button>
                </div>
                <div style={{ justifyContent: "space-between", display: "flex" }}>
                  <Button
                    color="warning"
                    onClick={() => {
                      updateStatus(statusEnum.noResponse, selectedUser._id);
                    }}
                  >
                    No Response
                  </Button>
                  <Button
                    color="danger"
                    onClick={() => {
                      updateStatus(statusEnum.unavailable, selectedUser._id);
                    }}
                  >
                    Unavailable
                  </Button>
                </div>
              </div>
            </FormGroup>
          ) : (
            <FormGroup style={{ width: 300, backgroundColor: "#fff", borderRadius: 10 }}>
              <div style={{ padding: 25 }}>
                The Selected user's sin number has already been expired. You cannot add the worker
                to shift. Please update the sin number through the worker's profile.
              </div>
              <div style={{ textAlign: "center" }}>
                <Button color="success" onClick={handleCloseAddUserModal}>
                  OK
                </Button>
              </div>
            </FormGroup>
          ))}
        <div />
      </>
    );
  };

  /**
 * 1. Add selected user to selected shift and update server
 * 2. Params 
 *  1. @status should be enum type @statusEnum => number
 *    2. @shiftFunction  should be object type 
 * {
        name: "Test Shift",
        payRate: 15,
        payBasis: "hourly",
        description: "test shift description",
        shiftBoard: "true"
      },
 * 3. No output returned
 */
  const addUserToSelected = async (status, shiftFunction) => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    const shift = selectedShift;
    const selectedShiftData = shiftsList.find(
      (s) => selectedShift && s._id === selectedShift.value,
    );
    if (!shift) {
      setFormErrors({ ...formErrors, selectedData: "You must select a shift" });
      setIsLoading(false);
      return;
    }

    if (selectedShiftData.shiftFunctions?.length && !shiftFunction) {
      setFormErrors({ ...formErrors, selectedData: "You must select a shift function/line!" });
      setIsLoading(false);
      return;
    }
    const selectedShiftFunction = selectedShiftData.shiftFunctions?.length
      ? selectedShiftData.shiftFunctions.find(
          (_shiftFunction) => _shiftFunction._id === shiftFunction.value,
        )
      : undefined;

    try {
      await api().post("/selected-for-work", {
        user: userId,
        business: selectedShiftData.business,
        businessName: selectedShiftData.businessName,
        shiftName: selectedShiftData.shiftName,
        status: status,
        shift: selectedShiftData._id,
        agency: selectedShiftData.agency,
        start: selectedShiftData.start, //start and end of shift
        end: selectedShiftData.end,
        dayStart: startOfDay(filter.date), //start and end of current day
        dayEnd: startOfNextDay(filter.date),
        shiftFunction: selectedShiftFunction,
      });
      pullLatestData("User successfully updated");
    } catch (error) {
      showErrorMessage(error);
    }
  };

  /**
   * 1. Change selected user between shifts
   * 2. No Params
   * 3. Return component to swap the user between shifts
   */
  const ReAssignShiftModal = () => {
    return (
      <>
        {selectedForWorkstatus ? (
          <div style={{ width: 550, backgroundColor: "#fff", borderRadius: 10 }}>
            <div style={{ padding: 15 }}>
              {selectedForWorkstatus === statusEnum?.selectedForWork?.Rejected && (
                <div style={{ justifyContent: "center", display: "flex" }}>
                  <h5>
                    {selectedUser.firstName} {selectedUser.lastName} has rejected for applied shift
                  </h5>
                </div>
              )}
              {selectedForWorkstatus === statusEnum?.selectedForWork?.UnScheduled && (
                <div style={{ justifyContent: "center", display: "flex" }}>
                  <h5>
                    {selectedUser.firstName} {selectedUser.lastName} has unscheduled for applied
                    shift
                    <p>Unscheduled Reason: {unScheduleReason}</p>
                  </h5>
                </div>
              )}

              {selectedForWorkstatus === statusEnum?.selectedForWork?.Dropped && (
                <div style={{ justifyContent: "center", display: "flex" }}>
                  <h5>
                    {selectedUser.firstName} {selectedUser.lastName} has dropped the applied shift
                  </h5>
                </div>
              )}

              <>
                {selectedUser &&
                  (canWork ? (
                    <FormGroup style={{ width: 500, backgroundColor: "#fff", borderRadius: 10 }}>
                      <div style={{ padding: 25 }}>
                        <SelectingUserToWorkComponent />
                        <div style={{ justifyContent: "space-between", display: "flex" }}>
                          <Button onClick={handleCloseCommonModal}>Cancel</Button>

                          <Button
                            color="info"
                            disabled={cantAddToThisBusiness}
                            onClick={() => {
                              changeShift(statusEnum.selectedForWork.Backup, selectedShiftFunction);
                            }}
                          >
                            Backup
                          </Button>

                          <Button
                            color="success"
                            disabled={cantAddToThisBusiness}
                            onClick={() => {
                              changeShift(
                                statusEnum.selectedForWork.selected,
                                selectedShiftFunction,
                              );
                            }}
                          >
                            Selected
                          </Button>
                        </div>
                      </div>
                    </FormGroup>
                  ) : (
                    <FormGroup style={{ width: 300, backgroundColor: "#fff", borderRadius: 10 }}>
                      <div style={{ padding: 25 }}>
                        The Selected user's sin number has already been expired. You cannot add the
                        worker to shift. Please update the sin number through the worker's profile.
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <Button color="success" onClick={handleCloseReAssignShiftModal}>
                          OK
                        </Button>
                      </div>
                    </FormGroup>
                  ))}
              </>
            </div>
          </div>
        ) : (
          <div>HandleReAssignShiftModal</div>
        )}
      </>
    );
  };

  /**
   * 1. Confirm selected user as backUp/selected/rejected
   * 2. No Params
   * 3. Returns modal with action buttons
   */
  const ConfirmModal = () => {
    if (!selectedUser || !selectedShift) {
      return null;
    }
    return (
      <div style={{ width: 400, backgroundColor: "#fff", borderRadius: 10 }}>
        <div style={{ padding: 15 }}>
          <div style={{ justifyContent: "center", display: "flex" }}>
            <h5>
              Confirm {selectedUser.firstName} {selectedUser.lastName} for a shift?
            </h5>
          </div>
          <div style={{ marginTop: "8px" }}>
            <h5>Selected shift:</h5>
            <p>
              <b>Shift: </b>
              {selectedShift?.shiftName}
              <Select
                classNamePrefix="react-select"
                name="selectShift"
                value={selectedShift}
                onChange={(value) => {
                  setSelectedShift(value);
                  if (
                    selectedUser?.userBans?.length &&
                    selectedUser.userBans.find((b) => b === value.business)
                  ) {
                    setCantAddToThisBusiness(true);
                  } else if (
                    selectedUser.workerPin &&
                    selectedUser.workerPin.filter(
                      (wPin) => wPin.agency === agencyContext.agency.agency._id,
                    ).length > 0 &&
                    !selectedUser.workerPin.some((wPin) => wPin.business === value.business)
                  ) {
                    setAddUserToNewBusiness(true);
                    setCantAddToThisBusiness(false);
                  } else if (
                    selectedUser.workerPin.some(
                      (wPin) =>
                        wPin.business === value.business &&
                        wPin.agency !== agencyContext.agency.agency._id,
                    )
                  ) {
                    setAddUserToNewBusiness(false);
                    setCantAddToThisBusiness(true);
                  } else {
                    setAddUserToNewBusiness(false);
                    setCantAddToThisBusiness(false);
                  }
                }}
                styles={reactSelectStyles}
                options={shiftsList.map((b) => {
                  let timeOfDay = shiftTimeType(b.start);
                  return {
                    label:
                      b.businessName +
                      " : " +
                      timeOfDay +
                      ` @${moment(b.start).format("hh:mm a")}-${moment(b.end).format("hh:mm a")}`,
                    value: b._id,
                    business: b.business,
                    shift: b,
                  };
                })}
                placeholder="Select shift"
                style={{ margin: "15px 0" }}
              />
            </p>
            <p>
              <b>Business: </b>
              {selectedShift?.shift?.businessName} ({shiftTimeType(selectedShift?.shift?.start)})
            </p>
            <p>
              <b>Start: </b>
              {moment(selectedShift?.shift?.start).format("dddd, MMM D, h:mm a")}
            </p>
            <p>
              <b>End: </b>
              {moment(selectedShift?.shift?.end).format("dddd, MMM D, h:mm a")}
            </p>
            <p>
              <b>Shift Function/Line: </b>
              {selectedShiftFunction && selectedShiftFunction.label}
            </p>
            {selectedShiftData?.shiftFunctions?.length ? (
              <Select
                classNamePrefix="react-select"
                name="selectShiftFunction"
                value={selectedShiftFunction}
                onChange={(value) => {
                  //
                  setSelectedShiftFunction(value);
                }}
                options={selectedShiftData.shiftFunctions?.map((sf) => {
                  return {
                    label: sf.name,
                    value: sf._id,
                    shiftFunction: sf,
                  };
                })}
                placeholder="Select shift Function"
                style={{ margin: "15px 0" }}
              />
            ) : null}
          </div>

          {selectedUserIsUntrained ? (
            <>
              <Label style={{ color: "black", fontWeight: "bold" }}>
                Are you sure want to schedule unqualified workers ?
              </Label>
            </>
          ) : selectedShift?.shift?.closed ? (
            <>
              <Label style={{ color: "black", fontWeight: "bold" }}>
                {selectedShift?.shift?.closed && addUserToNewBusiness
                  ? "* (This shift is already closed and this user already works at another location.)"
                  : selectedShift?.shift?.closed
                  ? "* (This shift is already closed.)"
                  : addUserToNewBusiness
                  ? "* (This user already works at another location.)"
                  : ""}
              </Label>
            </>
          ) : null}

          {cantAddToThisBusiness && (
            <div>
              <p style={{ color: "red" }}>
                This user can't be added to this shift. They already have worked at this business
                from another agency.
              </p>
              (or)
              <p style={{ color: "red" }}>This user might have banned from this business.</p>
            </div>
          )}
          {addUserToNewBusiness && (
            <div>
              <p style={{ color: "red" }}>This employee already works at another location.</p>
            </div>
          )}
          <div style={{ marginRight: 15, marginLeft: 15, marginTop: 15 }}></div>
          <div style={{ justifyContent: "space-between", display: "flex" }}></div>
          <div style={{ justifyContent: "space-between", display: "flex" }}>
            <Button onClick={handleCloseCommonModal}>Cancel</Button>
            <Button
              color="info"
              disabled={cantAddToThisBusiness}
              onClick={() => {
                if (selectedShiftData.shiftFunctions?.length > 0) {
                  if (!selectedShiftFunction) {
                    console.log("no shift function, but function options detected");
                  } else {
                    confirmUser(
                      statusEnum.selectedForWork.Backup,
                      selectedShiftFunction?.shiftFunction,
                      selectedShift?.shift,
                    );
                  }
                } else {
                  confirmUser(
                    statusEnum.selectedForWork.Backup,
                    selectedShiftFunction?.shiftFunction,
                    selectedShift?.shift,
                  );
                }
              }}
            >
              Backup
            </Button>
          </div>
          <div style={{ justifyContent: "space-between", display: "flex" }}>
            <Button color="danger" onClick={() => confirmUser(statusEnum.selectedForWork.Rejected)}>
              Reject
            </Button>
            <Button
              color="success"
              disabled={cantAddToThisBusiness}
              onClick={() => {
                if (selectedShiftData.shiftFunctions?.length > 0) {
                  if (!selectedShiftFunction) {
                    console.log("no shift function, but function options detected");
                  } else {
                    confirmUser(
                      statusEnum.selectedForWork.selected,
                      selectedShiftFunction?.shiftFunction,
                      selectedShift?.shift,
                    );
                  }
                } else {
                  confirmUser(
                    statusEnum.selectedForWork.selected,
                    selectedShiftFunction?.shiftFunction,
                    selectedShift?.shift,
                  );
                }
              }}
            >
              Add
            </Button>
          </div>
        </div>
      </div>
    );
  };

  /**
 * 1. update selected for work status backup/selected/rejected to server
 * 2. Params 
 *    1. @status should be enum type @statusEnum => number
 *    2. @shiftFunction  should be object type 
 * {
        name: "Test Shift",
        payRate: 15,
        payBasis: "hourly",
        description: "test shift description",
        shiftBoard: "true"
      },
 *    3. @shift should be object type 
 * 3. Returns modal with action buttons
 */

  const confirmUser = async (status, shiftFunction, shift) => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    try {
      const selectedForWorkId = selectedUser?.selectForWork[0]?._id;
      if (selectedForWorkId) {
        const updates = {
          status: status,
          shiftFunction,
        };
        if (shift) {
          updates.shift = shift._id;
          updates.start = shift.start;
          updates.end = shift.end;
          updates.business = shift.business;
          updates.businessName = shift.businessName;
          updates.userId = userId;
        }

        const resp = await api().patch(`/selected-for-work/${selectedForWorkId}`, updates);
        pullLatestData("User Successfully updated!");
      }
    } catch (error) {
      showErrorMessage(error);
    }
  };

  /**
   * 1. Remove selected user from selected shift before or else change user to another shift either as a backup/selected
   * 2. No Params
   * 3. Returns modal with action buttons necessary to remove woker or change to another shift
   */
  const RemoveModal = () => {
    return (
      <>
        {selectedUser ? (
          <div style={{ width: 550, backgroundColor: "#fff", borderRadius: 10 }}>
            <div style={{ padding: 15 }}>
              <div style={{ justifyContent: "center", display: "flex" }}>
                <h5>
                  Do you want to change status of {selectedUser.firstName} {selectedUser.lastName}{" "}
                  from All Employees?
                </h5>
              </div>
              {selectedUser &&
                selectedUser.selectForWork.map((sfw) => (
                  <div key={sfw._id} style={{ marginTop: "8px" }}>
                    <h5>Selected shift:</h5>
                    <p>
                      <b>Shift: </b>
                      {sfw.shiftName}
                    </p>
                    <p>
                      <b>Business: </b>
                      {sfw.businessName}({shiftTimeType(sfw.start)})
                    </p>
                    <p>
                      <b>Start: </b>
                      {moment(sfw.start).format("LLLL")}
                    </p>
                    <p>
                      <b>End: </b>
                      {moment(sfw.end).format("LLLL")}
                    </p>
                    {sfw.backup && (
                      <p>
                        <b>* Selected as a backup </b>
                      </p>
                    )}
                    {sfw.shiftFunction && (
                      <p>
                        <b>Shift Function:</b> {sfw.shiftFunction.name}
                      </p>
                    )}
                  </div>
                ))}
              <div style={{ marginRight: 15, marginLeft: 15, marginTop: 15 }}>
                <Row>
                  <Col className="checkbox-radios" sm="10">
                    <div className="form-check-radio" style={{ marginTop: 8 }}>
                      <Label check>
                        <Input
                          defaultValue="Unschedule"
                          id="Unschedule"
                          name="UnscheduleRadio"
                          type="radio"
                          onChange={() => setAction("Unschedule")}
                          checked={action === "Unschedule"}
                        />
                        Unschedule <span className="form-check-sign" />
                      </Label>
                    </div>
                    {(selectedUser?.selectForWork[0]?.status ===
                      statusEnum.selectedForWork.Backup ||
                      selectedUser?.selectForWork[0]?.status ===
                        statusEnum.selectedForWork.Applied) && (
                      <div className="form-check-radio">
                        <Label check>
                          <Input
                            defaultValue="ChangeStatusToSelected"
                            id="ChangeStatusToSelected"
                            name="ChangeStatusSelectedRadio"
                            type="radio"
                            checked={action === "ChangeStatusSelected"}
                            onChange={() => setAction("ChangeStatusSelected")}
                          />
                          Change to regular selected
                          <span className="form-check-sign" />
                        </Label>
                      </div>
                    )}
                    {(selectedUser?.selectForWork[0]?.status ===
                      statusEnum.selectedForWork.selected ||
                      selectedUser?.selectForWork[0]?.status ===
                        statusEnum.selectedForWork.Applied) && (
                      <div className="form-check-radio">
                        <Label check>
                          <Input
                            defaultValue="ChangeStatusToBackup"
                            id="ChangeStatusToBackup"
                            name="ChangeStatusRadio"
                            type="radio"
                            checked={action === "ChangeStatusBackup"}
                            onChange={() => setAction("ChangeStatusBackup")}
                          />
                          Change to backup
                          <span className="form-check-sign" />
                        </Label>
                      </div>
                    )}

                    <div className="form-check-radio">
                      <Label check>
                        <Input
                          defaultValue="ChangeShift"
                          id="ChangeShift"
                          name="ChangeShiftRadio"
                          type="radio"
                          checked={action === "ChangeShift"}
                          onChange={() => setAction("ChangeShift")}
                        />
                        Schedule to another shift/ Change shift <span className="form-check-sign" />
                      </Label>
                      <FormText color="danger" tag="span">
                        {/* {this.state.errorGender} */}
                      </FormText>
                    </div>
                  </Col>
                </Row>

                {action === "Unschedule" ? (
                  <>
                    <Label for="Search" style={{ color: "black", fontSize: "14px" }}>
                      <b>Unschedule Reason</b>
                    </Label>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="singleSelect"
                      value={unscheduleReason}
                      onChange={(value) => setUnscheduleReason(value)}
                      id="unscheduleReason"
                      options={UnScheduleReasonList}
                      placeholder="Select Unscheduled Reason"
                    />
                    <FormText color="danger" tag="span">
                      {formErrors.unscheduleReason}
                    </FormText>
                    <div style={{ justifyContent: "space-between", display: "flex" }}></div>
                    <div style={{ justifyContent: "space-between", display: "flex" }}>
                      <Button onClick={handleCloseCommonModal}>Cancel</Button>
                      <Button
                        color="danger"
                        onClick={() => {
                          if (unscheduleReason === "" || unscheduleReason === null) {
                            setFormErrors({
                              ...formErrors,
                              unscheduleReason: "* Must select a reason",
                            });
                            return;
                          }
                          removeUserFromSelected();
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                  </>
                ) : action === "ChangeStatusSelected" ? (
                  <>
                    <FormGroup row className="align-items-right">
                      <Label for="Search" style={{ color: "black", fontSize: "14px" }}>
                        <b>Are you sure want to change status to regular selected?</b>
                      </Label>
                    </FormGroup>
                    <div style={{ justifyContent: "space-between", display: "flex" }}></div>
                    <div style={{ justifyContent: "space-between", display: "flex" }}>
                      <Button onClick={handleCloseCommonModal}>Cancel</Button>
                      <Button
                        color="danger"
                        onClick={() =>
                          changeStatus(
                            selectedUser?.selectForWork,
                            statusEnum.selectedForWork.selected,
                          )
                        }
                      >
                        Change
                      </Button>
                    </div>
                  </>
                ) : action === "ChangeStatusBackup" ? (
                  <>
                    <FormGroup row className="align-items-right">
                      <Label for="Search" style={{ color: "black", fontSize: "14px" }}>
                        <b>Are you sure want to change status to backup?</b>
                      </Label>
                    </FormGroup>
                    <div style={{ justifyContent: "space-between", display: "flex" }}></div>
                    <div style={{ justifyContent: "space-between", display: "flex" }}>
                      <Button onClick={handleCloseCommonModal}>Cancel</Button>
                      <Button
                        color="danger"
                        onClick={() =>
                          changeStatus(
                            selectedUser?.selectForWork,
                            statusEnum.selectedForWork.Backup,
                          )
                        }
                      >
                        Change
                      </Button>
                    </div>
                  </>
                ) : action === "ChangeShift" ? (
                  <>
                    {selectedUser &&
                      (canWork ? (
                        <FormGroup
                          style={{ width: 500, backgroundColor: "#fff", borderRadius: 10 }}
                        >
                          <div style={{ padding: 25 }}>
                            <SelectingUserToWorkComponent />

                            <div style={{ justifyContent: "space-between", display: "flex" }}>
                              <Button onClick={handleCloseCommonModal}>Cancel</Button>

                              <Button
                                color="info"
                                disabled={cantAddToThisBusiness}
                                onClick={() => {
                                  changeShift(
                                    statusEnum.selectedForWork.Backup,
                                    selectedShiftFunction,
                                  );
                                }}
                              >
                                Backup
                              </Button>

                              <Button
                                color="success"
                                disabled={cantAddToThisBusiness}
                                onClick={() => {
                                  changeShift(
                                    statusEnum.selectedForWork.selected,
                                    selectedShiftFunction,
                                  );
                                }}
                              >
                                Selected
                              </Button>
                            </div>
                          </div>
                        </FormGroup>
                      ) : (
                        <FormGroup
                          style={{ width: 300, backgroundColor: "#fff", borderRadius: 10 }}
                        >
                          <div style={{ padding: 25 }}>
                            The Selected user's sin number has already been expired. You cannot add
                            the worker to shift. Please update the sin number through the worker's
                            profile.
                          </div>
                          <div style={{ textAlign: "center" }}>
                            <Button color="success" onClick={handleCloseAddUserModal}>
                              OK
                            </Button>
                          </div>
                        </FormGroup>
                      ))}
                  </>
                ) : (
                  <div>
                    <Button onClick={handleCloseCommonModal}>Cancel</Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div>Remove User Modal</div>
        )}
      </>
    );
  };

  /**
   * 1. Remove selected user and update the shift status
   * 2. No Params
   * 3. No returns
   */
  const removeUserFromSelected = async () => {
    setIsLoading(true);
    const selectedForWorkId = selectedUser.selectForWork[0]._id;

    try {
      await api().patch(`/selected-for-work/${selectedForWorkId}`, {
        status: statusEnum.selectedForWork.UnScheduled,
        unscheduleReason: unscheduleReason?.value,
        userId,
        start: selectedUser.selectForWork[0].start,
        end: selectedUser.selectForWork[0].end,
        dayStart: startOfDay(filter.date), //start and end of current day
        dayEnd: startOfNextDay(filter.date),
      });
      pullLatestData("User successfully removed");
    } catch (error) {
      showErrorMessage(error);
    }
  };

  /**
   * 1. Change status  of selected worker from backup to selected or selected to backup
   * 2. Params
   *     1. @status should be enum type @statusEnum => number
   *     2. @data should be an object selected for work data example { _id: "string", start: "date", end: "date", status: number }
   * 3. No Returns
   */
  const changeStatus = async (data, status) => {
    try {
      setIsLoading(true);
      await api().patch(`/selected-for-work/${data[0]._id}`, { status });
      pullLatestData("User work status successfully updated");
    } catch (err) {
      showErrorMessage(err);
    }
  };

  /**
 * 1. Change shift of selected worker
 * 2. Params
 *     1. @status should be enum type @statusEnum => number
 *     2. @shiftFunction  should be object type 
 * {
        name: "Test Shift",
        payRate: 15,
        payBasis: "hourly",
        description: "test shift description",
        shiftBoard: "true"
      },
 * 3.No returns
 */
  const changeShift = async (status, shiftFunction) => {
    try {
      if (isLoading) {
        return;
      }
      setIsLoading(true);
      const shift = selectedShift;
      const selectedShiftData = shiftsList.find(
        (s) => selectedShift && s._id === selectedShift.value,
      );
      if (!shift) {
        setFormErrors({ ...formErrors, selectedData: "You must select a shift" });
        setIsLoading(false);
        return;
      }
      if (selectedShiftData.shiftFunctions?.length && !shiftFunction) {
        setFormErrors({ ...formErrors, selectedData: "You must select a shift function/line!" });
        setIsLoading(false);
        return;
      }
      const selectedShiftFunction = selectedShiftData.shiftFunctions?.length
        ? selectedShiftData.shiftFunctions.find(
            (_shiftFunction) => _shiftFunction._id === shiftFunction.value,
          )
        : undefined;

      setIsLoading(true);
      await api().patch(`/selected-for-work/${selectedUser?.selectForWork[0]?._id}`, {
        business: selectedShiftData.business,
        businessName: selectedShiftData.businessName,
        status: status,
        shift: selectedShiftData._id,
        start: selectedShiftData.start, //start and end of shift
        end: selectedShiftData.end,
        shiftFunction: selectedShiftFunction,
        userId: selectedUser._id,
      });
      pullLatestData("User shift changed successfully");
    } catch (err) {
      showErrorMessage(err);
    }
  };

  /**
   * 1. Display proper modal for selected modal type
   * 2. Params
   *     1. @modalType string one of the @USER_MODAL_TYPES example like "addUser"
   * 3. returns modal based on prop type
   */
  const DisplayAppropriateModal = ({ modalType }) => {
    switch (modalType) {
      case USER_MODAL_TYPES.ADD_USER:
        return <AddModal />;
      case USER_MODAL_TYPES.REASSIGN_USER:
        return <ReAssignShiftModal />;
      case USER_MODAL_TYPES.CONFIRM_USER:
        return <ConfirmModal />;
      case USER_MODAL_TYPES.REMOVE_USER:
        return <RemoveModal />;
      default:
        return <></>;
    }
  };

  return (
    modalType && (
      <Modal
        open={openCommonModal}
        onClose={handleCloseCommonModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 15,
          overflow: "scroll",
        }}
      >
        <DisplayAppropriateModal modalType={modalType} />
      </Modal>
    )
  );
}

export default AllUsersModals;
