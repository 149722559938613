const saveStateToLocalStorage = (user) => {
  localStorage.setItem("staffingUser", JSON.stringify(user));
  return user;
};

const staffingReducer = (state, action) => {
  switch (action.type) {
    case "SET_USER":
      saveStateToLocalStorage(action.payload.user);
      return { ...state, user: action.payload.user };
    case "REMOVE_USER":
      saveStateToLocalStorage(null);
      return { ...state };
    default:
      return state;
  }
};

export default staffingReducer;
