import LoadingOverlay from "components/OverLay/LoadingOverlay";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import ReactTable from "react-table";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  FormGroup,
  Label,
  FormText,
  Input
} from "reactstrap";
import api from "components/API/api";
import { appointmentStatus,  CHEQUE_PICKUP_MODAL_TYPES } from "utils/statusVariables";
import {
  Add,
  CheckBox,
  Clear,
  Comment,
  IndeterminateCheckBox,
  DirectionsRun,
  Refresh,
} from "@mui/icons-material";
import { Modal } from "@mui/material";
import Permission from "components/permissions/Permission";
import SweetAlert from "react-bootstrap-sweetalert";
import QrReader from "react-qr-reader";
import ImageDisplay from "components/ImageDisplay";
import { DocumentType } from "../allUserUtilities";

const CAMERA_TIME = 20; // seconds that the QR scanner is active
const UserAppointments = (props) => {
  const [appointments, setAppointments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const lastScanned = useRef(new Date());
  const scanned = useRef([]);
  const [pages, setPages] = useState(-1);
  const [pickedByOther, setPickedByOther] = useState(false);
  const [pickedByOwner, setPickedByOwner] = useState(false);
  const [agree, setAgree] = useState(false);
  const [alert, setAlert] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [openCommonModal, setOpenCommonModal] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [comment, setComment] = useState("");
  const [images, setImages] = useState(null); // Images for worker to verify
  const [errorComment] = useState("");
  const [deletionReason, setDeletionReason] = useState("");
  const [errorDeletion, setErrorDeletion] = useState("");
    // cheque picked up by other = > other details
    const [details, setDetails] = useState({
      firstName: "",
      lastName: "",
      relation: "",
    });
    const [errorDetails, setErrorDetails] = useState({
      firstName: "",
      lastName: "",
      relation: "",
    });

  useEffect(() => {
    fetchAppointments();
  }, []);

  /**
   * Description: fetch all appointments of a user
   * No input params
   * No output returns
   */
  const fetchAppointments = async () => {
    setIsLoading(true);
    try {
      const { data } = await api().get("/all-user-appointments", {
        params: {
          userId: props.userId,
        },
      });

      const tempAppointments = [];
      data.appointments.map((d) => {
        const userInfo = d.booked.find((d) => d.user === props.userId);
        const data = {
          ...d,
          ...userInfo,
          _id: d._id,
          fullName: userInfo?.firstName + ' ' + userInfo?.lastName,
          businessName: userInfo?.businessName,
          slot: `${moment(d.start).format("YYYY/MM/DD")} ${moment(d.start).format(
            "h:mm a",
          )}-${moment(d.end).format("h:mm a")}`,
          startOfWeek: moment(userInfo.weekStart).format("YYYY/MM/DD"),
          endOfWeek: moment(userInfo.weekEnd).format("YYYY/MM/DD"),
        };
        tempAppointments.push({
           ...data,
          actions: RenderActionButtons(data)
        })

      });
      setAppointments(tempAppointments);
      setPages(data.noPages);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };


  /**
   * Description: load images of user to verify signature and id for cheque pickup
   * No input params
   * No output returns
   */
  const loadImages = async (userData) => {
    try {
      setIsLoading(true);
      let images = [];
      const { data } = await api().get(`/users/documents?userId=${userData.user}`);

      data.map((doc) => {
        if (doc.type === DocumentType.id || doc.type === DocumentType.signature) {
          console.log(doc);
          images.push({
            id: doc._id,
            img: doc.url,
            displayName: doc.type === DocumentType.id ? "Photo ID" : "Original Signature",
          });
        }
      });

      if (userData.proof && userData.proof.status === "pending") {
        const proof = await api().get(
          `image/sign-s3/get?file-path=signature/${userData.user}/${userData?.proof?.signature}`,
        );
        images.push({ id: "proof", displayName: "Signature Proof", img: proof?.data?.signedUrl });
      }

      setImages([...images]);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  /**
   * Description: Function to render action buttons
   * Input 
   *  @data - appointment info as object
   * Returns action buttons as output
   */
  const RenderActionButtons = (data) => {
    return (
      <div className="actions-center">
        {data.status === appointmentStatus.processing && (
          <Button
            onClick={() => {
              handleOpenCommonModal(data, CHEQUE_PICKUP_MODAL_TYPES.signature);
              data && loadImages(data);
            }}
            color="success"
            size="md"
            className="btn-link btn-icon"
          >
            <Add style={{ marginLeft: -1 }} />
          </Button>
        )}
        {data.status === appointmentStatus.ready && (
          <Button
            onClick={() => {
              handleOpenCommonModal(data, CHEQUE_PICKUP_MODAL_TYPES.verifyUser);
            }}
            color="success"
            size="md"
            className="btn-link btn-icon"
          >
            <CheckBox style={{ marginLeft: -1 }} />
          </Button>
        )}
        {data.status === appointmentStatus.pending ? (
          <>
            <Button
              onClick={() => {
                handleOpenCommonModal(data, CHEQUE_PICKUP_MODAL_TYPES.chequePickup);
              }}
              color="success"
              size="md"
              className="btn-link btn-icon"
            >
              <IndeterminateCheckBox style={{ marginLeft: -1 }} />
            </Button>
          </>
        ) : null}
        <Button
          onClick={() => {
            handleOpenCommonModal(data, CHEQUE_PICKUP_MODAL_TYPES.comment);
          }}
          color="info"
          size="md"
          className="btn-link remove btn-icon"
        >
          <Comment style={{ marginLeft: -1 }} />
        </Button>
        {data.status === appointmentStatus.pending ? (
          <Button
            disabled={!moment().isAfter(data.end)}
            color={"danger"}
            style={{ backgroundColor: "transparent" }}
            size="md"
            className="btn-link btn-icon"
            onClick={() => {
              handleOpenCommonModal(data, CHEQUE_PICKUP_MODAL_TYPES.chequeMissed);
            }}
          >
            <DirectionsRun />
          </Button>
        ) : null}
        {data.status === appointmentStatus.pending ? (
          <Permission
            module="Cheque"
            action="Delete"
            error="Not enough permission to perform this action."
          >
            <Button
              onClick={() => {
                handleOpenCommonModal(data, CHEQUE_PICKUP_MODAL_TYPES.chequeDelete);
              }}
              color="danger"
              size="md"
              className="btn-link btn-icon"
            >
              <Clear style={{ marginLeft: -1 }} />
            </Button>
          </Permission>
        ) : null}
      </div>
    );
  };

  /**
   * Description: After updating appointment status display alert and fetch latest appointments data
   * Input 
   *    @title - string - title text in alert
   *    @body - string - body text in alert
   * No output returns
   */
  const onUpdate=(title, body) => {
    fetchAppointments();
    setAlert(
      <SweetAlert
        success
        title={title}
        onConfirm={() => {
          hideAlert();
        }}
        showConfirm={true}
      >
        {body}
      </SweetAlert>,
    );
  }


  /**
   * Description: Display error message fails in upddating appointment status
   * Input 
   *    @title - string - title text in alert
   *    @body - string - body text in alert
   * No output returns
   */
  const onError=(title, body) => {
    setAlert(
      <SweetAlert error title={title} onConfirm={hideAlert} showConfirm={true}>
        {body}
      </SweetAlert>,
    );
  }


   /**
   * Description: Set alert to null
   * No inputs
   * No output returns
   */
  const hideAlert = () => {
    setAlert(null);
  };

  /**
   * Description: Open Modal for action buttons in react table
   * Input 
   *    @data - object - selected appointment info
   *    @modalType - string - return modal type
   * No output returns
   */
  const handleOpenCommonModal = (data, modalType) => {
    setSelectedAppointment(data);
    setOpenCommonModal({ verifySignatureModal: true });
    setModalType(modalType);
    setComment(data.note);  
  }


   /**
   * Description: function to close modal and reset the states
   * No inputs
   * No output returns
   */
  const handleCloseCommonModal = () => {
    setOpenCommonModal(false);
    setModalType(null);
    setComment("");
    setSelectedAppointment(null);
    setComment("");
    setAgree(false);
    setImages(null);
    setErrorDeletion("");
    setDeletionReason("");
    setDetails({
        firstName: "",
        lastName: "",
        relation: "",
      });
      setErrorDetails({
        firstName: "",
        lastName: "",
        relation: "",
      });
      setPickedByOther(false);
      setPickedByOwner(false);
  };

  
  /**
   * Description: Display the comments in a modal
   * No inputs
   * Returns Modal to add comments
   */
  const commentsModal = () => {
    if (!selectedAppointment) return null;
    return (
      <Modal
        open={openCommonModal && modalType === CHEQUE_PICKUP_MODAL_TYPES.comment}
        onClose={handleCloseCommonModal}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 350, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>{`Admin notes for ${selectedAppointment.fullName}'s appointment`}</h5>
            </div>
            <div
              style={{ justifyContent: "space-between", display: "flex", flexDirection: "column" }}
            >
              <textarea
                name="adminNotes"
                cols="40"
                rows="5"
                autoComplete="off"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              ></textarea>
            </div>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button
                color="secondary"
                style={{ marginRight: "12px" }}
                onClick={handleCloseCommonModal}
              >
                Cancel
              </Button>{" "}
              <Button
                color="primary"
                onClick={() => {
                  if (!comment) {
                    return;
                  }
                  api()
                    .patch("/appointment-slot/cheque-pickup/comment", {
                      slot: selectedAppointment._id,
                      user: selectedAppointment.user,
                      comment: comment,
                    })
                    .then(() => {
                      handleCloseCommonModal();
                      onUpdate(
                        "SuccessFully",
                        `Successfully Added Comment for ${selectedAppointment.fullName}`,
                      );
                    })
                    .catch((e) => {
                      onError("Error adding comment", e.response?.data?.message || e.message);
                    });
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };


   /**
   * Description: Display the modal to update missed status of appointment
   * No inputs
   * Returns Modal to update missed appointment
   */
  function missedAppointmentModal() {
    if (!selectedAppointment) return null;
    return (
      <Modal
      open={openCommonModal && modalType === CHEQUE_PICKUP_MODAL_TYPES.chequeMissed}
        onClose={handleCloseCommonModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 500, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>
                {`Change status of ${selectedAppointment.fullName}'s appointment as Missed ?`}
              </h5>
            </div>

            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button
                color="secondary"
                style={{ marginRight: "12px" }}
                onClick={handleCloseCommonModal}
              >
                Cancel
              </Button>

              <Button
                color="primary"
                onClick={async () => {
                  handleOpenCommonModal(data, CHEQUE_PICKUP_MODAL_TYPES.confirmChequeMissed);
                }}
              >
                Mark Appointment as Missed
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  }

   /**
   * Description: Display the modal to confirm missed status of appointment
   * No inputs
   * Returns Modal to confirm missed appointment
   */
  function confirmMissedAppointmentModal() {
    return (
      <Modal
      open={openCommonModal && modalType ===CHEQUE_PICKUP_MODAL_TYPES.confirmChequeMissed}
      onClose={handleCloseCommonModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 400, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>Confirm marking the Appointment as Missed? This action cannot be undone</h5>
            </div>

            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button
                color="secondary"
                style={{ marginRight: "12px" }}
                onClick={handleCloseCommonModal}
              >
                Cancel
              </Button>
              <Button
                color="danger"
                onClick={async () => {
                  const dataToSend = {
                    slotId: selectedAppointment._id,
                    userId: selectedAppointment.user,
                  };

                  try {
                    await api().post("/appointment-slot/cheque-pickup/missed", dataToSend);
                    onUpdate("SuccessFull", `Marked user missed the appointment`);
                  } catch (e) {
                    onError("Error changing status", e.response?.data?.message || e.message);
                  }
                  handleCloseCommonModal();
                }}
              >
                Mark Appointment as Missed
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  }


   /**
   * Description: Display the modal to verify the user signature for cheque pickup
   * No inputs
   * Returns Modal with user signature info
   */
  const verifySignatureModal = () => {
    if (!selectedAppointment) return null;

    return (
      selectedAppointment && (
        <Modal
        open={openCommonModal && modalType === CHEQUE_PICKUP_MODAL_TYPES.signature}
          onClose={handleCloseCommonModal}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "scroll",
            paddingTop: 80,
            paddingBottom: 20,
          }}
        >
          {isLoading ? (
            <LoadingOverlay />
          ) : (
            <FormGroup style={{ width: 800, backgroundColor: "#fff", borderRadius: 10 }}>
              <div style={{ padding: 15 }}>
                <Row
                  md="12"
                  style={{ justifyContent: "space-between", display: "flex", alignItems: "center" }}
                >
                  <Col md="10">
                    <h5 style={{ fontWeight: "bold" }}>
                      {selectedAppointment.proof
                        ? "Verify the worker signature"
                        : "Please wait for worker to upload signature"}
                    </h5>
                  </Col>
                  <Col md="2">
                    <Button
                      onClick={() => {
                        // fetch signature proof
                        fetchAppointments();
                      }}
                      color="default"
                      size="md"
                      className="btn"
                      outline
                    >
                      <Refresh style={{ marginLeft: -1 }} />
                    </Button>
                  </Col>
                </Row>
                <div
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                    padding: 30,
                  }}
                >
                  <Row>
                    <Label
                      md="2"
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      * Notes
                    </Label>
                    <Col md="10">
                      <FormGroup>
                        <textarea
                          name="adminNotes"
                          cols="40"
                          rows="3"
                          autoComplete="off"
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        ></textarea>
                        <FormText color="danger" tag="span">
                          {errorComment}
                        </FormText>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                {selectedAppointment?.pickupBy?.owner ? (
                  <div style={{ justifyContent: "center", padding: 30 }}>
                    <h6 style={{ textAlign: "center" }}>Picked Up By Owner</h6>

                    <Row
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      Full Name: {selectedAppointment?.fullName}
                    </Row>
                  </div>
                ) : (
                  <div style={{ justifyContent: "center", padding: 30 }}>
                    <h6 style={{ textAlign: "center" }}>Picked Up By Other</h6>
                    <Row
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      Full Name:
                      {selectedAppointment?.pickupBy?.firstName +
                        " " +
                        selectedAppointment?.pickupBy?.lastName}
                    </Row>
                    <Row>Relationship: {selectedAppointment?.pickupBy?.relationship}</Row>
                  </div>
                )}

                <Row>{images?.map((doc) => doc.img && <ImageDisplay document={doc} />)}</Row>
                <span>* please click on image to enlarge</span>

                <Row style={{ marginTop: 30 }}>
                  <Col>
                    <FormGroup check inline>
                      <Label check>
                        <Input type="checkbox" checked={agree} onChange={() => setAgree(!agree)} />
                        <span className="form-check-sign" />I agree that the signature is verified
                        and that the cheque has been issued.
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <div style={{ justifyContent: "space-between", display: "flex" }}>
                  <Button
                    color="danger"
                    style={{ marginRight: "12px" }}
                    onClick={async () => {
                      try {
                        await api().post("/appointment-slot/cheque-pickup/sign/approve", {
                          slot: selectedAppointment._id,
                          user: selectedAppointment.user,
                          approve: false,
                        });
                        onUpdate(
                          "Not Verified",
                          `Successfully rejected signature for ${selectedAppointment.fullName}`,
                        );
                      } catch (e) {
                        onError("Error occured", e.response?.data?.message || e.message);
                      }
                      handleCloseCommonModal();
                    }}
                  >
                    Not Verified/ Reject
                  </Button>{" "}
                  <Button
                    color="success"
                    disabled={!agree}
                    onClick={async () => {
                      try {
                        if (comment !== selectedAppointment.note) {
                          await api().patch("/appointment-slot/cheque-pickup/comment", {
                            slot: selectedAppointment._id,
                            user: selectedAppointment.user,
                            comment: comment,
                          });
                        }
                        await api().post("/appointment-slot/cheque-pickup/sign/approve", {
                          slot: selectedAppointment._id,
                          user: selectedAppointment.user,
                          approve: true,
                        });

                        await api().post("/cheque-pickup/admin", {
                          user: selectedAppointment.user,
                          pickedUp: true,
                          pickupBy: selectedAppointment.pickupBy,
                          proof: selectedAppointment.proof,
                          note: comment,
                          weekStart: selectedAppointment.weekStart,
                          weekEnd: selectedAppointment.weekEnd,
                          business: selectedAppointment.business,
                        });

                        onUpdate(
                          "SuccessFully",
                          `Successfully marked as cheque pickup for ${selectedAppointment.fullName}`,
                        );
                      } catch (e) {
                        onError("Error changing status", e.response?.data?.message || e.message);
                      }
                      handleCloseCommonModal();
                    }}
                  >
                    Verified/Cheque Issued
                  </Button>
                </div>
              </div>
            </FormGroup>
          )}
        </Modal>
      )
    );
  };


   /**
   * Description: Display the modal to delete the selected appointment
   * No inputs
   * Returns Modal to delete appointment slot
   */
  const deleteChequePickupModal = () => {
    if (!selectedAppointment) return null;
    return (
      <Modal
      open={openCommonModal && modalType === CHEQUE_PICKUP_MODAL_TYPES.chequeDelete}
        onClose={handleCloseCommonModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 550, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>{`Delete ${selectedAppointment.fullName}'s appointment?`}</h5>
            </div>
            <div>
              <Row>
                <Col md="3">Reason:</Col>
                <Col md="9">
                  <Input
                    type="textarea"
                    value={deletionReason}
                    onChange={(e) => {
                      setDeletionReason(e.target.value);
                    }}
                  />
                  <p style={{ color: "red" }}>{errorDeletion}</p>
                </Col>
              </Row>
            </div>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button
                color="secondary"
                style={{ marginRight: "12px" }}
                onClick={handleCloseCommonModal}
              >
                Close
              </Button>{" "}
              <Button
                color="warning"
                onClick={async () => {
                  const dataToSend = {
                    slotId: selectedAppointment._id,
                    userId: selectedAppointment.user,
                    reason: deletionReason,
                  };
                  if (!deletionReason) {
                    setErrorDeletion("Must enter a reason of appointment cancellation");
                    return;
                  } else {
                    setErrorDeletion("");
                  }
                  try {
                    await api().post("/appointment-slot/cheque-pickup/cancel", dataToSend);
                    onUpdate("SuccessFull", `Successfully removed cheque pickup appointment`);
                  } catch (e) {
                    onError("Error removing appointment", e.response?.data?.message || e.message);
                  }
                  handleCloseCommonModal();
                }}
              >
                Cancel Appointment
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

   /**
   * Description: Display the modal to mark cheque as ready for pickup
   * No inputs
   * Returns Modal to update cheque is ready to pickup by user
   */
  const chequePickupModal = () => {
    if (!selectedAppointment) return null;
    return (
      <Modal
      open={openCommonModal && modalType === CHEQUE_PICKUP_MODAL_TYPES.chequePickup}
        onClose={handleCloseCommonModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 500, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>
                {`Change status of ${selectedAppointment.fullName}'s appointment as Ready for Pickup ?`}
              </h5>
            </div>
            <div style={{ justifyContent: "center", display: "flex", flexDirection: "column" }}>
              <Row>
                <Label md="2">* Notes</Label>
                <Col md="10">
                  <FormGroup>
                    <textarea
                      name="adminNotes"
                      cols="40"
                      rows="5"
                      autoComplete="off"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    ></textarea>
                    <FormText color="danger" tag="span">
                      {errorComment}
                    </FormText>
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button
                color="secondary"
                style={{ marginRight: "12px" }}
                onClick={handleCloseCommonModal}
              >
                Cancel
              </Button>{" "}
              <Button
                color="primary"
                onClick={async () => {
                  try {
                    if (comment !== selectedAppointment.note) {
                      await api().patch("/appointment-slot/cheque-pickup/comment", {
                        slot: selectedAppointment._id,
                        user: selectedAppointment.user,
                        comment: comment,
                      });
                    }
                    await api().post("/appointment-slot/cheque-pickup/ready-for-pickup", {
                      slotId: selectedAppointment._id,
                      userId: selectedAppointment.user,
                    });

                    onUpdate(
                      "SuccessFully",
                      `Successfully marked as cheque pickup for ${selectedAppointment.fullName}`,
                    );
                  } catch (e) {
                    onError("Error changing status", e.response?.data?.message || e.message);
                  }
                  handleCloseCommonModal();
                }}
              >
                Mark as Ready for Pickup
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  const handleError = (err) => {
    console.error(err);
  };

   /**
   * Description: Display the modal to enter details of cheque pickup person
   * No inputs
   * Returns Modal to add cheque pickup user details
   */
  const verifyUserModal = () => {
    if (!selectedAppointment) return null;
    return (
      <Modal
      open={openCommonModal && modalType === CHEQUE_PICKUP_MODAL_TYPES.verifyUser}
        onClose={handleCloseCommonModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "scroll",
          paddingTop: 80,
          paddingBottom: 20,
        }}
      >
        <FormGroup style={{ width: 500, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "space-between", display: "flex", alignItems: "center" }}>
              <h5>Who is Picking up Cheque ?</h5>
              <Button
                onClick={handleCloseCommonModal}
                color="default"
                size="md"
                className="btn-link remove btn-icon"
                style={{ marginTop: -4 }}
              >
                <Clear style={{ marginLeft: -1 }} />
              </Button>
            </div>
            <div style={{ justifyContent: "center", display: "flex", flexDirection: "column" }}>
              <Row>
                <Label md="2">* Notes</Label>
                <Col md="10">
                  <FormGroup>
                    <textarea
                      name="adminNotes"
                      cols="40"
                      rows="5"
                      autoComplete="off"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    ></textarea>
                    <FormText color="danger" tag="span">
                      {errorComment}
                    </FormText>
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button
                color="warning"
                style={{ marginRight: "12px" }}
                onClick={() => {
                  setPickedByOther(true);
                  setPickedByOwner(false);
                }}
              >
                PickUp By Other
              </Button>{" "}
              <Button
                color="success"
                style={{ marginRight: "12px" }}
                onClick={() => {
                  setPickedByOwner(true);
                  setPickedByOther(false);
                }}
              >
                PickUp By Owner
              </Button>{" "}
              <Button
                color="danger"
                style={{ marginRight: "12px" }}
                onClick={() => {
                  setIsLoading(true);
                  api()
                    .post("/appointment-slot/cheque-pickup/owner", {
                      userId: selectedAppointment.user,
                      slot: selectedAppointment._id,
                    })
                    .then(() => {
                      onUpdate(
                        "Notification send successfully",
                        `Please wait for worker to upload signature`,
                      );
                      handleCloseCommonModal();
                      setIsLoading(false);
                    })
                    .catch((e) => {
                      console.log(e);
                      setIsLoading(false);
                      handleCloseCommonModal();
                      onError("Worker not found!", e.response?.data?.message || e.message);
                    });
                }}
              >
                Skip Qr Scan
              </Button>{" "}
            </div>
            {pickedByOwner && (
              <div style={{ padding: 15 }}>
                <div style={{ justifyContent: "center", display: "flex" }}>
                  <h5>Please scan owner Qr code from app.</h5>
                </div>
                <div style={{ justifyContent: "center", display: "flex", flexDirection: "column" }}>
                  <QrReader
                    delay={1000}
                    onError={handleError}
                    onScan={(code) => {
                      if (
                        moment(lastScanned.current).isBefore(
                          moment().subtract(CAMERA_TIME, "second"),
                        )
                      ) {
                        return;
                      }
                      code && readCode(code);
                    }}
                    style={{ width: 500, padding: 35 }}
                    facingMode={"user"}
                  />
                </div>
              </div>
            )}
            {pickedByOther && (
              <div style={{ padding: 15 }}>
                <div style={{ justifyContent: "center", display: "flex" }}>
                  <h5>Please Enter details.</h5>
                </div>
                <div>
                  <Row>
                    <Label sm="3">First Name:</Label>
                    <Col sm="9">
                      <FormGroup>
                        <Input
                          type="text"
                          value={details.firstName}
                          onChange={(e) => {
                            setDetails({
                              ...details,
                              firstName: e.target.value,
                            });
                            setErrorDetails({
                              ...errorDetails,
                              firstName: "",
                            });
                          }}
                        />
                        <FormText color="danger" tag="span">
                          {errorDetails.firstName}
                        </FormText>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="3">Last Name:</Label>
                    <Col md="9">
                      <FormGroup>
                        <Input
                          type="text"
                          value={details.lastName}
                          onChange={(e) => {
                            setDetails({
                              ...details,
                              lastName: e.target.value,
                            });
                            setErrorDetails({
                              ...errorDetails,
                              lastName: "",
                            });
                          }}
                        />
                        <FormText color="danger" tag="span">
                          {errorDetails.lastName}
                        </FormText>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="3">Relation:</Label>
                    <Col md="9">
                      <FormGroup>
                        <Input
                          type="text"
                          value={details.relation}
                          onChange={(e) => {
                            setDetails({
                              ...details,
                              relation: e.target.value,
                            });
                            setErrorDetails({
                              ...errorDetails,
                              relation: "",
                            });
                          }}
                        />
                        <FormText color="danger" tag="span">
                          {errorDetails.relation}
                        </FormText>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div style={{ justifyContent: "flex-end", display: "flex" }}>
                  <Button
                    color="success"
                    style={{ marginRight: "12px" }}
                    onClick={() => handleNotification()}
                  >
                    Send Notification
                  </Button>{" "}
                </div>
              </div>
            )}
          </div>
        </FormGroup>
      </Modal>
    );
  };

   /**
   * Description: function to send notification if the cheque is picked up by someone other than owner of cheque
   * No inputs
   * No outputs
   */
  const handleNotification = async () => {
    try {
      let hasError = false;
      let errors = errorDetails;
      if (!details.firstName) {
        hasError = true;
        errors.firstName = "* Must enter first name.";
      }
      if (!details.lastName) {
        hasError = true;
        errors.lastName = "* Must enter last name.";
      }
      if (!details.relation) {
        hasError = true;
        errors.relation = "* Must enter relation with owner.";
      }

      if (hasError) {
        setErrorDetails({ ...errors });
        return;
      } else {
        setIsLoading(true);
        await api().post("/appointment-slot/cheque-pickup/other", {
          firstName: details.firstName,
          lastName: details.lastName,
          relationship: details.relation,
          user: selectedAppointment.user,
          slot: selectedAppointment._id,
        });
        onUpdate("Notification send successfully", `Please wait for worker to upload signature`);
        handleCloseCommonModal();
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      handleCloseCommonModal();
      onError("Error occured!", e.response?.data?.message || e.message);
    }
  };

   /**
   * Description: Scan the QR code of user, if cheque is picked up by owner
   * No inputs
   * No outputs
   */
  const readCode = async (result) => {
    lastScanned.current = new Date();

    scanned.current = scanned.current.filter((_scanned) =>
      moment(_scanned.time).isAfter(moment().subtract(5, "second")),
    );
    if (scanned.current.find((scan) => scan.code === result)) {
      return;
    }
    scanned.current.push({
      time: new Date(),
      code: result,
    });
    try {
      setIsLoading(true);
      lastScanned.current = new Date();
      await api().post("/appointment-slot/cheque-pickup/owner", {
        qrcode: result,
        slot: selectedAppointment._id,
      });
      onUpdate("Notification send successfully", `Please wait for worker to upload signature`);
      handleCloseCommonModal();
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      handleCloseCommonModal();
      onError("Worker not found!", e.response?.data?.message || e.message);
    }
  };


  /**
   * Description: Filter react table case insensitive
   * No inputs
   * Output =>  boolean - return true or false 
   */
  function filterCaseInsensitive(filter, row) {
    const id = filter.pivotId || filter.id;
    return (
        row[id] !== undefined ?
            String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
        :
            true
    );
}

  return (
    <>
      {isLoading && <LoadingOverlay />}
      <div className="content">
        <Card className="no-transition">
          <CardHeader>
            <Row>
              <Col className="d-flex justify-content-between">
                <CardTitle tag="h4">Worker Appointments</CardTitle>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>        
            <ReactTable
              filterable
              getTrProps={(state, rowInfo, instance) => {
                if (rowInfo?.row?._original?.rowColor || rowInfo?.row?._original?.backgroundColor) {
                  return {
                    style: {
                      color: rowInfo?.row?._original?.rowColor,
                      background: rowInfo?.row?._original?.backgroundColor,
                    },
                  };
                }
                return {};
              }}
              defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row) }
              columns={[
                {
                  Header: "Slot",
                  accessor: "slot",
                },

                {
                  Header: "Business",
                  accessor: "businessName",
                },
                {
                  Header: "Start of Week",
                  accessor: "startOfWeek",
                  maxWidth: 140,
                },

                {
                  Header: "End if Week",
                  accessor: "endOfWeek",
                  maxWidth: 140,
                },
                {
                  Header: "Status",
                  accessor: "status",
                  maxWidth: 90,
                },

                {
                  Header: "Actions",
                  accessor: "actions",
                  sortable: false,
                  filterable: false,
                },
              ]}
              showPaginationTop
              showPaginationBottom
              data={appointments}
              pages={pages}
              className="-striped -highlight primary-pagination"
            />
          </CardBody>
        </Card>
      </div>
      {commentsModal()}
      {chequePickupModal()}
      {missedAppointmentModal()}
      {confirmMissedAppointmentModal()}
      {verifyUserModal()}
      {deleteChequePickupModal()}
      {verifySignatureModal()}
      {alert}
    </>
  );
};

export default UserAppointments;
