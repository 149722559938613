import React from "react";
import { Modal } from "@mui/material";
import Select from "react-select";
import moment from "moment-timezone";
import { Button, Input, Label } from "reactstrap";
import { statusEnum } from "utils/statusVariables";

// Confirm user from selected modal
const ConfirmUserModal = ({
  selectedForWorkData,
  selectedShift,
  setSelectedShift,
  selectedHdId,
  agencyContext,
  openModal,
  handleCloseConfirmUserModal,
  shiftsList,
  selectedShiftFunction,
  addUserToNewBusiness,
  setAddUserToNewBusiness,
  cantAddToThisBusiness,
  setCantAddToThisBusiness,
  shiftTimeType,
  confirmUser,
  setSelectedShiftFunction,
}) => {
  const selectedUser = selectedForWorkData.find((u) => u.hdId === selectedHdId);
  const selectedUserIsUntrained = false; // only trained workers can apply for shifts
  const businessList = selectedUser?.business;

  let tempAddUserToNewBusiness = null;
  let tempCantAddToThisBusiness = null;
  if (selectedUser) {
    if (
      selectedUser?.workerPin &&
      selectedUser?.workerPin?.filter((wPin) => wPin.agency === agencyContext.agency.agency._id)
        .length > 0 &&
      !selectedUser?.workerPin?.some((wPin) => businessList.includes(wPin.business))
    ) {
      tempAddUserToNewBusiness = true;
      tempCantAddToThisBusiness = false;
    } else if (
      selectedUser?.workerPin?.some(
        (wPin) =>
          wPin.business === businessList?.includes(wPin.business) &&
          wPin.agency !== agencyContext.agency.agency._id,
      )
    ) {
      tempAddUserToNewBusiness = false;
      tempCantAddToThisBusiness = true;
    } else {
      tempAddUserToNewBusiness = false;
      tempCantAddToThisBusiness = false;
    }
  }

  if (!selectedShift) return null;

  let closed = false;
  let closedDate = selectedUser?.closedDate && moment(selectedUser?.closedDate);
  if (
    closedDate &&
    closedDate.get("date") === moment(selectedUser?.start).get("date") &&
    closedDate.isBefore(selectedUser?.start)
  ) {
    closed = true;
  }

  const reactSelectStyles = {
    option: (styles, { data, isFocused, isSelected }) => {
      if (data.shift && data.shift.closed) {
        return {
          ...styles,
          color: "#c5093d",
          backgroundColor: isFocused ? "#eee" : null,
        };
      }
      if (
        selectedUser.workerPin &&
        selectedUser.workerPin.some(
          (wPin) =>
            wPin.business === data.business && wPin.agency !== agencyContext.agency.agency._id,
        )
      ) {
        //if the user hasn't been selected for work in another business then make the color red
        return {
          ...styles,
          color: "#f5593d",
          backgroundColor: isFocused ? "#eee" : null,
        };
      }
      if (
        selectedUser.workerPin &&
        selectedUser.workerPin.filter((wPin) => wPin.agency === agencyContext.agency.agency._id)
          .length > 0 &&
        !selectedUser.workerPin.some((wPin) => wPin.business === data.business)
      ) {
        //if the user hasn't been selected for work in another business then make the color red
        return {
          ...styles,
          color: "#f5593d",
          backgroundColor: isFocused ? "#eee" : null,
        };
      }
      if (selectedUser.workerPin && selectedUser.workerPin.length > 0) {
        if (
          selectedUser.workerPin.some((wPin) => wPin.business === data.business && wPin.preferred)
        ) {
          //if the user has been selected for work then make the color blue
          return {
            ...styles,
            color: "#51cbce",
            backgroundColor: isFocused ? "#eee" : null,
          };
        }
      }
      return {
        ...styles,
        cursor: "pointer",
        color: isSelected ? "#000" : null,
        backgroundColor: isFocused || isSelected ? "#eee" : null,
      };
    },
  };

  const addUserToBackup = () => {
    setAddUserToNewBusiness(tempAddUserToNewBusiness);
    setCantAddToThisBusiness(tempCantAddToThisBusiness);
    confirmUser(
      statusEnum.selectedForWork.Backup,
      selectedShiftFunction?.shiftFunction,
      selectedShift?.shift,
    );
  };

  const addUserToBusiness = () => {
    setAddUserToNewBusiness(tempAddUserToNewBusiness);
    setCantAddToThisBusiness(tempCantAddToThisBusiness);
    confirmUser(
      statusEnum.selectedForWork.selected,
      selectedShiftFunction?.shiftFunction,
      selectedShift?.shift,
    );
  };

  return (
    <Modal
      open={openModal.confirmUser}
      onClose={handleCloseConfirmUserModal}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {selectedUser && (
        <div style={{ width: 500, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <h5 style={{ textAlign: "center" }}>
              Confirm {selectedUser.firstName} {selectedUser.lastName} for a shift?
            </h5>
            <div style={{ marginTop: "8px" }}>
              <h5>Selected shift:</h5>
              <p>
                <b>Shift: </b>
                {selectedShift.shiftName}
                <Select
                  classNamePrefix="react-select"
                  name="selectShift"
                  value={selectedShift}
                  onChange={(value) => {
                    setSelectedShift(value);
                    if (
                      selectedUser.workerPin &&
                      selectedUser.workerPin.filter(
                        (wPin) => wPin.agency === agencyContext.agency.agency._id,
                      ).length > 0 &&
                      !selectedUser.workerPin.some((wPin) => wPin.business === value.business)
                    ) {
                      setAddUserToNewBusiness(true);
                      setCantAddToThisBusiness(false);
                    } else if (
                      selectedUser.workerPin.some(
                        (wPin) =>
                          wPin.business === value.business &&
                          wPin.agency !== agencyContext.agency.agency._id,
                      )
                    ) {
                      setAddUserToNewBusiness(false);
                      setCantAddToThisBusiness(true);
                    } else {
                      setAddUserToNewBusiness(false);
                      setCantAddToThisBusiness(false);
                    }
                  }}
                  styles={reactSelectStyles}
                  options={shiftsList.map((b) => {
                    let timeOfDay = shiftTimeType(b.start);
                    return {
                      label:
                        b.businessName +
                        " : " +
                        timeOfDay +
                        ` @${moment(b.start).format("hh:mm a")}-${moment(b.end).format(
                          "hh:mm a",
                        )} `,
                      value: b._id,
                      business: b.business,
                      shift: b,
                    };
                  })}
                  placeholder="Select shift"
                />
              </p>
              <p>
                <b>Business: </b>
                {selectedShift.shift.businessName} ({shiftTimeType(selectedShift.shift.start)})
              </p>
              <p>
                <b>Start: </b>
                {moment(selectedShift.shift.start).format("MMM DD YYYY, hh:mm a")}
              </p>
              <p>
                <b>End: </b>
                {moment(selectedShift.shift.end).format("MMM DD YYYY, hh:mm a")}
              </p>
              <p>
                <b>Shift Function/Line: </b>
                {selectedShiftFunction && selectedShiftFunction.label}
              </p>
              {selectedUser?.shiftFunctions?.length ? (
                <Select
                  classNamePrefix="react-select"
                  name="selectShiftFunction"
                  value={selectedShiftFunction}
                  onChange={(value) => {
                    setSelectedShiftFunction(value);
                  }}
                  options={selectedUser.shiftFunctions?.map((sf) => {
                    return {
                      label: sf.name,
                      value: sf._id,
                      shiftFunction: sf,
                    };
                  })}
                  placeholder="Select shift Function"
                />
              ) : null}
            </div>

            {selectedUserIsUntrained ? (
              <>
                <Label style={{ color: "black", fontWeight: "bold" }}>
                  Are you sure want to schedule unqualified workers ?
                </Label>
              </>
            ) : addUserToNewBusiness || closed ? (
              <>
                <Label style={{ color: "black", fontWeight: "bold" }}>
                  {closed && addUserToNewBusiness
                    ? "* (This shift is already closed and this user already works at another location.)"
                    : closed
                    ? "* (This shift is already closed.)"
                    : addUserToNewBusiness
                    ? "* (This user already works at another location.)"
                    : ""}
                </Label>
              </>
            ) : null}
            {cantAddToThisBusiness && (
              <div style={{ color: "red" }}>
                This user can't be added to this shift. They already have worked at this business
                from another agency.
              </div>
            )}
            <div
              style={{
                justifyContent: "space-between",
                display: "flex",
                marginRight: 15,
                marginLeft: 15,
                marginTop: 15,
              }}
            >
              <Button onClick={() => handleCloseConfirmUserModal()}>Cancel</Button>
              <Button
                color="info"
                disabled={tempCantAddToThisBusiness}
                onClick={() => addUserToBackup()}
              >
                Backup
              </Button>
              <Button
                color="success"
                disabled={tempCantAddToThisBusiness}
                onClick={() => addUserToBusiness()}
              >
                Add
              </Button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ConfirmUserModal;
