const roles = {
  admin: {
    value: "Admin",
    label: "Admin",
  },
  supervisor: { value: "Supervisor", label: "Supervisor" },
  recruiter: { value: "Recruiter", label: "Recruiter" },
  staff: { value: "Staff", label: "Staff" },
  scheduler: { value: "Scheduler", label: "Scheduler" },
  headScheduler: { value: "HeadScheduler", label: "Head Scheduler" },
  operationsManager: { value: "OperationsManager", label: "Operations Manager" },
  accountant: { value: "Accountant", label: "Accountant" },
  founder: { value: "Founder", label: "Founder" },
};
export default roles;

// const permissions = {
//     liveBusiness:{
//         categories:{

//         },
//         displayName:
//     },
//     adminLiveBusiness:"AdminLiveBusiness",

// }
