import React, { useEffect, useContext, useState } from "react";
// reactstrap components
import {
  Button,
  Card,
  CardTitle,
  Form,
  Input,
  Container,
  Row,
  Col,
  FormGroup,
  FormText,
} from "reactstrap";

import RegisterContext from "context/RegisterContext";
import { Link } from "react-router-dom";
import api from "components/API/api";

import RegisterText from "views/Staffing/register/RegisterText.jsx"

function RegisterAdmin(props) {
  const registerContext = useContext(RegisterContext);
  const [user, setUser] = useState({});
  const [confirmPassword, setConfirmPassword] = useState("");
  document.documentElement.classList.remove("nav-open");

  const [nameError, setNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [generalError, setGeneralError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  useEffect(() => {
    document.body.classList.add("register-page");
    document.body.classList.add("full-screen");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("register-page");
      document.body.classList.remove("full-screen");
    };
  }, []);

  useEffect(() => {
    console.log(registerContext.registration);
    if (registerContext.registration.user) {
      setUser(registerContext.registration.user);
      setConfirmPassword(registerContext.registration.user.password)
    }
    registerContext.registrationDispatch({
      type: "SET_PROGRESS_STEP",
      payload: { progressBarIndex: 0 },
    });
  }, []);

  const navigateToRegisterBusiness = async (e) => {
    e.preventDefault();

    let hasError = false;

    //Validation
    if (!user.firstName) {
      // console.log(nameError);
      setNameError("* Must write name");
      hasError = true;
    } else {
      setNameError("");
    }
    if (!user.lastName) {
      // console.log(nameError);
      setLastNameError("* Must write last name");
      hasError = true;
    } else {
      setNameError("");
    }

    if (user.address == null || user.address == "") {
      setAddressError("* Must write mailing address");
      hasError = true;
    } else {
      setAddressError("");
    }

    if (user.email == null || user.email == "") {
      setEmailError("* Must enter email");
      hasError = true;
    } else {
      setEmailError("");
    }

    if (user.phone == null || user.phone == "") {
      setPhoneError("* Must enter phone number");
      hasError = true;
    } else {
      setPhoneError("");
    }

    if (user.password == null || user.password == "") {
      setPasswordError("* Must enter password");
      hasError = true;
    } else {
      setPasswordError("");
    }

    // if (user.confirmPassword == null || user.confirmPassword == "") {
    //   setConfirmPasswordError("* Password does not match");
    //   hasError = true;
    // } else {
    //   setConfirmPasswordError("");
    // }

    if (hasError) {
      return;
    } else {
      try {
        await api().post(`/users/validate/owner`, user)
      } catch (e) {
        console.log(e.response)
        setGeneralError(e.response.data.error.message)
        return
      }
      registerContext.registrationDispatch({
        type: "SET_USER",
        payload: { user },
      });
      props.history.push("/staffing/register/register-business");
    }
  };

  const goToPayment = () => {
    const user = {
      firstName: "TestOwner1",
      lastName: "lastNameOwner",
      address: "fake address",
      email: "TestOwner1@test.com",
      phone: "1321313232",
      password: "12345678"
    }
    registerContext.registrationDispatch({
      type: "SET_USER",
      payload: { user },
    });
    const agency = {
      name: "Staff Payment Test1",
      address: "Fake address",
      city: "Fake city",
      phone: "111144777",
      businessNo: "11444555",
      email: user.email,
    }
    registerContext.registrationDispatch({
      type: "SET_AGENCY",
      payload: { agency },
    });
    props.history.push("/staffing/register/select-plan");
  }

  return (
    <>
      <div className="section section-pricing cd-section" id="pricing">
        {/* ********* PRICING 2 ********* */}
        <div className="pricing-2">
          <div className="filter" />
          <Container>
            <Row>
            <Col className="ml-auto" lg="6" md="6" sm="7" xs="12">
              <RegisterText />
            </Col>
              <Col className="mr-auto" lg="6" md="6" sm="5" xs="12" style={{ marginTop: "-100px" }}>
                <Card className="card-register" style={{ paddingTop: "0px", backgroundColor: "white" }}>
                  <CardTitle className="text-center" tag="h3">
                    Register
                  </CardTitle>

                  {/* <div className="social">
                    <Button className="btn-just-icon mr-1" color="facebook">
                      <i className="fa fa-facebook" />
                    </Button>
                    <Button className="btn-just-icon mr-1" color="google">
                      <i className="fa fa-google" />
                    </Button>
                    <Button className="btn-just-icon" color="twitter">
                      <i className="fa fa-twitter" />
                    </Button>
                  </div> */}

                  <div
                    style={{
                      // backgroundColor: "black",
                      marginTop: "35px",
                      marginBottom: "10px",
                      color: "#66615b",
                    }}
                  >
                    <h6 className="text-center" color="#66615b">
                      First, tell us a bit about yourself.
                    </h6>
                  </div>
                  <Form action="/" className="register-form" method="get">
                    <FormGroup>
                      <Input
                        placeholder="* First Name"
                        type="text"
                        value={user.firstName}
                        onChange={(e) => {
                          setUser({ ...user, firstName: e.target.value });
                        }}
                      />
                      <FormText color="danger" tag="span">
                        {nameError}
                      </FormText>
                    </FormGroup>
                    <FormGroup>
                      <Input
                        placeholder="* Last Name"
                        type="text"
                        value={user.lastName}
                        onChange={(e) => {
                          setUser({ ...user, lastName: e.target.value });
                        }}
                      />
                      <FormText color="danger" tag="span">
                        {lastNameError}
                      </FormText>
                    </FormGroup>

                    <FormGroup>
                      <Input
                        placeholder="* Mailing Address"
                        type="text"
                        value={user.address}
                        onChange={(e) => {
                          setUser({ ...user, address: e.target.value });
                        }}
                      />
                      <FormText color="danger" tag="span">
                        {addressError}
                        {console.log("Address Error: ", addressError)}
                      </FormText>
                    </FormGroup>

                    <FormGroup>
                      <Input
                        placeholder="* Phone"
                        type="text"
                        value={user.phone}
                        onChange={(e) => {
                          setUser({ ...user, phone: e.target.value });
                        }}
                      />
                      <FormText color="danger" tag="span">
                        {phoneError}
                      </FormText>
                    </FormGroup>

                    <FormGroup>
                      <Input
                        placeholder="* Email"
                        type="text"
                        value={user.email}
                        onChange={(e) => {
                          setUser({ ...user, email: e.target.value });
                        }}
                      />
                      <FormText color="danger" tag="span">
                        {emailError}
                        {console.log("Email Error: ", emailError)}
                      </FormText>
                    </FormGroup>

                    <FormGroup>
                      <Input
                        placeholder="* Password"
                        type="password"
                        value={user.password}
                        onChange={(e) => {
                          setUser({ ...user, password: e.target.value });
                        }}
                      />

                      <FormText color="danger" tag="span">
                        {passwordError}
                      </FormText>
                    </FormGroup>

                    <FormGroup>
                      <Input
                        placeholder="* Confirm Password"
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => {
                          setConfirmPassword(e.target.value);
                        }}
                      />
                      <FormText color="danger" tag="span">
                        {confirmPasswordError}
                      </FormText>
                    </FormGroup>
                    <FormText color="danger" tag="span">
                      {generalError}
                    </FormText>
                    <Button
                      className="btn-round w-100"
                      color="success"
                      href="#continue"
                      onClick={navigateToRegisterBusiness}
                    >
                      Continue
                    </Button>
                  </Form>
                  <div
                    style={{
                      // backgroundColor: "black",
                      marginTop: "35px",
                      marginBottom: "10px",
                      color: "#66615b",
                    }}
                  >
                    <h6 className="text-center" color="#66615b">
                      Already got an account? <Link to="/staffing/login">LOG IN</Link>
                    </h6>
                  </div>
                </Card>
              </Col>
            </Row>
            <Button
              onClick={goToPayment}
            >
              DEVTEST Go to payment
            </Button>
          </Container>
        </div>
      </div>
    </>
  );
}

export default RegisterAdmin;
