import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Row,
  Col,
  Form,
  Input,
  Spinner,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import AgencyContext from "context/AgencyContext";
import Messages from "./Messages";
import moment from "moment";
import { useSocket } from "context/SocketContext";
import { Block } from "@mui/icons-material";
import api from "components/API/api";
import { useModal } from "context/ModalContext";

function CreateNewChat({
  receiverId,
  blockUserChat,
  receiverFirstName,
  receiverLastName,
  lastAppActive,
}) {
  let agencyContext = useContext(AgencyContext);
  const [isLoading, setIsLoading] = useState(false);
  const { addAlert } = useModal();
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [blockChat, setBlockChat] = useState(blockUserChat);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(45);
  const [scroll, setScroll] = useState(true);
  const [end, setEnd] = useState(false); // to know end of messages
  const senderId = agencyContext.agency.user.id;
  const senderFirstName = agencyContext.agency.user.firstName;
  const senderLastName = agencyContext.agency.user.lastName;
  const agency = agencyContext.agency.agency._id;
  const receiverFullName = `${receiverFirstName} ${receiverLastName}`;
  const [defaultResponse, setDefaultResponse] = useState([
    {
      label: "Welcome to the Chat",
      value:
        "Hi, welcome to the HD Group Support Chat. Please leave your question here and a support representative will assist once they are available during business hours.",
    },
    {
      label: "HD Group phone number",
      value:
        "Please call (905) 763-9543 to contact HD Group for more information. If you cannot reach a representative, please wait 10-20 minutes and try again.",
    },
    {
      label: "If you are calling about a shift and cannot reach someone.",
      value:
        "If you are calling the office phone line (905-763-9543) about a shift, the team answers calls in the order that they receive them. If your call is not answered it is because they are on calls with other workers. Please try calling again in 10-20 minutes, or apply on our shiftboard.",
    },
    {
      label: "HD Head Office location",
      value:
        "The HD Head Office is located @ 6175 Hwy 7 Unit 3, Vaughan L4H 0P6 (2nd Floor, above the Toronto Spine and Sports Clinic). The head office is open 10-4 on weekdays, excluding holidays.",
    },
    {
      label: "Effective Jan 20, workers must book at least 3 days in advance for appointments",
      value:
        "Effective Jan 20 2023, workers must book at least 3 days in advance for all cheque pickup appointments.",
    },
    {
      label: "Please book a cheque pickup appointment in app",
      value:
        "Please book a cheque pickup appointment in app to collect your pay. Cheques are given out @ the HD Group HEAD OFFICE located at 6175 Hwy 7 Unit 3B, Vaughan L4H 0P6 (2nd Floor, above the Toronto Spine and Sports Clinic). Appointments are available 10-4 on Tuesday - Friday excluding holidays.",
    },
    {
      label: "You may send another person to collect your cheque",
      value:
        "You may send another person to collect your cheque. They must show up during your appointment time with their photo ID. Please send the photo ID of the person collecting your pay to aditi@hdgroup.ca. Their photo ID must match this emailed ID to receive your pay.",
    },
    {
      label: "Steps to book a cheque pickup appointment",
      value:
        "To book a cheque pickup appointment, please open the left menu and press HD Group Cheques. Then, select your date and timeslot. If you cannot see any timeslots, select another date since there are no available spots on your chosen date. Once you have chosen a date and time, press Next. You will need to select your Work Location and the Start and End of Week for your outstanding cheque. Finally, press Confirm Booking to reserve your slot!",
    },
    {
      label: "A training code was sent to your registered email",
      value:
        "A training code was sent to your registered email. Please check your inbox and spam folders. Use this code in the Training Centre to unlock and start the mandatory training. You must complete 5 training modules to unlock the shiftboard bidding feature.",
    },
    {
      label: "Please complete the mandatory training to unlock the bidding feature",
      value:
        "Please complete all mandatory training modules in your Training Centre to unlock the bidding feature. You will not be allowed to submit bids on the shiftboard until you finish all five (5) modules.",
    },
    {
      label: "Please submit bids on the shiftboard if you are interested in work",
      value:
        "Please submit bids on the shiftboard if you are interested in work to indicate that you are available. Please do not go to work unless you have received a confirmation call from an HD Group scheduler.",
    },
    {
      label: "Workers are selected based on the available shift quota",
      value:
        "Workers are selected based on the available shift quota. Once the quota is reached all other bids are rejected. If you are selected, an HD Group scheduler will call to confirm your shift, otherwise, you will not receive any calls.",
    },
    {
      label: "Shift availability depends on client requests",
      value:
        "Shift availability depends on the client and their requests. Sometimes they request specific people and we must meet their requirements.",
    },
    {
      label: "Quotas are low, this is the slow season",
      value:
        "Unfortunately, this is the slow season, so requirements are lower than normal. Please continue bidding and we will schedule as many workers as possible",
    },
    {
      label: "Each morning, schedulers staff for today's afternoon/night shifts.",
      value:
        "Each morning, schedulers will receive requirements for today's afternoon and night shifts, and tomorrow morning shifts. After we receive requirements, we will begin staffing for today's shifts, and then we staff for tomorrow's morning shifts in the afternoon.",
    },
    {
      label: "All available shifts are displayed on the shiftboard",
      value:
        "All available shifts are displayed on the shiftboard. To view each shift's location, kindly tap the business name (top left corner of the shift) to view the shift details page. Please read the shift details page carefully, as it will list necessary equipment and requirments for all workers.",
    },
    {
      label: "HD Group is CLOSED on all Statutory Holidays",
      value:
        "The HD Group Head Office is CLOSED on all weekends and Statutory Holidays: New Year's Day, Family Day, Good Friday, Victoria Day, Canada Day,Labour Day, Thanksgiving Day, Christmas Day, Boxing Day",
    },
    {
      label: "HD Group has very limited weekend shift availability",
      value:
        "HD Group has very limited weekend shift availability and many bids during the weekend. For a better chance of getting work, please bid for a weekday shift.",
    },
    {
      label: "Cheque Issues, Payroll, Missing Hours",
      value:
        "For all issues related to payroll, missing hours, or other cheque issues please open the left menu, press HD Group Cheques and press Report Cheque Issues on the bottom menu. Fill out the report to contact the payroll department.",
    },
  ]);
  const { socket } = useSocket();

  useEffect(() => {
    if (socket) {
      socket.emit(
        "join",
        { senderFirstName, senderLastName, senderId, room: receiverId },
        (resp) => {
          // console.log(resp);
        },
      );
      openConversation(page, size); // loads older conversation if exists
      socket.on("message", (resp) => {
        if (resp.worker === receiverId) {
          let newMessage = {
            text: resp.message,
            user: resp.worker === resp.senderId ? resp.senderId : "Staff",
            time: moment(resp.createdAt).format("MMM Do YY, h:mm a"),
            note: resp.note,
            senderName: resp.senderName,
          };
          setMessages((messages) => [...messages, newMessage]);
          setScroll(true);
        }
      });
    }
  }, [senderId, socket]);

  useEffect(() => {
    if (loadMore) {
      setScroll(false);
      setPage(page + 1);
      openConversation(page + 1, size);
      setLoadMore(false);
    }
  }, [loadMore]);

  const openConversation = (page, size) => {
    if (socket) {
      setIsLoading(true);
      socket.emit(
        "openConversation",
        { agency, workerId: receiverId, isWorker: false, page, size },
        (resp) => {
          if (resp.length > 0) {
            let tempArray = resp.map((res) => ({
              text: res.message,
              user: res.worker === res.senderId ? res.senderId : "Staff",
              time: moment(res.createdAt).format("MMM Do YY, h:mm a"),
              createdAt: res.createdAt,
              note: res.note,
              senderName: res.senderName,
            }));
            tempArray = tempArray.filter((msg) => msg.note !== "Auto-reply");
            tempArray.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
            let newArray = [...tempArray, ...messages];
            setMessages(newArray);
            setIsLoading(false);
          } else {
            setEnd(true);
          }
        },
      );
      setIsLoading(false);
    }
  };

  const loadingOverlay = () => {
    return (
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
          background: "transparent",
        }}
        title={<Spinner color="light" style={{ width: "3rem", height: "3rem" }} />}
        onConfirm={() => null}
        showConfirm={false}
      />
    );
  };

  const sendMessage = (e) => {
    e.preventDefault();
    if (message) {
      socket.emit(
        "sendMessage",
        {
          message,
          senderId,
          receiverId,
          agency,
          room: receiverId,
          workerName: receiverFullName,
          worker: receiverId,
          adminName: senderFirstName + " " + senderLastName,
        },
        (resp) => {
          setMessage("");
          let newMessage = {
            text: resp.message,
            user: "Staff",
            time: moment(resp.createdAt).format("MMM Do YY, h:mm a"),
            note: resp.note,
            senderName: senderFirstName + " " + senderLastName,
          };
          setMessages((messages) => [...messages, newMessage]);
          setScroll(true);
        },
      );
    }
  };

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleBlockChat = async () => {
    try {
      const resp = await api().patch("/users/block-chat", {
        userId: receiverId,
        blockChat: !blockChat,
      });
      addAlert({
        title: blockChat ? "User successfully un blocked" : "User successfully blocked",
        success: true,
      });
      setBlockChat(!blockChat);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {isLoading ? (
        loadingOverlay()
      ) : (
        <Form action="/" className="form-horizontal" method="get">
          {lastAppActive ? (
            moment(lastAppActive).isBefore(moment().subtract(7, "days")) ? (
              <div
                style={{
                  backgroundColor: "#ffffc2", // light yellow
                  fontWeight: "bold",
                }}
              >
                WARNING: This user last logged in over 7 days ago on{" "}
                {moment(lastAppActive).calendar()}. They may have deleted the app and may not
                receive any messages sent here. Please contact this user by other methods.
              </div>
            ) : (
              <div
                style={{
                  backgroundColor: "#D2FFCB", //light green
                  fontWeight: "bold",
                }}
              >
                This user last logged in on {moment(lastAppActive).calendar()}
              </div>
            )
          ) : (
            <div
              style={{
                backgroundColor: "#EE9999", //light red
                fontWeight: "bold",
              }}
            >
              WARNING: This user has NO recorded app login time. Any messages sent in this chat WILL
              NOT be received. Please contact this user by other methods.
            </div>
          )}

          <Row>
            <Col align="right">
              <Button
                onClick={() => handleBlockChat()}
                color={blockChat ? "success" : "warning"}
                size="md"
                className="btn"
                // outline
              >
                <Block style={{ marginLeft: -1 }} />
                {blockChat ? "Un Block Chat" : "Block Chat"}
              </Button>
            </Col>
          </Row>

          <Row>
            <Messages
              messages={messages}
              receiverId={receiverId}
              receiverFullName={receiverFullName}
              setLoadMore={setLoadMore}
              scroll={scroll}
              end={end}
            />
          </Row>
          <Row style={{ display: "flex", alignItems: "center" }} md="12">
            <Col sm="8">
              <Input
                type="text-area"
                rows="5"
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                onKeyPress={(e) => (e.key == "Enter" ? sendMessage(e) : null)}
                placeholder="Type message here"
              />
            </Col>
            <Col sm="2">
              <Button color="success" onClick={(e) => sendMessage(e)}>
                Send
              </Button>
            </Col>

            <Col sm="2" style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="up">
                <DropdownToggle style={{ textTransform: "none" }} size="sm" caret>
                  Default Messages
                </DropdownToggle>
                <DropdownMenu style={{ marginBottom: 20 }}>
                  {defaultResponse.map((res) => (
                    <DropdownItem onClick={() => setMessage(res.value)}>{res.label}</DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
}

export default CreateNewChat;
