import React from "react";
import ReactTable from "react-table";

// reactstrap components
import { Button, Card, CardHeader, CardBody, CardTitle, Row, Col, FormGroup } from "reactstrap";
import { Add, PriorityHigh, Comment, AssignmentInd, Clear, Visibility } from "@mui/icons-material";
import { Modal } from "@mui/material";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import { Link } from "react-router-dom";
import AgencyContext from "./../../../context/AgencyContext";
import api, { useEventSource } from "components/API/api";
import Permission from "components/permissions/Permission";
import CreateChequeRequest from "./CreateChequeRequest";

//sendsms function
// const fn_sendSmsManual = firebase.functions().httpsCallable("sendSmsManual");

class ChequePickup extends React.Component {
  static contextType = AgencyContext;
  constructor(props) {
    super(props);
    this.state = {
      chequeData: [],
      selectedChequePickupNumber: null,
      selectedDocId: null,
      confirmDeleteModalOpen: false,
      alert: null,
      confirmChangeStatusModalOpen: false,
      selectedPickedUp: null,
      selectedPhone: null,
      selectedChequeData: null,
      selectedUserId: null,
      chequesToProcess: 0,
      totalProcessed: 0,

      confirmViewCommentsModalOpen: false,
      confirmMoveToChequeIssuesOpen: false,
      createChequeRequestModal: false,
      selectedAdminNotes: null,

      reactTableState: {
        page: 1,
        size: 10,
        field: "name",
        sort: "asc",
        filter: "",
      },
      pages: -1,
      loading: false,
      newNote: "",
      timer: null,
    };
    this.source = null;
  }

  // componentDidMount() {
  //   console.log(this.context);
  //   // this.loadCheques();
  //   let agency =
  //     this.context.agency && this.context.agency.agency && this.context.agency.agency._id;
  //   this.source = useEventSource(`/cheque-pickup/listen?agency=${agency}`, (data) => {
  //     if (data.changed) {
  //       this.loadCheques();
  //     }
  //   });
  // }

  componentWillUnmount() {
    // this.source.close();
    clearTimeout(this.state.timer);
  }

  loadCheques = async (filterOptions = this.state.reactTableState) => {
    try {
      this.setState({ loading: true });
      const { data } = await api().get("/cheque-pickup", {
        params: {
          ...filterOptions,
          processed: false,
        },
      });

      console.log(data);
      //Looping through the business data for action button implementation
      let tempChequeRequestData = data.chequeRequest.map((request) => {
        request.actions = this.renderActionButton(
          request._id,
          request.note,
          request.assignedTo,
          request.firstName,
          request.lastName,
          request.pickedUp,
          request.phone,
          request.userId,
        );
        if (request.duplicated) {
          request.lastName = request.lastName + "-" + request.duplicated;
        }
        request.createdAt = moment(request.createdAt).format("YYYY-MM-DD, hh:mm a");
        request.weekStart = moment(request.weekStart).format("YYYY-MM-DD");
        request.weekEnd = moment(request.weekEnd).format("YYYY-MM-DD");
        return request;
      });

      this.setState({
        chequeData: tempChequeRequestData,
        pages: data.noPages,
        chequesToProcess: data.waiting || 0,
        totalProcessed: data.processed || 0,
        loading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  };

  updatePickupStatus = async (_id, pickedUp, userId, phone) => {
    try {
      this.setState({ isLoading: true });
      const { data } = await api().patch(`/cheque-pickup/${_id}`, {
        pickedUp: pickedUp,
        userId: userId,
        phone: phone,
      });

      this.setState({ isLoading: false });

      this.setState((prevState) => {
        return {
          ...prevState,
          chequeData: prevState.chequeData.filter((c) => {
            return c._id !== _id;
          }),
        };
      });
      this.setState({
        alert: (
          <SweetAlert
            success
            title="Successfully changed the status."
            onConfirm={this.hideAlert}
            showConfirm={false}
          />
        ),
        isLoading: false,
      });
      setTimeout(this.hideAlert, 1000);
    } catch (e) {
      this.setState({
        alert: (
          <SweetAlert
            error
            title="Couldn't update the status. Please try again"
            onConfirm={this.hideAlert}
            showConfirm={false}
          />
        ),
        isLoading: false,
      });
      setTimeout(this.hideAlert, 1000);
    }
  };

  updateIsAssigned = async (_id, update) => {
    try {
      this.setState({ isLoading: true });
      const { data } = await api().patch(`/cheque-pickup/${_id}`, {
        isAssigned: update,
      });
      this.setState((prevState) => {
        return {
          ...prevState,
          chequeData: prevState.chequeData.map((c) => {
            if (c._id === _id) {
              let assignedTo =
                data.assignedTo &&
                this.context.agency.user.firstName + " " + this.context.agency.user.lastName;
              c.actions = this.renderActionButton(
                c._id,
                c.note,
                assignedTo,
                c.firstName,
                c.lastName,
                c.pickedUp,
                c.phone,
                c.userId,
              );
              c.assignedTo = assignedTo;
            }
            return c;
          }),
        };
      });
    } catch (e) {
      console.log(e);
    }
  };

  renderActionButton = (
    chequeRequestId,
    adminNotes,
    assignedTo,
    firstName,
    lastName,
    pickedUp,
    phone,
    userId,
  ) => {
    return (
      <div className="actions-right">
        {userId ? (
          <Button
            tag={Link}
            onClick={() => {
              let path = `/${this.context.agency.agency.slug}/admin/user-profile/${userId}`;

              window.open(path);
            }}
            color="info"
            size="md"
            className="btn-link remove btn-icon"
          >
            <Visibility style={{ marginLeft: -1 }} />
          </Button>
        ) : null}
        <Permission
          module="Cheque"
          action="Edit"
          error="Not enough permission to perform this action."
        >
          {assignedTo ? (
            <Button
              onClick={() => {
                this.updateIsAssigned(chequeRequestId, false);
              }}
              color="warning"
              size="md"
              className="btn-link btn-icon"
            >
              <AssignmentInd style={{ marginLeft: -1 }} />
            </Button>
          ) : (
            <Button
              onClick={() => {
                this.updateIsAssigned(chequeRequestId, true);
              }}
              color="secondary"
              size="md"
              className="btn-link btn-icon"
            >
              <AssignmentInd style={{ marginLeft: -1 }} />
            </Button>
          )}
        </Permission>
        <Button
          onClick={() => {
            this.handleOpenViewCommentsModal(chequeRequestId, adminNotes, userId);
          }}
          color="info"
          size="md"
          className="btn-link remove btn-icon"
        >
          <Comment style={{ marginLeft: -1 }} />
        </Button>
        <Button
          onClick={() => {
            this.handleOpenMoveToChequeIssuesModal(chequeRequestId);
          }}
          color="warning"
          size="md"
          className="btn-link remove btn-icon"
        >
          <PriorityHigh style={{ marginLeft: -1 }} />
        </Button>{" "}
        <Button
          onClick={() => {
            this.setState({
              confirmChangeStatusModalOpen: true,
              selectedId: chequeRequestId,
              selectedFirstName: firstName,
              selectedLastName: lastName,
              selectedPickedUp: true,
              selectedPhone: phone,
              selectedUserId: userId,
            });
          }}
          color="success"
          size="md"
          className="btn-link btn-icon"
        >
          <Add style={{ marginLeft: -1 }} />
        </Button>
        <Permission
          module="Cheque"
          action="Delete"
          error="Not enough permission to perform this action."
        >
          <Button
            onClick={() => {
              this.setState({
                confirmChangeStatusModalOpen: true,
                selectedId: chequeRequestId,
                selectedFirstName: firstName,
                selectedLastName: lastName,
                selectedPickedUp: false,
                selectedPhone: phone,
                selectedUserId: userId,
              });
            }}
            color="danger"
            size="md"
            className="btn-link btn-icon"
          >
            <Clear style={{ marginLeft: -1 }} />
          </Button>
        </Permission>
        {/*<Button
          onClick={() => {
            this.handleOpenDeleteModal(chequePickupNumber, docId, firstName, lastName)
          }}
          color="danger"
          size="md"
          className="btn-link remove btn-icon"
        >
          <Delete style={{ marginLeft: -1 }} />
        </Button>{" "}*/}
      </div>
    );
  };

  // Deletes data
  handleOpenDeleteModal = (chequePickupNumber, docId, firstName, lastName) => {
    this.setState({
      confirmDeleteModalOpen: true,
      selectedChequePickupNumber: chequePickupNumber,
      selectedDocId: docId,
      selectedFirstName: firstName,
      selectedLastName: lastName,
    });
  };

  handleCloseDeleteModal = () => {
    this.setState({
      confirmDeleteModalOpen: false,
      selectedChequePickupNumber: null,
      selectedDocId: null,
      selectedFirstName: null,
      selectedLastName: null,
    });
  };

  handleCloseChangeStatusModal = () => {
    this.setState({
      confirmChangeStatusModalOpen: false,
      selectedId: null,
      selectedFirstName: null,
      selectedLastName: null,
      selectedPickedUp: null,
    });
  };

  changeStatusModal = () => {
    return (
      <Modal
        open={this.state.confirmChangeStatusModalOpen}
        onClose={this.handleCloseChangeStatusModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 350, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>
                Set status of {this.state.selectedFirstName} {this.state.selectedLastName}'s cheque
                request to {this.state.selectedPickedUp ? "Picked Up" : "Not Picked Up"}?
              </h5>
            </div>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button onClick={this.handleCloseChangeStatusModal}>Cancel</Button>
              {this.state.selectedPickedUp ? (
                <Button
                  color="success"
                  onClick={() => {
                    this.updatePickupStatus(
                      this.state.selectedId,
                      this.state.selectedPickedUp,
                      this.state.selectedUserId,
                      this.state.selectedPhone,
                    );
                    this.setState({ isLoading: true });
                    this.handleCloseChangeStatusModal();
                  }}
                >
                  Update Picked Up
                </Button>
              ) : (
                <Button
                  color="danger"
                  onClick={() => {
                    this.updatePickupStatus(
                      this.state.selectedId,
                      this.state.selectedPickedUp,
                      this.state.selectedUserId,
                      this.state.selectedPhone,
                    );
                    this.setState({ isLoading: true });
                    this.handleCloseChangeStatusModal();
                  }}
                >
                  Update Not Picked Up
                </Button>
              )}
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  handleCloseChangeStatusModal = () => {
    this.setState({
      confirmChangeStatusModalOpen: false,
      selectedChequePickupNumber: null,
      selectedDocId: null,
      selectedPickedUp: null,
      selectedFirstName: null,
      selectedLastName: null,
      selectedPhone: null,
      selectedStateToChange: null,
    });
  };

  handleOpenMoveToChequeIssuesModal = (id) => {
    this.setState({
      confirmMoveToChequeIssuesOpen: true,
      selectedId: id,
    });
  };

  handleCloseMoveToChequeIssuesModal = () => {
    this.setState({
      confirmMoveToChequeIssuesOpen: false,
      selectedId: null,
    });
  };

  moveToChequeIssuesModal = () => {
    return (
      <Modal
        open={this.state.confirmMoveToChequeIssuesOpen}
        onClose={this.handleCloseMoveToChequeIssuesModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 350, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>
                Move {this.state.selectedFirstName} {this.state.selectedLastName}'s cheque request
                to Cheque Issues?
              </h5>
            </div>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button onClick={this.handleCloseMoveToChequeIssuesModal}>Cancel</Button>
              <Button
                color="danger"
                onClick={() => {
                  this.setState({ isLoading: true });
                  this.handleMoveToChequeIssues(this.state.selectedId);
                  this.handleCloseMoveToChequeIssuesModal();
                }}
              >
                Move to Issues
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  handleMoveToChequeIssues = async (_id) => {
    try {
      this.setState({ isLoading: true });
      const { data } = await api().patch(`/cheque-pickup/${_id}`, {
        chequeIssue: true,
        adminName: this.context.agency.user.firstName + " " + this.context.agency.user.lastName,
      });

      this.setState({
        isLoading: false,
        alert: (
          <SweetAlert success title="Moved to Cheque Issues" onConfirm={this.hideAlert}>
            This request has been moved to cheque issues with ticket number: {data.chequeTicket}
          </SweetAlert>
        ),
      });

      this.setState({ isLoading: false });

      this.setState((prevState) => {
        return {
          ...prevState,
          chequeData: prevState.chequeData.filter((c) => {
            return c._id !== _id;
          }),
        };
      });
    } catch (e) {
      this.setState({
        isLoading: false,
        alert: (
          <SweetAlert danger title="An unexpected error has occured" onConfirm={this.hideAlert}>
            Please check your internet connection and try again {e.message}
          </SweetAlert>
        ),
      });
    }
  };

  deleteChequeModal = () => {
    return (
      <Modal
        open={this.state.confirmDeleteModalOpen}
        onClose={this.handleCloseDeleteModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 350, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>
                Delete {this.state.selectedFirstName} {this.state.selectedLastName}'s cheque request
                with ID: {this.state.selectedChequePickupNumber}?
              </h5>
            </div>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button onClick={() => this.handleCloseDeleteModal()}>Cancel</Button>
              <Button
                color="danger"
                onClick={() =>
                  this.deleteCheque(this.state.selectedChequePickupNumber, this.state.selectedDocId)
                }
              >
                Delete
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  deleteCheque = (chequePickupNumber, docId, noDeleteModal) => {
    console.log("Start Delete for : ", chequePickupNumber, docId);
    let data = this.state.chequeData;
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handleOpenViewCommentsModal = (id, adminNotes, user) => {
    this.setState({
      confirmViewCommentsModalOpen: true,
      selectedId: id,
      selectedAdminNotes: adminNotes,
      selectedUserId: user,
    });
  };

  handleCloseViewCommentsModal = () => {
    this.setState({
      confirmViewCommentsModalOpen: false,
      selectedId: null,
      selectedAdminNotes: null,
      selectedUserId: null,
    });
  };

  viewCommentsModal = () => {
    return (
      <Modal
        open={this.state.confirmViewCommentsModalOpen}
        onClose={this.handleCloseViewCommentsModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 350, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>
                Admin notes for {this.state.selectedFirstName} {this.state.selectedLastName}'s
                request with ID: {this.state.selectedChequePickupNumber}.
              </h5>
            </div>
            <div
              style={{ justifyContent: "space-between", display: "flex", flexDirection: "column" }}
            >
              {this.state.selectedAdminNotes && this.state.selectedAdminNotes.length > 0 ? (
                this.state.selectedAdminNotes.map((n, i) => {
                  return (
                    <div key={n._id} className="d-flex justify-content-between">
                      <p>{n.note}</p>
                      <p>-{n.adminName}</p>
                    </div>
                  );
                })
              ) : (
                <p>No notes :)</p>
              )}
              <textarea
                name="adminNotes"
                cols="40"
                rows="5"
                autoComplete="off"
                value={this.state.newNote}
                onChange={(e) => this.setState({ newNote: e.target.value })}
              ></textarea>
            </div>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button
                color="secondary"
                style={{ marginRight: "12px" }}
                onClick={() => this.handleCloseViewCommentsModal()}
              >
                Cancel
              </Button>{" "}
              <Button
                color="primary"
                onClick={() => {
                  this.saveNote(
                    this.state.selectedId,
                    this.state.selectedAdminNotes,
                    this.state.selectedUserId,
                    this.state.newNote,
                  );
                  this.handleCloseViewCommentsModal();
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  saveNote = async (_id, note, user, newNote) => {
    try {
      this.setState({ isLoading: true });
      // const { data } = await api().post(`/user-note`, {
      //   user: user,
      //   commentField: "ChequePickup",
      //   commentFieldId: _id,
      //   note: newNote,
      // });

      const { data } = await api().patch(`/cheque-pickup/${_id}`, {
        note: newNote,
      });
      this.setState((prevState) => {
        return {
          ...prevState,
          newNote: "",
          chequeData: prevState.chequeData.map((c) => {
            if (c._id === _id) {
              c.note = [
                ...c.note,
                {
                  note: newNote,
                  noteBy:
                    this.context.agency.user.firstName + " " + this.context.agency.user.lastName,
                },
              ];
              c.actions = this.renderActionButton(
                c._id,
                c.note,
                c.assignedTo,
                c.firstName,
                c.lastName,
                c.pickedUp,
                c.phone,
                c.userId,
              );
            }
            return c;
          }),
        };
      });
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="no-transition">
              <CardHeader>
                <Row>
                  <Col md="9">
                    <CardTitle tag="h4">Cheque Requests</CardTitle>
                  </Col>
                   <Col md="3">
                      <Button
                        color="secondary"            
                        onClick={() => this.setState({ createChequeRequestModal: true })}
                      >
                        Add Cheque Request
                      </Button>
                    </Col>
                  <Col md="8">
                    <CardTitle tag="h4">Requests Waiting: {this.state.chequesToProcess}</CardTitle>
                  </Col>
                  <Col md="4">
                    <CardTitle tag="h4">
                      Total Processed Today: {this.state.totalProcessed}
                    </CardTitle>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={this.state.chequeData}
                  filterable={true}
                  getTrProps={(state, rowInfo, column) => {
                    if (rowInfo && rowInfo.row) {
                      return {
                        style: {
                          background: rowInfo.row._original.assignedTo ? "#EDCCB7" : "white",
                          color: rowInfo.row._original.userId ? "black" : "red",
                        },
                      };
                    }
                    return {};
                  }}
                  columns={[
                    // {
                    //   Header: "Cheque ID",
                    //   accessor: "chequePickUpNumber",
                    // },
                    {
                      Header: "HD ID",
                      accessor: "hdId",
                    },
                    {
                      Header: "Created Date",
                      accessor: "createdAt",
                      filterable: false,
                      sortable: false,
                    },
                    {
                      Header: "First Name",
                      accessor: "firstName",
                    },
                    {
                      Header: "Last Name",
                      accessor: "lastName",
                    },
                    {
                      Header: "Phone",
                      accessor: "phone",
                    },
                    {
                      Header: "Workplace",
                      accessor: "business",
                      filterable: false,
                    },
                    {
                      Header: "Pin or Badge #",
                      accessor: "pin",
                    },
                    {
                      Header: "Start of Week",
                      accessor: "weekStart",
                      filterable: false,
                    },
                    {
                      Header: "End of Week",
                      accessor: "weekEnd",
                      filterable: false,
                    },
                    {
                      Header: "Assigned",
                      accessor: "assignedTo",
                      filterable: false,
                      sortable: false,
                    },
                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                      width: 280,
                    },
                  ]}
                  pages={this.state.pages}
                  defaultPageSize={10}
                  showPaginationTop
                  showPaginationBottom
                  loading={this.state.loading}
                  /*
                      You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                    */
                  className="-striped -highlight primary-pagination"
                  manual // informs React Table that you'll be handling sorting and pagination server-side
                  onFetchData={(state, instance) => {
                    // let searchParams = {
                    //   page: state.page + 1,
                    //   size: state.pageSize,
                    //   field: state.sorted.length ? state.sorted[0].id : "",
                    //   sort: state.sorted.length && state.sorted[0].desc ? "desc" : "asc",
                    //   // filter: state.filtered,
                    // };
                    // this.setState({
                    //   reactTableState: searchParams,
                    // });
                    // this.loadCheques(searchParams);
                    let searchParams = {
                      page: state.page + 1,
                      size: state.pageSize,
                      field: state.sorted.length ? state.sorted[0].id : "chequeTicket",
                      sort: state.sorted.length && state.sorted[0].desc ? "desc" : "asc",
                      filter: state.filtered,
                    };
                    this.setState({
                      reactTableState: searchParams,
                    });
                    clearTimeout(this.state.timer);
                    this.setState({
                      timer: setTimeout(() => {
                        this.loadCheques(searchParams);
                      }, 1000),
                    });
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        {this.deleteChequeModal()}
        {this.changeStatusModal()}
        {this.viewCommentsModal()}
        {this.moveToChequeIssuesModal()}
        {this.state.alert}

        <CreateChequeRequest
            openModal={this.state.createChequeRequestModal}
            closeModal={() => this.setState({ createChequeRequestModal: false })}
            loadCheques={this.loadCheques}
          />
      </div>
    );
  }
}

export default ChequePickup;
