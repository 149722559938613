import React from 'react'
import { Modal } from "@mui/material";
import { Button, FormGroup } from "reactstrap";

/**
 * Description : Modal for opening data upload guide in CreateNewUser.jsx  
 * @param {FUNCTION} openBulkUploadInfoModal
 * @param {FUNCTION} handleCloseBulkUploadInfoModal
 * @returns null
 */
const BulkUploadInfoModal = ({ openBulkUploadInfoModal, handleCloseBulkUploadInfoModal }) => {
  return (
    <Modal
      open={openBulkUploadInfoModal}
      onClose={handleCloseBulkUploadInfoModal}
      style={modalDialogueStyle}
    >
      <FormGroup style={formGroupStyle}>
        <div style={modalPaddingStyle}>
          <h5 style={contentStyle}>
            Bulk Data Upload Guide
          </h5>
          <div style={modalTextStyle}>
            First, download the .csv template file. This file contains the necessary headings
            and instructions to successfully upload workers in bulk. Once you have downloaded
            the .csv template file, you must ensure your data matches the correct format.
            Specific instructions can be found in the .csv template file
          </div>
          <div style={contentStyle}>
            <Button onClick={() => handleCloseBulkUploadInfoModal()}>Cancel</Button>
          </div>
        </div>
      </FormGroup>
    </Modal>
  );
};

const modalDialogueStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}

const formGroupStyle = { width: 500, backgroundColor: "#fff", borderRadius: 10 }

const modalPaddingStyle = { padding: 15 }

const contentStyle = { justifyContent: "center", display: "flex" }

const modalTextStyle = { textAlign: "justify"}

export default BulkUploadInfoModal