import { Add, Clear, Visibility, Edit, Person } from "@mui/icons-material";
import React, { useContext, useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Row,
  Input,
  FormText,
} from "reactstrap";
import api from "../../../components/API/api";
import AgencyContext from "./../../../context/AgencyContext";
import { useHasPermissions } from "hooks/usePermissions";
import CustomCalender from "components/Calendar/CustomCalendar";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import { Modal } from "@mui/material";
import Select from "react-select";
import { startOfDay, startOfNextDay, midDay } from "utils/dateUtils";

const filterOptions = [
  { value: "all", label: "All Assigned Workers" },
  { value: "pickup/dropoff", label: "Two Pickups" },
  { value: "pickup", label: "One Pickup Only" },
  { value: "nothing", label: "No Pickup" },
];

function AllAssignedWorkers(props) {
  const context = useContext(AgencyContext);
  const [workerData, setWorkerData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filter, setFilter] = useState({ value: "all", label: "All Assigned Workers" });
  const [workerDataHeaders, setWorkerDataHeaders] = useState([
    {
      label: "HDID",
      key: "employeeHdId",
    },
    {
      label: "Full Name",
      key: "employeeName",
    },
    {
      label: "Note",
      key: "note",
    },
    {
      label: "Shift Timing",
      key: "shiftTiming",
    },
    {
      label: "First Pickup",
      key: "firstPickup",
    },
    {
      label: "Second Pickup",
      key: "secondPickup",
    },
    {
      label: "Driver",
      key: "driver",
    },
    {
      label: "Assigned By",
      key: "assignedBy",
    },
  ]);
  const [hdId, setHdId] = useState(null);
  const [driverId, setDriverId] = useState(null);
  const [errorHdId, setErrorHdId] = useState(null);
  const [selectedWorker, setSelectedWorker] = useState(null);
  const [errorSelectedWorker, setErrorSelectedWorker] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tableQuery, setTableQuery] = useState("");
  const [pages, setPages] = useState(0);
  const [reactTableState, setReactTableState] = useState({
    page: 1,
    size: 5,
    field: "hdId",
    sort: "desc",
    filter: [],
  });
  const [timer, setTimer] = useState(null);
  const [alert, setAlert] = useState(null);
  const [date, setDate] = useState(new Date());
  const [modalOpen, setModalOpen] = useState(false);
  const [driversList, setDriversList] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openChangeDriverModal, setOpenChangeDriverModal] = useState(false);
  const [errorSelectedShift, setErrorSelectedShift] = useState(null);
  const [note, setNote] = useState(null);
  const [errorNote, setErrorNote] = useState(null);
  const [selectedShift, setSelectedShift] = useState("");
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [errorSelectedDriver, setErrorSelectedDriver] = useState(null);

  const RenderActionButtons = (user) => {
    console.log(user);
    return (
      <div className="actions-right">
        <Button
          onClick={() => handleOpenEditAssignModal(user)}
          color="default"
          size="md"
          className="btn-link btn-icon"
        >
          <Edit style={{ marginLeft: -1 }} />
        </Button>{" "}
        <Button
          onClick={() => handleOpenChangeDriverModal(user)}
          color="warning"
          size="md"
          className="btn-link btn-icon"
        >
          <Person style={{ marginLeft: -1 }} />
        </Button>{" "}
        <Button
          tag={Link}
          to={`/${context.agency.agency.slug}/admin/user-profile/${user.assigned.employee}`}
          color="info"
          size="md"
          className="btn-link btn-icon"
        >
          <Visibility style={{ marginLeft: -1 }} />
        </Button>{" "}
        {moment(date).isAfter(moment(new Date()).set({ hour: 0, minute: 0, second: 0 })) && (
          <>
            <Button
              onClick={() => handleOpenUnAssignModal(user)}
              color="danger"
              size="md"
              className="btn-link btn-icon"
            >
              <Clear style={{ marginLeft: -1 }} />
            </Button>{" "}
          </>
        )}
      </div>
    );
  };

  //Loading Overlay
  const hideAlert = () => {
    setAlert(null);
  };

  useEffect(() => {
    fetchDrivers();
  }, []);

  const fetchDrivers = async () => {
    try {
      setIsLoading(true);
      const { data } = await api().get("/users/get-drivers", {
        params: {
          start: startOfDay(new Date()),
          end: startOfNextDay(new Date()),
        },
      });
      let users = data.users || [];
      users = users.map((u, index) => ({
        label: u.firstName + `${" "}` + u.lastName,
        value: u._id,
      }));
      setDriversList(users);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (date) {
      //  console.log(date)
      fetchData();
    }
  }, [date]);

  useEffect(() => {
    if (filter) {
      filterData();
    }
  }, [filter]);

  const fetchData = async (filterOptions = reactTableState) => {
    try {
      let selectedUrl = window.location.pathname.split("/");
      let selectedDocId = selectedUrl[4];
      setDriverId(selectedDocId);
      setIsLoading(true);
      const { data } = await api().get("/driver-pickup/all-assigned-employees", {
        params: {
          // ...filterOptions,
          from: startOfDay(date),
          till: startOfNextDay(date),
        },
      });
      setIsLoading(false);
      console.log("data", data);
      let users = data.employees || [];
      users = users.map((u, index) => ({
        ...u,
        employeeName: u?.assigned?.employeeName,
        employeeHdId: u?.assigned?.employeeHdId,
        note: u?.assigned?.note,
        shiftTiming: u?.assigned?.shiftTiming,
        driver: u?.driver?.firstName + " " + u?.driver?.lastName,
        assignedBy: u?.assigned?.autoAssigned ? "Driver (Auto-assigned)" : u?.assigned?.assignedBy,
        firstPickup: u.assigned?.firstPickup
          ? moment(u?.assigned?.firstPickup?.time).format("MMM Do YYYY, h:mm a").split(",")[1]
          : null,
        secondPickup: u?.assigned?.secondPickup
          ? moment(u?.assigned?.secondPickup?.time).format("MMM Do YYYY, h:mm a").split(",")[1]
          : null,
        actions: RenderActionButtons(u),
      }));
      setWorkerData(users);
      setFilteredData(users);
      setFilter({ value: "all", label: "Default (Assigned Workers)" });
      setPages(data.noPages);
    } catch (error) {
      console.log(error);
    }
  };

  //filter data based on selected filter option
  const filterData = () => {
    console.log("filter.value", filter.value);
    let tempData = [];
    if (filter.value === "all") {
      tempData = workerData;
    } else if (filter.value === "pickup/dropoff") {
      workerData.map((u) => {
        if (u.firstPickup && u.secondPickup) tempData.push(u);
      });
    } else if (filter.value === "pickup") {
      workerData.map((u) => {
        if (u.firstPickup && !u.secondPickup) tempData.push(u);
      });
    } else {
      workerData.map((u) => {
        if (!u.firstPickup && !u.secondPickup) tempData.push(u);
      });
    }
    setFilteredData(tempData);
  };

  // Opens modal to unassign user to worker
  const handleOpenUnAssignModal = (user) => {
    setModalOpen(true);
    setSelectedWorker(user);
  };

  const handleCloseUnAssignModal = () => {
    setModalOpen(false);
    setSelectedWorker(null);
  };

  const disablePreviousDates = (current) => {
    const today = new Date();
    return moment(today).isBefore(current);
  };

  ////////////////////////////////////////////////////
  //UNASSIGN USER
  ////////////////////////////////////////////////////
  const unAssignModal = () => {
    return (
      <Modal
        open={modalOpen}
        onClose={handleCloseUnAssignModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 350, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>Are you sure want to unassign {selectedWorker?.employeeName}?</h5>
            </div>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button onClick={() => handleCloseUnAssignModal()}>Cancel</Button>
              <Button color="danger" onClick={() => unAssignUser()}>
                UnAssign
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  // unAssign user function
  const unAssignUser = async () => {
    try {
      console.log(selectedWorker);
      let dataToSend = {
        driverId: selectedWorker.driver._id,
        employeeId: selectedWorker.assigned.employee,
        start: startOfDay(date),
        end: startOfNextDay(date),
      };
      await api().patch("/driver-pickup/unassign-employee", dataToSend);
      handleCloseUnAssignModal();
      setAlert(
        <SweetAlert success title="Worker unAssigned successfully " onConfirm={hideAlert} />,
      );
      fetchData();
    } catch (error) {
      console.log(error);
      handleCloseUnAssignModal();
      setAlert(
        <SweetAlert
          error
          title={error.response.data.message || error.message}
          onConfirm={hideAlert}
        />,
      );
    }
  };

  // Opens modal to edit assign user to driver
  const handleOpenEditAssignModal = (user) => {
    setOpenEditModal(true);
    setSelectedWorker(user);
    setNote(user?.assigned?.note);
    setSelectedShift({ value: user?.assigned?.shiftTiming, label: user?.assigned?.shiftTiming });
  };

  const handleCloseEditAssignModal = () => {
    setOpenEditModal(false);
    setSelectedWorker(null);
    setSelectedShift(null);
    setNote(null);
  };

  // Opens modal to change driver to user
  const handleOpenChangeDriverModal = (user) => {
    setOpenChangeDriverModal(true);
    setSelectedWorker(user);
  };

  const handleCloseChangeDriverModal = () => {
    setOpenChangeDriverModal(false);
    setSelectedWorker(null);
    setSelectedDriver(null);
  };

  ////////////////////////////////////////////////////
  // Edit Assign USER
  ////////////////////////////////////////////////////
  const editAssignModal = () => {
    return (
      <Modal
        open={openEditModal}
        onClose={handleCloseEditAssignModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 450, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <Row>
              <Col md="12">
                <h5>Edit Info</h5>
              </Col>
            </Row>

            <Row style={{ marginTop: 10 }}>
              <Col md="3">Pickup Location/Note</Col>
              <Col md="9">
                <Input
                  value={note}
                  placeholder="pickup location or note"
                  type="text"
                  onChange={(e) => {
                    setNote(e.target.value);
                    setErrorNote(null);
                  }}
                />
              </Col>
            </Row>
            {errorNote && (
              <Row>
                <Col md="3"></Col>
                <Col md="9">
                  <FormText color="danger" tag="span">
                    {errorNote}
                  </FormText>
                </Col>
              </Row>
            )}
            <Row style={{ marginTop: 10 }}>
              <Col md="3">Select Shift Timing</Col>
              <Col md="9">
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  options={[
                    { value: "Morning", label: "Morning" },
                    { value: "Afternoon", label: "Afternoon" },
                    { value: "Night", label: "Night" },
                  ]}
                  defaultValue={selectedShift}
                  onChange={(value) => {
                    console.log(value);
                    setSelectedShift(value);
                    setErrorSelectedShift(null);
                  }}
                  placeholder="Select Shift Timing"
                />
              </Col>
            </Row>
            {errorSelectedShift && (
              <Row>
                <Col md="3"></Col>
                <Col md="9">
                  <FormText color="danger" tag="span">
                    {errorSelectedShift}
                  </FormText>
                </Col>
              </Row>
            )}
            <div style={{ justifyContent: "space-between", display: "flex", marginTop: 15 }}>
              <Button onClick={() => handleCloseEditAssignModal()}>Cancel</Button>
              <Button color="success" onClick={() => editAssignUser()}>
                Save
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  //edit asign user function
  const editAssignUser = async () => {
    try {
      let hasError = false;
      if (!note || note.trim() === "") {
        hasError = true;
        setErrorNote("* please add a note to driver e.g pick up location");
      }
      if (!selectedShift) {
        hasError = true;
        setErrorSelectedShift("* please select the shift timing");
      }
      if (hasError) {
        return;
      } else {
        let dataToSend = {
          driverId: selectedWorker.driver._id,
          employeeId: selectedWorker.assigned?.employee,
          start: startOfDay(date),
          end: startOfNextDay(date),
          note: note,
          shiftTiming: selectedShift.value,
        };
        await api().patch("/driver-pickup/update-employee", dataToSend);
        handleCloseEditAssignModal();
        fetchData();
        setAlert(<SweetAlert success title="Worker edited successfully " onConfirm={hideAlert} />);
      }
    } catch (error) {
      console.log(error);
      handleCloseEditAssignModal();
      setAlert(
        <SweetAlert
          error
          title={error.response.data.message || error.message}
          onConfirm={hideAlert}
        />,
      );
    }
  };

  ////////////////////////////////////////////////////
  //Change Driver Assign USER
  ////////////////////////////////////////////////////
  const changeDriverModal = () => {
    return (
      <Modal
        open={openChangeDriverModal}
        onClose={handleCloseEditAssignModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 450, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <Row>
              <Col md="12">
                <h5>Change Driver for worker</h5>
              </Col>
            </Row>

            <Row>
              <Col md="3">Select Driver</Col>
              <Col md="9">
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  options={driversList}
                  defaultValue={selectedDriver}
                  onChange={(value) => {
                    // console.log(value);
                    setSelectedDriver(value);
                    setErrorSelectedDriver(null);
                  }}
                  placeholder="Select driver"
                />
              </Col>
            </Row>

            <div style={{ justifyContent: "space-between", display: "flex", marginTop: 15 }}>
              <Button onClick={() => handleCloseChangeDriverModal()}>Cancel</Button>
              <Button color="success" onClick={() => changeDriver()}>
                Change
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  //change driver user function
  const changeDriver = async () => {
    try {
      if (!selectedDriver?.value || selectedDriver?.value.trim() === "") {
        setErrorSelectedDriver("* please select a driver");
        return;
      } else {
        console.log(selectedWorker);
        console.log(selectedDriver);
        let dataToSend = {
          oldDriver: selectedWorker.driver?._id,
          newDriver: selectedDriver.value,
          employee: selectedWorker.assigned?.employee,
          start: startOfDay(date),
          end: startOfNextDay(date),
        };
        await api().patch("/driver-pickup/reassign-driver", dataToSend);
        handleCloseChangeDriverModal();
        fetchData();
        setAlert(<SweetAlert success title="Driver changed successfully " onConfirm={hideAlert} />);
      }
    } catch (error) {
      console.log(error);
      handleCloseChangeDriverModal();
      setAlert(
        <SweetAlert
          error
          title={error.response.data.message || error.message}
          onConfirm={hideAlert}
        />,
      );
    }
  };

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Row>
            <Col md="8">
              <FormGroup>
                <CSVLink
                  data={workerData}
                  headers={workerDataHeaders}
                  filename={"Drivers_Data.csv"}
                >
                  <Button color="default" outline>
                    Export All
                  </Button>
                </CSVLink>
              </FormGroup>
            </Col>
          </Row>

          <Card className="no-transition">
            <CardHeader>
              <Row>
                <Col md="8">
                  <div className="d-flex justify-content-between">
                    <CardTitle tag="h4">All Assigned Workers</CardTitle>
                  </div>
                </Col>
                <Col md="4">
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    options={filterOptions}
                    defaultValue={filter}
                    onChange={(value) => {
                      // console.log(value);
                      setFilter(value);
                    }}
                    placeholder="Select"
                  />
                </Col>
              </Row>
              <CustomCalender
                initialDate={date}
                onChangeDate={(date) => {
                  setDate(date);
                  // fetchData(date);
                }}
              />
            </CardHeader>
            <CardBody>
              This table displays all assigned workers for the selected date. If you wish to export
              a csv file with all workers + the number of rides per worker for a date range, please
              visit the Export All Rides page.
            </CardBody>
            <CardBody>
              <ReactTable
                columns={[
                  {
                    Header: "ID",
                    accessor: "employeeHdId",
                    maxWidth: 70,
                  },
                  {
                    Header: "Full Name",
                    accessor: "employeeName",
                  },
                  {
                    Header: "Note",
                    accessor: "note",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: "Shift Timing",
                    accessor: "shiftTiming",
                  },
                  {
                    Header: "First Pickup",
                    accessor: "firstPickup",
                    filterable: false,
                  },
                  {
                    Header: "Second Pickup",
                    accessor: "secondPickup",
                    filterable: false,
                  },
                  {
                    Header: "Driver",
                    accessor: "driver",
                  },
                  {
                    Header: "Assigned By",
                    accessor: "assignedBy",
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                    maxWidth: 180,
                  },
                ]}
                // manual
                loading={isLoading}
                pages={pages}
                data={filteredData}
                // onFetchData={(state, instance) => {
                //   let searchParams = {
                //     page: state.page + 1,
                //     size: state.pageSize,
                //     field: state.sorted.length ? state.sorted[0].id : "hdId",
                //     sort: state.sorted.length ? (state.sorted[0].desc ? "desc" : "asc") : "desc",
                //     filter: state.filtered,
                //   };
                //   setReactTableState(searchParams);
                //   clearTimeout(timer);

                //   setTimer(
                //     setTimeout(() => {
                //       setIsLoading(true);
                //       fetchData(searchParams);
                //     }, 1000),
                //   );
                // }}
                getTrProps={(state, rowInfo, instance) => {
                  const autoAssigned = rowInfo?.row?.assignedBy === "Driver (Auto-assigned)";
                  if (autoAssigned) {
                    return {
                      style: {
                        background: "#ddeff5",
                      },
                    };
                  }
                  return {};
                }}
                defaultPageSize={50}
                showPaginationTop
                showPaginationBottom
                filterable
                sortable
                className="-striped -highlight primary-pagination"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      {unAssignModal()}
      {editAssignModal()}
      {changeDriverModal()}
      {alert}
    </div>
  );
}

export default AllAssignedWorkers;
