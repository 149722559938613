import React, { useState } from "react";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import moment from "moment-timezone";

function BookAnAppointment() {
  const [isLoading, setIsLoading] = useState(false);
  const [businessList, setBusinessList] = useState([]);
  const [slotsForSelectedDate, setSlotsForSelectedDate] = useState([]);
  const [data, setData] = useState({
    userHdId: null,
    appointmentDate: null,
    slotTime: null,
    startOfWeek: null,
    endOfWeek: null,
    businessName: null,
  });
  const [error, setError] = useState({
    userHdIdError: "",
    appointmentDateError: "",
    slotTimeError: "",
    startOfWeekError: "",
    endOfWeekError: "",
    businessNameError: "",
  });

  const loadingOverlay = () => {
    return (
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
          background: "transparent",
        }}
        title={<Spinner color="light" style={{ width: "3rem", height: "3rem" }} />}
        showConfirm={false}
      />
    );
  };

  const disablePreviousDates = (current) => {
    // const today = new Date();
    let yesterday = new Date(moment().subtract(1, "days"));
    return current.isAfter(yesterday);
  };

  const handleSubmit = () => {
   let hasError = false;
   let tempError = error;

   if(!data.userHdId){
    hasError = true;
    tempError.userHdIdError = "* please enter a worker HdId";
   }

   if(!data.appointmentDate){
    hasError = true;
    tempError.appointmentDateError = "* please select the appointment date";
   }

   if(!data.slotTime){
    hasError = true;
    tempError.slotTimeError = "* please select the slot for appointment";
   }

   if(!data.startOfWeek){
    hasError = true;
    tempError.startOfWeekError = "* please select start of week for cheque";
   }

   if(!data.endOfWeek){
    hasError = true;
    tempError.endOfWeekError = "* please select end of week for cheque";
   }

   if(!data.businessName){
    hasError = true;
    tempError.businessNameError = "* please select business name";
   }

   if(hasError){
    setError(tempError);
    return;
   }else{
    console.log('submit a cheque')
   }

  };

  return (
    <div className="content" style={{ marginTop: "130px" }}>
      {isLoading && loadingOverlay()}
      <Row>
        <Col md="12">
          <Card className="no-transition">
            <CardHeader>
              <CardTitle tag="h4">Book an Appointment</CardTitle>
            </CardHeader>
            <CardBody>
              <Form action="/" className="form-horizontal" method="get">
                <Row style={{ paddingTop: "4px" }}>
                  <Label sm="2">* Worker HDID</Label>
                  <Col sm="6">
                    <FormGroup>
                      <Input
                        type="number"
                        min="15"
                        value={data.userHdId}
                        onChange={(e) => {
                          setData({
                            ...data,
                            userHdId: e.target.value,
                          });
                          setError({ ...error, userHdIdError: "" });
                        }}
                      />
                      <FormText color="danger" tag="span">
                        {error.userHdIdError}
                      </FormText>
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={{ paddingTop: "20px" }}>
                  <Label sm="2">* Appointment Date</Label>
                  <Col md="6">
                    <ReactDatetime
                      isValidDate={disablePreviousDates}
                      value={data.appointmentDate}
                      timeFormat={false}
                      onChange={(date) => {
                        // console.log("End Time: ", date);
                        setData({ ...data, appointmentDate: date });
                        setError({ ...error, appointmentDateError: "" });
                      }}
                    />
                    <FormText color="danger" tag="span">
                      {error.appointmentDateError}
                    </FormText>
                  </Col>
                </Row>
                <Row style={{ paddingTop: "20px"  }}>
                  <Label sm="2">* Select slot</Label>
                  <Col sm="6">
                    <FormGroup>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="slots"
                        value={data.slotTime}
                        onChange={(value) => {
                          setData({ ...data, slotTime: value });
                          setError({ ...error, slotTimeError: "" });
                        }}
                        options={slotsForSelectedDate}
                        placeholder="Select Business"
                      />
                      <FormText color="danger" tag="span">
                        {error.slotTimeError}
                      </FormText>
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={{ paddingTop: "20px" }}>
                  <Label sm="2">* Start of Week</Label>
                  <Col md="6">
                    <ReactDatetime
                      isValidDate={disablePreviousDates}
                      value={data.startOfWeek}
                      onChange={(date) => {
                        // console.log("Start Time: ", date);
                        setData({ ...data, startOfWeek: date });
                        setError({ ...error, startOfWeekError: "" });
                      }}
                    />
                    <FormText color="danger" tag="span">
                      {error.startOfWeekError}
                    </FormText>
                  </Col>
                </Row>
                <Row style={{ paddingTop: "20px" }}>
                  <Label sm="2">* End of Week </Label>
                  <Col md="6">
                    <ReactDatetime
                      isValidDate={disablePreviousDates}
                      value={data.endOfWeek}
                      onChange={(date) => {
                        // console.log("End Time: ", date);
                        setData({ ...data, endOfWeek: date });
                        setError({ ...error, endTimeError: "" });
                      }}
                    />
                    <FormText color="danger" tag="span">
                      {error.endTimeError}
                    </FormText>
                  </Col>
                </Row>

                <Row style={{ paddingTop: "20px"  }}>
                  <Label sm="2">* Business Name</Label>
                  <Col sm="6">
                    <FormGroup>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="businesses"
                        value={data.businessName}
                        onChange={(value) => {
                          setData({ ...data, businessName: value });
                          setError({ ...error, businessNameError: "" });
                        }}
                        options={businessList}
                        placeholder="Select Business"
                      />
                      <FormText color="danger" tag="span">
                        {error.businessNameError}
                      </FormText>
                    </FormGroup>
                  </Col>
                </Row>

                <Row style={{ paddingTop: "4px" }}>
                  <Col sm="9"></Col>
                  <Col sm="3">
                    <Button color="success" onClick={handleSubmit}>
                      Create Appointment
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default BookAnAppointment;
