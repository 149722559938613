import {
  AssignmentTurnedIn,
  Lock,
  LockOpen,
  Notifications,
  NotificationsActive,
  Sync,
  Visibility,
} from "@mui/icons-material";
import api from "components/API/api";
import CustomCalendar from "components/Calendar/CustomCalendar";
import CustomExportBusiness, { customExcelData } from "components/Excel/CustomExportBusiness";
import LoadingOverlay from "components/OverLay/LoadingOverlay";
import Permission from "components/permissions/Permission";
import AgencyContext from "context/AgencyContext";
import { useModal } from "context/ModalContext";
import { useSocket } from "context/SocketContext";
import { useHasPermissions } from "hooks/usePermissions";
import moment from "moment-timezone";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Col,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import {
  afternoonTimeRange,
  morningTimeRange,
  nightTimeRange,
  shiftTimeType,
} from "utils/dateUtils";
import { getDay, startOfDay, startOfNextDay } from "utils/dateUtils.js";
import { statusEnum } from "utils/statusVariables";
import { PermissionActionEnum, PermissionModuleEnum } from "../AllStaff/permissionTemplate";
import { csvDataHeaders, listneningSockets } from "./LiveBusinessConstants";
import GlobalModal from "components/Modal/GlobalModal";

const RequiredWorkersCell = ({ _id, value, onChange }) => {
  const [clicked, setClicked] = useState(false);
  const [newValue, setNewValue] = useState(value);
  const [loading, setLoading] = useState(false);
  const canLockOrChangeRequiredPeople = useHasPermissions({
    module: "LiveBusiness",
    action: "ChangeRequired",
  });
  const { addAlert } = useModal();
  const onEnter = () => {
    setClicked(false);
    if (newValue !== value) {
      const data = {
        noRequiredWorkers: parseInt(newValue),
      };

      if (!data.noRequiredWorkers && data.noRequiredWorkers !== 0) {
        setNewValue(value);
      } else {
        setLoading(true);
        api({
          headers: { client_timezone: moment?.tz.guess(), client_timestamp: moment().format() },
        })
          .patch(`/shift/required-people/${_id}`, data)
          .then(() => {
            setLoading(false);
            onChange(newValue, _id);
          })
          .catch((e) => {
            setLoading(false);
            setNewValue(value);
            addAlert({
              error: true,
              message: e?.response?.data?.message || e.message,
            });
          });
      }
    } else {
      setNewValue(value);
    }
  };
  return (
    <div className="w-100 h-100" style={{ cursor: "pointer" }}>
      {loading ? (
        <Spinner />
      ) : clicked && canLockOrChangeRequiredPeople ? (
        <Input
          type="text"
          value={newValue}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onEnter();
            }
          }}
          onBlur={() => {
            onEnter();
          }}
          autoFocus={clicked}
          onChange={(e) => setNewValue(e.target.value)}
        />
      ) : (
        <p onClick={() => setClicked(true)} className="w-100 h-100">
          {value}
        </p>
      )}
    </div>
  );
};

const Module = function ({ business, currentDate, onUpdate, setIsLoading }) {
  const context = useContext(AgencyContext);
  const canLockOrChangeRequiredPeople = useHasPermissions({
    module: "LiveBusiness",
    action: "Lock",
  });
  const { addAlert } = useModal();
  const canSendNotification = useHasPermissions({ module: "Notification", action: "Create" });
  const canExportDocument = useHasPermissions({ module: "LiveBusiness", action: "Export" });

  const [noMorningWorkers, setNoMorningWorkers] = useState(0),
    [noAfternoonWorkers, setNoAfterNoonWorkers] = useState(0),
    [noNightWorkers, setNoNightWorkers] = useState(0);

  const [noMorningBackupWorkers, setNoMorningBackupWorkers] = useState(0),
    [noAfternoonBackupWorkers, setNoAfterNoonBackupWorkers] = useState(0),
    [noNightBackupWorkers, setNoNightBackupWorkers] = useState(0);

  const [noMorningRequired, setNoMorningRequired] = useState(0),
    [noAfternoonRequired, setNoAfterNoonRequired] = useState(0),
    [noNightRequired, setNoNightRequired] = useState(0);

  const [noMorningNonConfirmed, setNoMorningNonConfirmed] = useState(0),
    [noAfternoonNonConfirmed, setNoAfternoonNonConfirmed] = useState(0),
    [noNightNonConfirmed, setNoNightNonConfirmed] = useState(0);

  const [shiftsData, setShiftsData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment(currentDate));
  const [selectedShift, setSelectedShift] = useState(null);
  const [notificationType, setNotificationType] = useState("private");
  const [notificationModal, setNotificationModal] = useState(false);

  const [download, setDownload] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const [shiftVisibilityLoading, setShiftVisibilityLoading] = useState(false);
  const accessShiftVisibility = useHasPermissions({
    module: PermissionModuleEnum.LiveBusiness,
    action: PermissionActionEnum.ShiftVisibility,
  });

  useEffect(() => {
    let morning = morningTimeRange(currentDate),
      afternoon = afternoonTimeRange(currentDate),
      night = nightTimeRange(currentDate);

    let noMorningWorkers = 0,
      noAfternoonWorkers = 0,
      noNightWorkers = 0;

    let noMorningBackupWorkers = 0,
      noAfternoonBackupWorkers = 0,
      noNightBackupWorkers = 0;

    let noMorningRequired = 0,
      noAfternoonRequired = 0,
      noNightRequired = 0;

    let noMorningNonConfirmed = 0,
      noAfternoonNonConfirmed = 0,
      noNightNonConfirmed = 0;

    business &&
      business.shifts.forEach((shift) => {
        let start = new Date(shift.start);

        if (moment(start).isBetween(morning.start, morning.end)) {
          noMorningWorkers += shift.noSelected;
          noMorningBackupWorkers += shift.noBackup;
          noMorningRequired += shift.noRequiredWorkers;
          noMorningNonConfirmed += shift.noApplied;
        } else if (moment(start).isBetween(afternoon.start, afternoon.end)) {
          noAfternoonWorkers += shift.noSelected;
          noAfternoonBackupWorkers += shift.noBackup;
          noAfternoonRequired += shift.noRequiredWorkers;
          noAfternoonNonConfirmed += shift.noApplied;
        } else if (moment(start).isBetween(night.start, night.end)) {
          noNightWorkers += shift.noSelected;
          noNightBackupWorkers += shift.noBackup;
          noNightRequired += shift.noRequiredWorkers;
          noNightNonConfirmed += shift.noApplied;
        }
      });

    setNoMorningWorkers(noMorningWorkers);
    setNoAfterNoonWorkers(noAfternoonWorkers);
    setNoNightWorkers(noNightWorkers);

    setNoMorningBackupWorkers(noMorningBackupWorkers);
    setNoAfterNoonBackupWorkers(noAfternoonBackupWorkers);
    setNoNightBackupWorkers(noNightBackupWorkers);

    setNoMorningRequired(noMorningRequired);
    setNoAfterNoonRequired(noAfternoonRequired);
    setNoNightRequired(noNightRequired);

    setNoMorningNonConfirmed(noMorningNonConfirmed);
    setNoAfternoonNonConfirmed(noAfternoonNonConfirmed);
    setNoNightNonConfirmed(noNightNonConfirmed);

    setData(business);
  }, [business, currentDate]);

  const setData = async (business) => {
    try {
      let shifts = business.shifts;
      let newShifts = shifts
        .map((s) => ({
          ...s,
          startDate: moment(s.start).format("hh:mm A"),
          endDate: moment(s.end).format("hh:mm A"),
          actions: RenderActionButtons(s, business),
        }))
        .sort((a, b) => moment(a.start) - moment(b.start));
      setShiftsData(newShifts);
    } catch (error) {
      addAlert({
        error: true,
        title: error.message,
      });
      console.log(error);
    }
  };

  const RenderActionButtons = (s, business) => {
    let showNotification = false;
    if (
      (moment(s.start).isAfter(s.end) && moment(s.end).add(1, "days").isAfter(moment())) ||
      moment().isBefore(s.end)
    )
      showNotification = true;
    const closed = s.closed;
    const shiftBoard = s.shiftBoard;

    //View shift information
    let search = new URLSearchParams();
    search.append("date", moment(currentDate).format());
    search.append("shift", s._id);
    let slug = context.agency.agency.slug;

    return (
      <div style={{ textAlign: "center" }}>
        <Button
          onClick={() => {
            if (!canLockOrChangeRequiredPeople) {
              addAlert({
                warning: true,
                title: "Not enough permissions",
              });
              return;
            }

            const resp = api()
              .patch(`/shift/${s._id}`, {
                closed: !closed,
              })
              .then(() => {
                setShiftsData((oldShiftData) => {
                  return oldShiftData.map((sh) => {
                    if (sh._id === s._id) {
                      sh.closed = !closed;
                      sh.actions = RenderActionButtons(sh, business);
                    }
                    return sh;
                  });
                });
              })
              .catch((e) => {
                addAlert({
                  error: true,
                  title: e.response?.data?.message || e.message,
                });
                console.log(e);
              });
          }}
          color={closed ? "success" : "secondary"}
          size="md"
          className="btn-link btn-icon"
        >
          {closed ? <Lock /> : <LockOpen />}
        </Button>

        {accessShiftVisibility && (
          <Button
            onClick={() => {
              setShiftVisibilityLoading(true);
              api()
                .patch(`/shift/${s._id}`, {
                  shiftBoard: !shiftBoard,
                })
                .then(() => {
                  setShiftsData((oldShiftData) => {
                    return oldShiftData.map((sh) => {
                      if (sh._id === s._id) {
                        sh.shiftBoard = !shiftBoard;
                        sh.actions = RenderActionButtons(sh, business);
                      }
                      return sh;
                    });
                  });
                })
                .catch((e) => {
                  console.log(e);
                  addAlert({
                    title: "Couldn't Update shift board",
                    error: true,
                    children: e.response?.data?.message || e.message,
                  });
                })
                .finally(() => {
                  setShiftVisibilityLoading(false);
                });
            }}
            color={shiftBoard ? "success" : "secondary"}
            size="md"
            className="btn-link btn-icon"
            disabled={shiftVisibilityLoading}
          >
            {<AssignmentTurnedIn />}
          </Button>
        )}

        <Permission
          module="Notification"
          action="Create"
          error="You don't have enough permission to send notification."
        >
          <Button
            onClick={() => {
              showNotification && handleOpenSendPushNotificationModal(s);
            }}
            color={showNotification ? "info" : "secondary"}
            size="md"
            className="btn-link btn-icon"
          >
            {s.notification?.length > 0 ? <NotificationsActive /> : <Notifications />}
          </Button>
        </Permission>
        <Button
          tag={Link}
          to={`/${slug}/admin/all-live-shift-workers?${search.toString()}`}
          color="info"
          size="md"
          className="btn-link btn-icon"
        >
          <Visibility />
        </Button>
      </div>
    );
  };

  const handleOpenSendPushNotificationModal = (s) => {
    setNotificationModal(true);
    setSelectedShift(s);
  };

  const handleCloseSendPushNotificationModal = () => {
    setNotificationModal(false);
    setNotificationType("private");
    setSelectedDate(moment(currentDate));
  };

  const sendNotification = async (shift, date) => {
    handleCloseSendPushNotificationModal();
    setIsLoading(true);
    try {
      const { data } = await api().post("/notification/shiftNotification", {
        shiftId: shift._id,
        startOfDay: startOfDay(shift.start),
        endOfDay: startOfNextDay(shift.start),
        type: notificationType,
        push: true,
        shiftStart: shift.start,
        shiftEnd: shift.end,
      });
      setIsLoading(false);
      addAlert({
        success: true,
        title: "Successfully sent notification",
        children: `Total of notifications sent to ${data.notificationsSent} users`,
      });
    } catch (error) {
      setIsLoading(false);
      addAlert({
        error: true,
        title: "An error has occurred",
        children: error.response?.data?.message || error.message,
      });
    }
  };

  const fetchUsers = async (b_id, s_id, _date = currentDate) => {
    let dataCsv = [];
    setIsLoading(true);
    try {
      let data = await api()
        .get(`selected-for-work/users/${s_id}`, {
          params: {
            start: startOfDay(new Date(_date)),
            end: startOfNextDay(new Date(_date)),
            status: "confirmed",
          },
        })
        .then(({ data }) => {
          let newData = data.map((s) => ({
            ...s,
            displayLastName:
              s.displayLastName || s.duplicated ? s.lastName + "-" + s.duplicated : s.lastName,
          }));
          return data;
        });
      dataCsv = [...dataCsv, data];
      return dataCsv.flat();
    } catch (error) {
      addAlert({
        error: true,
        title: error?.response?.data?.message || error.message,
      });
      console.log(error);
      return [];
    }
  };

  return (
    <Card className="no-transition bg-light">
      <CardHeader className="d-flex justify-content-between">
        <div>
          <CardTitle tag="h6">{business.name}</CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted d-block">
            {!business.address && !business.intersection && "-"}
            {business.address} {business.intersection}
          </CardSubtitle>
        </div>
        {canExportDocument && (
          <>
            <Button
              onClick={() => {
                (async () => {
                  let excelData = [];
                  for (let s of business.shifts) {
                    let response = await fetchUsers(business._id, s._id);
                    response.sort(function (a, b) {
                      var nameA = a.firstName.toLowerCase(),
                        nameB = b.firstName.toLowerCase();
                      if (nameA < nameB)
                        //sort string ascending
                        return -1;
                      if (nameA > nameB) return 1;
                      return 0; //default return value (no sorting)
                    });
                    console.log(response);
                    for (let r of response) {
                      r.start = moment(s.start).format("hh:mm a");
                      r.lastName = r.duplicated ? r.lastName + "-" + r.duplicated : r.lastName;
                      r.vaccineStatus =
                        r.vaccineStatus === 2
                          ? "Fully Vaccinated"
                          : r.vaccineStatus === 1
                          ? "1st dose"
                          : "No Vaccine";
                      r.shiftId = s._id;
                      r.workerPin = r.workerPin.find(
                        (w) =>
                          w.agency === context.agency.agency._id && w.business === business._id,
                      )?.pin;
                      r.gender = r.gender === "F" ? "FEMALE" : "MALE";
                      r.agency = "LYR";
                      r.dept = "PRODUCTION";
                      r.time = r.start;
                    }
                    if (response.length > 0) {
                      excelData.push(
                        customExcelData({
                          regular: response.filter(
                            (r) => r.status === statusEnum.selectedForWork.selected,
                          ),
                          currentDate,
                          headers: csvDataHeaders,
                          businessName: business.name,
                          shiftType: shiftTimeType(s.start),
                          date: moment(s.start).format("MMMM DD YYYY"),
                          day: moment(s.start).format("dddd"),
                          extras: response.filter(
                            (r) => r.status === statusEnum.selectedForWork.Backup,
                          ),
                          time: moment(s.start).format("hh_mm a"),
                        }),
                      );
                    }
                  }
                  setExcelData(excelData);
                  setDownload(true);
                })();
              }}
            >
              EXPORT
            </Button>
            <CustomExportBusiness
              excelData={excelData}
              download={download}
              setDownload={(download) => setDownload(download)}
              fileName={`${business.name}_${moment(currentDate).format("YYYY_MM_DD")}`}
            />
          </>
        )}

        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            setIsLoading(true);
            api()
              .post("/shift/reset/counter", {
                from: startOfDay(currentDate),
                till: startOfNextDay(currentDate),
                businessId: business._id,
              })
              .then(() => {
                addAlert({ success: true, title: "Successfully refreshed the counter!" });
                onUpdate();
              })
              .catch((e) => {
                addAlert({
                  success: false,
                  title: "Sorry! Couldn't refresh the counter!",
                  children: e.response?.data?.message || e.message,
                });
              })
              .finally(() => setIsLoading(false));
          }}
        >
          <Sync fontSize="small" />
        </div>
      </CardHeader>
      <CardBody>
        <div className="d-flex justify-content-between">
          <p style={boxBlankColumn}></p>
          <p style={boxColumnStyle}>{window.innerWidth > 500 ? "Required" : "Req"}</p>
          <p style={boxColumnStyle}>{window.innerWidth > 500 ? "Confirmed" : "Con"}</p>
          <p style={boxColumnStyle}>{window.innerWidth > 500 ? "Backup" : "Bac"}</p>
          <p style={boxColumnStyle}>{window.innerWidth > 500 ? "Available" : "Ava"}</p>
        </div>
        <div className="d-flex justify-content-between">
          <p style={boxBlankColumn}>{window.innerWidth > 500 ? "Morning" : "M"}</p>
          <p style={boxColumnStyle}>{noMorningRequired}</p>
          <p style={boxColumnStyle}>{noMorningWorkers}</p>
          <p style={boxColumnStyle}>{noMorningBackupWorkers}</p>
          <p style={boxColumnStyle}>{noMorningNonConfirmed}</p>
        </div>
        <div className="d-flex justify-content-between">
          <p style={boxBlankColumn}>{window.innerWidth > 500 ? "Afternoon" : "A"}</p>
          <p style={boxColumnStyle}>{noAfternoonRequired}</p>
          <p style={boxColumnStyle}>{noAfternoonWorkers}</p>
          <p style={boxColumnStyle}>{noAfternoonBackupWorkers}</p>
          <p style={boxColumnStyle}>{noAfternoonNonConfirmed}</p>
        </div>
        <div className="d-flex justify-content-between">
          <p style={boxBlankColumn}>{window.innerWidth > 500 ? "Night" : "N"}</p>
          <p style={boxColumnStyle}>{noNightRequired}</p>
          <p style={boxColumnStyle}>{noNightWorkers}</p>
          <p style={boxColumnStyle}>{noNightBackupWorkers}</p>
          <p style={boxColumnStyle}>{noNightNonConfirmed}</p>
        </div>
        <hr style={{ border: "0.5px solid grey" }} />
        <div className="d-flex justify-content-between font-weight-bold">
          <p style={boxBlankColumn}>{window.innerWidth > 500 ? "Total" : "T"}</p>
          <p style={boxColumnStyle}>{noMorningRequired + noAfternoonRequired + noNightRequired}</p>
          <p style={boxColumnStyle}>{noMorningWorkers + noAfternoonWorkers + noNightWorkers}</p>
          <p style={boxColumnStyle}>
            {noMorningBackupWorkers + noAfternoonBackupWorkers + noNightBackupWorkers}
          </p>
          <p style={boxColumnStyle}>
            {noMorningNonConfirmed + noAfternoonNonConfirmed + noNightNonConfirmed}
          </p>
        </div>
      </CardBody>
      <CardFooter>
        <ReactTable
          columns={[
            {
              Header: "Start",
              accessor: "startDate",
              maxWidth: 80,
            },
            {
              Header: "End",
              accessor: "endDate",
              maxWidth: 80,
            },
            {
              Header: "Required",
              accessor: "noRequiredWorkers",
              minWidth: 60,
              Cell: (row) => {
                return (
                  <RequiredWorkersCell
                    value={row.value}
                    noRequiredWorkers={row.original.noRequiredWorkers}
                    _id={row.original._id}
                    shiftStart={row.original.start}
                    shiftEnd={row.original.end}
                    onChange={(newNoPeople) => {
                      setShiftsData(
                        shiftsData.map((s) => {
                          let openDate = moment(row.original.start)
                            .set({ hour: 23, minute: 59 })
                            .format();
                          let closeDate = moment(row.original.start)
                            .set({ hour: 0, minute: 0 })
                            .format();
                          if (s._id === row.original._id) {
                            s.noRequiredWorkers = newNoPeople;
                            if (newNoPeople && newNoPeople > row.original.noSelected) {
                              s.closedDate = new Date(openDate);
                            } else if (newNoPeople) {
                              s.closedDate = new Date(closeDate);
                            }
                          }
                          s.actions = RenderActionButtons(s, business);

                          return s;
                        }),
                      );
                    }}
                  />
                );
              },
            },
            {
              Header: "Confirmed",
              accessor: "noSelected",
              minWidth: 60,
            },
            {
              Header: "Backup",
              accessor: "noBackup",
              minWidth: 60,
            },
            {
              Header: "Available",
              accessor: "noApplied",
              minWidth: 60,
            },
            {
              Header: "UnScheduled",
              accessor: "noUnScheduled",
              minWidth: 60,
            },
            {
              Header: "C/I",
              accessor: "noCheckIn",
              maxWidth: 60,
            },
            {
              Header: "C/O",
              accessor: "noCheckOut",
              maxWidth: 60,
            },
            {
              Header: "Actions",
              accessor: "actions",
              sortable: false,
              filterable: false,
              width: 190,
            },
          ]}
          data={shiftsData}
          defaultPageSize={24}
          minRows={0}
          showPagination={false}
          className="-striped -highlight primary-pagination"
          getTrProps={(state, rowInfo, column) => {
            let closed = false;
            let closedDate = rowInfo.original.closedDate && moment(rowInfo.original.closedDate);
            if (rowInfo) {
              return {
                style: {
                  background:
                    rowInfo.row.noRequiredWorkers !== 0 && !rowInfo.row.noRequiredWorkers
                      ? "#f7f2e4"
                      : rowInfo.row.noSelected >= rowInfo.row.noRequiredWorkers
                      ? "#e4f7e7"
                      : rowInfo.row.noRequiredWorkers === 0 ||
                        (rowInfo.row.noRequiredWorkers === 0 && shiftsData.noSelected > 0)
                      ? "#f0eeed"
                      : "",
                  color:
                    parseInt(rowInfo.row.noSelected) >= parseInt(rowInfo.row.noRequiredWorkers)
                      ? "green"
                      : parseInt(rowInfo.row.noRequiredWorkers) > parseInt(rowInfo.row.noSelected)
                      ? "red"
                      : "#333333",
                },
              };
            }
          }}
        />
      </CardFooter>
      <GlobalModal
        open={notificationModal}
        close={handleCloseSendPushNotificationModal}
        title="Send a notification"
        desc="Send a notifications to all workers that are available to work at this shift"
        secTitle="Who should received this notification?"
        cancelAction={handleCloseSendPushNotificationModal}
        btnColor="info"
        action={() => canSendNotification && sendNotification(selectedShift, currentDate)}
        btnMessage="Send"
        // modal specific actions
        children={
          <Input
            type="select"
            value={notificationType}
            onChange={(e) => setNotificationType(e.target.value)}
          >
            <option value="private">Private for this business workers</option>
            <option value="public">Public for those who have never worked</option>
            <option value="allWorkers">All workers who haven't worked at this business</option>
          </Input>
        }
        notificationType={notificationType}
        setNotificationType={setNotificationType}
        // modal specific styles
        formGroupGeneral={{ width: 400, backgroundColor: "#fff", borderRadius: 10, padding: 15 }}
      />
    </Card>
  );
};

export default function AllBusiness(props) {
  const [businesses, setBusinesses] = useState([]);
  const context = useContext(AgencyContext);
  const agency = context.agency.agency;
  const [date, setDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [requiredWokers, setRequiredWorkers] = useState(0);
  const [confirmedWorkers, setConfirmedWorkers] = useState(0);
  const { addAlert } = useModal();
  const { socket } = useSocket();

  useEffect(() => {
    setIsLoading(true);
    loadLiveBusinesses(agency, date);
  }, []);

  const liveUpdate = (data) => {
    if (data.source._id === context.agency.user._id) {
      console.log("sender is same do nothing");
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (businesses.length > 0) {
      const requiredWorkers = businesses.reduce(
        (acc, business) =>
          acc + business.shifts.reduce((acc2, shift2) => acc2 + shift2.noRequiredWorkers, 0),
        0,
      );
      const confirmedWorkers = businesses.reduce(
        (acc, business) =>
          acc + business.shifts.reduce((acc2, shift2) => acc2 + shift2.noSelected, 0),
        0,
      );
      setConfirmedWorkers(confirmedWorkers);
      setRequiredWorkers(requiredWorkers);
    }
  }, [businesses]);

  useEffect(() => {
    if (socket) {
      listneningSockets.map((soc) => {
        socket.on(soc, (data) => {
          liveUpdate(data) && loadLiveBusinesses(agency);
        });
      });
    }
    return () => {
      console.log("disconnected");
      socket.off();
    };
  }, [socket, date]);

  const loadLiveBusinesses = function (agency, selectedDate) {
    if (!selectedDate) selectedDate = date;
    api()
      .post(`/business/all-shift-requests/`, {
        agencyId: agency._id,
        start: startOfDay(selectedDate),
        end: startOfNextDay(selectedDate),
        day: getDay(selectedDate),
      })
      .then(({ data }) => {
        //manupulate data in case the start and end date are null for a shift
        let newBusinessData = data.map((business) => {
          return {
            ...business,
            shifts: business.shifts.map((shift) => {
              if (!shift.start) {
                let currentWeekDaySchedule = shift.weekSchedule.find(
                  (s) => s.day === getDay(selectedDate),
                );
                shift.start = moment(selectedDate).set({
                  hour: currentWeekDaySchedule?.start.hour,
                  minute: currentWeekDaySchedule.start.minute,
                });
                shift.end = moment(selectedDate).set({
                  hour: currentWeekDaySchedule?.end.hour,
                  minute: currentWeekDaySchedule.end.minute,
                });

                if (shift.start.isAfter(shift.end)) {
                  shift.end = moment(shift.end).add(1, "days");
                }
              }
              return shift;
            }),
          };
        });

        setIsLoading(false);
        setBusinesses(newBusinessData);
        context.agencyDispatch({
          type: "SET_LIVE_BUSINESSES",
          payload: { liveBusinesses: newBusinessData },
        });
      })
      .catch((e) => {
        addAlert({
          error: true,
          title: e.response?.data?.message || e.message,
        });
        setIsLoading(false);
        console.log(e);
      });
  };

  return (
    <div className="content">
      <Card className="no-transition">
        <CardHeader>
          <div className="d-flex justify-content-between align-items-center">
            {window.innerWidth > 500 ? (
              <>
                <CardTitle tag="h4">All Business shift requests</CardTitle>
                <h6>Required Workers: {requiredWokers}</h6>
                <h6>Confirmed Workers: {confirmedWorkers}</h6>
                {/* This button is for UI and informational purposes, there is no onClick. */}
                <Button
                  color={requiredWokers <= confirmedWorkers ? "success" : "danger"}
                  disabled="true"
                >
                  Reach goal
                </Button>
              </>
            ) : (
              <>
                <CardTitle tag="h4">All Business shift requests</CardTitle>
                <h6>Req: {requiredWokers}</h6>
                <h6>Con: {confirmedWorkers}</h6>
              </>
            )}
          </div>
          <CustomCalendar
            initialDate={date}
            onChangeDate={(date) => {
              setDate(date);
              setIsLoading(true);
              if (agency) {
                loadLiveBusinesses(agency, date);
              }
            }}
          />
        </CardHeader>
        <CardBody>
          {isLoading ? (
            <LoadingOverlay />
          ) : (
            <Row>
              {businesses.length > 0 ? (
                businesses.map((business, i) => {
                  return (
                    <Col xl="6" lg="12" md="12" sm="12" xs="12">
                      <Module
                        business={business}
                        key={i}
                        index={i}
                        setIsLoading={setIsLoading}
                        currentDate={date}
                        onUpdate={() => loadLiveBusinesses(agency, date)}
                      />
                    </Col>
                  );
                })
              ) : (
                <p>No workers requested for the day.</p>
              )}
            </Row>
          )}
        </CardBody>
      </Card>
    </div>
  );
}

const boxColumnStyle = { flex: 2, textAlign: "center" };
const boxBlankColumn = { flex: 2 };
