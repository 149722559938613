import React, { useState, useEffect } from "react";
import { Button, Input } from "reactstrap";
import Papa from "papaparse";

//import { fs } from "components/Firebase/firebase.js";
import SweetAlert from "react-bootstrap-sweetalert";

export default function UploadCheque(props) {
  /*
  const [csvfile, setcsvfile] = useState(undefined);

  const [cheque, setCheque] = useState(null);

  const [alert, setAlert] = useState(null);

  const handleChange = (event) => {
    setcsvfile(event.target.files[0]);
  };

  const importCSV = () => {
    Papa.parse(csvfile, {
      complete: updateData,
      header: true,
    });
  };

  const updateData = async (result) => {
    let cheques = result.data;

    if (cheques) {
      let createdAt = new Date();
      cheques = cheques.map((c) => {
        let key,
          keys = Object.keys(c);
        let n = keys.length;
        let newobj = {};
        newobj.status = "Unpaid";
        while (n--) {
          key = keys[n];
          newobj[key.toLowerCase()] = c[key];
        }
        newobj.createdAt = createdAt;
        return newobj;
      });
      cheques = cheques.filter(
        (c) => c.account && c.account !== "" && c.badge && c.badge !== "" && c.num && c.num !== "",
      );

      const promises = [];

      cheques.forEach((cheque) => {
        promises.push(
          fs
            .collection("cheque_data")
            .where("num", "==", cheque.num)
            .get()
            .then((snap) => {
              if (snap.docs.length === 0) {
                promises.push(fs.collection("cheque_data").add(cheque));
              }
            }),
        );
      });

      await Promise.all(promises);

      setAlert(
        <SweetAlert
          success
          title="Successfully uploaded the data."
          onConfirm={() => {
            setAlert(null);
          }}
        />,
      );
      props.loadCheques();
      setCheque(null);
    }
  };
*/
  return (
    <div className="App">
      <input
        className="csv-input"
        type="file"
        name="file"
        placeholder={null}
        //onChange={handleChange}
      />
      <Button 
      color="default" 
      outline 
      //onClick={importCSV}
      >
        Upload CSV
      </Button>
      {alert}
    </div>
  );
}
