import React from "react";
import { Modal, Button } from "@mui/material";
import PropTypes from "prop-types";
export default function DeleteAppointmentSlot({ onClose, open, onDelete }) {
  return (
    <Modal
      onClose={onClose}
      open={open}
      style={{ justifyContent: "center", display: "flex", alignItems: "center" }}
    >
      <div style={{ width: "500px", backgroundColor: "#fff", padding: "40px" }}>
        <p>Are you sure you want to delete the time slot?</p>
        <p>Deleting the slot will delete all the appointments within.</p>

        <div className="justify-content-between d-flex">
          <Button
            variant="contained"
            onClick={() => {
              onDelete();
            }}
          >
            Yes Delete
          </Button>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
}

DeleteAppointmentSlot.propTypes = {
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  open: PropTypes.bool,
};
