import React, { useState, useRef } from "react";
import ReactDatetime from "react-datetime";
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  FormText,
  CardHeader,
  CardTitle,
} from "reactstrap";
import LoadingOverlay from "components/OverLay/LoadingOverlay";
import moment from "moment-timezone";
import api from "components/API/api";
import { getDay, startOfDay, startOfNextDay } from "utils/dateUtils.js";
import { CSVLink } from "react-csv";

function ExportSelectedWorkers() {
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState({
    fromDate: new Date(),
    toDate: new Date(),
  });

  const [workersData, setWorkersData] = useState([]);
  const workerHeaders = [
    {
      label: "HDID",
      key: "hdId",
    },
    {
      label: "FirstName",
      key: "firstName",
    },
    {
      label: "LastName",
      key: "lastName",
    },
    {
      label: "Sin",
      key: "sin",
    },
    {
      label: "Email",
      key: "email",
    },
    {
      label: "Phone",
      key: "phone",
    },
    {
      label: "Address",
      key: "address",
    },
    {
      label: "City",
      key: "city",
    },
  ];

  const csvRef = useRef(null);

  const handleDownload = () => {
    let params = {
      from: startOfDay(filter.fromDate),
      till: startOfNextDay(filter.toDate),
    };
    api()
      .get("selected-for-work/history", { params })
      .then((res) => {
        console.log(res);
        let data = res.data.history;
        setWorkersData(data);
        console.log(csvRef.current);
        csvRef.current.link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="content">
      {isLoading && <LoadingOverlay />}
      <Row>
        <Col md="12">
          <Card className="no-transition">
            <CardHeader>
              <Row>
                <Col md="6">
                  <CardTitle tag="h4">Export Workers</CardTitle>
                  <CardBody>
                    Export all workers who has been selected for work once during the following date
                    range.
                  </CardBody>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row
                style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                md="12"
              >
                <Col md="4">
                  <FormGroup>
                    <Label>From</Label>
                    <ReactDatetime
                      inputProps={{
                        placeholder: "Select From date",
                      }}
                      timeFormat={false}
                      value={filter.fromDate}
                      onChange={(date) => {
                        setFilter({ ...filter, fromDate: moment(date) });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>To</Label>
                    <ReactDatetime
                      inputProps={{
                        placeholder: "Select To date",
                      }}
                      timeFormat={false}
                      value={filter.toDate}
                      onChange={(date) => {
                        setFilter({ ...filter, toDate: moment(date) });
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col md="2" style={{ marginTop: 8 }}>
                  <CSVLink
                    data={workersData}
                    headers={workerHeaders}
                    ref={csvRef}
                    filename={"Workers_Data.csv"}
                  ></CSVLink>
                  <Button color="default" onClick={() => handleDownload()}>
                    Download
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default ExportSelectedWorkers;
