import React, { useEffect, useContext, useState } from "react";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
import Checkout from "../payment/Checkout";
import Info from "../payment/Info";
import SweetAlert from "react-bootstrap-sweetalert";

// reactstrap components
import { Spinner } from "reactstrap";

import api from "components/API/api";
import RegisterContext from "context/RegisterContext";

import RegisterText from "views/Staffing/register/RegisterText.jsx"

function RegisterAdmin(props) {
  const registerContext = useContext(RegisterContext);
  document.documentElement.classList.remove("nav-open");

  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  useEffect(() => {
    document.body.classList.add("register-page");
    document.body.classList.add("full-screen");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("register-page");
      document.body.classList.remove("full-screen");
    };
  }, []);

  useEffect(() => {
    console.log(registerContext.registration);
    if (
      !registerContext.registration.user ||
      !registerContext.registration.agency ||
      !registerContext.registration.plan
    ) {
      props.history.push("/staffing/register/register-admin");
    }
    registerContext.registrationDispatch({
      type: "SET_PROGRESS_STEP",
      payload: { progressBarIndex: 3 },
    });
  }, []);

  const hideAlert = () => {
    setAlert(null);
  };

  const loadingOverlay = () => {
    return (
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
          background: "transparent",
        }}
        title={<Spinner color="light" style={{ width: "3rem", height: "3rem" }} />}
        onConfirm={hideAlert}
        showConfirm={false}
      />
    );
  };

  const confirmPayment = async (paymentMethod) => {
    //TODO: send data to backend
    setIsLoading(true);
    try {
      const resp = await api().post(`/agency/register-flow`, {
        paymentMethod,
        user: registerContext.registration.user,
        agency: registerContext.registration.agency,
        plan: registerContext.registration.plan,
      })

      setIsLoading(false);

      registerContext.registrationDispatch({
        type: "SET_PAYMENT_SUCCESS",
        payload: { success: true, paymentData: resp.data.paymentData },
      });

      props.history.push("/staffing/register/success");
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setAlert(
        <SweetAlert
          error
          title={`There was a problem creating your account. ${error.message.data}`}
          onConfirm={hideAlert}
        >
          Please try again. If this problem continues, please contact technical support with your error details.
        </SweetAlert>,
      );
    }
  };
  return (
    <>
      <div
        className="section section-pricing cd-section"
        id="pricing"
        style={{ marginTop: "-70px" }}
      >
        {/* ********* PRICING 2 ********* */}
        {isLoading ? (
          loadingOverlay()
        ) : (
            <div className="pricing-2">
              <div className="filter" />
              <Container>
                <Row>
                  <Col className="ml-auto" lg="5" md="5" sm="5" xs="12">
                    <RegisterText/>
                  </Col>
                  <Col className="mr-auto" lg="7" md="7" sm="7" xs="12">
                    <Checkout onConfirm={confirmPayment} plan={registerContext.registration.plan} />
                    <Info />
                  </Col>
                </Row>
              </Container>
            </div>
          )}
      </div>
      {alert}
    </>
  );
}

export default RegisterAdmin;
