import React from "react";
import ReactTable from "react-table";

// reactstrap components
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";

//import { fs } from "components/Firebase/firebase.js";
import * as moment from "moment-timezone";
import ReactDatetime from "react-datetime";
import { Highlight } from "@mui/icons-material";
import api from "../../../components/API/api";

const scheduleColours = "#ffe4c6";

class RecruiterStatistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      employeeStatisticsData: [],
      employeeInfoData: [],
      date: new Date(),

      //for calculating total
      sunChequeTotal: 0,
      monChequeTotal: 0,
      tueChequeTotal: 0,
      wedChequeTotal: 0,
      thuChequeTotal: 0,
      friChequeTotal: 0,
      satChequeTotal: 0,

      sunSchTotal: 0,
      monSchTotal: 0,
      tueSchTotal: 0,
      wedSchTotal: 0,
      thuSchTotal: 0,
      friSchTotal: 0,
      satSchTotal: 0,

      totalCheque: 0,
      totalSchedule: 0,

      dates: []
    };
  }

  componentDidMount = () => {
    this.loadDate()
  }

  getThisWeek = (date) => {
    return {
      start: moment(date).startOf("week").toDate(), 
      end: moment(date).endOf("week").toDate() 
    }
  }

  getTrProps = (state, rowInfo, instance) => {
    if (rowInfo) {
      if (rowInfo.row._index === 0) {
        return {
          style: {
            color: "black",
            fontWeight: "bold",
          },
        };
      } else {
        return {};
      }
    } else {
      return {};
    }
  };

  loadDate = async (date = this.state.date) => {
    // this.setState({loading: true})

    // const dates = this.getThisWeek(date)
    // const {data} = await api().get(`/log/recruiter/`, {
    //   params:{ ...dates }
    // });
    // const result = this.calculateStats({...data, ...dates})

    // this.setState({loading: false, employeeStatisticsData: result})
  }

  calculateStats = ({logs, recruiters, start, end}) => {
    for (let i = 0; i < recruiters.length; i++) {
      const recruiter = recruiters[i];
      recruiter.day = []
      const recruiterLogs = logs.filter(l => l.user === recruiter._id).map( l=> ({ ...l, createdAt: moment(l.createdAt)}))
      let today = moment(start);
      while(today <= moment(end)){
        const tomorrow = moment(today).add(1, "day")
        const filteredLogs = recruiterLogs.filter(l => l.createdAt.isBetween(today, tomorrow))
        const metrics = {
          calls: filteredLogs.filter(l => l.action == "Call").length,
          profilesOpen: filteredLogs.filter(l => l.action.startsWith("/users/worker") && l.method === "GET").length,
          profilesEdited: filteredLogs.filter(l => l.action === "/users" && l.method === "PATCH").length,
          profilesApproved: filteredLogs.filter(l => l.action === "/users" && l.method === "PATCH" && JSON.parse(l.data).active === 1).length,
          profilesRejected: filteredLogs.filter(l => l.action === "/users" && l.method === "PATCH" && JSON.parse(l.data).active > 1).length,
        }
        recruiter.day.push(metrics)
        today = today.add(1, "day")
      }
    }
    return recruiters
  }

  renderColumns = () => {
    const col = [{
      Header: "Employee",
      accessor: "email",
      maxWidth: 220,
    }]
    const date = this.state.date ? this.state.date : new Date()
    const {start, end} = this.getThisWeek(date)
    
    let today = moment(start)
    let day = 0;
    while(today < moment(end)){
      const tomorrow = moment(today).add(1, "day")
      const color = (day+1)%2 === 1
      col.push({
        Header: (
          <span>
            {today.format("ddd")}<br></br>call
          </span>
        ),
        accessor: `day[${day}].calls`,
        maxWidth: 60,
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              background: color?scheduleColours:"white",
            },
          };
        },
      })
      col.push({
        Header: (
          <span>
            {today.format("ddd")}<br></br>open
          </span>
        ),
        accessor: `day[${day}].profilesOpen`,
        maxWidth: 60,
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              background: color?scheduleColours:"white",
            },
          };
        },
      })
      col.push({
        Header: (
          <span>
            {today.format("ddd")}<br></br>edit
          </span>
        ),
        accessor: `day[${day}].profilesEdited`,
        maxWidth: 60,
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              background: color?scheduleColours:"white",
            },
          };
        },
      })
      col.push({
        Header: (
          <span>
            {today.format("ddd")}<br></br>apr
          </span>
        ),
        accessor: `day[${day}].profilesApproved`,
        maxWidth: 60,
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              background: color?scheduleColours:"white",
            },
          };
        },
      })
      col.push({
        Header: (
          <span>
            {today.format("ddd")}<br></br>rej
          </span>
        ),
        accessor: `day[${day}].profilesRejected`,
        maxWidth: 60,
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              background: color?scheduleColours:"white",
            },
          };
        },
      })
      day++
      today = today.add(1, "day")
    }
    return col

  }

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card className="no-transition">
                <CardHeader>
                  <Row>
                    <Col md="6">
                      <CardTitle tag="h4">Recruiter Statistics</CardTitle>
                    </Col>
                    <Col md="6">
                      <ReactDatetime
                        inputProps={{
                          className: "form-control",
                          placeholder: "Date Picker Here",
                        }}
                        timeFormat={false}
                        isValidDate={this.disableFutureDates}
                        value={this.state.date}
                        onChange={(date) => {
                          this.setState({ date: date });
                          this.loadDate(date);
                        }}
                      />
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={this.state.employeeStatisticsData}
                    filterable
                    columns={this.renderColumns()}
                    defaultPageSize={20}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight primary-pagination"
                    getTrProps={this.getTrProps}
                    loading={this.state.loading}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default RecruiterStatistics;
