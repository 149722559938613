import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Col, Input, Row } from "reactstrap";
import Button from "reactstrap/lib/Button";
import FormGroup from "reactstrap/lib/FormGroup";
import Label from "reactstrap/lib/Label";
// import Label from "reactstrap/lib/Label";

// export const ShiftFunctionList = [
//   { label: "Sanitation", value: "Sanitation" },
//   { label: "Forklift", value: "Forklift" },
//   { label: "Packaging", value: "Packaging" },
//   { label: "Machine Operator", value: "Machine Operator" },
//   { label: "Heavy Duty Labour", value: "Heavy Duty Labour" },
//   { label: "Light Duty Labour", value: "Light Duty Labour" },
//   { label: "Shipping & Receiving", value: "shipping & Receiving" },
//   {label:"Other", value:"Other"}
// ];

export const shiftFunctionList = [
  "Sanitation",
  "Forklift",
  "Packaging",
  "Machine Operator",
  "Heavy Duty Labour",
  "Light Duty Labour",
  "Shipping & Receiving",
  "Other",
];

export const shiftFunctionRS = shiftFunctionList.map((sf) => ({ label: sf, value: sf }));

function ShiftFunction({ onDelete, shiftFunction, onChange }) {
  const [_shiftFunction, setShiftFunction] = useState(
    shiftFunctionList.some((sf) => sf === shiftFunction.name) ? shiftFunction.name : "Other",
  );
  return (
    <>
      <Row className="m-2">
        <Col sm={4}>Function*</Col>
        <Col sm={8}>
          <Select
            className="react-select primary"
            classNamePrefix="react-select"
            name="shiftFunction"
            //   value={data.qualifications}
            value={{ label: _shiftFunction, value: _shiftFunction }}
            // value={shiftFunctionRS.find((_sfl) => _sfl.value === shiftFunction?.name)}
            onChange={(value) => {
              onChange({
                ...shiftFunction,
                name: value.value,
              });
              setShiftFunction(value.value);
            }}
            options={shiftFunctionRS}
            placeholder="Select Shift Functions"
          />
        </Col>
      </Row>

      {_shiftFunction === "Other" && (
        <Row className="m-2">
          <Col sm={4}></Col>
          <Col sm={8}>
            <Input
              type="text"
              value={shiftFunction.name}
              onChange={(e) => {
                onChange({
                  ...shiftFunction,
                  name: e.target.value,
                });
              }}
            ></Input>
          </Col>
        </Row>
      )}
      <Row className="m-2">
        <Col sm={4}>Pay Rate(Hourly)(Optional)</Col>
        <Col sm={8}>
          <Input
            onChange={(e) => {
              onChange({
                ...shiftFunction,
                payRate: e.target.value,
              });
            }}
            type="number"
            value={shiftFunction?.payRate || ""}
          />
        </Col>
      </Row>
      <Row className="m-2">
        <Col sm={4}>Description/Instructions</Col>
        <Col sm={8}>
          <Input
            type="textarea"
            rows="5"
            onChange={(e) => {
              onChange({
                ...shiftFunction,
                description: e.target.value,
              });
            }}
            value={shiftFunction?.description || ""}
          />
        </Col>
      </Row>
      <Row className="m-2">
        <Col sm={4}>Show in Shift Board</Col>
        <Col sm={8} className="checkbox-radios">
          <FormGroup check inline>
            <Label check>
              <Input
                type="checkbox"
                onChange={() =>
                  onChange({ ...shiftFunction, shiftBoard: !shiftFunction.shiftBoard })
                }
                checked={shiftFunction.shiftBoard}
                id={"shiftBoard"}
              />
              <span className="form-check-sign" />
            </Label>
          </FormGroup>
        </Col>
      </Row>
      <Row className="m-2">
        <Col sm={4}></Col>
        <Col sm={8}>
          <Button color="danger" onClick={onDelete}>
            Delete
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default function ShiftFunctions({ defaultValue, onChange }) {
  const [shiftFunctions, setShiftFunctions] = useState([]);

  useEffect(() => {
    if (defaultValue?.length) {
      setShiftFunctions(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    onChange(shiftFunctions);
  }, [shiftFunctions]);
  return (
    <>
      <div style={{ border: "1px solid #DDDDDD", borderRadius: "5px", padding: "5px" }}>
        {shiftFunctions.map((_sf, i) => (
          <div key={i}>
            <ShiftFunction
              shiftFunction={_sf}
              key={i}
              onDelete={() => {
                setShiftFunctions((_sfs) => {
                  const returnValue = _sfs.filter((_s, _i) => {
                    return _s !== _sf;
                  });
                  return returnValue;
                });
              }}
              onChange={(newSf) => {
                setShiftFunctions((_sfs) => {
                  const returnValue = _sfs.map((_s, _i) => {
                    if (_s === _sf) {
                      return newSf;
                    }
                    return _s;
                  });
                  return returnValue;
                });
              }}
            />
            {i < shiftFunctions.length - 1 && <hr />}
          </div>
        ))}
        <Row className="m-2">
          <Col>
            <Button
              onClick={() => {
                setShiftFunctions((_sfs) => [
                  ..._sfs,
                  { name: "", payRate: 0, payBasis: "Hourly", description: "", shiftBoard: true },
                ]);
              }}
            >
              Add Shift Function
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
}

// export default ShiftFunction;
