import Footer from "components/Footer/Footer.jsx";
import AuthNavbar from "components/Navbars/AuthNavbar.jsx";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import React, { useContext, useEffect, useRef } from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import Login from "views/Agency/pages/Login.jsx";
import api, { baseURL } from "../../components/API/api";
import AgencyContext from "../../context/AgencyContext";

var ps;
function Auth(props) {
  let { slug } = useParams();
  const { agency, agencyDispatch } = useContext(AgencyContext);
  const fullPagRef = useRef();
  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(fullPagRef.current);
    }
    return () => {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  }, []);
  const processLoginData = ({ user, token }) => {
    let profile = user.profile.find(
      (p) =>
        (p.role === "Admin" ||
          p.role === "Owner" ||
          p.role === "AgencyAdmin" ||
          p.role === "Supervisor" ||
          p.role === "Recruiter" ||
          p.role == "Staff") &&
        (p.agency.slug === slug || p.agency._id === agency._id),
    );
    if (profile && profile.agency && profile.agency._id) {
      let agencyId = profile.agency._id;
      localStorage.setItem("Token", JSON.stringify(token));
      localStorage.setItem("AgencyId", agencyId);
      agencyDispatch({ type: "SET_USER", payload: { user: user } });
      agencyDispatch({
        type: "SET_PROFILE",
        payload: { profile: profile },
      });
      props.history.push(`/${slug}/admin/all-users`);
    } else {
      localStorage.Token = null;
    }
  };

  useEffect(() => {
    if (localStorage.Token) {
      api()
        .get("/users/login/validate-token")
        .then(({ data }) => {
          // console.log(data);

          let token = JSON.parse(localStorage.getItem("Token"));
          processLoginData({
            user: data,
            token: token,
          });
        })
        .catch((e) => {
          localStorage.Token = null;
        });
    }
  }, []);

  let logo = agency?.agency?._id ? `${baseURL}/agency/${agency.agency._id}/logo` : null;
  return (
    <>
      <AuthNavbar logo={logo} companyName={agency?.agency?.name} />
      <div className="wrapper wrapper-full-page" ref={fullPagRef}>
        <div className="full-page section-image">
          <Switch>
            <Route exact path="/:slug/auth/login" render={(props) => <Login {...props} />} />
            <Redirect to="/:slug/auth/login" />
            {/* {this.getRoutes(routes)} */}
          </Switch>
          <Footer fluid />
        </div>
      </div>
    </>
  );
}

export default Auth;
