/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import { Redirect, Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import SweetAlert from "react-bootstrap-sweetalert";
import { filterCaseInsensitive } from "components/Utilities/utilities";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Spinner,
  Label,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

import { Visibility, PersonAdd, AddCircleOutline, Clear } from "@mui/icons-material";
import { Modal } from "@mui/material";

import * as utils from "components/Utilities/utilities.js";
import api from "../../../components/API/api";
import { Input } from "reactstrap";
import AgencyContext from "./../../../context/AgencyContext";
class AllTraining extends React.Component {
  static contextType = AgencyContext;
  constructor(props) {
    super(props);
    this.state = {
      pageTabs: "private",
      trainingData: [],
      trainingDataHeaders: [
        {
          label: "Training ID",
          key: "_id",
        },
        {
          label: "Training Name",
          key: "name",
        },
        {
          label: "Business",
          key: "business",
        },
      ],
      confirmDeleteModalOpen: false,
      canDeleteTraining: false, // check for delete training permission

      selectedDocId: null,
      selectedTrainingName: null,

      isLoading: false,
      openModal: false,

      tableQuery: "",

      //pagination parameters
      loading: "false",
      pages: 0,
    };
  }

  componentDidMount() {
    const role = this.context.agency?.profile?.roleDisplayName;
    const isAdmin = role === "Admin" || role === "Owner" ? true : false;
    if(isAdmin){
      this.setState({ canDeleteTraining: true });
    }else {
      let userPermissions = this.context.agency?.profile?.permissions;
      console.log(userPermissions)
      userPermissions.some((p) => {
        if (p.module === "Training" && p.action === "Delete") {
          this.setState({ canDeleteTraining: true });
        }
      });
    }
    
  }

  RenderActionButtons = (docId, trainingName) => {
    return (
      <div className="actions-right">
        <Button
          tag={Link}
          to={`/${this.context.agency.agency.slug}/admin/edit-training/${docId}`}
          color="info"
          size="md"
          className="btn-link btn-icon"
        >
          <Visibility style={{ marginLeft: -1 }} />
        </Button>{" "}
        {this.state.canDeleteTraining ? (
          <Button
            onClick={() => {
              this.setState(
                {
                  selectedDocId: docId,
                  selectedTrainingName: trainingName,
                },
                this.handleOpenDeleteModal(),
              );
            }}
            color="danger"
            size="md"
            className="btn-link remove btn-icon"
          >
            <Clear style={{ marginLeft: -1 }} />
          </Button>
        ) : null}
      </div>
    );
  };

  //Loading Overlay
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
  loadingOverlay = () => {
    return (
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
          background: "transparent",
        }}
        title={<Spinner color="light" style={{ width: "3rem", height: "3rem" }} />}
        onConfirm={this.hideAlert}
        showConfirm={false}
      />
    );
  };

  handleOpenDeleteModal = () => {
    this.setState({ confirmDeleteModalOpen: true });
  };

  handleCloseDeleteModal = () => {
    this.setState({ confirmDeleteModalOpen: false });
  };

  deleteTraining = async (docId) => {
    this.setState({ isLoading: true });
    try {
      const resp = await api().delete("/training", {
        params: {
          id: docId,
        },
      });
      this.fetchData();
      this.setState(
        {
          isLoading: false,
          alert: (
            <SweetAlert
              success
              title="Training successfully deleted"
              onConfirm={this.hideAlert}
              showConfirm={true}
            />
          ),
        },
        this.handleCloseDeleteModal(),
      );
    } catch (error) {
      this.setState({ isLoading: false });
      console.log("error", error);
    }
  };

  confirmDeleteBody = () => {
    // console.log(this.state.selectedLastName)
    return (
      <Modal
        open={this.state.confirmDeleteModalOpen}
        onClose={this.handleCloseDeleteModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        // aria-labelledby="simple-modal-title"
        // aria-describedby="simple-modal-description"
      >
        <FormGroup style={{ width: 300, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>Delete {this.state.selectedTrainingName}?</h5>
            </div>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button onClick={() => this.handleCloseDeleteModal()}>Cancel</Button>
              <Button color="danger" onClick={() => this.deleteTraining(this.state.selectedDocId)}>
                Delete
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  fetchData = async (state) => {
    this.setState({ loadingTable: true });
    if (!state) {
      state = this.state.lastTableState;
    }
    this.setState({ lastTableState: state });
    console.log("state", state);

    let field = null;
    let sort = null;
    if (state.sorted.length > 0) {
      field = state.sorted[0].id;
      sort = state.sorted[0].desc ? "desc" : "asc";
    }
    const { data } = await api().get("/training/list/agency/", {
      params: {
        page: state.page + 1,
        size: state.pageSize,
        field,
        sort,
        query: this.state.tableQuery,
      },
    });
    console.log(data.trainings);
    let trainings = data.trainings || [];
    trainings = trainings.map((u, index) => ({
      ...u,
      actions: this.RenderActionButtons(u._id, u.name),
      businesses: u.business.map((b) => <p>{b.name}</p>),
    }));

    let publicShifts = [];
    let privateShifts = [];

    trainings.map((t) => (t.public === true ? publicShifts.push(t) : privateShifts.push(t)));

    // console.log("data", data);
    this.setState({
      trainingData: this.state.pageTabs === "private" ? privateShifts : publicShifts,
      pages: data.noPages,
      loadingTable: false,
    });
  };

  render() {
    //<Button color="default" outline>Export All</Button>
    return (
      <>
        <div className="content">
          {this.state.isLoading ? (
            this.loadingOverlay()
          ) : (
            <Row>
              <Col md="12">
                <FormGroup>
                  <CSVLink
                    data={this.state.trainingData}
                    headers={this.state.trainingDataHeaders}
                    filename={"Training_Data.csv"}
                  >
                    <Button color="default" outline>
                      Export All
                    </Button>
                  </CSVLink>
                </FormGroup>
                <Card className="no-transition">
                  <CardHeader>
                    <CardTitle tag="h4">All Trainings</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Nav
                      className="nav-pills-primary nav-pills-icons justify-content-center"
                      pills
                      role="tablist"
                    >
                      <NavItem>
                        <NavLink
                          data-toggle="tab"
                          role="tablist"
                          className={this.state.pageTabs === "private" ? "active" : ""}
                          onClick={() =>
                            this.setState({ pageTabs: "private" }, () => this.fetchData())
                          }
                        >
                          <i className="now-ui-icons objects_umbrella-13" />
                          Private Trainings
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          data-toggle="tab"
                          role="tablist"
                          className={this.state.pageTabs === "public" ? "active" : ""}
                          onClick={() =>
                            this.setState({ pageTabs: "public" }, () => this.fetchData())
                          }
                        >
                          <i className="now-ui-icons ui-2_settings-90" />
                          Public Trainings
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <div style={{ textAlign: "right" }}>
                      <FormGroup row className="align-items-right">
                        <Col sm="7"></Col>
                        <Label for="Search" sm={2} style={{ color: "black", fontSize: "14px" }}>
                          Search
                        </Label>
                        <Col sm={3}>
                          <Input
                            type="text"
                            id="Search"
                            value={this.state.tableQuery}
                            onChange={(e) =>
                              this.setState({ tableQuery: e.target.value }, () => this.fetchData())
                            }
                          />
                        </Col>
                      </FormGroup>
                    </div>
                    <ReactTable
                      columns={[
                        {
                          Header: "Training Name",
                          accessor: "name",
                        },
                        {
                          Header: "Business",
                          accessor: "businesses",
                          show: this.state.pageTabs === "private",
                        },
                        {
                          Header: "Actions",
                          accessor: "actions",
                          show: this.state.pageTabs === "private",
                          sortable: false,
                          filterable: false,
                          maxWidth: 110,
                        },
                      ]}
                      manual
                      loading={this.state.loadingTable}
                      pages={this.state.pages}
                      data={this.state.trainingData}
                      onFetchData={this.fetchData}
                      sorted={this.state.sorted}
                      defaultPageSize={10}
                      showPaginationTop
                      showPaginationBottom
                      /*
                        You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                      */
                      className="-striped -highlight primary-pagination"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          {this.state.alert}
          {this.confirmDeleteBody()}
        </div>
      </>
    );
  }
}

export default AllTraining;
