import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "@mui/material";
import { Button, FormGroup } from "reactstrap";
import moment from "moment";
import CustomCalendar from "components/Calendar/CustomCalendar";
import api from "components/API/api";
import { startOfDay } from "utils/dateUtils";
import { startOfNextDay } from "utils/dateUtils";
import { Bar } from "react-chartjs-2";
import { useModal } from "context/ModalContext";
import LoadingOverlay from "components/OverLay/LoadingOverlay";
export default function DailyStatsModal({ openModal, handleCloseModal }) {
  const [stats, setStats] = useState({
    totalActiveApplied: 0,
    totalApplied: 0,
    totalAutoDropped: 0,
    totalBackup: 0,
    totalDropped: 0,
    totalRejected: 0,
    totalRequiredWorkers: 0,
    totalSelected: 0,
    totalUnScheduled: 0,
  });
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(moment());
  console.log(stats);
  const { addAlert } = useModal();
  useEffect(() => {
    setLoading(true);
    api()
      .get("/shift/counter", {
        params: {
          from: startOfDay(date),
          till: startOfNextDay(date),
        },
      })
      .then(({ data }) => setStats(data))
      .catch((e) => addAlert({ title: e.response?.data?.message || e.message, error: true }))
      .finally(() => setLoading(false));
  }, [date]);

  const graph = useMemo(() => {
    return {
      labels: [
        "Total Required",
        "Total Applications",
        "Current Applications",
        "Backup",
        "Selected",
        "Dropped",
        "Rejected",
        "UnScheduled",
      ],
      datasets: [
        {
          label: "Daily Selected for work stats",
          data: [
            stats.totalRequiredWorkers,
            stats.totalApplied,
            stats.totalActiveApplied,
            stats.totalBackup,
            stats.totalSelected,
            stats.totalDropped,
            stats.totalRejected,
            stats.totalUnScheduled,
          ],
          backgroundColor: [
            "rgba(54, 162, 235, 0.2)",
            "rgba(95, 99, 132, 0.2)",
            "rgba(255, 206, 86, 0.2)",
            "rgba(255, 99, 132, 0.2)",
            "rgba(255, 99, 132, 1)",
            "rgba(155, 106, 86, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
          ],
          // borderColor: [

          // ],
        },
      ],
    };
  }, [stats]);

  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 800, backgroundColor: "#fff", borderRadius: 10 }}>
          {loading && <LoadingOverlay />}
          <div style={{ padding: 25, marginTop: 20 }}>
            <CustomCalendar
              inputProps={{
                placeholder: "Date Picker Here",
              }}
              initialDate={date}
              onChangeDate={(date) => {
                setDate(date);
              }}
              myDate={date}
            />
            <Bar
              data={graph}
              width={700}
              height={400}
              options={{
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                      },
                    },
                  ],
                },
              }}
            />
            <div style={{ position: "absolute", right: "20px", top: "10px" }}>
              <div onClick={handleCloseModal} style={{ cursor: "pointer" }}>
                X
              </div>
            </div>
          </div>
        </FormGroup>
      </Modal>
    </div>
  );
}
