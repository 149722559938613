import api from "components/API/api";
import React, { useState } from "react";
import { Input, Spinner } from "reactstrap";

const LastNameCell = ({ _id, value, onChange }) => {
  const [clicked, setClicked] = useState(false);
  const [newValue, setNewValue] = useState(value);
  const [loading, setLoading] = useState(false);

  const _onEnter = () => {
    setClicked(false);
    if (newValue !== value) {
      let lastName = newValue.split("-")[0];
      let duplicated = newValue.split("-")[1];
      if (duplicated >>> 0 === parseFloat(duplicated)) {
        const data = {
          lastName: lastName,
          duplicated: parseFloat(duplicated),
          userId: _id,
        };
        setLoading(true);
        api()
          .patch("/users", data)
          .then(() => {
            setLoading(false);
            onChange(newValue, _id);
          })
          .catch(() => {
            setLoading(false);
            setNewValue(value);
          });
      } else {
        setNewValue(value);
      }
    }
  };

  return (
    <div className="w-100 h-100" style={{ cursor: "pointer" }}>
      {loading ? (
        <Spinner />
      ) : clicked ? (
        <Input
          type="text"
          value={newValue}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              _onEnter();
            }
          }}
          onBlur={() => {
            _onEnter();
          }}
          autoFocus={clicked}
          onChange={(e) => {
            setNewValue(e.target.value);
          }}
        />
      ) : (
        <p onClick={() => setClicked(true)} className="w-100 h-100">
          {value}
        </p>
      )}
    </div>
  );
};

export default LastNameCell;
