import React, { Component } from "react";
import { Container, Col, Row } from "reactstrap";
import MoneyBackBadge from "../../../assets/img/checkout/money-back-guarantee-badge.png";
import FastShippingBadge from "../../../assets/img/checkout/fast-shipping-badge.png";
import SecureCheckoutBadge from "../../../assets/img/checkout/secure-checkout-badge.png";
export default class InfoReel extends Component {
  render() {
    return (
      <div>
        <Container className="inforeel-container mt-4 mb-4">
          <div className="mb-4">
            <span>Why Us?</span>
          </div>
          <Row>
            <Col xs="3">
              <Container className="co-badge-container">
                <img src={MoneyBackBadge} alt="90 days badge" />
              </Container>
            </Col>
            <Col xs="9">
              <p className="title-p">
                <strong>100% Satisfaction Guarantee</strong>
              </p>
              <p>If You're not 100% satisfied, we'll issue a full refund.</p>
            </Col>
          </Row>
          <Row>
            <Col xs="3">
              <Container className="co-badge-container">
                <img src={FastShippingBadge} alt="fast shipping badge" />
              </Container>
            </Col>
            <Col xs="9">
              <p className="title-p">
                <strong>Over 1 Million Registered Job Seekers</strong>
              </p>
              <p>Over 5 million Happy Job seekers Can't Be Wrong.</p>
            </Col>
          </Row>
          <Row>
            <Col xs="3">
              <Container className="co-badge-container">
                <img src={SecureCheckoutBadge} alt="secutity badge" />
              </Container>
            </Col>
            <Col xs="9">
              <p className="title-p">
                <strong>100% Security</strong>
              </p>
              <p>Your Information Is HIGHLY Secure and Completely Safe GUARANTEED.</p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
