import React, { useMemo, useRef } from "react";
import ReactTable from "react-table";
import { CSVLink } from "react-csv";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  FormText,
  Spinner,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Label,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import {
  Visibility,
  PersonAdd,
  Clear,
  Check,
  CallEnd,
  Delete,
  Comment,
  AccessTime,
  LayersClear,
  Done,
  ClearAll,
} from "@mui/icons-material";
import { Modal } from "@mui/material";
import RenderAvailability, {
  initialAvailability,
} from "components/Availability/RenderAvailability";
import * as utils from "components/Utilities/utilities.js";
import Select from "react-select";
import { stringifyTimeStamp } from "components/Utilities/utilities.js";
import { Redirect, Link } from "react-router-dom";
import LoadingOverlay from "components/OverLay/LoadingOverlay";
import moment from "moment";

import api from "components/API/api";
import { Input } from "reactstrap";
import AgencyContext from "../../../context/AgencyContext";
import ReactDatetime from "react-datetime";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";

import CustomCalendar from "components/Calendar/CustomCalendar";

import { getDay, startOfDay, startOfNextDay } from "utils/dateUtils.js";

import { baseURL as ENDPOINT } from "components/API/api";
import io from "socket.io-client";

const EmployeeStatisticsDetails = (props) => {
  const ACTIONS = [
    { label: "Schedule", value: "schedule" },
    { label: "Unschedule", value: "unschedule" },
    { label: "Cheques", value: "cheques" },
  ];

  const agencyContext = useContext(AgencyContext);
  const [logData, setLogData] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [columns, setColumns] = useState(DEFAULT_COLUMNS);

  const [isLoading, setIsLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [alert, setAlert] = useState(null);

  const [pages, setPages] = useState(-1);

  const [reactTableState, setReactTableState] = useState({
    page: 1,
    size: 5,
    field: "createdAt",
    sort: "desc",
    filter: [],
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(props.location.search);
    const selectedStaff = urlParams.get("staff");
    const selectedAction = urlParams.get("action");
    const selectedDate = urlParams.get("date");

    if (selectedStaff) {
      setSelectedStaff(selectedStaff);
    }
    if (selectedAction) {
      setSelectedAction(selectedAction);
    }
    if (selectedDate) {
      setSelectedDate(selectedDate);
    }

    fetchStaff();
    if (selectedAction === "schedule") {
      setColumns(SCHEDULE_COLUMNS);
    }
  }, []);

  useEffect(() => {
    fetchData();
    if (selectedAction === "schedule") {
      setColumns(SCHEDULE_COLUMNS);
    } else if (selectedAction === "unschedule") {
      setColumns(UNSCHEDULE_COLUMNS);
    } else {
      setColumns(DEFAULT_COLUMNS);
    }
  }, [selectedAction, selectedDate, selectedStaff]);

  const fetchStaff = async () => {
    try {
      const { data } = await api().get("/users/all/staff");
      setStaffList(
        data.staff.sort((a, b) => {
          try {
            const compare = a.firstName.localeCompare(b.firstName);
            return compare;
          } catch (err) {
            const compare = a.email.localeCompare(b.email);
            console.log(err, compare);
          }
        }),
      );
    } catch (error) {
      console.log(error);
    }
  };

  const RenderActionButtons = (user) => {
    return (
      <div className="actions-right">
        <Button
          tag={Link}
          to={`/${agencyContext.agency.agency.slug}/admin/user-profile/${user._id}`}
          onClick={() => {
            handleOpenProfile(user._id);
          }}
          color="info"
          size="md"
          className="btn-link btn-icon"
        >
          <Visibility style={{ marginLeft: -1 }} />
        </Button>
      </div>
    );
  };

  const handleOpenProfile = (id) => {
    let path = `/${agencyContext.agency.agency.slug}/admin/user-profile/${id}`;
    props.history.push(path);
  };

  //Loading Overlay
  const hideAlert = () => {
    setAlert(null);
  };

  const fetchData = async (filterOptions = reactTableState) => {
    if (!selectedStaff || !selectedAction || selectedAction === "Select") {
      setLoadingTable(false);
      setIsLoading(false);
      return;
    }
    setLoadingTable(true);

    try {
      const { data } = await api().get("/log/staff/details", {
        params: {
          ...filterOptions,
          action: selectedAction,
          staffId: selectedStaff,
          date: selectedDate,
        },
      });

      console.log("data", data);

      const logsData = data.logs.map((log) => {
        const user = log.user;
        const shift = log.shift;
        let businessName = "";
        let shiftDescription = "";
        let startTime = "";
        let endTime = "";
        if (shift && typeof shift !== "string") {
          businessName = shift.business[0].name;
          shiftDescription = shift.description;
          startTime = moment(JSON.parse(log.data).start).format("L LT");
          endTime = moment(JSON.parse(log.data).end).format("L LT");
        }
        if (log.selectedForWork) {
          startTime = moment(log.selectedForWork.start).format("L LT");
          endTime = moment(log.selectedForWork.end).format("L LT");
        }
        if (user) {
          return {
            ...log,
            user,
            hdId: user.hdId,
            firstName: user.firstName,
            lastName:
              user.duplicated && user.duplicated > 1
                ? `${user.lastName}-${user.duplicated}`
                : user.lastName,
            phone: user.phone,
            gender: user.gender,
            createdAt: moment(log.createdAt).format("LT"),
            businessName,
            shiftDescription,
            startTime,
            endTime,
            actions: RenderActionButtons(user),
          };
        } else {
          return {
            ...log,
            businessName,
            shiftDescription,
            startTime,
            endTime,
            createdAt: moment(log.createdAt).format("LT"),
          };
        }
      });

      setLogData(logsData);
      setPages(data.noPages);

      setLoadingTable(false);
    } catch (error) {
      setLoadingTable(false);
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <div className="content">
        {isLoading && <LoadingOverlay />}
        <Row>
          <Col md="12">
            <FormGroup>
              {/* <CSVLink
                  data={userData}
                  headers={userDataHeaders}
                  filename={"User_Data.csv"}
                >
                  <Button color="default" outline>
                    Export All
                    </Button>
                </CSVLink> */}
            </FormGroup>
            <Card className="no-transition">
              <CardHeader>
                <CardTitle tag="h4">Employee Detail Statistics</CardTitle>
              </CardHeader>
              <CardBody>
                <Row className="justify-content-between">
                  <Col xs="auto" sm="4" md="4">
                    <CustomCalendar
                      inputProps={{
                        placeholder: "Date Picker Here",
                      }}
                      initialDate={selectedDate}
                      myDate={selectedDate}
                      onChangeDate={(date) => {
                        setSelectedDate(date);
                      }}
                    />
                  </Col>

                  <Col xs="auto" sm="4" md="4">
                    <FormGroup row className="align-items-right">
                      <Label for="Search" sm={3} style={{ color: "black", fontSize: "14px" }}>
                        Staff member
                      </Label>
                      <Col sm={9}>
                        <Input
                          type="select"
                          name="businessId"
                          onChange={(e) => setSelectedStaff(e.target.value)}
                          value={selectedStaff}
                        >
                          <option value={null}>Select</option>
                          {staffList &&
                            staffList.length > 0 &&
                            staffList.map((staff) => {
                              return (
                                <option key={staff._id} value={staff._id}>
                                  {staff.firstName} {staff.lastName} ({staff.email})
                                </option>
                              );
                            })}
                        </Input>
                      </Col>
                    </FormGroup>
                  </Col>

                  <Col xs="auto" sm="4" md="4">
                    <div style={{ textAlign: "right" }}>
                      <FormGroup row className="align-items-right">
                        <Label for="Search" sm={3} style={{ color: "black", fontSize: "14px" }}>
                          Action
                        </Label>
                        <Col sm={9}>
                          <Input
                            type="select"
                            name="status"
                            onChange={(e) => {
                              setSelectedAction(e.target.value);
                            }}
                            value={selectedAction}
                          >
                            <option value={null}>Select</option>
                            {ACTIONS.map((b, i) => {
                              return (
                                <option key={i} value={b.value}>
                                  {b.label}
                                </option>
                              );
                            })}
                          </Input>
                        </Col>
                      </FormGroup>
                    </div>
                  </Col>
                </Row>

                <ReactTable
                  filterable
                  columns={columns}
                  manual
                  loading={loadingTable}
                  pages={pages}
                  data={logData}
                  defaultPageSize={25}
                  pageSizeOptions={[10, 25, 50, 100]}
                  showPaginationTop
                  showPaginationBottom
                  onFetchData={(state, instance) => {
                    let searchParams = {
                      page: state.page + 1,
                      size: state.pageSize,
                      field: state.sorted.length ? state.sorted[0].id : "createdAt",
                      sort: state.sorted.length ? (state.sorted[0].desc ? "desc" : "asc") : "desc",
                      filter: state.filtered,
                    };
                    setReactTableState(searchParams);
                    fetchData(searchParams);
                  }}
                  className="-striped -highlight primary-pagination"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        {alert}
      </div>
    </>
  );
};

export default EmployeeStatisticsDetails;

const SCHEDULE_COLUMNS = [
  {
    Header: "HD Id",
    accessor: "hdId",
    maxWidth: 70,
    sortable: false,
    filterable: false,
  },
  {
    Header: "First Name",
    accessor: "firstName",
    sortable: false,
    filterable: false,
  },
  {
    Header: "Last Name",
    accessor: "lastName",
    sortable: false,
    filterable: false,
  },
  {
    Header: "Gender",
    accessor: "gender",
    maxWidth: 90,

    sortable: false,
    filterable: false,
  },
  {
    Header: "Phone",
    accessor: "phone",
    Cell: (row) => {
      let telephone = "tel:" + row.original.phone;
      return (
        <a
          href={telephone}
          style={{
            color: "#51bcda",
            textDecoration: "underline",
            fontWeight: 400,
          }}
        >
          {row.original.phone}
        </a>
      );
    },
    maxWidth: 130,
    sortable: false,
    filterable: false,
  },
  {
    Header: "Business",
    accessor: "businessName",
    filterable: false,
  },
  {
    Header: "Shift",
    accessor: "shiftDescription",
    filterable: false,
  },
  {
    Header: "Shift start",
    accessor: "startTime",
    filterable: false,
  },
  {
    Header: "Shift end",
    accessor: "endTime",
    filterable: false,
  },
  {
    Header: "Action Date",
    accessor: "createdAt",
    filterable: false,
  },
  {
    Header: "Actions",
    accessor: "actions",
    sortable: false,
    filterable: false,
    width: 80,
  },
];

const UNSCHEDULE_COLUMNS = [
  {
    Header: "HD Id",
    accessor: "hdId",
    maxWidth: 70,
    sortable: false,
    filterable: false,
  },
  {
    Header: "First Name",
    accessor: "firstName",
    sortable: false,
    filterable: false,
  },
  {
    Header: "Last Name",
    accessor: "lastName",
    sortable: false,
    filterable: false,
  },
  {
    Header: "Gender",
    accessor: "gender",
    maxWidth: 90,

    sortable: false,
    filterable: false,
  },
  {
    Header: "Phone",
    accessor: "phone",
    Cell: (row) => {
      let telephone = "tel:" + row.original.phone;
      return (
        <a
          href={telephone}
          style={{
            color: "#51bcda",
            textDecoration: "underline",
            fontWeight: 400,
          }}
        >
          {row.original.phone}
        </a>
      );
    },
    maxWidth: 130,
    sortable: false,
    filterable: false,
  },
  {
    Header: "Business",
    accessor: "selectedForWork.businessName",
    filterable: false,
  },
  {
    Header: "Shift",
    accessor: "selectedForWork.shiftDescription",
    filterable: false,
  },
  {
    Header: "Shift start",
    accessor: "startTime",
    filterable: false,
  },
  {
    Header: "Shift end",
    accessor: "endTime",
    filterable: false,
  },
  {
    Header: "Reason",
    accessor: "selectedForWork.unscheduleReason",
    filterable: false,
  },
  {
    Header: "Action Date",
    accessor: "createdAt",
    filterable: false,
  },
  {
    Header: "Actions",
    accessor: "actions",
    sortable: false,
    filterable: false,
    width: 80,
  },
];

const DEFAULT_COLUMNS = [
  {
    Header: "HD Id",
    accessor: "hdId",
    maxWidth: 70,
    sortable: false,
    filterable: false,
  },
  {
    Header: "First Name",
    accessor: "firstName",
    sortable: false,
    filterable: false,
  },
  {
    Header: "Last Name",
    accessor: "lastName",
    sortable: false,
    filterable: false,
  },
  {
    Header: "Gender",
    accessor: "gender",
    maxWidth: 90,

    sortable: false,
    filterable: false,
  },
  {
    Header: "Phone",
    accessor: "phone",
    Cell: (row) => {
      let telephone = "tel:" + row.original.phone;
      return (
        <a
          href={telephone}
          style={{
            color: "#51bcda",
            textDecoration: "underline",
            fontWeight: 400,
          }}
        >
          {row.original.phone}
        </a>
      );
    },
    maxWidth: 130,
    sortable: false,
    filterable: false,
  },
  {
    Header: "Action Date",
    accessor: "createdAt",
    filterable: false,
  },
  {
    Header: "Actions",
    accessor: "actions",
    sortable: false,
    filterable: false,
    width: 80,
  },
];
