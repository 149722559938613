/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
//import { auth } from "components/Firebase/firebase.js";
import { Redirect, Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import SweetAlert from "react-bootstrap-sweetalert";
import { filterCaseInsensitive } from "components/Utilities/utilities";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Spinner,
  Label,
} from "reactstrap";

import { Visibility, PersonAdd, AddCircleOutline, Clear } from "@mui/icons-material";
import { Modal } from "@mui/material";

import * as utils from "components/Utilities/utilities.js";
import api from "../../../components/API/api";
import { Input } from "reactstrap";
import AgencyContext from "./../../../context/AgencyContext";
class AllQualifications extends React.Component {
  static contextType = AgencyContext;
  constructor(props) {
    super(props);
    this.state = {
      qualificationData: [],
      qualificationDataHeaders: [
        {
          label: "Qualification ID",
          key: "_id",
        },
        {
          label: "Staff Qualification Name",
          key: "name",
        },
        {
          label: "Staff Training List",
          key: "requiredTraining",
        },
      ],
      confirmDeleteModalOpen: false,

      selectedQualificationName: null,
      selectedTrainingList: null,
      canDeleteQualification: false, // check for Qualification delete option

      isLoading: false,
      openModal: false,

      tableQuery: "",

      //pagination parameters
      loading: "false",
      pages: 0,
    };
  }

  componentDidMount() {
    const role = this.context.agency?.profile?.roleDisplayName;
    const isAdmin = role === "Admin" || role === "Owner" ? true : false;
    if(isAdmin){
      this.setState({ canDeleteQualification: true });
    }else {
    let userPermissions = this.context.agency?.profile?.permissions;
    console.log(userPermissions)
    userPermissions.some((p) => {
      if (p.module === "Qualification" && p.action === "Delete") {
        this.setState({ canDeleteQualification: true });
      }
    });
  }
  }

  RenderActionButtons = (docId, QualificationName) => {
    return (
      <div className="actions-right">
        <Button
          tag={Link}
          to={`/${this.context.agency.agency.slug}/admin/qualification-profile/${docId}`}
          color="info"
          size="md"
          className="btn-link btn-icon"
        >
          <Visibility style={{ marginLeft: -1 }} />
        </Button>{" "}
        {
         this.state.canDeleteQualification ? 
         <Button
          onClick={() => {
            this.setState(
              {
                selectedDocId: docId,
                selectedQualificationName: QualificationName,
              },
              this.handleOpenDeleteModal(),
            );
          }}
          color="danger"
          size="md"
          className="btn-link remove btn-icon"
        >
          <Clear style={{ marginLeft: -1 }} />
        </Button> : null
        }
       
      </div>
    );
  };

  //Loading Overlay
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
  loadingOverlay = () => {
    return (
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
          background: "transparent",
        }}
        title={<Spinner color="light" style={{ width: "3rem", height: "3rem" }} />}
        onConfirm={this.hideAlert}
        showConfirm={false}
      />
    );
  };

  handleOpenDeleteModal = () => {
    this.setState({ confirmDeleteModalOpen: true });
  };

  handleCloseDeleteModal = () => {
    this.setState({ confirmDeleteModalOpen: false });
  };

  deleteQualification = async (docId) => {
    this.setState({ isLoading: true });
    try {
      const resp = await api().delete("/qualification", {
        params: {
          id: docId,
        },
      });
      this.fetchData();
      this.setState(
        {
          isLoading: false,
          alert: (
            <SweetAlert
              success
              title="Qualification successfully deleted"
              onConfirm={this.hideAlert}
              showConfirm={true}
            />
          ),
        },
        this.handleCloseDeleteModal(),
      );
    } catch (error) {
      this.setState({ isLoading: false });
      console.log("error", error);
    }
  };

  confirmDeleteBody = () => {
    // console.log(this.state.selectedTraining List)
    return (
      <Modal
        open={this.state.confirmDeleteModalOpen}
        onClose={this.handleCloseDeleteModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        // aria-labelledby="simple-modal-title"
        // aria-describedby="simple-modal-description"
      >
        <FormGroup style={{ width: 300, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>Delete {this.state.selectedQualificationName} ?</h5>
            </div>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button onClick={() => this.handleCloseDeleteModal()}>Cancel</Button>
              <Button
                color="danger"
                onClick={() => this.deleteQualification(this.state.selectedDocId)}
              >
                Delete
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  fetchData = async (state) => {
    this.setState({ loadingTable: true });
    if (!state) {
      state = this.state.lastTableState;
    }
    this.setState({ lastTableState: state });
    console.log("state", state);

    let field = null;
    let sort = null;
    if (state.sorted.length > 0) {
      field = state.sorted[0].id;
      sort = state.sorted[0].desc ? "desc" : "asc";
    }
    const { data } = await api().get("/qualification", {
      params: {
        page: state.page + 1,
        size: state.pageSize,
        field,
        sort,
        query: this.state.tableQuery,
      },
    });
    // console.log("qualifications", data.qualifications);

    const resp = await api().get("/training", {
      params: {
        page: state.page + 1,
        size: state.pageSize,
        field,
        sort,
        query: this.state.tableQuery,
      },
    });
    // console.log("trainings list", resp.data.trainings)

    let qualifications = data.qualifications || [];

    qualifications.map((q) => {
      let tempArray = [];
      q.requiredTraining.map((t) => {
        resp.data.trainings.map((l) => {
          if (l._id === t) {
            tempArray.push(l.name);
          }
        });
      });
      //replace traininglist id array with training names to display
      q.requiredTraining.length = 0;
      q.requiredTraining.push.apply(q.requiredTraining, tempArray);
    });

    // console.log(qualifications)

    qualifications = qualifications.map((u, index) => ({
      ...u,
      actions: this.RenderActionButtons(u._id, u.name),
      trainingList: u.requiredTraining.map((t) => <p>{t}</p>),
    }));

    this.setState({
      qualificationData: qualifications,
      pages: data.noPages,
      loadingTable: false,
    });
  };

  render() {
    //<Button color="default" outline>Export All</Button>
    return (
      <>
        <div className="content">
          {this.state.isLoading ? (
            this.loadingOverlay()
          ) : (
            <Row>
              <Col md="12">
                <FormGroup>
                  <CSVLink
                    data={this.state.qualificationData}
                    headers={this.state.qualificationDataHeaders}
                    filename={"Qualifications_Data.csv"}
                  >
                    <Button color="default" outline>
                      Export All
                    </Button>
                  </CSVLink>
                </FormGroup>
                <Card className="no-transition">
                  <CardHeader>
                    <CardTitle tag="h4">All Qualifications</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div style={{ textAlign: "right" }}>
                      <FormGroup row className="align-items-right">
                        <Col sm="7"></Col>
                        <Label for="Search" sm={2} style={{ color: "black", fontSize: "14px" }}>
                          Search
                        </Label>
                        <Col sm={3}>
                          <Input
                            type="text"
                            id="Search"
                            value={this.state.tableQuery}
                            onChange={(e) =>
                              this.setState({ tableQuery: e.target.value }, () => this.fetchData())
                            }
                          />
                        </Col>
                      </FormGroup>
                    </div>
                    <ReactTable
                      columns={[
                        {
                          Header: "Qualification Name",
                          accessor: "name",
                        },
                        {
                          Header: "Training List",
                          accessor: "trainingList",
                        },

                        {
                          Header: "Actions",
                          accessor: "actions",
                          sortable: false,
                          filterable: false,
                          maxWidth: 110,
                        },
                      ]}
                      manual
                      loading={this.state.loadingTable}
                      pages={this.state.pages}
                      data={this.state.qualificationData}
                      onFetchData={this.fetchData}
                      sorted={this.state.sorted}
                      defaultPageSize={10}
                      showPaginationTop
                      showPaginationBottom
                      /*
                        You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                      */
                      className="-striped -highlight primary-pagination"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          {this.state.alert}
          {this.confirmDeleteBody()}
        </div>
      </>
    );
  }
}

export default AllQualifications;
