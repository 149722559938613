/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect, useRef, useContext } from "react";
import { NavLink } from "react-router-dom";
import { Nav, Collapse } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { useParams } from "react-router-dom";
import logo from "assets/img/HdGroupLogo.png";
import { checkAccessSideBar } from "../../utils/checkAccess";
import AgencyContext from "../../context/AgencyContext";
import { baseURL } from "../../components/API/api";
import { useSocket } from "context/SocketContext";
// import api from "components/API/api";
// import { startOfDay, startOfNextDay } from "utils/dateUtils.js";
// import { shiftTimeType } from "utils/dateUtils";
// import moment from "moment";

function Sidebar(props) {
  let agencyContext = useContext(AgencyContext);
  // const [appliedWorkersExist, setAppliedWorkersExist] = useState(false);
  // const [appointmentsExist, setAppointmentsExist] = useState(false);
  // const [appointments, setAppointments] = useState([]);
  const { newMessage, unreadMessage } = useSocket();
  let logoUrl =
    agencyContext.agency.agency &&
    agencyContext.agency.agency._id &&
    `${baseURL}/agency/${agencyContext.agency.agency._id}/logo`;
  // const [red, setRed] = useState(false);
  // const [yellow, setYellow] = useState(false);

  useEffect(() => {
    let ps;
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sideBarRef.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return () => {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  }, []);

  // to indicate any applied workers for today
  // useEffect(() => {
  //   fetchData();
  // }, []);

  // useEffect(() => {
  //   if (agencyContext?.agency?.agency?._id) fetchAllTimeSlots(new Date());
  // }, [agencyContext]);

  // const fetchAllTimeSlots = async (date) => {
  //   let { data } = await api().get("/appointment-slot/cheque-pickup", {
  //     params: {
  //       from: new Date(moment(date).startOf("day")),
  //       till: new Date(moment(date).endOf("day")),
  //       agencyId: agencyContext.agency.agency._id,
  //     },
  //   });
  //   let tempAppointments = [];
  //   data.map((d) => {
  //     if (d.noRegistered > 0) tempAppointments.push(d);
  //   });
  //   if(tempAppointments.length > 0) setAppointments(tempAppointments)

  // };

  //   useEffect(() => {
  //     if (appointments.length > 0) {
  //       let redAppointments = false; //Show only if appointments rn
  //       let yellowAppointments = false; //Show only if it has any future appointments today
  //       appointments.map((d) => {
  //         if (moment(new Date()).isBetween(moment(new Date(d.start)), moment(new Date(d.end)))) {
  //           redAppointments = true;
  //         } else if (moment(new Date()).isBefore(moment(new Date(d.start)))) {
  //           yellowAppointments = true;
  //         }
  //       });
  //       redAppointments && setRed(true)
  //       yellowAppointments && setYellow(true);
  //       if(redAppointments || yellowAppointments) setAppointmentsExist(true);
  //     }
  //   }, [appointments, new Date()])

  // console.log(appointmentsExist)
  //   const fetchData = async () => {
  //     const { data } = await api().get("/selected-for-work", {
  //       params: {
  //         status: 0, // to get only applied workers
  //         from: startOfDay(new Date()),
  //         till: startOfNextDay(new Date()),
  //       },
  //     });
  //     // console.log("data", data.selectedForWorks)
  //     if (data.selectedForWorks?.length > 0) {
  //       setAppliedWorkersExist(true);
  //     }
  //   };

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };

  // this creates the intial state of this component based on the collapse routes
  // that it gets through props.routes
  const getCollapseStates = (routes) => {
    let initialState = {
      adminPicture: null,
      adminEmail: null,
    };
    routes.map((route, key) => {
      if (route.collapse) {
        initialState = {
          [route.state]: getCollapseInitialState(route.views),
          ...getCollapseStates(route.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  const [state, setState] = useState(getCollapseStates(props.routes));

  const { slug } = useParams();
  const sideBarRef = useRef();

  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    // console.log(routes)
    return routes.map((route, key) => {
      if (route.redirect) {
        return null;
      }
      if (
        route.collapse &&
        checkAccessSideBar(
          route.module,
          route.action,
          route.access,
          agencyContext.agency &&
            agencyContext.agency.profile &&
            agencyContext.agency.profile.permissions,
          agencyContext.agency && agencyContext.agency.profile && agencyContext.agency.profile.role,
        )
      ) {
        var st = {};
        st[route["state"]] = !state[route.state];
        return (
          <li className={getCollapseInitialState(route.views) ? "active" : ""} key={key}>
            <a
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={state[route.state]}
              onClick={(e) => {
                e.preventDefault();
                setState(st);
              }}
            >
              {route.icon !== undefined ? (
                <div>
                  <i className={route.icon} />
                  <p>
                    {route.name}
                    <b className="caret" />
                  </p>
                  {/* Current Appointments Indicator */}
                  {/* {route.appointments && appointmentsExist && (
                    <div
                      style={{
                        position: "absolute",
                        top: "5px",
                        left: "3%",
                        background: red ? "red" : "yellow",
                        padding: "5px",
                        boxSizing: "border-box",
                        borderRadius: "100%",
                      }}
                    />
                  )} */}
                </div>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{route.mini}</span>
                  <span className="sidebar-normal">
                    {route.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={state[route.state]}>
              <ul className="nav">{createLinks(route.views)}</ul>
            </Collapse>
          </li>
        );
      }

      //Check if the component is accessible to the admin
      if (
        checkAccessSideBar(
          route.module,
          route.action,
          route.access,
          agencyContext.agency &&
            agencyContext.agency.profile &&
            agencyContext.agency.profile.permissions,
          agencyContext.agency && agencyContext.agency.profile && agencyContext.agency.profile.role,
        )
      ) {
        // if (route.path === "/create-new-user") {
        //   // window.open("https://hdgroup.netlify.app/hd-group/register");
        //   return (
        //     <li className={activeRoute("/" + slug + route.layout + route.path)}>
        //       <a
        //         data-toggle="collapse"
        //         href="https://hdgroup.netlify.app/hd-group/register"
        //         target="_blank"
        //       >
        //         {route.icon !== undefined ? (
        //           <>
        //             <i className={route.icon} />
        //             <p>{route.name}</p>
        //           </>
        //         ) : route.materialIcon !== undefined ? (
        //           <>
        //             <span className="sidebar-mini-icon">{route.materialIcon}</span>
        //             <span className="sidebar-normal">{route.name}</span>
        //           </>
        //         ) : (
        //           <>
        //             <span className="sidebar-mini-icon">{route.mini}</span>
        //             <span className="sidebar-normal">{route.name}</span>
        //           </>
        //         )}
        //       </a>
        //     </li>
        //   );
        // }
        return route.hidden == true ? null : (
          <li className={activeRoute("/" + slug + route.layout + route.path)} key={key}>
            <NavLink to={"/" + slug + route.layout + route.path} activeClassName="">
              {route.icon !== undefined ? (
                <>
                  <i className={route.icon} />
                  <p>{route.name}</p>
                  {/* Unread Message Indicator */}
                  {route.chatBadge && (newMessage || unreadMessage) && (
                    <div
                      style={{
                        position: "absolute",
                        top: "5px",
                        left: "3%",
                        background: "red",
                        padding: "5px",
                        boxSizing: "border-box",
                        borderRadius: "100%",
                      }}
                    />
                  )}
                </>
              ) : route.materialIcon !== undefined ? (
                <>
                  <span className="sidebar-mini-icon">{route.materialIcon}</span>
                  <span className="sidebar-normal">{route.name}</span>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{route.mini}</span>

                  {/* Hide applied workers indicator
                  {route.appliedWorkers && appliedWorkersExist && (
                    <div
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "25%",
                        background: "red",
                        padding: "5px",
                        boxSizing: "border-box",
                        borderRadius: "100%",
                      }}
                    />
                  )} */}

                  <span className="sidebar-normal">{route.name}</span>
                </>
              )}
            </NavLink>
          </li>
        );
      } else {
        return null;
      }
    });
  };
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  return (
    <div className="sidebar" data-color={props.bgColor} data-active-color={props.activeColor}>
      <div className="logo" style={{ textAlign: "center" }}>
        {/* <div className="logo" className="logo-mini">
          <img src={logoUrl || logo} alt="hd-group-logo" />
        </div> */}

        <div className="logo">
          <img src={logo} style={{ width: "120px", height: "120px" }} alt="business-logo" />
        </div>

        {/* <div className="info d-flex justify-content-center">
          <span style={{ color: "#2ba9cd", fontWeight: "bold" }}>
            {agencyContext.agency &&
              agencyContext.agency.agency &&
              agencyContext.agency.agency.name}
          </span>
        </div> */}
      </div>

      <div className="sidebar-wrapper" ref={sideBarRef}>
        <div className="user">
          {/* <div className="photo">
            <img uri={state.adminPicture} alt="" />
          </div> */}
          <div className="info">
            <a
            //  href="#pablo"
            //  data-toggle="collapse"
            ///aria-expanded={state.openAvatar}
            //  onClick={() =>
            //    setState({ openAvatar: !state.openAvatar })
            // }
            >
              <span style={{ color: "#2ba9cd" }}>{agencyContext?.agency?.user?.email}</span>
            </a>
          </div>
        </div>
        <Nav>
          {createLinks(props.routes)}
          <li>
            <a
              data-toggle="collapse"
              onClick={(e) => {
                props.onLogout();
              }}
            >
              <i className="nc-icon nc-minimal-left" />
              <p>Logout</p>
            </a>
          </li>
        </Nav>
      </div>
    </div>
  );
}

export default Sidebar;
