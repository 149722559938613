import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Button, Card, CardTitle, Form, Input, Container, Row, Col, Label } from "reactstrap";

import SweetAlert from "react-bootstrap-sweetalert";
import { Spinner as ReactSpinner } from "reactstrap";

import api from "components/API/api";
import StaffingContext from "context/StaffingContext";
// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import { useEffect } from "react";

function LoginPage(props) {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("register-page");
    document.body.classList.add("full-screen");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("register-page");
      document.body.classList.remove("full-screen");
    };
  });

  const staffingContext = useContext(StaffingContext);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [loginData, setLoginData] = useState(JSON.parse(localStorage.getItem("staffingUser")));
  const [loginResponse, setLoginResponse] = useState();

  const goToOwnerAdminPanel = () => {
    props.history.push(`/staffing/admin`);
  }
  
  const goToSuperAdminPanel = () => {
    props.history.push(`/super/admin`);
  }

  const goToAdminPanel = (slug) => {
    props.history.push(`/${slug}/auth/login`);
  }

  const selectDashboardForm = () => {
    console.log("agenciesListSome",loginData.profile.some(a => a.role === "Admin" || a.role === "Owner"))
    console.log("agenciesList",loginData.profile)
    return (
      <Row>
        <Col className="ml-auto mr-auto" lg="4" md="6" sm="6">
          <Card className="card-register no-transition">
            <CardTitle tag="h3">Select your dashboard:</CardTitle>
            {loginData.profile.some(a => a.role === "Admin") &&
              <>
                <CardTitle tag="p">Access Admin panel:</CardTitle>
                <Button block className="btn-round btn-primary" onClick={goToSuperAdminPanel}>
                  Admin panel
                </Button>
            </>
            }
            {loginData.profile.some(a => a.role === "Admin" || a.role === "Owner") &&
              <>
                <CardTitle tag="p">Access Owner panel:</CardTitle>
                <Button block className="btn-round btn-primary" onClick={goToOwnerAdminPanel}>
                  Manage Subscriptions
                </Button>
              </>
            }
            <CardTitle tag="p">Access agency panel:</CardTitle>
            {
              loginData.profile
                .filter(a => a.role === "Admin" || a.role === "Owner" || a.role === "Staff")
                .map(a => {
                  return (
                    <Button
                      block
                      className="btn-round btn-primary"
                      onClick={() => goToAdminPanel(a.agency.slug)}
                    >
                      {a.agency?.name}
                    </Button>
                  );
                })
            }
          </Card>
        </Col>
      </Row>
    )
  }

  const goToLogin = () => {
    props.history.push(`/staffing/select-agency`);
  }

  return (
    <>
      <ColorNavbar />
      <div className="wrapper">
        <div
          className="page-header"
          style={{
            backgroundImage: "url(" + require("assets/img/sections/bruno-abatti.jpg") + ")",
          }}
        >
          <div className="filter" />
          <Container>
            {loading ? (
              <div className="d-flex justify-content-center">
                <ReactSpinner className="text-center" type="border" color="secondary" size="6" />
              </div>
            ) : (selectDashboardForm())}
            <div className="demo-footer text-center mt-5" style={{ position: "relative" }}>
              <h6>
                © {new Date().getFullYear()}, made with <i className="fa fa-heart heart" /> by
                Omadatek
              </h6>
            </div>
          </Container>
        </div>
      </div>
      {alert}
    </>
  );
}

export default LoginPage;
