/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import { CSVLink } from "react-csv";
import { filterCaseInsensitive } from "components/Utilities/utilities";

import api from "../../../components/API/api";
// reactstrap components
import { Button, Card, CardHeader, CardBody, CardTitle, Row, Col, FormGroup } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import {
  Visibility,
  PersonAdd,
  Person,
  AddCircleOutline,
  CheckCircle,
  Clear,
  Check,
  PersonAddDisabled,
} from "@mui/icons-material";
import { Modal } from "@mui/material";

import * as utils from "components/Utilities/utilities.js";
import Select from "react-select";
import { stringifyTimeStamp, dateToTimeString } from "components/Utilities/utilities.js";

import LoadingOverlay from "components/OverLay/LoadingOverlay";
import AgencyContext from "./../../../context/AgencyContext";

// Bring out of class to manage it's own state.

class AllShifts extends React.Component {
  static contextType = AgencyContext;
  constructor(props) {
    super(props);

    //number of data that is loaded.
    this.numDataLoaded = 500;
    this.state = {
      notificationData: [],
      isLoading: false,
      confirmDeleteModalOpen: false,
      docId: null,
      reactTableState: {
        page: 1,
        size: 10,
        field: "createdAt",
        sort: "desc",
        filter: "",
      },
      pages: -1,
    };
  }
  // componentDidMount() {
  //   // this.notificationDataRef = fs.collection("notification_data");
  //   // this.loadFirst();
  // }

  componentWillUnmount() {
    var id = window.setTimeout(null, 0);
    while (id--) {
      window.clearTimeout(id);
    }
  }

  // New Listens to Database changes
  loadFirst = (count = 10) => {};

  //Loading Overlay
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  //save users snapshot from firestore to state
  getAllNotifications = async (params = { ...this.state.reactTableState }) => {
    try {
      this.setState({ loading: true });
      const { data } = await api().get(`/notification`, { params });
      //Looping through the business data for action button implementation
      console.log(data);
      data.notifications.map((notification) => {
        notification.createdAt = moment(notification.createdAt).format("YYYY-MM-DD hh:mm a");
        notification.actions = this.RenderActionButtons(notification._id);
      });
      this.setState({
        notificationData: data.notifications,
        pages: data.noPages,
        loading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  };

  RenderActionButtons = (docId) => {
    return (
      <div className="actions-right">
        <Button
          onClick={() => {
            this.handleOpenProfile(docId);
          }}
          color="info"
          size="md"
          className="btn-link btn-icon"
        >
          <Visibility style={{ marginLeft: -1 }} />
        </Button>{" "}
        {/* <Button
          onClick={() => {
            // Deletes data

            this.setState({
              confirmDeleteModalOpen: true,
              docId,
            });
          }}
          color="danger"
          size="md"
          className="btn-link remove btn-icon"
        >
          <Clear style={{ marginLeft: -1 }} />
        </Button>{" "} */}
      </div>
    );
  };

  deleteShift = (docId) => {
    this.notificationDataRef
      .doc(docId)
      .delete()
      .then(() => {
        // data[i].selected = true; // change to selected
        this.setState({
          alert: (
            <SweetAlert
              success
              title="Shift successfully removed"
              onConfirm={this.hideAlert}
              showConfirm={false}
            />
          ),
        });
        setTimeout(this.hideAlert, 1000);

        this.setState((prevState) => {
          let newShiftData = [...this.state.notificationData];

          //remove the recently deleted data from array
          newShiftData.splice(
            prevState.notificationData.indexOf(
              prevState.notificationData.find((data) => {
                return data.id === docId;
              }),
            ),
            1,
          );
          return {
            ...prevState,
            confirmDeleteModalOpen: false,
            notificationData: newShiftData,
            docId: null,
          };
        });
      })
      .catch((error) => {
        console.error("Error adding document to server: ", error);
        this.setState({
          alert: (
            <SweetAlert
              warning
              title="Couldn't remove the shift. Please try again later."
              onConfirm={this.hideAlert}
              showConfirm={false}
            />
          ),
        });
        setTimeout(this.hideAlert, 1000);
        this.setState({
          confirmDeleteModalOpen: false,
          docId: null,
        });
      });
  };

  deleteUserModal = () => {
    return (
      <Modal
        open={this.state.confirmDeleteModalOpen}
        onClose={() =>
          this.setState({
            confirmDeleteModalOpen: false,
            docId: null,
          })
        }
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 350, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>
                Delete {this.state.selectedFirstName} {this.state.selectedLastName}?
              </h5>
            </div>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button
                onClick={() =>
                  this.setState({
                    confirmDeleteModalOpen: false,
                    docId: null,
                  })
                }
              >
                Cancel
              </Button>
              <Button color="danger" onClick={() => this.deleteShift(this.state.docId)}>
                Delete
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  handleOpenProfile = (id) => {
    let path = `/${this.context.agency.agency.slug}/admin/viewReplies/${id}`;
    this.props.history.push(path);
  };

  render() {
    return (
      <>
        <div className="content">
          {this.state.isLoading ? (
            <LoadingOverlay />
          ) : (
            <Row style={{ fontWeight: 400 }}>
              <Col md="12">
                <FormGroup></FormGroup>
                <Card className="no-transition">
                  <CardHeader>
                    <CardTitle tag="h4">Sent Notification History</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <ReactTable
                      data={this.state.notificationData}
                      columns={[
                        {
                          Header: "Message Sent",
                          accessor: "createdAt",
                          maxWidth: 160,
                        },
                        {
                          Header: "Message",
                          accessor: "message",
                        },
                        {
                          Header: "Total Sent to",
                          accessor: "sentTo",
                          maxWidth: 150,
                        },
                        {
                          Header: "Total Reply",
                          accessor: "totalReply",
                          maxWidth: 150,
                        },
                        {
                          Header: "Sending Method",
                          accessor: "type",
                          maxWidth: 150,
                        },
                        {
                          maxWidth: 130,
                          Header: "Actions",
                          accessor: "actions",
                          sortable: false,
                          filterable: false,
                          width: 80,
                        },
                      ]}
                      pages={this.state.pages}
                      defaultPageSize={10}
                      showPaginationTop
                      showPaginationBottom
                      loading={this.state.loading}
                      /*
                      You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                    */
                      className="-striped -highlight primary-pagination"
                      manual // informs React Table that you'll be handling sorting and pagination server-side
                      onFetchData={(state, instance) => {
                        let searchParams = {
                          page: state.page + 1,
                          size: state.pageSize,
                          field: state.sorted.length ? state.sorted[0].id : "",
                          // sort: state.sorted.length && state.sorted[0].desc ? "desc" : "asc",
                          filter: state.filtered,
                        };
                        this.setState({
                          reactTableState: searchParams,
                        });
                        // console.log(searchParams);
                        // this.getAllBusiness(searchParams);
                        this.getAllNotifications(searchParams);
                      }}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {this.deleteUserModal()}
          {this.state.alert}
        </div>
      </>
    );
  }
}

export default AllShifts;
