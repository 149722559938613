import React, { useState } from "react";
import CardDetails from "./Card.jsx";
import PaymentDetails from "./PaymentDetails.jsx";

export default function Checkout(props) {
  return (
    <div style={{ background: "white" }}>
      <PaymentDetails price={props.plan && props.plan.price} />
      <CardDetails
        confirm={props.onConfirm}
      />
    </div>
  );
}
