/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useContext, useState } from "react";
// reactstrap components
import {
  Button,
  Card,
  CardTitle,
  Form,
  Input,
  Container,
  Row,
  Col,
  FormGroup,
  FormText,
} from "reactstrap";


// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";

// core components
import DashboardNavbar from "components/Navbars/DashboardNavbar.jsx";
import FooterBlack from "components/Footers/FooterBlack.js";

import ImageUpload from "components/CustomUpload/ImageUpload.js";
import api from "../../../components/API/api";

export default function AddBusiness(props) {

  const [agency, setAgency] = useState({});
  document.documentElement.classList.remove("nav-open");

  const [nameError, setNameError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [cityError, setCityError] = useState("");
  const [contactNameError, setContactNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [businessNoError, setBusinessNoError] = useState("");
  const [emailError, setEmailError] = useState("");

  const submit = async () => {
    let hasError = false;

    //Validation
    if (agency.name == null || agency.name == "") {
      // console.log(nameError);
      setNameError("* Must enter Business name");
      hasError = true;
    } else {
      setNameError("");
    }

    if (agency.address == null || agency.address == "") {
      setAddressError("* Must enter Business Address");
      hasError = true;
    } else {
      setAddressError("");
    }

    if (agency.city == null || agency.city == "") {
      setCityError("* Must enter City");
      hasError = true;
    } else {
      setCityError("");
    }
    if (agency.phone == null || agency.phone == "") {
      setPhoneError("* Must enter Business Phone");
      hasError = true;
    } else {
      setPhoneError("");
    }

    if (agency.contactName == null || agency.contactName == "") {
      setContactNameError("* Must enter Contact Name ");
      hasError = true;
    } else {
      setContactNameError("");
    }

    if (agency.businessNo == null || agency.businessNo == "") {
      setBusinessNoError("* Must enter Business Number ");
      hasError = true;
    } else {
      setBusinessNoError("");
    }

    if (agency.email == null || agency.email == "") {
      setEmailError("* Must enter Email");
      hasError = true;
    } else {
      setEmailError("");
    }

    if (hasError) {
      return;
    } else {
      const resp = await api().post("/agency", agency)
      props.history.push("/staffing/admin")
    }
  }

  return (
    <>
      <DashboardNavbar />
      <div className="main">
        <div className="section">
          <Container style={{ marginTop: "50px" }}>
            <h3>Register a new business</h3>
            <Row>
              <Col md="5" sm="5">
              </Col>
              <Col md="7" sm="7">
                <FormGroup>
                  <h6>
                    Business Name <span className="icon-danger">*</span>
                  </h6>
                  <Input
                    className="border-input"
                    placeholder=""
                    type="text"
                    value={agency.name}
                    onChange={(e) => {
                      setAgency({ ...agency, name: e.target.value });
                    }} />
                  <FormText color="danger" tag="span">
                    {nameError}
                    {/* {console.log("Span Error: ", nameError)} */}
                  </FormText>
                </FormGroup>
                <FormGroup>
                  <h6>
                    Address <span className="icon-danger">*</span>
                  </h6>
                  <Input
                    className="border-input"
                    placeholder="" type="text"
                    value={agency.address}
                    onChange={(e) => {
                      setAgency({ ...agency, address: e.target.value });
                    }} />
                  <FormText color="danger" tag="span">
                    {addressError}
                  </FormText>
                </FormGroup>
                <FormGroup>
                  <h6>
                    City <span className="icon-danger">*</span>
                  </h6>
                  <Input
                    className="border-input"
                    placeholder="" type="text"
                    value={agency.city}
                    onChange={(e) => {
                      setAgency({ ...agency, city: e.target.value });
                    }} />
                  <FormText color="danger" tag="span">
                    {cityError}
                  </FormText>
                </FormGroup>
                <FormGroup>
                  <h6>
                    No of Employees <span className="icon-danger"></span>
                  </h6>
                  <Input
                    className="border-input"
                    placeholder="" type="number"
                    value={agency.employees}
                    onChange={(e) => {
                      setAgency({ ...agency, employees: e.target.value });
                    }} />
                </FormGroup>
                <FormGroup>
                  <h6>
                    Business Phone <span className="icon-danger">*</span>
                  </h6>
                  <Input
                    className="border-input"
                    placeholder="" type="text"
                    value={agency.phone}
                    onChange={(e) => {
                      setAgency({ ...agency, phone: e.target.value });
                    }} />
                  <FormText color="danger" tag="span">
                    {phoneError}
                  </FormText>
                </FormGroup>
                <FormGroup>
                  <h6>
                    Business Number <span className="icon-danger">*</span>
                  </h6>
                  <Input
                    className="border-input"
                    placeholder="" type="number"
                    value={agency.businessNo}
                    onChange={(e) => {
                      setAgency({ ...agency, businessNo: e.target.value });
                    }} />
                  <FormText color="danger" tag="span">
                    {businessNoError}
                  </FormText>
                </FormGroup>
                <FormGroup>
                  <h6>
                    Contact name <span className="icon-danger">*</span>
                  </h6>
                  <Input
                    className="border-input"
                    placeholder="" type="text"
                    value={agency.contactName}
                    onChange={(e) => {
                      setAgency({ ...agency, contactName: e.target.value });
                    }} />
                  <FormText color="danger" tag="span">
                    {contactNameError}
                  </FormText>
                </FormGroup>
                <FormGroup>
                  <h6>
                    Business Email <span className="icon-danger">*</span>
                  </h6>
                  <Input
                    className="border-input"
                    placeholder="" type="email"
                    value={agency.email}
                    onChange={(e) => {
                      setAgency({ ...agency, email: e.target.value });
                    }} />
                  <FormText color="danger" tag="span">
                    {emailError}
                  </FormText>
                </FormGroup>
                <Row>
                  <Col md="6">
                    <Button
                      block
                      className="btn-round"
                      color="primary"
                      outline
                      type="submit"
                      size="md"
                      onClick={submit}
                    >
                      Continue
                      </Button>
                  </Col>
                  <Col md="6">
                    <Button
                      block
                      className="btn-round"
                      color="danger"
                      outline
                      type="reset"
                      size="md"
                      onClick={() => props.history.goBack()}
                    >
                      Cancel
                      </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}
