import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import ReactDatetime from "react-datetime";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";

export const RECURRING_DAYS_ARRAY = [
  {
    // 0: Monday
    active: false,
    day: 0,
    start: null,
    end: null,
  },
  {
    active: false,
    day: 1,
    start: null,
    end: null,
  },
  {
    active: false,
    day: 2,
    start: null,
    end: null,
  },
  {
    active: false,
    day: 3,
    start: null,
    end: null,
  },
  {
    active: false,
    day: 4,
    start: null,
    end: null,
  },
  {
    active: false,
    day: 5,
    start: null,
    end: null,
  },
  {
    // 6: Sunday
    active: false,
    day: 6,
    start: null,
    end: null,
  },
];

const RecurringDays = ({ onChange, availability }) => {
  const [_availability, setAvailability] = useState([...RECURRING_DAYS_ARRAY]);
  const [isSameStart, setIsSameStart] = useState(false);
  const [isSameEnd, setIsSameEnd] = useState(false);
  const toggleCheckMark = (day) => {
    setAvailability((prevAvailability) => {
      return prevAvailability.map((a, i) => {
        let _a = prevAvailability.find((a) => a.active && a.start);
        let _aEnd = prevAvailability.find((a) => a.active && a.end);

        a.active = i === day ? !a.active : a.active;
        if (i === day && _a && a.active && isSameStart) {
          a.start = _a.start;
        }
        if (i === day && _aEnd && a.active && isSameEnd) {
          a.end = _aEnd.end;
        }
        return a;
      });
    });
  };

  useEffect(() => {
    onChange(_availability);
  }, [_availability]);

  //   const isSameAvailability = (a1, a2) => {
  //     if (a1.length != a2.length) {
  //       return false;
  //     }

  //     for (let i = 0; i < 7; i++) {
  //       if (
  //         a1[i].day !== a2[i].day ||
  //         a1[i].active !== a2[i].active ||
  //         a1[i].start !== a2[i].start ||
  //         a1[i].end !== a2[i].end
  //       ) {
  //         return false;
  //       }
  //     }
  //     return true;
  //   };

  useEffect(() => {
    // if (!isSameAvailability(_availability, availability)) {
    availability?.length && setAvailability(availability);
    // }
    // console.log(_availability, availability);
    // console.log(isSameAvailability(_availability, availability));
  }, [availability]);

  const _renderAvailability = (dayOfWeekText, day) => {
    return (
      <Row>
        <Col sm="2">
          <FormGroup check inline>
            <Label check>
              <Input
                type="checkbox"
                onChange={() => toggleCheckMark(day)}
                checked={
                  _availability[day].active
                  // true
                }
                id={dayOfWeekText === "Monday" ? "availability" : null}
              />
              <span className="form-check-sign" />
              {dayOfWeekText}
            </Label>
          </FormGroup>
        </Col>

        {_availability[day].active ? (
          <Col sm="8">
            <FormGroup check inline>
              <Label check>
                {/* <Input
                      type="checkbox"
                      checked={_availability[day].m}
                      onChange={() => toggleShifts("m", day)}
                    />
                    <span className="form-check-sign" /> */}
                Start
                <ReactDatetime
                  // initialValue={_availability[day].start}
                  value={_availability[day].start}
                  onChange={(date) => {
                    setAvailability((prevAvailability) => {
                      return prevAvailability.map((a, i) => {
                        a.start = !isSameStart ? (i === day ? date : a.start) : date;
                        return a;
                      });
                    });
                  }}
                  dateFormat={false}
                />
              </Label>
            </FormGroup>

            <FormGroup check inline>
              <Label check>
                {/* <Input
                      type="checkbox"
                      checked={_availability[day].a}
                      onChange={() => toggleShifts("a", day)}
                    />
                    <span className="form-check-sign" /> */}
                End
                <ReactDatetime
                  value={_availability[day].end}
                  onChange={(date) => {
                    setAvailability((prevAvailability) => {
                      return prevAvailability.map((a, i) => {
                        a.end = !isSameEnd ? (i === day ? date : a.end) : date;
                        return a;
                      });
                    });
                  }}
                  dateFormat={false}
                />
              </Label>
            </FormGroup>
          </Col>
        ) : null}
      </Row>
    );
  };
  return (
    <div className="checkbox-radios">
      <Row>
        <Col sm="2">
          <FormGroup check inline>
            <Label check>
              <Input
                type="checkbox"
                onChange={() =>
                  setAvailability((prevAvailability) => {
                    let fullyAvailable = prevAvailability.filter((a) => a.active).length >= 7;
                    let _a1 = prevAvailability.find((a) => a.active && a.start);
                    let _a2 = prevAvailability.find((a) => a.active && a.end);
                    return prevAvailability.map((a) => {
                      if (fullyAvailable) {
                        a.active = false;
                      } else {
                        a.active = true;
                        if (isSameStart && _a1) a.start = _a1.start;

                        if (isSameEnd && _a2) a.end = _a2.end;
                      }

                      return a;
                    });
                  })
                }
                checked={_availability.filter((a) => a.active).length >= 7}
                id="allAvailability"
              />
              <span className="form-check-sign" />
              All
            </Label>
          </FormGroup>
        </Col>
        <Col sm="2">
          <FormGroup check inline>
            <Label check>
              <Input
                type="checkbox"
                onChange={() => {
                  let sameTime = !isSameStart;
                  sameTime &&
                    setAvailability((prevAvailability) => {
                      //finding start and end time for all other active shifts
                      let _a = prevAvailability.find((a) => a.active && a.start);

                      return prevAvailability.map((a) => {
                        if (a.active && _a) {
                          a.start = _a.start;
                        }

                        return a;
                      });
                    });
                  setIsSameStart(sameTime);
                }}
                checked={isSameStart}
                id="sameStart"
              />
              <span className="form-check-sign" />
              Same Start Time
            </Label>
          </FormGroup>
        </Col>
        <Col sm="2">
          <FormGroup check inline>
            <Label check>
              <Input
                type="checkbox"
                onChange={() => {
                  let sameTime = !isSameEnd;
                  sameTime &&
                    setAvailability((prevAvailability) => {
                      let _aEnd = prevAvailability.find((a) => a.active && a.end);
                      return prevAvailability.map((a) => {
                        if (a.active && _aEnd) {
                          a.end = _aEnd.end;
                        }
                        return a;
                      });
                    });
                  setIsSameEnd(sameTime);
                }}
                checked={isSameEnd}
                id="sameEnd"
              />
              <span className="form-check-sign" />
              Same End Time
            </Label>
          </FormGroup>
        </Col>
      </Row>

      {_renderAvailability("Monday", 0)}
      {_renderAvailability("Tuesday", 1)}
      {_renderAvailability("Wednesday", 2)}
      {_renderAvailability("Thursday", 3)}
      {_renderAvailability("Friday", 4)}
      {_renderAvailability("Saturday", 5)}
      {_renderAvailability("Sunday", 6)}
    </div>
  );
};

export default RecurringDays;

RecurringDays.propTypes = {
  onChange: PropTypes.func,
  availability: PropTypes.arrayOf(RECURRING_DAYS_ARRAY),
};
