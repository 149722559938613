/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import { CSVLink } from "react-csv";
import { filterCaseInsensitive } from "components/Utilities/utilities";
// reactstrap components
import { Button, Card, CardHeader, CardBody, CardTitle, Row, Col, FormGroup } from "reactstrap";
import { Redirect, Link } from "react-router-dom";
import { Visibility, PersonAdd, AddCircleOutline, Clear } from "@mui/icons-material";
import { Modal } from "@mui/material";

import api from "../../../components/API/api";
import { data } from "jquery";
//react component for searching the existing businesses
import AsyncSelect from "react-select/async";
import AgencyContext from "./../../../context/AgencyContext";
import Permission from "components/permissions/Permission";

class AllBusinesses extends React.Component {
  static contextType = AgencyContext;
  constructor(props) {
    super(props);
    this.state = {
      businessData: [],
      confirmDeleteModalOpen: false,
      confirmSearchModalOpen: false,

      initialReactSelectBusinessData: [],
      //search bar of adding business
      selectedBusiness: null,
      addingBusinessError: "",
      selectedBId: null,
      reactTableState: {
        page: 1,
        size: 10,
        field: "name",
        sort: "asc",
        filter: "",
      },
      pages: -1,
      loading: false,
    };
  }

  componentDidMount() {
    this.getAllBusiness();
  }

  getAllBusiness = async (filterOptions = this.state.reactTableState) => {
    try {
      this.setState({ loading: true });
      const { data } = await api().get(`/business/associated`);
      //Looping through the business data for action button implementation
      data.businesses.map((business) => {
        business.actions = this.RenderActionButtons(business._id);
      });
      this.setState({
        businessData: data.businesses,
        pages: data.noPages,
        loading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  };

  RenderActionButtons = (bId) => {
    return (
      <div className="actions-right">
        <Button
          tag={Link}
          to={`/${this.context.agency.agency.slug}/admin/business-profile/${bId}`}
          color="info"
          size="md"
          className="btn-link btn-icon"
        >
          <Visibility style={{ marginLeft: -1 }} />
        </Button>
        <Permission module="Business" action="Delete">
          <Button
            onClick={() => {
              this.setState(
                {
                  selectedBId: bId,
                },
                this.handleOpenDeleteModal(),
              );
            }}
            color="danger"
            size="md"
            className="btn-link remove btn-icon"
          >
            <Clear style={{ marginLeft: -1 }} />
          </Button>
        </Permission>
      </div>
    );
  };

  handleOpenCreateNewBusiness = () => {
    let path = `/${this.context.agency.agency.slug}/admin/create-new-business`;
    this.props.history.push(path);
  };

  addBusinessToAgency = () => {
    //do some validations

    if (this.state.selectedBusiness === null) {
      this.setState({ addingBusinessError: "Please select a business before adding." });
      return;
    }

    if (
      this.state.businessData.some((business) => this.state.selectedBusiness._id === business._id)
    ) {
      this.setState({ addingBusinessError: "You have already added this business." });
      return;
    }
    api()
      .patch("/agency", {
        businesses: [this.state.selectedBusiness._id],
        _id: this.context.agency.agency && this.context.agency.agency._id,
      })
      .then(() => {
        console.log("successfully added business");
        //refresh the page
        this.getAllBusiness();
      })
      .catch((e) => {
        console.log("Error adding business");
      });

    this.setState({ confirmSearchModalOpen: false });
  };

  handleOpenDeleteModal = () => {
    this.setState({ confirmDeleteModalOpen: true });
  };

  handleCloseDeleteModal = () => {
    this.setState({ confirmDeleteModalOpen: false });
  };

  removeBusinessFromAgency = (businessId) => {
    console.log("Start Delete for : ", businessId);
    api()
      .delete(`/business`, { params: { id: businessId } })
      .then(() => {
        console.log("successfully removed");
        this.getAllBusiness();

        this.setState({ confirmDeleteModalOpen: false });
      })
      .catch((e) => {
        console.log(e);
        this.setState({ confirmDeleteModalOpen: false });
      });
  };

  confirmDeleteBody = () => {
    return (
      <FormGroup style={{ width: 300, backgroundColor: "#fff", borderRadius: 10 }}>
        <div style={{ padding: 15 }}>
          <div style={{ justifyContent: "center", display: "flex" }}>
            <h5>Delete {this.state.selectedBusinessName}?</h5>
          </div>
          <div style={{ justifyContent: "space-between", display: "flex" }}>
            <Button onClick={() => this.handleCloseDeleteModal()}>Cancel</Button>
            <Button
              color="danger"
              onClick={() => this.removeBusinessFromAgency(this.state.selectedBId)}
            >
              Delete
            </Button>
          </div>
        </div>
      </FormGroup>
    );
  };

  addBusinessBody = (bId, businessName) => {
    return (
      <FormGroup
        style={{
          width: 500,
          backgroundColor: "#fff",
          borderRadius: 10,
        }}
      >
        <div style={{ padding: 25 }}>
          <div style={{ justifyContent: "center", display: "flex" }}>
            <div style={{ width: "400px" }}>
              <AsyncSelect
                cacheOptions
                defaultOptions={this.state.initialReactSelectBusinessData}
                loadOptions={this.searchPromise}
                onChange={(value) =>
                  this.setState({ selectedBusiness: value, addingBusinessError: "" })
                }
                placeholder="Search by Business name, address or city..."
              />
              <p style={{ color: "#800000" }}>{this.state.addingBusinessError}</p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "50%",
              justifyContent: "center",
              alignContent: "center",
              marginLeft: "120px",
            }}
          >
            <Button onClick={this.addBusinessToAgency}>Add Business</Button>
            <Button onClick={this.handleOpenCreateNewBusiness}>Create New Business</Button>
            <Button onClick={() => this.handleCloseSearchModal()}>Cancel</Button>
          </div>
        </div>
      </FormGroup>
    );
  };

  render() {
    return (
      <div className="content">
          <Row>
            <Col md="12">
              <FormGroup>
                <CSVLink
                  data={this.state.businessData}
                  headers={this.state.businessDataHeaders}
                  filename={"Business_Data.csv"}
                >
                  <Button color="default" outline>
                    Export All
                  </Button>
                </CSVLink>
              </FormGroup>
              <Card className="no-transition">
                <CardHeader>
                  <CardTitle tag="h4">All Businesses</CardTitle>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={this.state.businessData}
                    filterable
                    defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}
                    columns={[
                      {
                        Header: "Business Name",
                        accessor: "name",
                      },
                      {
                        Header: "Business City",
                        accessor: "city",
                      },
                      {
                        Header: "Business Address",
                        accessor: "address",
                      },
                      {
                        Header: "Contact Name",
                        accessor: "contactName",
                      },
                      {
                        Header: "Contact Email",
                        accessor: "email",
                      },
                      {
                        Header: "Contact Phone",
                        accessor: "phone",
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                        width: 105,
                      },
                    ]}
                    pages={this.state.pages}
                    defaultPageSize={50}
                    showPaginationTop
                    showPaginationBottom
                    loading={this.state.loading}
                    className="-striped -highlight primary-pagination"
                    // manual // informs React Table that you'll be handling sorting and pagination server-side
                    // onFetchData={(state, instance) => {
                    //   let searchParams = {
                    //     page: state.page + 1,
                    //     size: state.pageSize,
                    //     field: state.sorted.length ? state.sorted[0].id : "",
                    //     sort: state.sorted.length && state.sorted[0].desc ? "desc" : "asc",
                    //     filter: state.filtered,
                    //   };
                    //   this.setState({
                    //     reactTableState: searchParams,
                    //   });
                    //   this.getAllBusiness(searchParams);
                    // }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            open={this.state.confirmDeleteModalOpen}
            onClose={this.handleCloseDeleteModal}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {this.confirmDeleteBody()}
          </Modal>
      </div>
    );
  }
}

export default AllBusinesses;
