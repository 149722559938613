import useFetchBusiness from "hooks/useFetchBusiness";
import React, { useEffect, useState } from "react";
import Nav from "reactstrap/lib/Nav";
import NavItem from "reactstrap/lib/NavItem";
import NavLink from "reactstrap/lib/NavLink";

const BusinessTab = ({ onSelect }) => {
  //   const [business, setBusiness] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const businesses = useFetchBusiness();
  useEffect(() => {
    onSelect(selectedBusiness);
  }, [selectedBusiness]);

  return (
    <div className="d-flex justify-content-center" style={{ marginTop: 3 }}>
      <Nav
        className="nav-pills-primary nav-pills-icons justify-content-center"
        pills
        role="tablist"
      >
        <NavItem>
          <NavLink
            data-toggle="tab"
            role="tablist"
            className={selectedBusiness === null ? "active" : ""}
            onClick={() => setSelectedBusiness(null)}
          >
            <i className="now-ui-icons objects_umbrella-13" />
            All
          </NavLink>
        </NavItem>
        {businesses.map((business) => {
          return (
            <NavItem key={business.value}>
              <NavLink
                data-toggle="tab"
                role="tablist"
                className={selectedBusiness?.value === business?.value ? "active" : ""}
                onClick={() => setSelectedBusiness(business)}
              >
                <i className="now-ui-icons objects_umbrella-13" />
                {business.label}
              </NavLink>
            </NavItem>
          );
        })}
      </Nav>
    </div>
  );
};

export default BusinessTab;
