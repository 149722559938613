/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect, useRef, useContext } from "react";
import { NavLink } from "react-router-dom";
import { Nav, Collapse } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { useParams } from "react-router-dom";
import logo from "assets/img/scout-logo1.png";
import { checkAccessSideBar } from "../../utils/checkAccess";
import AgencyContext from "../../context/AgencyContext";
import { baseURL } from "../API/api";
import StaffingContext from "../../context/StaffingContext";
import { Link } from "react-router-dom";

function SidebarBusiness(props) {
  let staffingContext = useContext(StaffingContext);
  const sideBarRef = useRef();

  useEffect(() => {}, []);

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms

  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };

  // this creates the intial state of this component based on the collapse routes
  // that it gets through props.routes
  const getCollapseStates = (routes) => {
    let initialState = {
      adminPicture: null,
      adminEmail: null,
    };
    routes.map((route, key) => {
      if (route.collapse) {
        initialState = {
          [route.state]: getCollapseInitialState(route.views),
          ...getCollapseStates(route.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };

  const [state, setState] = useState(getCollapseStates(props.routes));

  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    return routes.map((route, key) => {
      if (route.redirect) {
        return null;
      }
      if (route.collapse) {
        var st = {};
        st[route["state"]] = !state[route.state];
        return (
          <li className={getCollapseInitialState(route.views) ? "active" : ""} key={key}>
            <a
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={state[route.state]}
              onClick={(e) => {
                e.preventDefault();
                setState(st);
              }}
            >
              {route.icon !== undefined ? (
                <>
                  <i className={route.icon} />
                  <p>
                    {route.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{route.mini}</span>
                  <span className="sidebar-normal">
                    {route.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={state[route.state]}>
              <ul className="nav">{createLinks(route.views)}</ul>
            </Collapse>
          </li>
        );
      }

      //Check if the component is accessible to the admin
      if (true) {
        return route.hidden == true ? null : (
          <li className={activeRoute(route.layout + route.path)} key={key}>
            <NavLink to={"/super/admin" + route.path} activeClassName="active">
              {route.icon !== undefined ? (
                <>
                  <i className={route.icon} />
                  <p>{route.name}</p>
                </>
              ) : route.materialIcon !== undefined ? (
                <>
                  <span className="sidebar-mini-icon">{route.materialIcon}</span>
                  <span className="sidebar-normal">{route.name}</span>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{route.mini}</span>
                  <span className="sidebar-normal">{route.name}</span>
                </>
              )}
            </NavLink>
          </li>
        );
      } else {
        return null;
      }
    });
  };

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    try {
      return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
    } catch (error) {
      return null;
    }
  };

  return (
    <div className="sidebar" data-color={props.bgColor} data-active-color={props.activeColor}>
      <div className="sidebar-wrapper" ref={sideBarRef}>
        <div className="logo">
          <div className="logo" className="logo-mini">
            <img src={logo} style={{ width: "200px", height: "80px" }} alt="business-logo" />
          </div>
        </div>
        <Nav>{createLinks(props.routes)}</Nav>
      </div>
    </div>
  );
}

export default SidebarBusiness;
