export const genderOptions = [
  { label: "Female", value: "F" },
  { label: "Male", value: "M" },
];

export const legalToWorkOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const maskedPostalCode = [/[A-Za-z]/, /\d/, /[A-Za-z]/, /\d/, /[A-Za-z]/, /\d/];

// For opening specific modal
export const USER_PROFILE_MODULE_TYPE = {
  DELETE_USER: "deleteUserModal",
  PERMANENT_DELETE_USER: "permanentDeleteModal",
  BLOCK_USER: "blockModal",
  UNBLOCK_USER: "unBlockModal",
  REACTIVATE_USER: "reactivateModal",
  WARN_USER: "warnModal",
};

// For rendering the nav bar of user profile
export const tabList = [
  { name: "Edit Profile", id: "editProfile" },
  { name: "Shift History", id: "shiftHistory" },
  { name: "Training History", id: "trainingHistory" },
  { name: "Pay History", id: "payHistory" },
  { name: "Appointments", id: "appointments" },
  { name: "Driver Ride History", id: "rideHistory" },
  { name: "Images and Files", id: "images" },
  { name: "Comments", id: "comments" },
  { name: "Chat", id: "chat" },
  // { name: "Change Log", id: "changeLog" },
];

export const userProfileSpinner = { margin: "300px auto", width: "3rem", height: "3rem" };
