import api from "components/API/api";

/**
 * Description: Currently handling user profile comment only, to be integrated with other handleAddComment
 * @param {STRING} userParamId {user id from url}
 * @returns Success / failed to add comment response
 */
export const handleAddComment = ({
  userParamId, agencyContext,
  addComment, setAddComment,
  error, setError,
  commentHistory, setCommentHistory,
  setIsLoading 
}) => {

  if (addComment === "" || addComment === null) {
    setError({ ...error, addComment: "Please enter comment" });
    return;
  }

  setIsLoading(true);
  api()
    .post(`/user-note`, {
    user: userParamId,
    commentField: "User",
    commentFieldId: userParamId,
    note: addComment,
    })
    .then((res) => {
    let tempCommentHistory = commentHistory;
    let tempDate = res.data.createdAt.split(".")[0];
    let newComment = {
        note: addComment,
        noteBy: agencyContext.agency.user.firstName + " " + agencyContext.agency.user.lastName,
        time: tempDate.split("T")[0] + " " + tempDate.split("T")[1],
    };
    tempCommentHistory.push(newComment);

    setAddComment("");
    setIsLoading(false);
    setCommentHistory(tempCommentHistory);
    })
    .catch((err) => console.log("Failed to handle add Comment", err));
  }
  